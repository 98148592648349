import React from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import type { ThemePaletteType } from "../../theme";
import Theme from "./Theme";

type ThemeFromLocalStorageProps = React.PropsWithChildren<{}>;

const THEME_KEY = "theme";

export function ThemeFromLocalStorage({ children }: ThemeFromLocalStorageProps) {
    const browserPreferredPalette = useBrowserPreferredPalette("light");
    const [palette, setPalette] = useLocalStorage<ThemePaletteType>(THEME_KEY, browserPreferredPalette);

    return (
        <Theme palette={palette} onChangePalette={setPalette}>
            {children}
        </Theme>
    );
}

function useBrowserPreferredPalette(fallback: ThemePaletteType): ThemePaletteType {
    if (!window.matchMedia) {
        return fallback;
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
    } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
        return "light";
    } else {
        return fallback;
    }
}
