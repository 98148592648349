/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { sortBy } from "lodash";
import * as React from "react";
import DebounceValue from "~/components/DebounceValue";
import IconButton from "~/components/IconButton";
import { Icon } from "~/components/IconButton/IconButton";
import UseLabelStrategy from "~/components/LabelStrategy/LabelStrategy";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { Item } from "~/components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import VirtualListWithKeyboard from "~/components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import type { FormFieldProps } from "~/components/form";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import type { TextInput } from "~/primitiveComponents/form/Text/Text";
import Text from "~/primitiveComponents/form/Text/Text";
import ToolTip from "../../primitiveComponents/dataDisplay/ToolTip";
import type { KeyedItemProps, NameOrIdKey } from "../KeyAccessProvider/types";
import Markdown from "../Markdown";
import styles from "./MultiSelect.module.less";

interface MultiSelectProps<T extends SelectItem> extends FormFieldProps<string[]>, Partial<KeyedItemProps> {
    value: string[];
    label?: string | JSX.Element;
    placeholder?: string;
    description?: string;
    items: T[];
    error?: string;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    hideFloatingLabel?: boolean;
    addNewTemplate?: (text: string) => string;
    renderItem?: (item: T) => Item;
    renderChip: (item: T | SelectItem, onRequestDelete: () => void) => JSX.Element;
    onNew?: (text: string) => void;
    onRemove?: (text: string) => void;
    multiSelectRef?(multiSelect: FocusableComponent | null): void;
    accessibleName?: string;
    disabled?: boolean;
    helperText?: string;
    sortItems?: boolean;
}

interface MultiSelectState {
    filter: string;
    open: boolean;
    isFocused: boolean;
}

const DebounceText = DebounceValue(Text);

export function MultiSelect<T extends SelectItem>() {
    const VirtualList = VirtualListWithKeyboard<T>();

    //eslint-disable-next-line react/no-unsafe
    class MultiSelectInternal extends React.Component<MultiSelectProps<T>, MultiSelectState> {
        get itemKey(): NameOrIdKey {
            return this.props.itemKey ?? "Id";
        }

        static defaultProps: Partial<MultiSelectProps<T>> = {
            addNewTemplate: (text) => `${text} (add new)`,
            multiSelectRef: (multiSelect) => {
                /* Do nothing */
            },
            renderItem: (item: T) => ({ primaryText: item.Name }),
            items: [],
            value: [],
        };

        private updatePopoverPosition: () => void = undefined!;
        private textField: TextInput | undefined;
        private textAnchor: HTMLDivElement | null = null;
        private uniqueId: string = undefined!;
        private timeoutId: ReturnType<typeof setTimeout> = undefined!;
        private virtualList: FocusableComponent | null = undefined!;
        private skipOpenningOnNextFocus: boolean = false;

        constructor(props: MultiSelectProps<T>) {
            super(props);
            this.state = {
                filter: "",
                open: false,
                isFocused: false,
            };
        }

        focus() {
            this.focusText();
        }

        componentDidMount() {
            if (this.props.multiSelectRef) {
                this.props.multiSelectRef(this);
            }
        }

        componentWillUnmount() {
            if (this.props.multiSelectRef) {
                this.props.multiSelectRef(null);
            }
        }

        UNSAFE_componentWillMount() {
            const uniqueId = `MultiSelect-${Math.floor(Math.random() * 0xffff)}`;
            this.uniqueId = uniqueId.replace(/[^A-Za-z0-9-]/gi, "");
        }

        render() {
            const { sortItems = true } = this.props;
            const errorTextElement = this.props.error && <div className={styles.error}>{this.props.error}</div>;

            const value = this.props.value || [];
            const items = sortItems ? sortBy(this.props.items || [], (x) => x.Name.toLowerCase()) : this.props.items || [];

            let newItem: SelectItem | null = null;
            const trimmedFilter = this.state.filter.trim();
            if (trimmedFilter.length > 0 && this.props.onNew) {
                // TODO: The case insensitive comparison here is specific to roles
                // (which is the only use of `newItem` at the moment) and probably shouldn't be hard-coded here.
                // Either toggle this behavior from a prop, or extract it from this component entirely
                const val = trimmedFilter.toLowerCase();

                if (items.find((i) => i.Name.toLowerCase() === val) === undefined && !value.find((i) => i && i.toLowerCase() === val)) {
                    newItem = { Id: "", Name: trimmedFilter };
                }
            }

            const filteredList = this.filteredList(items, value, trimmedFilter);
            if (newItem) {
                filteredList.unshift(newItem as T);
            }

            if (this.state.open) {
                this.updatePopoverPositionWorkaround();
            }

            let label = this.props.label;
            if (this.props.description) {
                label = <ToolTip content={<Markdown markup={this.props.description} />}>{this.props.label}</ToolTip>;
            }

            const getNamesForSelectedValues = (values: string[]) => {
                const selectedItems = this.props.items.filter((i) => values.includes(i[this.itemKey]));
                return JSON.stringify(selectedItems.map((item) => item.Name));
            };

            return (
                <div role="combobox" aria-label={this.props.accessibleName}>
                    {/* Added relative positioning to fix the flexbox layout issue caused by the absolute-positioned hidden control below: https://github.com/OctopusDeploy/Issues/issues/6811 */}
                    <div className={styles.visuallyHiddenContainer}>
                        {value.map(this.renderChip)}
                        {/* Hidden input that has the all the selected items as it's value. This helps us make this component more accessible and aids with tests.*/}
                        <input type="text" readOnly className={styles.visuallyHidden} aria-label={`Selected multiselect values: ${this.props.accessibleName}`} value={getNamesForSelectedValues(value)} tabIndex={-1} />
                    </div>
                    <div ref={(el) => (this.textAnchor = el)} className={styles.textContainer}>
                        <DebounceText
                            id={this.uniqueId}
                            textInputRef={(textField: TextInput) => (this.textField = textField)}
                            label={label}
                            placeholder={this.props.placeholder}
                            value={this.state.filter}
                            autoFocus={this.props.autoFocus}
                            onKeyDown={(e) => this.onTextKeyDown(e, filteredList)}
                            onChange={this.onSearchTextChange}
                            onFocus={this.onTextFocus}
                            onBlur={this.onTextBlur}
                            onClick={this.onClick}
                            debounceDelay={100} // Need this more responsive when adding new entries into a multi-select (I.e. roles).
                            generateUniqueName={true} // To stop browser's autocomplete shenanigans by force (when our autocomplete settings do not work).
                            disabled={this.props.disabled}
                            helperText={this.props.helperText}
                        />
                        <div className={styles.iconContainer}>
                            <IconButton icon={Icon.ArrowDown} onClick={this.onToggle} tabIndex={-1} accessibleName="ToggleDropDown" disabled={this.props.disabled} />
                        </div>
                    </div>
                    {errorTextElement}
                    <Popover
                        open={this.state.open}
                        disableAutoFocus={true}
                        disableEnforceFocus={true} // fixes an issue where debounce text's focus is lost to popover
                        anchorEl={this.textAnchor}
                        onClose={() => this.onRequestClose()}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        getUpdatePosition={(update) => (this.updatePopoverPosition = update)}
                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                    >
                        <div style={{ minWidth: this.textAnchor ? `${this.textAnchor.offsetWidth}px` : undefined }} onKeyDown={this.onKeyEsc}>
                            <VirtualList
                                multiSelectRef={(el) => (this.virtualList = el)}
                                items={filteredList}
                                onSelected={(id) => {
                                    this.onRequestClose(() => this.focusText(true));
                                    const match = items.find((i) => i.Id === id);

                                    if (match) {
                                        if (this.props.onChange) {
                                            this.props.onChange(value.concat(match[this.itemKey]));
                                        }
                                    } else {
                                        if (this.props.onNew && newItem) {
                                            this.props.onNew(newItem.Name);
                                        }
                                    }
                                }}
                                onResized={() => {
                                    // When the content's size changes, we re-render so that the
                                    // popover can re-position itself based on the new `VirtualList` size
                                    // if (this.updatePopoverPosition) {
                                    //     this.updatePopoverPosition();
                                    // }
                                    this.updatePopoverPositionWorkaround();
                                }}
                                renderItem={this.props.renderItem}
                                addNewTemplate={() => {
                                    if (this.props.addNewTemplate) {
                                        return this.props.addNewTemplate(newItem!.Name);
                                    }
                                    return null;
                                }}
                                onBlur={() => this.textField?.focus()}
                            />
                        </div>
                    </Popover>
                </div>
            );
        }

        private onTextBlur = () => {
            // We using a timout here to stop the flickering, see https://medium.com/@jessebeach/dealing-with-focus-and-blur-in-a-composite-widget-in-react-90d3c3b49a9b
            this.timeoutId = setTimeout(() => {
                if (this.state.isFocused) {
                    this.setState({
                        isFocused: false,
                    });
                }
            }, 0);
        };

        private onClick = () => {
            if (!this.props.disabled) {
                this.setState({ open: true });
            }
        };

        private onTextFocus = () => {
            clearTimeout(this.timeoutId);

            if (!this.state.isFocused) {
                this.setState({ isFocused: true });
            }

            if (!this.skipOpenningOnNextFocus) {
                this.setState({
                    open: this.props.openOnFocus !== undefined ? this.props.openOnFocus : false,
                });
            }

            this.skipOpenningOnNextFocus = false;
        };

        private focusText = (skipOpenningOnNextFocus?: boolean) => {
            this.skipOpenningOnNextFocus = !!skipOpenningOnNextFocus;

            if (this.textField) {
                this.textField.focus();
            }
        };

        private onTextKeyDown = (event: KeyboardEvent, filteredList: SelectItem[]) => {
            if (event.key === "ArrowDown") {
                this.setState({
                    open: true,
                });

                if (this.state.open) {
                    this.virtualList?.focus();
                }

                event.preventDefault();
            }

            if (event.key === "Tab") {
                this.onRequestClose();
            }

            // To stop ppl accidentally triggering deployments when using multi-selects in deployment screens!
            if (event.key === "Enter") {
                event.preventDefault();
                this.executeOnNewHandlerOnEnter();
            }
        };

        private executeOnNewHandlerOnEnter() {
            // If they've typed a new entry and hit enter...
            const trimmedFilter = this.state.filter.trim();
            if (trimmedFilter.length > 0 && this.props.onNew) {
                // TODO: The case insensitive comparison here is specific to roles
                // (which is the only use of `newItem` at the moment) and probably shouldn't be hard-coded here.
                // Either toggle this behavior from a prop, or extract it from this component entirely
                const val = trimmedFilter.toLowerCase();
                const items = this.props.items ? this.props.items : [];
                const value = this.props.value ? this.props.value : [];
                let newItem: SelectItem | null = null;
                if (items.find((i) => i.Name.toLowerCase() === val) === undefined && !value.find((i) => i && i.toLowerCase() === val)) {
                    newItem = { Id: "", Name: trimmedFilter };
                }
                if (newItem) {
                    this.props.onNew(newItem.Name);
                    this.setState({
                        open: false,
                        filter: "",
                    });
                }
            }
        }

        private onKeyEsc = (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Escape") {
                this.onRequestClose(() => this.focusText(true));
            }
        };

        private filteredList(items: T[], value: string[], trimmedFilter: string) {
            let results = items.slice(); //clone array

            if (trimmedFilter.length > 0) {
                const search = trimmedFilter.toLowerCase();
                results = items.filter((i) => i.Name.toLowerCase().includes(search));
            }

            results = results.filter((i) => !value.includes(i[this.itemKey])); // filter out existing selected items

            return results;
        }

        private onSearchTextChange = (val: string) => {
            this.setState({
                open: true,
                filter: val,
            });
        };

        private onToggle = () => {
            this.setState((prevState) => ({
                open: !prevState.open,
            }));
        };

        private onRequestClose = (callback?: () => void) => {
            this.setState(
                {
                    filter: "",
                    open: false,
                },
                callback
            );
        };

        private renderChip = (nameOrId: string, index: number) => {
            const item = this.props.items && this.props.items.find((i) => i[this.itemKey] === nameOrId);

            const element = this.props.renderChip(item || { Id: nameOrId, Name: nameOrId }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.props.value.filter((i) => i !== nameOrId));
                }
                if (this.props.onRemove) {
                    this.props.onRemove(nameOrId);
                }
            });

            if (!React.isValidElement(element)) {
                return null;
            }

            return <span key={nameOrId}>{element}</span>;
        };

        // TODO: This is a workaround to this issue - https://github.com/mui-org/material-ui/issues/16901
        // MUI Core version at the time of this workaround: 4.0.2, official MUI fix is in 4.6.0
        // What's happening on octopus? - If the list goes beyond the window, the overflow is hidden and you can scroll
        // dispatching a resize event, fires an internal update function in MUI. The `updatePopoverPosition` function in
        // no longer works, therefore same work around is use in line onResized function in virtual list
        private updatePopoverPositionWorkaround = () => {
            window.requestAnimationFrame(() => {
                window.dispatchEvent(new CustomEvent("resize"));
            });
        };
    }

    return UseLabelStrategy(MultiSelectInternal, (fieldName) => `Select ${fieldName}`);
}
