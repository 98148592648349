import React from "react";
import type { AnalyticSession } from "~/analytics/AnalyticSession";
import { AnalyticSessionProvider } from "~/analytics/AnalyticSession";
import { noOp } from "~/utils/noOp";

export function UnauthenticatedAnalyticSessionProvider(props: React.PropsWithChildren<{}>) {
    // If we don't have a user available (because they haven't signed in yet, etc.),
    // we might still render components that try to send analytics (like external links)
    // Currently we don't track these events because we can't attribute them to a user.
    // We might want to track them in future (e.g. by attributing them to a pseudo-user).
    return <AnalyticSessionProvider session={unauthenticatedAnalyticSession}>{props.children}</AnalyticSessionProvider>;
}

const unauthenticatedAnalyticSession: AnalyticSession = {
    end: noOp,
    anonymize: (s) => s,
    track: noOp,
};
