import type { ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition, RootInputSchema } from "@octopusdeploy/step-runtime-inputs";
import { createObjectInputPaths } from "@octopusdeploy/step-runtime-inputs";
import type { StepInputComponent, StepUI } from "@octopusdeploy/step-ui";
import React, { useCallback } from "react";
import { createRenderedComponentAndSummaryForStepFactory } from "~/components/StepPackageEditor/CreateRenderedComponentAndSummaryForStepFactory";
import { EditStepPackageInputs } from "~/components/StepPackageEditor/EditStepPackageInputs";
import type { StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";

export interface TypedStepPackageEditorProps<StepInputs> {
    editInputsForm: StepUI<StepInputs>["editInputsForm"];
    getRootInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => RootInputSchema;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    inputDependencies: StepInputDependencies;
    isExpandedByDefault: boolean;
    getFieldError: (name: PathToInput) => string;
}

export function TypedStepPackageEditor<StepInputs>(props: TypedStepPackageEditorProps<StepInputs>) {
    const { inputs, getRootInputSchema, inputDependencies, getFieldError, setInputs } = props;

    const getInputSchema = useCallback<(inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition>(
        (inputs) => {
            return { type: "object", discriminatorProperties: [], nonDiscriminatorProperties: getRootInputSchema(inputs).properties };
        },
        [getRootInputSchema]
    );

    const inputPaths = createObjectInputPaths<StepInputs>(getInputSchema(props.inputs));
    const formContent = props.editInputsForm(inputPaths);

    const getRenderedComponentAndSummary = createRenderedComponentAndSummaryForStepFactory(inputs, inputDependencies, setInputs, getInputSchema, getFieldError);

    return <EditStepPackageInputs<StepInputs, StepInputComponent> isExpandedByDefault={props.isExpandedByDefault} formContent={formContent} getRenderedComponentAndSummary={getRenderedComponentAndSummary} />;
}
