import * as base from "~/theme/base";

export interface OctopusTheme {
    // Constants
    whiteConstant: string;
    blackConstant: string;
    cyanConstant: string;
    transparent: string;

    //Shadows - these colours don't change between themes
    shadow40: string;
    shadow60: string;
    shadow20: string;
    shadow12: string;
    shadow14: string;
    shadow25: string;
    shadow22: string;
    shadow34: string;

    // Paper
    paper0: string;
    paper1: string;
    paper2: string;
    paper3: string;

    // Text
    primaryText: string;
    secondaryText: string;
    ternaryText: string;
    highlightText: string;
    highlightTextBackground: string;

    // Text links
    linkText: string;
    linkTextHover: string;
    sideMenuHover: string;
    sideMenuNestedText: string;
    sidebarMenuSelectedText: string;

    // Nav
    navBackground: string;
    navItemHover: string;
    navItemActive: string;
    navText: string;
    navTextHover: string;
    navTextActive: string;
    spaceMenuBackground: string;
    spaceMenuText: string;

    // Tabs
    tabActiveIndicator: string;

    // Buttons
    primaryButtonBackground: string;
    primaryButtonText: string;

    secondaryButtonBackground: string;
    secondaryButtonText: string;

    ternaryButton: string;
    ternaryButtonHover: string;
    categoryButtonBackground: string;

    disabledButtonBorder: string;
    disabledButtonBackground: string;
    disabledButtonText: string;

    createReleaseBackground: string;
    createReleaseText: string;

    deleteButtonText: string;
    deleteButtonBackground: string;

    cancelButtonBackground: string;
    cancelButtonText: string;

    subtleButtonBackground: string;
    subtleButtonBorder: string;

    // Paging
    pagingButtonBackground: string;
    pagingButtonBackgroundHover: string;
    pagingButtonBackgroundActive: string;

    // Dashboard
    tenantCount: string;

    // Section
    SectionBodyText: string;
    groupHeaderBackground: string;
    groupHeader: string;

    // Backgrounds & dividers
    primaryBackground: string;
    disabledBackground: string;
    secondaryBackground: string;
    ternaryBackground: string;
    hover: string;
    divider: string;
    secondaryDivider: string;
    logoBackground: string;
    stepLogoBackground: string;
    parentStepBackground: string;
    loadingIndicator: string;
    loadingIndicatorBackground: string;

    // Cards
    cardBackground: string;
    cardHover: string;
    cardFooterBuiltIn: string;
    cardFooterInstalled: string;
    cardFooterCommunity: string;

    // Form
    focus: string;
    selectBorder: string;

    // Chips
    chipBackground: string;
    chipText: string;
    chipIcon: string;
    chipDeleteButton: string;
    avatarBackground: string;

    // Code Editor hints
    hintHighlight: string;
    hintActive: string;
    placeholder: string;
    placeholderActive: string;

    // Icon Editor
    iconEditorIconHover: string;
    iconEditorColorPaletteInnerBorder: string;
    iconEditorColorPaletteHover: string;
    iconEditorColorPaletteSelected: string;

    // Callouts
    info: string;
    infoConstant: string;
    infoBackground: string;
    infoHeaderText: string;
    infoText: string;
    infoHighlight: string;
    infoBorder: string;

    success: string;
    successConstant: string;
    successBackground: string;
    successTaskLogBackground: string;
    successHeaderText: string;
    successText: string;
    successHighlight: string;
    successBorder: string;

    danger: string;
    dangerConstant: string;
    dangerBackground: string;
    dangerTaskLogBackground: string;
    dangerHeaderText: string;
    dangerText: string;
    dangerHighlight: string;
    dangerBorder: string;

    alert: string;
    alertBackground: string;
    alertConstant: string;
    alertHeaderText: string;
    alertText: string;
    alertHighlight: string;
    alertBorder: string;

    sunshineText: string;

    darkBackground: string;
    darkText: string;

    auditHighlight: string;
    codeHighlight: string;
    codeText: string;
    codeTextBright: string;

    featureText: string;
    featureBackground: string;
    featureBorder: string;

    //difs
    diffAddedBackground: string;
    diffWordAddedBackground: string;
    diffRemovedBackground: string;
    diffWordRemovedBackground: string;
    diffInfoBackground: string;
    diffHighlight: string;

    // Form validation
    errorText: string;

    // icons
    iconNavy: string;
    iconDark: string;
    iconLight: string;
    iconNeutral: string;

    // Tooltip
    tooltipText: string;
    tooltipBackground: string;

    // Primary color
    mainBackground: string;
    mainText: string;

    // Will be superceeded
    primary: string;
    primaryLight: string;
    primaryDark: string;
    primaryDarkest: string;
    primaryLightest: string;
    secondaryDark: string;

    // Images
    imgNavy: string;
    imgCyan: string;
    imgCyan50: string;
    imgWhite: string;
    imgBlueGrey: string;
    imgLightGrey: string;
    imgGrey: string;
    imgLight: string;
    imgDark: string;
    imgGreen: string;
    imgDanger: string;
}

export const LightTheme: OctopusTheme = {
    //Primary - these colours don't change between themes
    whiteConstant: base.white,
    blackConstant: base.black,
    cyanConstant: base.cyan,
    transparent: base.transparent,

    //Shadows - these colours don't change between themes
    shadow60: base.shadow60,
    shadow40: base.shadow40,
    shadow34: base.shadow34,
    shadow25: base.shadow25,
    shadow22: base.shadow22,
    shadow20: base.shadow20,
    shadow14: base.shadow14,
    shadow12: base.shadow12,

    paper0: base.white,
    paper1: base.white, // Paper background
    paper2: base.white, // Card background
    paper3: base.white, // Card hover

    // Text
    primaryText: base.grey70,
    secondaryText: base.grey50,
    ternaryText: base.grey60,
    highlightText: base.highlightText,
    highlightTextBackground: base.highlightTextBackground,

    // Text links
    linkText: base.cyan,
    linkTextHover: base.cyan50,
    sideMenuHover: base.white,
    sideMenuNestedText: base.grey60,
    sidebarMenuSelectedText: base.cyan,

    // Nav
    navBackground: base.cyan,
    navItemHover: base.cyan30,
    navItemActive: base.cyan50,
    navText: base.white,
    navTextHover: base.white,
    navTextActive: base.white,
    spaceMenuBackground: base.navy,
    spaceMenuText: base.white,

    // Tabs
    tabActiveIndicator: base.cyan,

    // Buttons
    primaryButtonBackground: base.green,
    primaryButtonText: base.white,

    secondaryButtonBackground: base.cyan20,
    secondaryButtonText: base.cyan,

    ternaryButton: base.cyan,
    ternaryButtonHover: base.cyan50,
    categoryButtonBackground: base.cyan,

    disabledButtonBorder: base.grey30,
    disabledButtonBackground: base.grey20,
    disabledButtonText: base.grey50,

    createReleaseBackground: base.navy,
    createReleaseText: base.white,

    deleteButtonText: base.white,
    deleteButtonBackground: base.red,

    cancelButtonBackground: base.orange,
    cancelButtonText: base.white,

    subtleButtonBackground: base.cyan10,
    subtleButtonBorder: base.cyan20,

    // Paging
    pagingButtonBackground: base.grey30,
    pagingButtonBackgroundHover: base.grey50,
    pagingButtonBackgroundActive: base.grey50,

    // Dashboard
    tenantCount: base.white,

    // Section
    SectionBodyText: base.grey70,
    groupHeaderBackground: base.grey20,
    groupHeader: base.grey70,

    // Backgrounds & dividers
    primaryBackground: base.grey10,
    disabledBackground: base.grey20,
    secondaryBackground: base.grey20,
    ternaryBackground: base.grey20,
    hover: base.grey20,
    divider: base.grey40,
    secondaryDivider: base.grey30,
    logoBackground: base.transparent,
    stepLogoBackground: base.white,
    parentStepBackground: base.cyan50,
    loadingIndicator: base.cyan30,
    loadingIndicatorBackground: base.grey30,

    // Cards
    cardBackground: base.white,
    cardHover: "rgba(158, 158, 158, 0.1)",
    cardFooterBuiltIn: base.cyan,
    cardFooterInstalled: base.grey40,
    cardFooterCommunity: base.white,

    // Form
    focus: base.cyan,
    selectBorder: base.grey50,

    // Chips
    chipBackground: base.grey30,
    chipText: base.grey70,
    chipIcon: base.grey70,
    chipDeleteButton: base.grey50,
    avatarBackground: base.grey40,

    // Code Editor hints
    hintHighlight: base.blue,
    hintActive: base.cyan30,
    placeholder: base.black,
    placeholderActive: base.black,

    // Icon Editor
    iconEditorIconHover: base.grey10,
    iconEditorColorPaletteInnerBorder: base.white,
    iconEditorColorPaletteHover: base.grey30,
    iconEditorColorPaletteSelected: base.grey50,

    // Callouts
    info: base.blue,
    infoConstant: base.blue,
    infoBackground: base.blue10,
    infoBorder: base.blue,
    infoHeaderText: base.blue,
    infoText: base.blue,
    infoHighlight: base.blue10,

    success: base.green,
    successConstant: base.green,
    successBackground: base.green10,
    successTaskLogBackground: base.green10,
    successBorder: base.green,
    successHeaderText: base.green,
    successText: base.green,
    successHighlight: base.green10,

    danger: base.red,
    dangerConstant: base.red,
    dangerBackground: base.red10,
    dangerTaskLogBackground: base.red10,
    dangerBorder: base.red,
    dangerHeaderText: base.red,
    dangerText: base.red,
    dangerHighlight: base.red10,

    alert: base.orange,
    alertBackground: base.orange10,
    alertBorder: base.orange,
    alertConstant: base.orange,
    alertHeaderText: base.orange,
    alertText: base.orange,
    alertHighlight: base.orange10,

    sunshineText: base.yellow,

    darkBackground: base.grey70,
    darkText: base.white,

    auditHighlight: base.grey20,
    codeHighlight: base.purple10,
    codeText: base.purple,
    codeTextBright: base.purple,

    featureText: base.cyan,
    featureBackground: base.cyan20,
    featureBorder: base.transparent,

    //Diffs
    diffAddedBackground: base.diffAddedBackground,
    diffWordAddedBackground: base.diffWordAddedBackground,
    diffRemovedBackground: base.diffRemovedBackground,
    diffWordRemovedBackground: base.diffWordRemovedBackground,
    diffInfoBackground: base.diffInfoBackground,
    diffHighlight: base.grey20,

    // Form validation
    errorText: base.red20,

    // icons
    iconNavy: base.navy,
    iconDark: base.navy,
    iconLight: base.white,
    iconNeutral: base.grey60,

    // Tooltip
    tooltipText: base.white,
    tooltipBackground: base.grey70,

    // Primary color
    mainBackground: base.cyan,
    mainText: base.cyan,

    // Will be superceeded
    primary: base.cyan,
    primaryLight: base.cyan30,
    primaryDark: base.cyan50,
    primaryDarkest: base.navy70,
    primaryLightest: base.cyan10,
    secondaryDark: base.cyan60,

    // Images
    imgNavy: base.navy,
    imgCyan: base.cyan,
    imgCyan50: base.cyan50,
    imgWhite: base.white,
    imgBlueGrey: base.cyan10,
    imgLightGrey: base.grey20,
    imgGrey: base.grey10,
    imgLight: base.white,
    imgDark: base.navy,
    imgGreen: base.green,
    imgDanger: base.red,
};

export const DarkTheme: OctopusTheme = {
    //Primary - these colours don't change between themes
    blackConstant: base.black,
    whiteConstant: base.cyan10,
    cyanConstant: base.cyan40,
    transparent: base.transparent,

    //Shadows - these colours don't change between themes
    shadow60: base.shadow60,
    shadow40: base.shadow40,
    shadow34: base.shadow34,
    shadow25: base.shadow25,
    shadow22: base.shadow22,
    shadow20: base.shadow20,
    shadow14: base.shadow14,
    shadow12: base.shadow12,

    // Paper
    paper0: base.navy,
    paper1: base.navy70,
    paper2: base.navy60,
    paper3: base.navy50,

    // Text
    primaryText: base.navy10,
    secondaryText: base.navy30,
    ternaryText: base.navy20,
    highlightText: base.highlightText,
    highlightTextBackground: base.highlightTextBackground,

    // Text links
    linkText: base.cyan30,
    linkTextHover: base.white,
    sideMenuHover: base.navy70,
    sideMenuNestedText: base.cyan20,
    sidebarMenuSelectedText: base.cyan40,

    // Nav
    navBackground: base.navy,
    navItemHover: base.cyan60,
    navItemActive: base.cyan40,
    navText: base.navy20,
    navTextHover: base.white,
    navTextActive: base.cyan10,
    spaceMenuBackground: base.cyan60,
    spaceMenuText: base.white,

    // Tabs
    tabActiveIndicator: base.cyan,

    // Buttons
    primaryButtonBackground: base.green50,
    primaryButtonText: base.green10,

    secondaryButtonBackground: base.navy,
    secondaryButtonText: base.cyan,

    ternaryButton: base.cyan30,
    ternaryButtonHover: base.cyan10,

    categoryButtonBackground: base.cyan,

    disabledButtonBorder: base.navy30,
    disabledButtonBackground: base.navy60,

    disabledButtonText: base.navy30,

    createReleaseBackground: base.cyan40,
    createReleaseText: base.cyan10,

    deleteButtonText: base.red10,
    deleteButtonBackground: base.red40,

    cancelButtonBackground: base.orange30,
    cancelButtonText: base.orange10,

    subtleButtonBackground: base.navy50,
    subtleButtonBorder: base.navy40,

    // Paging
    pagingButtonBackground: base.cyan60,
    pagingButtonBackgroundHover: base.cyan,
    pagingButtonBackgroundActive: base.cyan,

    // Dashboard
    tenantCount: base.grey70,

    // Section
    SectionBodyText: base.navy20,
    groupHeaderBackground: base.navy50,
    groupHeader: base.navy20,

    // Backgrounds & dividers
    primaryBackground: base.navy,
    disabledBackground: "rgba(153, 153, 153, 0.7)",
    secondaryBackground: base.navy50,
    ternaryBackground: base.navy,

    hover: "rgba(15, 36, 52, 0.9)",
    divider: base.navy40,
    secondaryDivider: base.navy30,
    logoBackground: base.navy10,
    stepLogoBackground: base.cyan10,
    parentStepBackground: base.cyan50,
    loadingIndicator: base.cyan40,
    loadingIndicatorBackground: base.navy50,

    // Cards
    cardBackground: base.navy70,
    cardHover: "rgba(5, 59, 98, 0.5)", //cyan60
    cardFooterBuiltIn: base.cyan,
    cardFooterInstalled: base.navy, //paper0,
    cardFooterCommunity: base.navy50, // paper3,

    // Form
    focus: base.cyan,
    selectBorder: base.grey50,

    // Chips
    chipBackground: base.cyan70,
    chipText: base.navy10,
    chipIcon: base.navy10,
    chipDeleteButton: base.grey30,
    avatarBackground: base.cyan50,

    // Code Editor hints
    hintHighlight: base.red20,
    hintActive: base.cyan40,
    placeholder: base.white,
    placeholderActive: base.white,

    // Icon Editor
    iconEditorIconHover: base.grey10,
    iconEditorColorPaletteInnerBorder: base.white,
    iconEditorColorPaletteHover: base.grey30,
    iconEditorColorPaletteSelected: base.grey50,

    // Callouts
    info: base.blue,
    infoConstant: base.blue,
    infoBackground: base.blue30,
    infoBorder: base.blue20,
    infoHeaderText: base.blue20,
    infoText: base.blue20,
    infoHighlight: "rgba(0, 0, 0, 0)",

    success: base.green,
    successConstant: base.green,
    successBackground: base.green60,
    successTaskLogBackground: base.navy,
    successBorder: base.green40,
    successHeaderText: base.green20, //For use on successBackground
    successText: base.green30, //For use on navy background
    successHighlight: "rgba(0, 0, 0, 0)",

    danger: base.red,
    dangerConstant: base.red,
    dangerBackground: base.red60,
    dangerTaskLogBackground: base.navy,
    dangerBorder: base.red50,
    dangerHeaderText: base.red30, //For use on dangerBackground
    dangerText: base.red20, //For use on navy background
    dangerHighlight: "rgba(0, 0, 0, 0)",

    alert: base.orange,
    alertBackground: base.orange40,
    alertBorder: base.orange20,
    alertConstant: base.orange,
    alertHeaderText: base.orange20, //For use on alertBackground
    alertText: base.orange30, //For use on navy background
    alertHighlight: "rgba(0, 0, 0, 0)",

    sunshineText: base.yellow,

    darkBackground: base.white,
    darkText: base.navy,

    auditHighlight: base.navy60,

    codeHighlight: base.purple40,
    codeText: base.purple20,
    codeTextBright: base.purple30,

    featureText: base.cyan10,
    featureBackground: base.cyan70,
    featureBorder: base.cyan10,

    //Audit diffs
    diffAddedBackground: base.diffAddedBackground,
    diffWordAddedBackground: base.diffWordAddedBackground,
    diffRemovedBackground: base.diffRemovedBackground,
    diffWordRemovedBackground: base.diffWordRemovedBackground,
    diffInfoBackground: base.diffInfoBackground,
    diffHighlight: base.grey20,

    // Form validation
    errorText: base.red20,

    // icons
    iconNavy: base.navy,
    iconDark: base.cyan20,
    iconLight: base.navy,
    iconNeutral: base.grey30,

    // Tooltip
    tooltipText: base.grey70,
    tooltipBackground: base.white,

    // Primary color
    primary: base.cyan30,
    mainBackground: base.cyan40,
    mainText: base.cyan30,

    primaryLight: base.navy40,
    primaryDark: base.navy10,
    primaryDarkest: base.navy,
    primaryLightest: base.navy10,
    secondaryDark: base.cyan,

    // Images
    imgNavy: base.navy,
    imgCyan: base.cyan,
    imgCyan50: base.cyan50,
    imgWhite: base.white,
    imgBlueGrey: base.cyan70,
    imgLightGrey: base.navy30,
    imgGrey: base.navy50,
    imgLight: base.navy,
    imgDark: base.white,
    imgGreen: base.green,
    imgDanger: base.red,
};
