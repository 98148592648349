/* eslint-disable @typescript-eslint/no-explicit-any */

import React = require("react");
import { connect, useSelector } from "react-redux";
import type { Dispatch } from "redux";
import { AnalyticLinkProvider } from "~/analytics/AnalyticLink";
import type { PageLayoutState } from "~/components/PageLayout/reducers/pageLayoutArea";
import { pageLayoutStateUpdated, pageSelector } from "~/components/PageLayout/reducers/pageLayoutArea";
import type IPageWrapper from "~/utils/pageId";
import { withErrorsProvider } from "../ErrorContext/hocs/withErrorsProvider";

interface GlobalDispatchProps {
    onPageLayoutStateUpdated?(pageLayoutState: PageLayoutState): void;
}

interface GlobalStateProps {
    currentPage: IPageWrapper;
}

export interface PageDetailProps {
    page: IPageWrapper;
}

export type PageProps = PageDetailProps & GlobalDispatchProps & GlobalStateProps;

// This reports additional page information to our global state (needed so we can identify our current page
// from anywhere in our component tree ... for onboarding feedback and reporting systems).
class Page extends React.Component<PageProps> {
    componentDidMount() {
        this.registerPathAsGlobalPageId(this.props.page);
    }

    componentDidUpdate(prevProps: PageProps, prevState: PageLayoutState) {
        if (this.props.currentPage?.Id !== this.props.page.Id) {
            this.registerPathAsGlobalPageId(this.props.page);
        }
    }

    shouldComponentUpdate(nextProps: PageProps & { children: React.ReactNode }) {
        //We need to compare individual properties in the page to avoid unintended re-renders since react generally does a shallow comparison
        return nextProps.page.Id !== this.props.page.Id || nextProps.page.Name !== this.props.page.Name || this.props.children !== nextProps.children;
    }

    render() {
        return <AnalyticLinkProvider location={`Page: ${this.props.page.Name}`}>{this.props.children}</AnalyticLinkProvider>;
    }

    private registerPathAsGlobalPageId(page: IPageWrapper): void {
        if (this.props.onPageLayoutStateUpdated) {
            this.props.onPageLayoutStateUpdated({ page });
        }
    }
}

const mapGlobalStateToProps = (state: GlobalState) => {
    return {
        currentPage: state.pageLayoutArea.config?.page,
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch) => {
    return {
        onPageLayoutStateUpdated: (pageLayoutState: PageLayoutState) => {
            dispatch(
                pageLayoutStateUpdated({
                    page: pageLayoutState && pageLayoutState.page,
                })
            );
        },
    };
};

const EnhancedPage = withErrorsProvider(connect<GlobalStateProps, GlobalDispatchProps, PageDetailProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(Page));

export default EnhancedPage;

export const withPage = (pageProps: PageDetailProps) => {
    return <TComponentProps extends unknown>(Component: React.ComponentType<TComponentProps>) => {
        const RenderWithPage: React.FC<TComponentProps> = (componentProps) => {
            return (
                <EnhancedPage {...pageProps}>
                    <Component {...componentProps} />
                </EnhancedPage>
            );
        };
        return RenderWithPage;
    };
};

type FromPropsCallback<TProps> = (mapPage: TProps) => PageDetailProps;

export const withPageFromProps = <TInnerProps extends any>(Component: React.ComponentType<TInnerProps>, mapPageProps: FromPropsCallback<TInnerProps>) => {
    const WithPageFromRouteProps: React.StatelessComponent<TInnerProps> = (componentProps) => {
        return (
            <EnhancedPage {...mapPageProps(componentProps)}>
                <Component {...componentProps} />
            </EnhancedPage>
        );
    };

    return WithPageFromRouteProps;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Page Context
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function usePage(): IPageWrapper | null {
    // Normally try-catch would not be required, but this function is used by useAnalyticLinkDispatch, in ExternalLink, which is used in TextWithLinks, which is used in Markdown.tsx in the LinkRenderer helper function.
    // Running in that context (makeHtml), the Redux context is unavailable, and rather than return undefined, it throws an exception
    // By returning null, we can detect this, and effectively turn off the sending of a page event in Analytics.tsx from any Markdown section (or context where the page is unavailable for some reason)
    try {
        return useSelector(pageSelector);
    } catch (_) {
        // Do nothing
    }

    return null;
}
