/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import moment from "moment";
import type { Moment } from "moment";
import * as React from "react";
import type {
    EnvironmentResource,
    UserResource,
    ProjectResource,
    EventCategoryResource,
    EventGroupResource,
    DocumentTypeResource,
    TenantResource,
    ResourceCollection,
    EventResource,
    EventAgentResource,
    SpaceResource,
    ProjectGroupResource,
    ProjectSummaryResource,
} from "~/client/resources";
import Permission from "~/client/resources/permission";
import { client, repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout";
import type { FilterSection } from "~/components/AdvancedFilterLayout";
import AuditListRow from "~/components/AuditListRow/AuditListRow";
import { ActionButton, ActionButtonType } from "~/components/Button";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DatePickerDialog from "~/components/Dialog/DatePickerDialog";
import { EventFilter } from "~/components/EventFilter";
import type { EventFilterSelections } from "~/components/EventFilter";
import FormPage from "~/components/FormPage/FormPage";
import List from "~/components/List";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { OverflowMenuDisabledItem, OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { Select } from "~/components/form";
import { expanderActions, defaultContainerKey } from "~/components/form/Sections/reducers/expanders";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Note from "~/primitiveComponents/form/Note/Note";
import ScrollToTop from "~/primitiveComponents/navigation/ScrollToTop";
import DateFormatter from "~/utils/DateFormatter";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import SystemUserFactory from "~/utils/SystemUserFactory";
import routeLinks from "../../../../routeLinks";
import store from "../../../../store";
import styles from "./style.module.less";

class AuditDataList extends List<EventResource> {}

export interface AuditQuery extends IQuery {
    environments?: string[];
    users?: string[];
    projects?: string[];
    projectGroups?: string[];
    eventCategories?: string[];
    eventGroups?: string[];
    eventAgents?: string[];
    documentTypes?: string[];
    tenants?: string[];
    tags?: string[];
    from?: string;
    to?: string;
    regardingAny?: string[];
    includeSystem?: string;
    spaces?: string[];
}

interface LookupData {
    environments: EnvironmentResource[];
    users: UserResource[];
    projects: ProjectSummaryResource[];
    projectGroups: ProjectGroupResource[];
    eventCategories: EventCategoryResource[];
    eventGroups: EventGroupResource[];
    eventAgents: EventAgentResource[];
    documentTypes: DocumentTypeResource[];
    tenants: TenantResource[];
    spaces: SpaceResource[];
}

interface AuditFilter extends EventFilterSelections {
    DateOption: DateOption;
    DateRange: DateRange;
    RegardingAny: string[];
    Spaces: string[];
    IncludeSystem: boolean;
}

export enum DateOption {
    Today = "Today",
    Last7Days = "Last 7 Days",
    Last30Days = "Last 30 Days",
    Last90Days = "Last 90 Days",
    Custom = "Custom",
}

interface DateRange {
    startDate: Moment;
    endDate: Moment;
}

class FilterLayout extends AdvancedFilterLayout<AuditFilter> {}

const AuditQueryStringFilters = QueryStringFilters.For<AuditFilter, AuditQuery>();

const dateFormatForApi = "YYYY-MM-DDTHH:mm:ss.Z"; // This matches the server-side date format for subscription notification deep links, play carefully!
const dateFormatForHumans = "D MMMM YYYY";

const AuditFormPage = FormPage<LookupData>();
const title = "Audit";

const AuditPage: React.FC = () => {
    return (
        <AuditFormPage
            title={title}
            load={async () => {
                const environments = isAllowed({ permission: Permission.EnvironmentView, environment: "*" }) ? repository.Environments.all() : Promise.resolve<EnvironmentResource[]>([]);
                const usersPromise = isAllowed({ permission: Permission.UserView }) ? repository.Users.all() : Promise.resolve<UserResource[]>([]);
                const projects = isAllowed({ permission: Permission.ProjectView, wildcard: true }) ? repository.Projects.summaries() : Promise.resolve<ProjectResource[]>([]);
                const projectGroups = isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" }) ? repository.ProjectGroups.all() : Promise.resolve<ProjectGroupResource[]>([]);
                const eventCategories = repository.Events.categories({});
                const eventGroups = repository.Events.groups({});
                const eventAgents = repository.Events.eventAgents();
                const documentTypes = repository.Events.documentTypes({});
                const tenants = repository.Tenants.all();
                const spaces = repository.Users.getSpaces(session.currentUser!);

                const users = await usersPromise;
                users.unshift(SystemUserFactory.systemUser());

                return {
                    environments: await environments,
                    users,
                    projects: await projects,
                    projectGroups: await projectGroups,
                    eventCategories: await eventCategories,
                    eventGroups: await eventGroups,
                    eventAgents: await eventAgents,
                    documentTypes: await documentTypes,
                    tenants: await tenants,
                    spaces: await spaces,
                };
            }}
            renderWhenLoaded={(lookupData: LookupData) => {
                return <AuditLayout lookupData={lookupData} />;
            }}
        />
    );
};

interface AuditLayoutProps {
    lookupData: LookupData;
}

interface AuditLayoutState extends DataBaseComponentState {
    eventsResponse?: ResourceCollection<EventResource>;
    filterSelections: AuditFilter;
    queryFilterSelections?: AuditFilter;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalRequestParams?: Map<string, any>;
    exportUrl: string;
    dialogOpen: boolean;
}

class AuditLayout extends DataBaseComponent<AuditLayoutProps, AuditLayoutState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: AuditLayoutProps) {
        super(props);

        this.state = {
            filterSelections: this.getDefaultFilter(),
            exportUrl: "",
            dialogOpen: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.reload());
    }

    componentWillUnmount() {
        store.dispatch(expanderActions.onExpanderContainerDestroyed({ containerKey: defaultContainerKey }));
    }

    render() {
        const overflowActions = [this.createSubscriptionAction()];
        const refreshButton = (
            <PermissionCheck permission={Permission.EventView} wildcard={true}>
                <ActionButton type={ActionButtonType.Secondary} label="Refresh" onClick={() => this.reload()} />
            </PermissionCheck>
        );
        const exportButton = (
            <PermissionCheck permission={Permission.EventView} wildcard={true}>
                <ActionButton type={ActionButtonType.Primary} label="Export" onClick={() => this.onExportClicked()} />
            </PermissionCheck>
        );
        const actions = overflowActions.length ? [refreshButton, exportButton, <OverflowMenu menuItems={overflowActions} />] : [refreshButton, exportButton];

        return (
            <PaperLayout busy={this.state.busy} errors={this.errors} sectionControl={<ActionList actions={actions} />} title={title}>
                <PermissionCheck
                    permission={Permission.EventView}
                    wildcard={true}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.EventView} permission is required to view the audit log
                        </Callout>
                    }
                >
                    <AuditQueryStringFilters
                        filter={this.state.filterSelections}
                        getQuery={(filter) => this.queryFromFilters(filter, true)}
                        getFilter={this.getFilterFromQuery}
                        onFilterChange={(filters) => {
                            this.setState({ queryFilterSelections: filters });
                            this.onFiltersChanged(filters);
                        }}
                    />
                    {this.renderContent()}
                </PermissionCheck>
                <ScrollToTop />
            </PaperLayout>
        );
    }

    private renderContent() {
        return (
            <FilterLayout
                //TODO: Investigate whether we really wish to pass the defualt filter down or not, If yes, can we pass a func instead of a cached value, so date time like  value can be re-evaluated if needed.
                defaultFilter={this.getDefaultFilter()}
                filterSections={this.filterSections()}
                filter={this.state.filterSelections}
                queryFilter={this.state.queryFilterSelections}
                onFilterReset={(_) => this.onFiltersChanged(this.getDefaultFilter())}
                additionalHeaderFilters={[this.dateFilterHeader()]}
                filterHeaderCustomStyle={styles.filterHeaderContainer}
                renderContent={() => (
                    <>
                        {this.dateFilterNote()}
                        {this.state.eventsResponse && (
                            <AuditDataList
                                initialData={this.state.eventsResponse}
                                additionalRequestParams={this.state.additionalRequestParams}
                                onRow={(item: EventResource) => {
                                    const category = this.props.lookupData.eventCategories.find((x) => x.Id === item.Category);
                                    return <AuditListRow event={item} categoryName={category ? category.Name : item.Category} />;
                                }}
                                onFilter={this.filter}
                                filterHintText="Filter by message..."
                                showPagingInNumberedStyle={true}
                                wrapRowsInListItem={false} // AuditListRow does custom styling on these rows.
                                empty={this.renderNoResults()}
                            />
                        )}
                    </>
                )}
            />
        );
    }

    private renderNoResults() {
        const startDate = DateFormatter.dateToCustomFormat(this.state.filterSelections.DateRange.startDate.clone(), dateFormatForHumans);
        const endDate = DateFormatter.dateToCustomFormat(this.state.filterSelections.DateRange.endDate.clone(), dateFormatForHumans);

        return (
            <Note className={styles.noResultsContainer}>
                Looks like we don't have any results between the dates{" "}
                <span className={styles.dateWrapper}>
                    {startDate} - {endDate}.
                </span>
                <br />
                Try expanding this range.
            </Note>
        );
    }

    private filter(filter: string, resource: EventResource) {
        return !filter || filter.length === 0 || !resource || resource.Message.toLowerCase().includes(filter.toLowerCase());
    }

    private filterSections(): FilterSection[] {
        return [
            {
                render: (
                    <div>
                        {this.state.filterSelections.RegardingAny && this.state.filterSelections.RegardingAny.length > 0 && (
                            <div className={styles.regardingAnyContainer}>
                                <Note style={{ fontSize: "0.875rem" }}>
                                    Regarding documents:
                                    <ul>
                                        {this.state.filterSelections.RegardingAny.map((x, index) => (
                                            <li key={index}>
                                                <strong>{x}</strong>
                                            </li>
                                        ))}
                                    </ul>
                                </Note>
                                <div>
                                    <ActionButton
                                        type={ActionButtonType.Ternary}
                                        onClick={() => {
                                            this.onFiltersChanged({ ...this.state.filterSelections, RegardingAny: null! });
                                        }}
                                        label="Clear"
                                    />
                                </div>
                            </div>
                        )}
                        <EventFilter
                            doBusyTask={this.doBusyTask}
                            documentTypes={this.props.lookupData.documentTypes}
                            environments={this.props.lookupData.environments}
                            eventCategories={this.props.lookupData.eventCategories}
                            eventGroups={this.props.lookupData.eventGroups}
                            eventAgents={this.props.lookupData.eventAgents}
                            projects={this.props.lookupData.projects}
                            projectGroups={this.props.lookupData.projectGroups}
                            tenants={this.props.lookupData.tenants}
                            users={this.props.lookupData.users}
                            spaces={this.props.lookupData.spaces}
                            selectedDocumentTypes={this.state.filterSelections.DocumentTypes}
                            selectedEnvironments={this.state.filterSelections.Environments}
                            selectedEventCategories={this.state.filterSelections.EventCategories}
                            selectedEventGroups={this.state.filterSelections.EventGroups}
                            selectedEventAgents={this.state.filterSelections.EventAgents}
                            selectedProjects={this.state.filterSelections.Projects}
                            selectedProjectGroups={this.state.filterSelections.ProjectGroups}
                            selectedTenants={this.state.filterSelections.Tenants}
                            selectedTenantTags={this.state.filterSelections.Tags}
                            selectedUsers={this.state.filterSelections.Users}
                            selectedSpaces={this.state.filterSelections.Spaces}
                            includeSystem={this.state.filterSelections.IncludeSystem}
                            onChangeDocumentTypes={(DocumentTypes) => this.onFiltersChanged({ ...this.state.filterSelections, DocumentTypes })}
                            onChangeEnvironments={(Environments) => this.onFiltersChanged({ ...this.state.filterSelections, Environments })}
                            onChangeEventCategories={(EventCategories) => this.onFiltersChanged({ ...this.state.filterSelections, EventCategories })}
                            onChangeEventGroups={(EventGroups) => this.onFiltersChanged({ ...this.state.filterSelections, EventGroups })}
                            onChangeEventAgents={(EventAgents) => this.onFiltersChanged({ ...this.state.filterSelections, EventAgents })}
                            onChangeProjects={(Projects) => this.onFiltersChanged({ ...this.state.filterSelections, Projects })}
                            onChangeProjectGroups={(ProjectGroups) => this.onFiltersChanged({ ...this.state.filterSelections, ProjectGroups })}
                            onChangeTenants={(Tenants) => this.onFiltersChanged({ ...this.state.filterSelections, Tenants })}
                            onChangeTenantTags={(Tags) => this.onFiltersChanged({ ...this.state.filterSelections, Tags })}
                            onChangeUsers={(Users) => this.onFiltersChanged({ ...this.state.filterSelections, Users })}
                            onChangeSpaces={(Spaces) => this.onFiltersChanged({ ...this.state.filterSelections, Spaces })}
                            onIncludeSystemChange={(IncludeSystem) => this.onFiltersChanged({ ...this.state.filterSelections, IncludeSystem })}
                        />
                    </div>
                ),
            },
        ];
    }

    private dateFilterHeader = () => {
        const onDateChange = async (value: string) => {
            const dateOption = Object.values(DateOption).find((option) => option.toString() === value);
            if (dateOption) {
                if (dateOption === DateOption.Custom) {
                    this.openDialog();
                } else {
                    await this.onDatePickerChange(dateOption, this.convertDateOptionToDateRange(dateOption));
                }
            }
        };

        const dateOptions = Object.values(DateOption).map((value) => {
            return {
                text: value,
                value,
            };
        });

        return (
            <div className={styles.dateRangeFilterContainer}>
                {this.state.dialogOpen && (
                    <DatePickerDialog
                        dialogOpen={true}
                        startDate={this.state.filterSelections.DateRange.startDate}
                        endDate={this.state.filterSelections.DateRange.endDate}
                        onSaveClick={(range) => this.onDatePickerChange(DateOption.Custom, range)}
                        onCancelClick={() => this.clearDialogState()}
                    />
                )}
                <Select
                    className={styles.dateRangeFilter}
                    label="Select date range"
                    items={dateOptions}
                    value={this.state.filterSelections.DateOption}
                    onChange={(value) => {
                        if (value) return onDateChange(value);
                    }}
                    sortItems={false}
                />
            </div>
        );
    };

    private dateFilterNote = () => {
        const startDate = this.state.filterSelections.DateRange.startDate.clone();
        const endDate = this.state.filterSelections.DateRange.endDate.clone();

        const dateRangeNote = DateFormatter.dateToCustomFormat(startDate, dateFormatForHumans) + " - " + DateFormatter.dateToCustomFormat(endDate, dateFormatForHumans);

        return (
            <div className={styles.dateFilterNote}>
                <Note className={styles.noWrap}>
                    Showing results for <span className={styles.dateWrapper}>{dateRangeNote}</span>
                </Note>
            </div>
        );
    };

    private openDialog() {
        this.setState({ dialogOpen: true });
    }

    private clearDialogState() {
        this.setState({ dialogOpen: false });
    }

    private onExportClicked() {
        window.location.href = this.state.exportUrl;
    }

    private async onDatePickerChange(dateOption: DateOption, dateRange: DateRange) {
        const filterSelections: AuditFilter = {
            ...this.state.filterSelections,
            DateOption: dateOption,
            DateRange: {
                startDate: dateRange.startDate.clone().startOf("day"),
                endDate: dateRange.endDate.clone().endOf("day"),
            },
        };
        this.clearDialogState();
        this.onFiltersChanged(filterSelections);
    }

    private onFiltersChanged = (filterSelections: AuditFilter) => {
        this.setState({ filterSelections }, async () => {
            await this.reload();
        });
    };

    private createSubscriptionAction(): OverflowMenuDisabledItem | OverflowMenuNavLink {
        const createSub = "Create subscription from this filter";

        if (this.state.filterSelections.Spaces.length > 1) {
            return OverflowMenuItems.disabledItem(createSub, "Multiple Spaces are selected, a subscription can only be created for a single Space");
        } else if (this.state.filterSelections.Spaces.length === 0) {
            return OverflowMenuItems.disabledItem(createSub, "A subscription can only be created for your current Space");
        } else if (this.state.filterSelections.Spaces.length === 1 && this.state.filterSelections.Spaces[0] !== client.spaceId) {
            const otherSpaceName = this.props.lookupData.spaces.find((s) => s.Id === this.state.filterSelections.Spaces[0])!.Name;
            return OverflowMenuItems.disabledItem(createSub, `A subscription can only be created for your current Space, you will need to switch to the '${otherSpaceName}' Space to create it.`);
        }

        return OverflowMenuItems.navItem(createSub, routeLinks.configuration.subscriptions.create(this.queryFromFilters(this.state.filterSelections, false)), {
            permission: Permission.SubscriptionEdit,
        });
    }

    private queryFromFilters(filterSelections: AuditFilter, includeDates: boolean): AuditQuery {
        return {
            users: filterSelections.Users,
            projects: filterSelections.Projects,
            projectGroups: filterSelections.ProjectGroups,
            environments: filterSelections.Environments,
            eventGroups: filterSelections.EventGroups,
            eventCategories: filterSelections.EventCategories,
            eventAgents: filterSelections.EventAgents,
            tenants: filterSelections.Tenants,
            tags: filterSelections.Tags,
            documentTypes: filterSelections.DocumentTypes,
            from: filterSelections.DateRange.startDate && includeDates ? filterSelections.DateRange.startDate.format(dateFormatForApi) : undefined,
            to: filterSelections.DateRange.endDate && includeDates ? filterSelections.DateRange.endDate.format(dateFormatForApi) : undefined,
            regardingAny: filterSelections.RegardingAny,
            includeSystem: filterSelections.IncludeSystem ? "true" : "false",
            spaces: filterSelections.Spaces,
        };
    }

    private getFilterFromQuery = (query: AuditQuery): AuditFilter => {
        const defaultDateConfig = this.getDefaultDateConfiguration();
        return {
            Users: arrayValueFromQueryString(query.users),
            Projects: arrayValueFromQueryString(query.projects),
            ProjectGroups: arrayValueFromQueryString(query.projectGroups),
            Environments: arrayValueFromQueryString(query.environments),
            EventGroups: arrayValueFromQueryString(query.eventGroups),
            EventCategories: arrayValueFromQueryString(query.eventCategories),
            EventAgents: arrayValueFromQueryString(query.eventAgents),
            Tenants: arrayValueFromQueryString(query.tenants),
            Tags: arrayValueFromQueryString(query.tags),
            DocumentTypes: arrayValueFromQueryString(query.documentTypes),
            DateOption: this.state.filterSelections.DateOption,
            DateRange: {
                startDate: getDate(query.from, defaultDateConfig.range.startDate),
                endDate: getDate(query.to, defaultDateConfig.range.endDate),
            },
            RegardingAny: arrayValueFromQueryString(query.regardingAny),
            Spaces: arrayValueFromQueryString(query.spaces),
            IncludeSystem: query.includeSystem === "true",
        };

        function getDate(dateQueryString: string | undefined, defaultDate: Moment) {
            if (dateQueryString) {
                const parsedDate = moment(dateQueryString, dateFormatForApi);
                if (parsedDate.isSame(defaultDate, "second")) {
                    return defaultDate;
                }
                return parsedDate;
            }
            return defaultDate;
        }
    };

    private getDefaultDateConfiguration = (): { option: DateOption; range: DateRange } => ({ option: DateOption.Last30Days, range: this.convertDateOptionToDateRange(DateOption.Last30Days) });

    private getAdditionalRequestParams(): Map<string, string[] | string> | undefined {
        const filterSelections = this.state.filterSelections;

        function isNotEmpty<T>(items: T[]) {
            return items.length !== 0;
        }

        const additionalRequestParams = new Map<string, string[] | string>();
        if (isNotEmpty(filterSelections.Users)) {
            additionalRequestParams.set("users", filterSelections.Users);
        }
        if (isNotEmpty(filterSelections.Projects)) {
            additionalRequestParams.set("projects", filterSelections.Projects);
        }
        if (isNotEmpty(filterSelections.ProjectGroups)) {
            additionalRequestParams.set("projectGroups", filterSelections.ProjectGroups);
        }
        if (isNotEmpty(filterSelections.Environments)) {
            additionalRequestParams.set("environments", filterSelections.Environments);
        }
        if (isNotEmpty(filterSelections.EventGroups)) {
            additionalRequestParams.set("eventGroups", filterSelections.EventGroups);
        }
        if (isNotEmpty(filterSelections.EventCategories)) {
            additionalRequestParams.set("eventCategories", filterSelections.EventCategories);
        }
        if (isNotEmpty(filterSelections.EventAgents)) {
            additionalRequestParams.set("eventAgents", filterSelections.EventAgents);
        }
        if (isNotEmpty(filterSelections.Tags)) {
            additionalRequestParams.set("tags", filterSelections.Tags);
        }
        if (isNotEmpty(filterSelections.Tenants)) {
            additionalRequestParams.set("tenants", filterSelections.Tenants);
        }
        if (isNotEmpty(filterSelections.DocumentTypes)) {
            additionalRequestParams.set("documentTypes", filterSelections.DocumentTypes);
        }
        if (filterSelections.DateRange.startDate) {
            additionalRequestParams.set("from", filterSelections.DateRange.startDate.format());
        }
        if (filterSelections.DateRange.endDate) {
            additionalRequestParams.set("to", filterSelections.DateRange.endDate.format());
        }
        if (isNotEmpty(filterSelections.RegardingAny)) {
            additionalRequestParams.set("regardingAny", filterSelections.RegardingAny);
        }
        return additionalRequestParams;
    }

    private async reload() {
        const filterSelections = this.state.filterSelections;

        function isNotEmpty<T>(items: T[]) {
            return items.length !== 0;
        }
        // Recall API.
        const eventListOptions: AuditQuery & { asCsv?: boolean; excludeDifference?: boolean } = {};
        if (isNotEmpty(filterSelections.Users)) {
            eventListOptions.users = filterSelections.Users;
        }
        if (isNotEmpty(filterSelections.Projects)) {
            eventListOptions.projects = filterSelections.Projects;
        }
        if (isNotEmpty(filterSelections.ProjectGroups)) {
            eventListOptions.projectGroups = filterSelections.ProjectGroups;
        }
        if (isNotEmpty(filterSelections.Environments)) {
            eventListOptions.environments = filterSelections.Environments;
        }
        if (isNotEmpty(filterSelections.EventGroups)) {
            eventListOptions.eventGroups = filterSelections.EventGroups;
        }
        if (isNotEmpty(filterSelections.EventCategories)) {
            eventListOptions.eventCategories = filterSelections.EventCategories;
        }
        if (isNotEmpty(filterSelections.EventAgents)) {
            eventListOptions.eventAgents = filterSelections.EventAgents;
        }
        if (isNotEmpty(filterSelections.Tags)) {
            eventListOptions.tags = filterSelections.Tags;
        }
        if (isNotEmpty(filterSelections.Tenants)) {
            eventListOptions.tenants = filterSelections.Tenants;
        }
        if (isNotEmpty(filterSelections.DocumentTypes)) {
            eventListOptions.documentTypes = filterSelections.DocumentTypes;
        }

        eventListOptions.spaces = getSpacesFilter();
        eventListOptions.includeSystem = filterSelections.IncludeSystem ? "true" : "false";
        eventListOptions.from = filterSelections.DateRange.startDate.format();
        eventListOptions.to = filterSelections.DateRange.endDate.format();
        if (isNotEmpty(filterSelections.RegardingAny)) {
            eventListOptions.regardingAny = filterSelections.RegardingAny;
        }
        eventListOptions.excludeDifference = true;

        await this.doBusyTask(async () => {
            const eventPromise = isAllowed({ permission: Permission.EventView, wildcard: true }) ? repository.Events.list(eventListOptions) : Promise.resolve([]);

            const eventAgents = await repository.Events.eventAgents();
            this.props.lookupData.eventAgents = eventAgents;

            await this.requestRaceConditioner.avoidStaleResponsesForRequest(eventPromise as Promise<ResourceCollection<EventResource>>, (response) => {
                const eventsResponse = response as ResourceCollection<EventResource>;
                eventListOptions.asCsv = true;
                const exportUrl = client.resolveLinkTemplate("Events", eventListOptions);
                const additionalRequestParams = this.getAdditionalRequestParams();

                this.setState({
                    eventsResponse,
                    exportUrl,
                    additionalRequestParams,
                });
            });
        });

        function getSpacesFilter() {
            const hasEventViewInAnySpace = session.currentPermissions!.hasPermissionInAnyScope(Permission.EventView);

            if (filterSelections.Spaces.length === 0) {
                if (hasEventViewInAnySpace) {
                    return ["all"];
                } else {
                    return [];
                }
            }

            return filterSelections.Spaces;
        }
    }

    private convertDateOptionToDateRange(option: DateOption): DateRange {
        const startOfToday = moment().startOf("day");
        const endOfToday = moment().endOf("day");

        switch (option) {
            case DateOption.Today:
                return { startDate: startOfToday, endDate: endOfToday };
            case DateOption.Last7Days:
                return { startDate: startOfToday.subtract(7, "days"), endDate: endOfToday };
            case DateOption.Last30Days:
                return { startDate: startOfToday.subtract(30, "days"), endDate: endOfToday };
            case DateOption.Last90Days:
                return { startDate: startOfToday.subtract(90, "days"), endDate: endOfToday };
            default:
                return { startDate: this.state.filterSelections.DateRange.startDate.clone(), endDate: this.state.filterSelections.DateRange.endDate.clone() };
        }
    }

    // We may not want the dates to be on this object, so that when the user hits the reset button, the dates are unaffected
    // Although on the other hand, you probably want them to 'reset' to the default range
    // to be consistent with the rest of the app... This is the way it is currently implemented
    private getDefaultFilter(): AuditFilter {
        const hasEventViewInCurrentSpace = session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.EventView);
        const shouldFilterToCurrentSpace = client.spaceId && hasEventViewInCurrentSpace;
        const includeSystem = true;
        const defaultDateConfig = this.getDefaultDateConfiguration();

        return {
            Users: [],
            Projects: [],
            ProjectGroups: [],
            Environments: [],
            EventGroups: [],
            EventCategories: [],
            EventAgents: [],
            Tenants: [],
            Tags: [],
            DocumentTypes: [],
            DateOption: defaultDateConfig.option,
            DateRange: {
                startDate: defaultDateConfig.range.startDate,
                endDate: defaultDateConfig.range.endDate,
            },
            RegardingAny: [],
            Spaces: shouldFilterToCurrentSpace && client.spaceId ? [client.spaceId] : [],
            IncludeSystem: includeSystem,
        };
    }
}

export default AuditPage;
