/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import classnames = require("classnames");
import { sortBy } from "lodash";
import * as React from "react";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import UseLabelStrategy from "~/components/LabelStrategy/LabelStrategy";
import { withTheme } from "~/components/Theme";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import type FormFieldProps from "../../../components/form/FormFieldProps";
import { SelectField } from "./SelectField";
import styles from "./style.module.less";

export type Item = DropdownMenuOption;

export interface OtherSelectProps {
    fixedLabel?: boolean;
    items: Item[];
    placeholder?: string;
    allowClear?: boolean;
    allowFilter?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string | null;
    warning?: string;
    className?: any;
    empty?: string;
    selectionRenderer?: (value: string, menuItem: any) => React.ReactNode;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onFilterChanged?(value: string): Promise<Item[]>;
    sortItems?: boolean;
    highContrastMode?: boolean;
    labelStyles?: any;
}

export type SelectProps = OtherSelectProps & FormFieldProps<string | undefined>;

interface SelectState {
    error?: string;
    filter?: string;
    filteredItems: Item[];
    showExternalError: boolean;
}

//eslint-disable-next-line react/no-unsafe
class SelectInternal extends React.Component<OtherSelectProps & FormFieldProps<string | undefined>, SelectState> {
    disabled: boolean = undefined!;
    constructor(props: OtherSelectProps & FormFieldProps<string | undefined>) {
        super(props);
        this.state = {
            filter: null!,
            filteredItems: null!,
            showExternalError: true,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: SelectProps) {
        const isNewExternalErrorAvailable = nextProps.error !== this.props.error;
        if (isNewExternalErrorAvailable) {
            this.setState({ showExternalError: true });
        }
    }

    render() {
        return withTheme((theme) => {
            const { value, label, highContrastMode, fixedLabel, items, error, onChange, onValidate, validate, warning, placeholder, allowClear, allowFilter, className, sortItems = true, ...otherProps } = this.props;
            const itemsInList = sortItems ? sortBy(this.state.filteredItems || items, (x) => x.text.toLowerCase()) : this.state.filteredItems || items;
            const err = this.state.error || (this.state.showExternalError && error);
            const errorText = err || warning;

            // We need to hack the labelStyle to stop MaterialUI from overflowing other controls that may be sitting underneath this.
            const labelStyle = {
                height: "40px",
                lineHeight: "40px",
                ...otherProps.labelStyles,
            };

            return (
                <div className={styles.container}>
                    <SelectField
                        className={classnames(className, this.props.disabled ? styles.isDisabled : styles.select)}
                        value={value}
                        selectedValueAccessibleName={items.find((i) => i.value === value)?.text ?? value}
                        allowClear={allowClear}
                        labelStyle={labelStyle}
                        onChange={this.handleChange}
                        floatingLabelText={label}
                        highContrastMode={highContrastMode}
                        placeholder={placeholder}
                        errorText={errorText}
                        selectedMenuItemStyle={{ color: theme.primary }}
                        dropDownMenuProps={{ onClose: this.handleOnClose }}
                        iconStyle={{ fill: theme.secondaryText }}
                        filter={
                            allowFilter && (
                                <div className={styles.filter}>
                                    <FilterSearchBox placeholder={"Find..."} autoFocus={true} value={this.state.filter} onChange={this.handleFilterChanged} fullWidth={true} />
                                </div>
                            )
                        }
                        disabled={otherProps.disabled}
                        selectionRenderer={otherProps.selectionRenderer}
                        autoFocus={otherProps.autoFocus}
                        items={itemsInList}
                    />
                </div>
            );
        });
    }

    private handleChange = (value: string) => {
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState((prev) => ({ ...prev, error: result }));
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }

        this.setState((prev) => ({
            ...prev,
            filter: null!,
            filteredItems: null!,
            showExternalError: false,
        }));

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    private handleFilterChanged = async (value: string) => {
        let filteredItems: Item[] = [];
        if (this.props.onFilterChanged) {
            filteredItems = await this.props.onFilterChanged(value);
        } else {
            filteredItems = this.props.items.filter((item) => {
                return item.text.toLowerCase().search(value.toLowerCase()) !== -1;
            });
        }

        this.setState((prev) => {
            return {
                ...prev,
                filter: value,
                filteredItems,
            };
        });
    };

    private handleOnClose = () => {
        if (this.state.filter) {
            this.setState({
                filter: null!,
                filteredItems: null!,
            });
        }
    };
}

export const Select = UseLabelStrategy(SelectInternal, (fieldName) => `Select ${fieldName}`);
export { SelectInternal };
export const BoundSelect = withBoundField(Select);
export default Select;
