import { omit } from "lodash";
import type { ByIdLookup } from "../types";

export const patch = <TModel extends {}>(lookup: { [key: string]: TModel }, key: string, updater: (prev: TModel) => TModel) => {
    if (!lookup[key]) {
        return lookup;
    }

    return {
        ...lookup,
        [key]: {
            ...lookup[key],
            ...updater(lookup[key]),
        },
    };
};

export const add = <TModel extends {}>(lookup: { [key: string]: TModel }, key: string, model: TModel) => {
    return {
        ...lookup,
        [key]: model,
    };
};

export const remove = <TModel extends {}>(lookup: { [key: string]: TModel }, key: string) => {
    return !lookup[key] ? lookup : omit(lookup, key);
};

export const setByIdState = <TModel extends {}, TState extends ByIdLookup<TModel>>(state: TState, updater: (prev: TState["byId"]) => TState["byId"]): TState => {
    return {
        ...state,
        byId: updater(state.byId),
    };
};

export const patchByIdState = <TModel extends {}, TState extends ByIdLookup<TModel>>(state: TState, patchModel: TState["byId"]) => {
    return setByIdState(state, (prev) => ({ ...prev, ...patchModel }));
};

export const setById = <TModel extends {}, TState extends ByIdLookup<TModel>>(state: TState, id: string, value: TModel) => {
    return setByIdState(state, (prev) => ({
        ...prev,
        [id]: value,
    }));
};

export const addById = <TModel extends {}, TState extends ByIdLookup<TModel>>(state: TState, id: string, model: TModel): TState => {
    return patchByIdState(state, { [id]: model });
};

export const deleteById = <TModel extends {}, TState extends ByIdLookup<TModel>>(state: TState, id: string): TState => {
    return setByIdState(state, (prev) => (!prev[id] ? prev : omit(prev, id)));
};

export const deleteAllIds = <TModel extends {}, TState extends ByIdLookup<TModel>>(state: TState, ids: string[]): TState => {
    return ids.reduce((prev, current) => deleteById(prev, current), state);
};

type LookupType<T> = T extends ByIdLookup<infer TType> ? TType : unknown;

export const patchById = <TState extends ByIdLookup<TModel>, TModel extends {}, TInferredModel extends LookupType<TState>>(
    state: TState,
    id: string,
    patcher: <TSubset extends keyof TModel>(prev: TInferredModel) => Pick<TModel, TSubset> | TModel
): TState => {
    return setByIdState(state, (prev) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return !prev[id] ? prev : { ...prev, [id]: { ...prev[id], ...patcher((prev[id] as unknown) as TInferredModel) } };
    });
};

export const addBefore = <TItem>(items: TItem[], target: TItem | undefined | null, itemToAdd: TItem) => {
    const index: number | undefined = target ? items.indexOf(target) : undefined;
    return !!index && index >= 0 ? [...items.slice(0, index), itemToAdd, ...items.slice(index)] : [...items, itemToAdd];
};

export const addAfter = <TItem>(items: TItem[], target: TItem | undefined | null, itemToAdd: TItem) => {
    const index: number | undefined = target ? items.indexOf(target) : undefined;
    return !!index && index >= 0 && index !== items.length - 1 ? [...items.slice(0, index + 1), itemToAdd, ...items.slice(index + 1)] : [...items, itemToAdd];
};
