import { cloneDeep } from "lodash";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import type { AnalyticLogoDispatcher } from "~/analytics/Analytics";
import { Action, LogoType, useAnalyticLogoDispatch } from "~/analytics/Analytics";
import IconAndLogoEditLayout, { LogoTypeSelection } from "~/areas/infrastructure/components/IconAndLogoEditLayout/IconAndLogoEditLayout";
import CloneProject from "~/areas/projects/components/Projects/CloneProject";
import { saveIcon, saveLogo } from "~/client/repositories/logoUpload";
import type { ChannelResource, IconMetadataResource, IconSvgResource, ProjectGroupResource, ProjectResource, ProjectSettingsMetadata, ResourceCollection } from "~/client/resources";
import { Permission, TenantedDeploymentMode } from "~/client/resources";
import type { ExtensionSettingsValues } from "~/client/resources/extensionSettingsValues";
import { repository } from "~/clientInstance";
import DynamicForm from "~/components/DynamicForm/DynamicForm";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import { List } from "~/components/List/List";
import Logo from "~/components/Logo/Logo";
import Markdown from "~/components/Markdown";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import { BooleanRadioButtonGroup, Checkbox, ExpandableFormSection, FormSectionHeading, LogoEditor, MarkdownEditor, Note, RadioButton, Select, Summary, Text, UnstructuredFormSection } from "~/components/form";
import type { LogoEditorSettings, SummaryNode } from "~/components/form";
import type { IconEditorSettings } from "~/components/form/IconEditor/IconEditor";
import IconEditor, { IconEditorDefaultColor } from "~/components/form/IconEditor/IconEditor";
import { required } from "~/components/form/Validators";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import routeLinks from "~/routeLinks";
import useIsMultiTenancyEnabledFeatureFlag from "../../../configuration/hooks/useIsMultiTenancyEnabledFeatureFlag";
import type { WithProjectContextInjectedProps } from "../../context";
import { useProjectContext } from "../../context";
import { IsAllowedToSeeDeploymentsOverview, OverviewRedirectPathForProject } from "../ProjectsRoutes/ProjectToOverviewRedirect";
import DeleteProject from "./DeleteProject";

enum CloneVisibility {
    NotCloned = "NotCloned",
    Available = "Available",
    NotFound = "NotFound",
    AccessDenied = "AccessDenied",
}

interface ClonedFromProjectDetails {
    clonedFromProject: ProjectResource | null;
    cloneVisibility: CloneVisibility;
}

interface ProjectModel {
    name: string;
    description: string;
    projectGroupId: string;
    tenantedDeploymentMode: TenantedDeploymentMode;
    discreteChannelRelease: boolean;
    logo: LogoEditorSettings;
    icon: IconEditorSettings;
    isDisabled: boolean;
    clonedFromProjectId: string;
    extensionSettings: ExtensionSettingsValues[];
}

interface ProjectSettingsState extends OptionalFormBaseComponentState<ProjectModel> {
    projectGroups: ProjectGroupResource[];
    channels: ChannelResource[];
    project: ProjectResource | null;
    redirectTo: string;
    clonedFromProjectDetails: ClonedFromProjectDetails | null;
    clonedProjectsCollection: ResourceCollection<ProjectResource> | null;
    metadata: ProjectSettingsMetadata[];
    canDelete: boolean;
    iconSvgResources: IconSvgResource[];
    iconMetadata: IconMetadataResource;
    logoTypeSelection: LogoTypeSelection;
}

class TenantedDeploymentModeRadioButtonGroup extends RadioButtonGroup<TenantedDeploymentMode> {}

class ClonedProjectsList extends List<ProjectResource> {}

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

interface ProjectSettingsAnalyticsProps {
    dispatchAction: AnalyticLogoDispatcher;
}

type MatchProps = { match: NonNullable<ReturnType<typeof useRouteMatch>> | undefined };
type ProjectSettingsInternalProps = MatchProps & GlobalConnectedProps & WithProjectContextInjectedProps & ProjectSettingsAnalyticsProps;

class ProjectSettingsInternal extends FormBaseComponent<ProjectSettingsInternalProps, ProjectSettingsState, ProjectModel> {
    constructor(props: ProjectSettingsInternalProps) {
        super(props);

        this.state = {
            projectGroups: [],
            channels: [],
            project: null,
            redirectTo: "",
            clonedFromProjectDetails: {
                cloneVisibility: CloneVisibility.NotCloned,
                clonedFromProject: null,
            },
            clonedProjectsCollection: null,
            metadata: [],
            canDelete: false,
            iconSvgResources: [],
            iconMetadata: {
                categories: {},
            },
            logoTypeSelection: LogoTypeSelection.NotSet,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project } = this.props.projectContext.state;

            // Set the project early. If any of the remaining promises fail, at least our overflow menu will populate.
            this.setState({
                project,
                model: this.buildModel(project),
                cleanModel: this.buildModel(project),
            });

            const hasProcessViewPermissions = isAllowed({
                permission: Permission.ProcessView,
                project: project.Id,
                tenant: "*",
            });

            const [projectGroups, clonedProjectsCollection, channels, metadata, iconSvgResources, iconMetadata] = await Promise.all<
                ProjectGroupResource[],
                ResourceCollection<ProjectResource>,
                ResourceCollection<ChannelResource> | null,
                ProjectSettingsMetadata[],
                IconSvgResource[],
                IconMetadataResource
            >([
                isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" }) ? repository.ProjectGroups.all() : Promise.resolve<ProjectGroupResource[]>([]),
                repository.Projects.list({ clonedFromProjectId: project.Id }),
                hasProcessViewPermissions ? repository.Projects.getChannels(project) : Promise.resolve(null),
                repository.Projects.getMetadata(project),
                repository.Icons.getIcons(),
                repository.Icons.getIconMetadata(),
            ]);

            let clonedFromProject: ProjectResource | null = null;
            let cloneVisibility = CloneVisibility.NotCloned;
            if (project.ClonedFromProjectId) {
                const canAccessClone = isAllowed({ permission: Permission.ProjectView, project: project.ClonedFromProjectId, wildcard: true });
                cloneVisibility = canAccessClone ? CloneVisibility.Available : CloneVisibility.AccessDenied;

                if (canAccessClone) {
                    try {
                        clonedFromProject = await repository.Projects.get(project.ClonedFromProjectId);
                    } catch (error) {
                        // if it's any other error let it go, if we failed to load due to bad data, just move on with 'NotFound'
                        if (error.StatusCode !== 404) {
                            throw error;
                        }
                        cloneVisibility = CloneVisibility.NotFound;
                    }
                }
            }

            this.setState({
                projectGroups,
                channels: channels ? channels.Items : [],
                clonedFromProjectDetails: {
                    clonedFromProject,
                    cloneVisibility,
                },
                clonedProjectsCollection,
                metadata,
                iconSvgResources,
                iconMetadata,
            });
        });
    }

    buildModel(project: ProjectResource): ProjectModel {
        return {
            name: project.Name,
            description: project.Description,
            projectGroupId: project.ProjectGroupId,
            tenantedDeploymentMode: project.TenantedDeploymentMode,
            discreteChannelRelease: project.DiscreteChannelRelease,
            logo: { file: undefined, reset: false },
            icon: {
                iconId: project.Icon?.Id ?? "",
                iconColor: project.Icon?.Color ?? IconEditorDefaultColor,
            },
            isDisabled: project.IsDisabled,
            clonedFromProjectId: project.ClonedFromProjectId,
            extensionSettings: cloneDeep(project.ExtensionSettings),
        };
    }

    handleSaveClick = async () => {
        const model = this.state.model;

        if (!model || !this.state.project) {
            throw "no model loaded";
        }

        const project: ProjectResource = {
            ...this.state.project,
            Name: model.name,
            Description: model.description,
            ProjectGroupId: model.projectGroupId,
            TenantedDeploymentMode: model.tenantedDeploymentMode,
            DiscreteChannelRelease: model.discreteChannelRelease,
            IsDisabled: model.isDisabled,
            ClonedFromProjectId: model.clonedFromProjectId,
            ExtensionSettings: cloneDeep(model.extensionSettings),
            Icon: {
                Id: model.icon.iconId,
                Color: model.icon.iconColor,
            },
        };

        const { dispatchAction } = this.props;

        await this.doBusyTask(async () => {
            if (this.state.logoTypeSelection === LogoTypeSelection.Icon) {
                dispatchAction("Save Logo", { resource: "Project", logoType: LogoType.icon, action: Action.Save });
                await saveIcon(project, model.icon?.iconId, model.icon?.iconColor);
            } else if (this.state.logoTypeSelection === LogoTypeSelection.CustomImage) {
                dispatchAction("Save Logo", { resource: "Project", logoType: LogoType.customImage, action: Action.Save });
                await saveLogo(project, model.logo.file, model.logo.reset || false);
            }

            await this.saveProject(project);
        });
    };

    descriptionSummary() {
        return this.state.model?.description ? Summary.summary(<Markdown markup={this.state.model.description} />) : Summary.placeholder("No project description provided");
    }

    renderCloneDetails() {
        const visibility = this.state.clonedFromProjectDetails?.cloneVisibility;

        if (!visibility) {
            return null;
        }
        if (visibility === CloneVisibility.NotFound) {
            const clonedFromId = this.state.project?.ClonedFromProjectId;
            return <div>This project was originally cloned from a project ({clonedFromId}) that cannot be found.</div>;
        } else if (visibility === CloneVisibility.AccessDenied) {
            return <div>This project was originally cloned from a project that you do not have {Permission.ProjectView} for.</div>;
        } else if (visibility === CloneVisibility.Available) {
            if (this.state.clonedFromProjectDetails?.clonedFromProject) {
                return (
                    <div>
                        This project was originally cloned from <InternalLink to={routeLinks.project(this.state.clonedFromProjectDetails.clonedFromProject).root}>{this.state.clonedFromProjectDetails.clonedFromProject.Name}</InternalLink>.
                    </div>
                );
            }
        }
        return null;
    }

    getOverFlowActions(): MenuItem[] {
        const overFlowActions: MenuItem[] = [];

        if (!this.state.model || !this.state.project) {
            return [];
        }

        overFlowActions.push(OverflowMenuItems.item(this.state.model.isDisabled ? "Enable" : "Disable", this.handleEnabledToggle, this.editPermission()));
        if (!this.state.project.IsVersionControlled) {
            overFlowActions.push(
                OverflowMenuItems.dialogItem("Clone", <CloneProject clone={this.state.project} projectCreated={(project) => this.setState({ redirectTo: OverviewRedirectPathForProject(project, undefined) })} />, this.clonePermission())
            );
        }

        overFlowActions.push(
            OverflowMenuItems.deleteItem(
                "Delete",
                "Are you sure you want to delete this project?",
                this.handleDeleteConfirm,
                (dialogDoBusyTask) => <DeleteProject doBusyTask={dialogDoBusyTask} projectName={this.state.project?.Name || ""} projectId={this.state.project?.Id || ""} onChange={this.onDeleteProjectChanged} />,
                this.deletePermission(),
                !this.state.canDelete
            )
        );
        overFlowActions.push(
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForProject(this.state.project.Id), {
                permission: Permission.EventView,
                wildcard: true,
            })
        );

        return overFlowActions;
    }

    shouldForceDisableFormSaveButton() {
        return this.state.logoTypeSelection === LogoTypeSelection.Icon && !this.state.model?.icon?.iconId;
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        if (!this.state.model || !this.state.project) {
            return null;
        }

        const isProjectCloned = this.state.clonedFromProjectDetails?.cloneVisibility !== CloneVisibility.NotCloned;
        const includesCloneInformation = (this.state.model && isProjectCloned) || (this.state.clonedProjectsCollection && this.state.clonedProjectsCollection.TotalResults > 0);
        const clonedFromElement = this.state.model && isProjectCloned && this.renderCloneDetails();
        const canViewDeploymentsMenu = IsAllowedToSeeDeploymentsOverview(this.state.project ? this.state.project.Id : null);

        return (
            <FormPaperLayout
                title="Settings"
                breadcrumbTitle={this.state.project.Name}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={this.editPermission()}
                onSaveClick={this.handleSaveClick}
                overFlowActions={this.getOverFlowActions()}
                saveText="Project details updated"
                forceDisableFormSaveButton={this.shouldForceDisableFormSaveButton()}
            >
                {this.state.cleanModel?.isDisabled && (
                    <UnstructuredFormSection stretchContent={true}>
                        <Callout type={CalloutType.Warning} title="This project is currently disabled" />
                    </UnstructuredFormSection>
                )}
                <UnstructuredFormSection stretchContent={true}>
                    <Callout type={CalloutType.Information} title="Some settings have been moved">
                        If you cannot find some settings on this page, please go to the <InternalLink to={routeLinks.project(this.state.project).deployments.settings}>Deployment Settings</InternalLink> to start configuring.
                    </Callout>
                </UnstructuredFormSection>
                <ExpandableFormSection
                    errorKey="name"
                    title="Name"
                    focusOnExpandAll
                    summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your project")}
                    help="Enter a name for your project."
                >
                    <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Project name" error={this.getFieldError("name")} validate={required("Please enter a project name")} />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="IsDisabled" title="Enabled" summary={this.state.model.isDisabled ? Summary.summary("No") : Summary.default("Yes")} help="Disable a project to prevent releases or deployments from being created.">
                    <Checkbox value={!this.state.model.isDisabled} onChange={(isDisabled) => this.setModelState({ isDisabled: !isDisabled })} label="Enabled" />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="logo" title="Logo" summary={this.logoSummary()} help="Choose an icon or upload a custom image.">
                    <IconAndLogoEditLayout
                        iconEditor={
                            <IconEditor
                                icons={this.state.iconSvgResources}
                                iconMetadata={this.state.iconMetadata}
                                selectedIconId={this.state.model.icon?.iconId}
                                selectedIconColor={this.state.model.icon?.iconColor}
                                onIconIdChange={(iconId) => {
                                    this.setState({ logoTypeSelection: LogoTypeSelection.Icon });
                                    this.setModelState({ icon: { iconId: iconId, iconColor: this.state.model?.icon?.iconColor ?? IconEditorDefaultColor }, logo: { file: undefined, reset: false } });
                                }}
                                onIconColorChange={(iconColor) => {
                                    this.setState({ logoTypeSelection: LogoTypeSelection.Icon });
                                    this.setModelState({ icon: { iconId: this.state.model?.icon?.iconId ?? "", iconColor: iconColor } });
                                }}
                            />
                        }
                        logoEditor={
                            <LogoEditor
                                value={this.state.model.logo}
                                onChange={(logo) => {
                                    this.setState({ logoTypeSelection: LogoTypeSelection.CustomImage });
                                    this.setModelState({ logo, icon: { iconId: "", iconColor: IconEditorDefaultColor } });
                                }}
                            />
                        }
                        onTabChange={(logoType) => this.setState({ logoTypeSelection: logoType })}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your project.">
                    <MarkdownEditor value={this.state.model.description} label="Project description" onChange={(description) => this.setModelState({ description })} />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="projectGroupId" title="Project Group" summary={this.projectGroupSummary()} help="Select which project group this project belongs to.">
                    <Select
                        value={this.state.model.projectGroupId}
                        onChange={(projectGroupId) => this.setModelState({ projectGroupId: projectGroupId || "" })}
                        items={this.state.projectGroups.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                        label="Project group"
                    />
                </ExpandableFormSection>
                {canViewDeploymentsMenu && (
                    <>
                        {(this.props.isMultiTenancyEnabled || this.state.cleanModel?.tenantedDeploymentMode !== TenantedDeploymentMode.Untenanted) &&
                            isAllowed({ permission: Permission.TenantView, tenant: "*", project: this.state.project && this.state.project.Id }) && (
                                <ExpandableFormSection errorKey="tenantedDeploymentMode" title="Multi-tenant Deployments" summary={this.tenantedDeploymentModeSummary()} help="Choose to enable or disable tenanted deployments of this project.">
                                    <TenantedDeploymentModeRadioButtonGroup value={this.state.model.tenantedDeploymentMode} onChange={(tenantedDeploymentMode) => this.setModelState({ tenantedDeploymentMode })}>
                                        <RadioButton value={TenantedDeploymentMode.Untenanted} label="Disable tenanted deployments" isDefault={true} />
                                        <RadioButton value={TenantedDeploymentMode.TenantedOrUntenanted} label="Allow deployments with or without a tenant" />
                                        <RadioButton value={TenantedDeploymentMode.Tenanted} label="Require a tenant for all deployments" />
                                    </TenantedDeploymentModeRadioButtonGroup>
                                    <Note>
                                        <ExternalLink href="ProjectTenantedDeploymentMode">Learn more about tenanted deployment modes</ExternalLink>
                                    </Note>
                                </ExpandableFormSection>
                            )}

                        {this.state.channels.length > 1 && (
                            <ExpandableFormSection
                                errorKey="discreteChannelRelease"
                                title="Discrete Channel Releases"
                                summary={this.discreteChannelReleaseSummary()}
                                help="Choose if channel release are treated independently or for the entire project."
                            >
                                <BooleanRadioButtonGroup value={this.state.model.discreteChannelRelease} onChange={(discreteChannelRelease) => this.setModelState({ discreteChannelRelease })} label="Discrete channel releases">
                                    <RadioButton value={false} label="Considered for the entire project" isDefault={true} />
                                    <Note>
                                        Any channel release will supersede existing channel releases.
                                        <ExternalLink href="WalkthroughChannelHotfix"> Learn more about hotfix deployments</ExternalLink>
                                    </Note>
                                    <RadioButton value={true} label="Treat independently from other channels" />
                                    <Note>Releases from different channels will be treated independently when displayed on the dashboard and when applying retention policies.</Note>
                                </BooleanRadioButtonGroup>
                                <Note>
                                    Selecting <code>Treat independently from other channels</code> will cause the latest releases from each channel to be displayed on the dashboard.
                                    <br />
                                    See the <ExternalLink href="DiscreteChannelReleases">documentation</ExternalLink> for more information.
                                </Note>
                            </ExpandableFormSection>
                        )}
                    </>
                )}
                {includesCloneInformation && (
                    <>
                        <FormSectionHeading title="Cloning History" />
                        {isProjectCloned && <ExpandableFormSection errorKey="ClonedFrom" title="Cloned From" summary={Summary.summary(clonedFromElement)} help={clonedFromElement} />}
                        {this.state.clonedProjectsCollection && this.state.clonedProjectsCollection.TotalResults > 0 && (
                            <ExpandableFormSection errorKey="ClonedProjects" title="Cloned Projects" summary={Summary.summary("This project was cloned to create other projects.")} help="This project was cloned to create the following projects.">
                                <Section>
                                    <ClonedProjectsList
                                        initialData={this.state.clonedProjectsCollection}
                                        onRow={(project: ProjectResource) => {
                                            return <ListTitle>{project.Name}</ListTitle>;
                                        }}
                                        onRowRedirectUrl={(project: ProjectResource) => routeLinks.project(project).root}
                                        filterSearchEnabled={false}
                                        autoFocusOnFilterSearch={false}
                                        apiSearchParams={["partialName"]}
                                        match={this.props.match}
                                        showPagingInNumberedStyle={true}
                                    />
                                </Section>
                            </ExpandableFormSection>
                        )}
                    </>
                )}
                <ExtensionSettings
                    metadata={this.state.metadata}
                    extensionSettings={this.state.model.extensionSettings}
                    refreshModel={() =>
                        this.setState({
                            model: this.state.model,
                        })
                    }
                />
            </FormPaperLayout>
        );
    }

    private onDeleteProjectChanged = (canDelete: boolean) => {
        this.setState({ canDelete });
    };

    private logoSummary(): SummaryNode {
        if (!this.state.project || this.state.model?.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model?.logo.file) {
            return Summary.summary(this.state.model.logo.file.name);
        }
        return Summary.summary(<Logo url={this.state.project.Links.Logo} size="2.5em" />);
    }

    private projectGroupSummary(): SummaryNode {
        const projectGroup = this.state.projectGroups.find((g) => g.Id === this.state.model?.projectGroupId);
        return projectGroup ? Summary.summary(projectGroup.Name) : Summary.placeholder("No project group selected");
    }

    private tenantedDeploymentModeSummary(): SummaryNode {
        switch (this.state.model?.tenantedDeploymentMode) {
            case TenantedDeploymentMode.Untenanted:
                return Summary.default("No tenanted deployments");
            case TenantedDeploymentMode.TenantedOrUntenanted:
                return Summary.summary("Both tenanted and untenanted deployments allowed");
            case TenantedDeploymentMode.Tenanted:
                return Summary.summary("Tenants required for all deployments");
            default:
                return Summary.placeholder("Please select");
        }
    }

    private discreteChannelReleaseSummary(): SummaryNode {
        return this.state.model?.discreteChannelRelease ? Summary.summary("Treat independently from other channels (feature branch style)") : Summary.default("Any channel release will supersede existing channel releases (hotfix style)");
    }

    private clonePermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectCreate,
            projectGroup: this.state.project?.ProjectGroupId,
        };
    }

    private deletePermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectDelete,
            project: this.state.project?.Id,
            tenant: "*",
        };
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project?.Id,
            tenant: "*",
        };
    }

    private handleDeleteConfirm = async () => {
        if (!this.state.project) {
            throw "No project to delete";
        }
        await repository.Projects.del(this.state.project);
        this.setState({ redirectTo: routeLinks.projects.root });
        return true;
    };

    private async saveProject(project: ProjectResource) {
        const result = await repository.Projects.save(project);
        await this.props.projectContext.actions.onProjectUpdated(result, this.props.projectContext.state.gitRef);

        const projectNameHasChanged = this.state.cleanModel?.name !== result.Name;
        const redirectTo = projectNameHasChanged ? routeLinks.project(result).settings.root : null;

        this.setState(() => {
            return {
                model: this.buildModel(result),
                cleanModel: this.buildModel(result),
                project: result,
                redirectTo,
            };
        });
    }

    private handleEnabledToggle = async () => {
        if (!this.state.model || !this.state.project) {
            throw "No project loaded";
        }

        const project: ProjectResource = {
            ...this.state.project,
            IsDisabled: !this.state.model.isDisabled,
        };
        await this.doBusyTask(async () => {
            await this.saveProject(project);
        });
    };
}

const ExtensionSettings: React.FC<{ metadata: ProjectSettingsMetadata[]; extensionSettings: ExtensionSettingsValues[]; refreshModel: () => void }> = (props: {
    metadata: ProjectSettingsMetadata[];
    extensionSettings: ExtensionSettingsValues[];
    refreshModel: () => void;
}) => {
    return (
        <>
            {props.metadata.map((m) => {
                let valuesForExtension = props.extensionSettings.find((e) => e.ExtensionId === m.ExtensionId);
                if (!valuesForExtension || !valuesForExtension.Values) {
                    valuesForExtension = {
                        ExtensionId: m.ExtensionId,
                        Values: {},
                    };

                    props.extensionSettings.push(valuesForExtension);
                }

                return (
                    <div key={m.ExtensionId}>
                        <FormSectionHeading title={m.Metadata.Description} />
                        <DynamicForm types={m.Metadata.Types} values={valuesForExtension.Values} onChange={props.refreshModel} />
                    </div>
                );
            })}
        </>
    );
};

const ProjectSettings: React.FC = () => {
    const match = useRouteMatch() ?? undefined;
    const isMultiTenancyEnabled = useIsMultiTenancyEnabledFeatureFlag();
    const projectContext = useProjectContext();

    const dispatchAction = useAnalyticLogoDispatch();

    return <ProjectSettingsInternal match={match} isMultiTenancyEnabled={isMultiTenancyEnabled} projectContext={projectContext} dispatchAction={dispatchAction} />;
};

export default ProjectSettings;
