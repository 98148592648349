import React from "react";
import type { ThemePaletteType } from "~/theme";
import { noOp } from "../../utils/noOp";

export type ChangeThemePalette = React.Dispatch<React.SetStateAction<ThemePaletteType>>;

type ThemePaletteContextState = {
    palette: ThemePaletteType;
    onChangePalette: ChangeThemePalette;
};

const ThemePaletteContext = React.createContext<ThemePaletteContextState>({ onChangePalette: noOp, palette: "light" });

type ThemePaletteProviderProps = React.PropsWithChildren<{
    palette: ThemePaletteType;
    onChangePalette: ChangeThemePalette;
}>;

export function ThemePaletteProvider({ children, palette, onChangePalette }: ThemePaletteProviderProps) {
    const contextValue = React.useMemo(() => ({ palette, onChangePalette }), [palette, onChangePalette]);

    return <ThemePaletteContext.Provider value={contextValue}>{children}</ThemePaletteContext.Provider>;
}

export function useThemePalette(): [ThemePaletteType, ChangeThemePalette] {
    const { palette, onChangePalette } = React.useContext(ThemePaletteContext);
    return [palette, onChangePalette];
}
