/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ActionTemplateParameterResource, NamedResource, NewSpaceScopedResource, ResourceWithLinks, SensitiveValue, SpaceScopedResource, TenantedDeploymentMode } from "~/client/resources";
import type { IconResource } from "~/client/resources/iconResource";
import type { DeploymentActionPackageResource } from "./deploymentActionPackageResource";
import type { MetadataTypeCollection } from "./dynamicFormResources";
import type { ExtensionSettingsValues } from "./extensionSettingsValues";
import type { GitRef } from "./versionControlledResource";

interface BaseProjectResourceLinks {
    Self: string;
    Releases: string;
    Channels: string;
    Triggers: string;
    ScheduledTriggers: string;
    OrderChannels: string;
    Variables: string;
    Progression: string;
    RunbookTaskRunDashboardItemsTemplate: string;
    DeploymentProcess: string;
    Web: string;
    Logo: string;
    Metadata: string;
    Runbooks: string;
    RunbookSnapshots: string;
    Summary: string;
    DeploymentSettings: string;
}

type VcsProjectResourceLinks = BaseProjectResourceLinks & {
    Branches: string;
    Commits: string;
    Tags: string;
};

type DatabaseProjectResourceLinks = BaseProjectResourceLinks & {
    ConvertToGit: string;
};

type ProjectResourceLinks = VcsProjectResourceLinks | DatabaseProjectResourceLinks;

export enum PersistenceSettingsType {
    VersionControlled = "VersionControlled",
    Database = "Database",
}

interface DatabasePersistenceSettings {
    Type: PersistenceSettingsType.Database;
}

export interface GitPersistenceSettings {
    Type: PersistenceSettingsType.VersionControlled;
    Credentials: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials;
    ConversionState: ConversionState;
    Url: string;
    DefaultBranch: string;
    BasePath: string;
}

export enum AuthenticationType {
    Anonymous = "Anonymous",
    UsernamePassword = "UsernamePassword",
    Reference = "Reference",
}

export interface ReferenceVcsCredentials {
    Type: AuthenticationType.Reference;
    Id: string;
}

export interface UsernamePasswordVcsCredentials {
    Type: AuthenticationType.UsernamePassword;
    Username: string;
    Password: SensitiveValue;
}

export interface AnonymousVcsCredentials {
    Type: AuthenticationType.Anonymous;
}

export interface ConversionState {
    VariablesAreInGit: boolean;
}

export function IsUsingUsernamePasswordAuth(T: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials): T is UsernamePasswordVcsCredentials {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (T as UsernamePasswordVcsCredentials).Type === AuthenticationType.UsernamePassword;
}

export function IsUsingAnonymousAuth(T: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials): T is AnonymousVcsCredentials {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (T as AnonymousVcsCredentials).Type === AuthenticationType.Anonymous;
}

export function IsUsingReferenceAuth(T: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials): T is ReferenceVcsCredentials {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (T as ReferenceVcsCredentials).Type === AuthenticationType.Reference;
}

export function HasVariablesInGit(T: GitPersistenceSettings | DatabasePersistenceSettings): T is GitPersistenceSettings {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return HasVersionControlledPersistenceSettings(T) && T.ConversionState?.VariablesAreInGit;
}

export function HasVcsProjectResourceLinks(links: ProjectResourceLinks): links is VcsProjectResourceLinks {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (links as VcsProjectResourceLinks).Branches !== undefined;
}

export function HasVersionControlledPersistenceSettings(T: GitPersistenceSettings | DatabasePersistenceSettings): T is GitPersistenceSettings {
    return T.Type === PersistenceSettingsType.VersionControlled;
}

export interface ProjectResource extends NamedResource<ProjectResourceLinks>, SpaceScopedResource {
    VariableSetId: string;
    DeploymentProcessId: string;
    DiscreteChannelRelease: boolean;
    IncludedLibraryVariableSetIds: string[];
    TenantedDeploymentMode: TenantedDeploymentMode;
    ReleaseCreationStrategy: ReleaseCreationStrategy;
    Templates: ActionTemplateParameterResource[];
    AutoDeployReleaseOverrides: any[];
    LifecycleId: string;
    AutoCreateRelease: boolean;
    ClonedFromProjectId: string;
    ExtensionSettings: ExtensionSettingsValues[];
    IsVersionControlled: boolean;
    PersistenceSettings: GitPersistenceSettings | DatabasePersistenceSettings;
    Slug: string;
    ProjectGroupId: string;
    Description: string;
    IsDisabled: boolean;
    Icon?: IconResource;
}

export type ProjectOrSummaryResource = ProjectResource | ProjectSummaryResource;

export interface ProjectSummaryResource extends NamedResource<BaseProjectResourceLinks>, SpaceScopedResource {
    Slug: string;
    ProjectGroupId: string;
    Description: string;
    IsDisabled: boolean;
}

export interface NewProjectResource extends NewSpaceScopedResource {
    Name: string;
    Description: string;
    ProjectGroupId: string;
    LifecycleId: string;
    ProjectConnectivityPolicy: { AllowDeploymentsToNoTargets: boolean };
}

export interface ProjectSettingsMetadata {
    ExtensionId: string;
    Metadata: MetadataTypeCollection;
}

export interface ReleaseCreationStrategy {
    ReleaseCreationPackage: DeploymentActionPackageResource;
    ChannelId?: string;
    ReleaseCreationPackageStepId?: string;
}

export interface VersionControlCompatibilityResponse {
    Errors: string[];
    Warnings: string[];
    Notices: string[];
}

export interface ValidateGitRefResponse {
    Error?: string;
    ValidatedGitRef?: GitRefResource;
}

export type ConvertProjectToVersionControlledResponse = {};

export interface RecentlyViewedProjectIds {
    [key: string]: string[];
}

export interface ProjectSummary {
    HasRunbooks: boolean;
    HasDeploymentProcess: boolean;
}

type GitRefResourceLinks = {
    DeploymentProcess: string;
    DeploymentSettings: string;
    Runbook: string;
    ReleaseTemplate: string;
    Channels: string;
};

export interface GitRefResource extends ResourceWithLinks<GitRefResourceLinks> {
    Name: string;
    CanonicalName: GitRef;
}

export type GitBranchResource = GitRefResource;
export type GitTagResource = GitRefResource;
export type GitCommitResource = GitRefResource;

export function getURISafeGitRef(branch: GitRefResource): string {
    return encodeURIComponent(branch.CanonicalName);
}

export function getGitRefFromRouteParameter(routeParameter: string | undefined): string | undefined {
    if (routeParameter) {
        return decodeURIComponent(routeParameter);
    }
}

export default ProjectResource;
