import { compose } from "recompose";
import { ControlType } from "~/client/resources";
import { VariableType } from "~/client/resources/variableResource";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type { VariableSelectRequiredProps, VariableSelectOptionalProps } from "~/components/form/VariableSelect";
import VariableSelect from "~/components/form/VariableSelect";
import type { WithProjectVariablesExternalProps } from "../withProjectVariables";
import withProjectVariables from "../withProjectVariables";
import type { WithValidateOnChangeExternalProps } from "../withValidateOnChange";
import withValidateOnChange from "../withValidateOnChange";

type AccountVariableSelectExternalProps = WithProjectVariablesExternalProps & WithValidateOnChangeExternalProps<string> & VariableSelectOptionalProps;
type AccountVariableSelectProps = VariableSelectRequiredProps & VariableSelectOptionalProps;

const enhanceAwsVariable = compose<AccountVariableSelectProps, AccountVariableSelectExternalProps>(
    withProjectVariables({
        controlType: ControlType.AmazonWebServicesAccount,
        variableType: VariableType.AmazonWebServicesAccount,
    }),
    withValidateOnChange()
);

const enhanceAzureVariable = compose<AccountVariableSelectProps, AccountVariableSelectExternalProps>(
    withProjectVariables({
        controlType: ControlType.AzureAccount,
        variableType: VariableType.AzureAccount,
    }),
    withValidateOnChange()
);

const enhanceGoogleCloudVariable = compose<AccountVariableSelectProps, AccountVariableSelectExternalProps>(
    withProjectVariables({
        controlType: ControlType.GoogleCloudAccount,
        variableType: VariableType.GoogleCloudAccount,
    }),
    withValidateOnChange()
);

const awsEnhancedComponent = enhanceAwsVariable(VariableSelect);
const azureEnhancedComponent = enhanceAzureVariable(VariableSelect);
const googleCloudEnhancedComponent = enhanceGoogleCloudVariable(VariableSelect);

export { VariableSelect, AccountVariableSelectProps, enhanceAwsVariable, enhanceAzureVariable, awsEnhancedComponent, azureEnhancedComponent, googleCloudEnhancedComponent };

export const AwsBoundAccountVariableSelect = withBoundField(awsEnhancedComponent);
export const AzureBoundAccountVariableSelect = withBoundField(azureEnhancedComponent);
export const GoogleCloudAccountVariableSelect = googleCloudEnhancedComponent;
