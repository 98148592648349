import * as React from "react";
import { ActionButton } from "~/components/Button";
import type { ActionButtonType } from "~/components/Button/ActionButton";
import Dialog from "~/components/Dialog/Dialog";
import type { PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { isAllowed } from "../PermissionCheck/PermissionCheck";
import DialogTrigger from "./DialogTrigger";
import styles from "./style.module.less";

interface RenderDialogProps {
    open: boolean;
    sequence: string;
    closeDialog(): void;
    openDialog(): void;
}
interface OpenDialogButtonProps {
    label: string;
    accessibleName?: string;
    title?: string;
    disabled?: boolean;
    permission?: PermissionCheckProps;
    icon?: JSX.Element;
    type?: ActionButtonType;
    wideDialog?: boolean;
    renderDialog?: (props: RenderDialogProps) => React.ReactNode;
    onClick?: () => void; //Track analytics
}

class OpenDialogButton extends React.Component<OpenDialogButtonProps, never> {
    constructor(props: OpenDialogButtonProps) {
        super(props);
    }

    render() {
        const disabledDueToPermission = !!this.props.permission ? !isAllowed(this.props.permission) : false;
        const permissionLabel = this.getPermissionLabel();
        const label = disabledDueToPermission ? `${permissionLabel} permission required` : this.props.label;

        return (
            <DialogTrigger
                render={({ open, openDialog, sequence, closeDialog }) => {
                    const wrappedOpenDialog = () => {
                        if (this.props.onClick) {
                            this.props.onClick();
                        }
                        openDialog();
                    };

                    return (
                        <div className={styles.buttonDialogContainer}>
                            <ActionButton
                                accessibleName={this.props.accessibleName}
                                type={this.props.type}
                                icon={this.props.icon}
                                label={label}
                                disabled={disabledDueToPermission || this.props.disabled}
                                onClick={wrappedOpenDialog}
                                title={this.props.title || this.props.label}
                            />
                            {this.props.renderDialog ? (
                                this.props.renderDialog({ open, openDialog: wrappedOpenDialog, sequence, closeDialog })
                            ) : (
                                <Dialog open={open} wide={this.props.wideDialog}>
                                    {this.props.children}
                                </Dialog>
                            )}
                        </div>
                    );
                }}
            />
        );
    }

    private getPermissionLabel(): string {
        if (this.props.permission === undefined) {
            return "No";
        }
        if (Array.isArray(this.props.permission.permission)) {
            return this.props.permission.permission.join(", ");
        }
        return this.props.permission.permission;
    }
}

export default OpenDialogButton;
