/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */

import { configurationActions } from "~/areas/configuration/reducers/configurationArea";
import Logger from "~/client/logger";
import type { EnabledFeatureToggles, FeatureToggle } from "~/client/resources/enabledFeatureToggles";
import type { FeaturesConfigurationResource } from "~/client/resources/featuresConfigurationResource";
import type { UserPermissionSetResource } from "~/client/resources/userPermissionSetResource";
import type { UserResource } from "~/client/resources/userResource";
import { UserPermissions } from "~/client/userPermissions";
import store from "~/store";

export class Session {
    currentUser: UserResource | null = undefined!;
    currentPermissions: UserPermissions | null = undefined!;
    featureToggles: ReadonlyArray<FeatureToggle> | null = undefined!;

    start(user: UserResource, featureConfigurations: FeaturesConfigurationResource, featureToggles: EnabledFeatureToggles) {
        Logger.info(`Starting session for user ${user.DisplayName}.`);
        this.currentUser = user;
        this.featureToggles = featureToggles.EnabledFeatureToggles;
        store.dispatch(configurationActions.featuresFetched(featureConfigurations));
    }

    end() {
        if (this.currentUser) {
            Logger.info(`Ending session for ${this.currentUser.DisplayName} user.`);
        }
        this.currentUser = null;
        this.currentPermissions = null;
        this.featureToggles = null;
        store.dispatch(configurationActions.featuresReset());
    }

    refreshPermissions(userPermission: UserPermissionSetResource) {
        this.currentPermissions = UserPermissions.Create(userPermission.SpacePermissions, userPermission.SystemPermissions, userPermission.Teams);
    }

    isAuthenticated() {
        return this.currentUser != null;
    }
}

export default Session;
