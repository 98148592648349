import type { AccountReference, ContainerImageReference, PackageReference, SensitiveValue } from "@octopusdeploy/step-inputs";
import type { NonDiscriminatorTypeDefinition, ObjectRuntimeInputs, PathToInput, RootInputSchema, RuntimeContainerImageSelection, RuntimePackageSelection } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, createPathToInput, isBoundValue } from "@octopusdeploy/step-runtime-inputs";
import v4 from "uuid/v4";
import { convertFromRuntimeAccountSelection, convertToRuntimeAccountSelection } from "./Components/AccountSelector/AccountSelectionConverters";
import { convertFromRuntimeContainerImageSelection, convertToRuntimeContainerImageSelection } from "./Components/ContainerImageSelector/ContainerImageConverters";
import { convertFromRuntimePackageSelection, convertToRuntimePackageSelection } from "./Components/PackageSelector/PackageSelectionConverters";
import { emptyInitialValue } from "./emptyInitialValue";

const createEmptySensitiveValue = (): SensitiveValue => {
    return {
        type: "empty",
    };
};

const createEmptyPackageReference = () => {
    const packageReference: RuntimePackageSelection = {
        referenceId: v4(),
        packageId: undefined,
        feedId: undefined,
    };
    return convertFromRuntimePackageSelection(packageReference);
};

const createEmptyContainerImageReference = () => {
    const containerReference: RuntimeContainerImageSelection = {
        referenceId: v4(),
        imageName: undefined,
        feedId: undefined,
    };
    return convertFromRuntimeContainerImageSelection(containerReference);
};

const createEmptyAccountReference = () => {
    const runtimeAccountSelection = convertToRuntimeAccountSelection(emptyInitialValue);
    return convertFromRuntimeAccountSelection(runtimeAccountSelection);
};

// todo-remove-initial-factories: make this work from InitialInputs<StepInputs> or a base type shared with ObjectRuntimeInputs<StepInputs>/ObjectResourceInputs<StepInputs>
export function mapRootInitialInputs<StepInputs>(inputSchema: RootInputSchema, inputs: ObjectRuntimeInputs<StepInputs>): ObjectRuntimeInputs<StepInputs> {
    return inputSchema.properties.reduce((acc, property) => {
        return mapInitialInputs(property.type, property.isRequired, [property.name], acc);
    }, inputs);
}

export function mapInitialInputs<StepInputs>(typeDefinition: NonDiscriminatorTypeDefinition, isRequired: boolean, path: PathToInput, inputs: ObjectRuntimeInputs<StepInputs>): ObjectRuntimeInputs<StepInputs> {
    switch (typeDefinition.type) {
        case "string":
        case "primitive":
            return inputs;
        case "sensitive": {
            const pathToInput = createPathToInput<SensitiveValue>(path);
            const inputAccessor = createInputValueAccessor<StepInputs, SensitiveValue>(pathToInput);
            const inputValue = inputAccessor.getInputValue(inputs);
            if ((inputValue === undefined || inputValue === null) && isRequired) {
                return inputAccessor.changeInputValue(inputs, createEmptySensitiveValue());
            }
            return inputs;
        }
        case "account": {
            const pathToInput = createPathToInput<AccountReference>(path);
            const inputAccessor = createInputValueAccessor<StepInputs, AccountReference>(pathToInput);
            const inputValue = inputAccessor.getInputValue(inputs);
            if ((inputValue === undefined || inputValue === null) && isRequired) {
                if (isRequired) return inputAccessor.changeInputValue(inputs, createEmptyAccountReference());
            }
            return inputs;
        }
        case "object":
            return typeDefinition.nonDiscriminatorProperties.reduce((acc, property) => {
                return mapInitialInputs(property.type, property.isRequired, [...path, property.name], acc);
            }, inputs);
        case "array":
            return typeDefinition.itemTypes.reduce((acc, property, i) => {
                return mapInitialInputs(property, isRequired, [...path, i], acc);
            }, inputs);
        case "container-image": {
            const pathToInput = createPathToInput<ContainerImageReference>(path);
            const inputAccessor = createInputValueAccessor<StepInputs, ContainerImageReference>(pathToInput);
            const inputValue = inputAccessor.getInputValue(inputs);
            if (inputValue === undefined || inputValue === null) {
                if (isRequired) return inputAccessor.changeInputValue(inputs, createEmptyContainerImageReference());
                // Else not required leave the inputs alone
                return inputs;
            }
            if (isBoundValue(inputValue)) {
                return inputs;
            }
            const runtimeContainerImageSelection = convertToRuntimeContainerImageSelection(inputValue);
            runtimeContainerImageSelection.referenceId = v4();
            return inputAccessor.changeInputValue(inputs, convertFromRuntimeContainerImageSelection(runtimeContainerImageSelection));
        }
        case "package": {
            const pathToInput = createPathToInput<PackageReference>(path);
            const inputAccessor = createInputValueAccessor<StepInputs, PackageReference>(pathToInput);
            const inputValue = inputAccessor.getInputValue(inputs);
            if (inputValue === undefined || inputValue === null) {
                if (isRequired) return inputAccessor.changeInputValue(inputs, createEmptyPackageReference());
                // Else not required leave the inputs alone
                return inputs;
            }
            if (isBoundValue(inputValue)) {
                return inputs;
            }
            const runtimePackageReference = convertToRuntimePackageSelection(inputValue);
            runtimePackageReference.referenceId = v4();
            return inputAccessor.changeInputValue(inputs, convertFromRuntimePackageSelection(runtimePackageReference));
        }
    }

    return inputs;
}
