import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import SectionNote from "~/components/SectionNote/SectionNote";

interface SettingsNoteSectionProps {
    isVersionControlled: boolean;
}

export const SettingsNoteSection: React.FC<SettingsNoteSectionProps> = ({ isVersionControlled }) => {
    return (
        <SectionNote>
            {isVersionControlled ? (
                <div>
                    Version control is configured. Please provide <ExternalLink href={"CaCEAPFeedbackForm"}>feedback</ExternalLink> on the Config as Code feature.
                </div>
            ) : (
                <div>
                    Configuring a project to use version control is an irreversible process. Please read the <ExternalLink href={"ConfigAsCodeEap"}>documentation</ExternalLink> first.
                </div>
            )}
        </SectionNote>
    );
};
