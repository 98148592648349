/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/Page";
import ReloadableRoute from "~/components/ReloadableRoute";
import pageIds from "~/pageIds";
import ProjectSettings from "./ProjectSettings";
import VersionControlSettings from "./VersionControl/VersionControlSettings";

const ProjectSettingsPage = withPage({ page: pageIds.project().settings.root })(ProjectSettings);
const VersionControlSettingsPage = withPage({ page: pageIds.project().settings.versionControl })(VersionControlSettings);

export default class ProjectSettingsRoute extends React.Component<any, any> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={`${match.path}/versioncontrol`} component={VersionControlSettingsPage} />
                <ReloadableRoute path={`${match.path}`} component={ProjectSettingsPage} />
            </Switch>
        );
    }
}
