/* eslint-disable no-restricted-imports */

import CircularProgress from "material-ui/CircularProgress";
import LinearProgress from "material-ui/LinearProgress";
import * as React from "react";
import { withTheme } from "~/components/Theme";
import styles from "./style.module.less";

interface BusyIndicatorProps {
    show: boolean;
    inline?: boolean;
}

const BusyIndicator: React.FC<BusyIndicatorProps> = (props) =>
    withTheme((theme) => {
        if (props.inline) {
            return (
                <>
                    {props.show && (
                        <span role="progressbar" className={styles.circularBusyIndicator}>
                            <CircularProgress mode="indeterminate" color={theme.primaryLight} size={22} thickness={2} />
                        </span>
                    )}
                </>
            );
        } else {
            return props.show ? (
                // Giving this a distinct key ensures that when we are no longer busy, the progressbar HTML element gets removed (rather than mutated into something else)
                // This means we are able to more reliably detect if it gets removed in tests
                <div role="progressbar" key="progressbar" className={styles.busyIndicator}>
                    <LinearProgress mode="indeterminate" color={theme.loadingIndicator} />
                </div>
            ) : (
                <div key="hidden-spacer" className={styles.busyIndicatorHiddenSpacer} />
            );
        }
    });

BusyIndicator.displayName = "BusyIndicator";

export default BusyIndicator;
