/* eslint-disable no-restricted-imports */

import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import type { TransitionProps } from "@material-ui/core/transitions/transition";
import { useCallback } from "react";
import * as React from "react";
import type { DialogLayoutDispatchProps } from "../DialogLayout/DialogLayout";
import ErrorContextProvider from "../ErrorContext/ErrorContext";

interface CustomDialogStateProps {
    open: boolean;
}

export interface RenderProps extends DialogLayoutDispatchProps, CustomDialogStateProps {}

interface CustomDialogRenderProps extends RenderProps {
    render: (props: RenderProps) => React.ReactNode;
}

export interface CustomDialogProps extends CustomDialogRenderProps, DialogLayoutDispatchProps, CustomDialogStateProps {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    TransitionComponent?: React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>;
}

export const CustomDialog: React.FC<CustomDialogProps> = ({ open, TransitionComponent = Fade, render, close, ...rest }) => {
    const handleClose = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);
    const renderProps = { open, close: handleClose };

    return (
        <ErrorContextProvider>
            <Modal open={open}>
                <TransitionComponent in={open}>
                    <div>{open && render(renderProps)}</div>
                </TransitionComponent>
            </Modal>
        </ErrorContextProvider>
    );
};

export default React.memo(CustomDialog);
