/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import { IsNonVcsRunbook, ProcessType } from "~/client/resources";
import { withPage } from "~/components/Page/Page";
import pageIds from "~/pageIds";
import { useRunbookContext } from "../../Runbooks/RunbookContext";
import ActionTemplateSelector from "../ActionTemplateSelector";
import type { ActionTemplateSelectorProps } from "../ActionTemplateSelector/ActionTemplateSelector";
import { ProcessPaperLayout } from "../CustomPaperLayouts/ProcessPaperLayout";
import type { ProcessActionDetailsProps } from "../ProcessActionDetails";
import ProcessActionDetails from "../ProcessActionDetails";
import type { ProcessListProps } from "../ProcessList";
import ProcessList from "../ProcessList";
import ProcessListLayoutDataLoader from "../ProcessListLayoutLoader";
import type { ProcessParentStepDetailsProps } from "../ProcessParentStepDetails";
import ProcessParentStepDetails from "../ProcessParentStepDetails";
import ProcessStepsLayoutLoader from "../ProcessStepsLayoutLoader";
import type { ProcessFilter } from "../types";

export type WithProjectDeploymentProcessInjectedProps = {
    processId: string;
};

//This HOC pulls the project ID off the current deployment process context and passes it into the wrapped component
export const withProjectDeploymentProcessId = <T extends WithProjectDeploymentProcessInjectedProps>(Component: React.ComponentType<T & WithProjectDeploymentProcessInjectedProps>) => {
    type ExternalProps = Omit<T, keyof WithProjectDeploymentProcessInjectedProps>;
    const WithProjectDeploymentProcess: React.FC<ExternalProps> = (props) => {
        const context = useProjectContext();
        if (!context.state.model) {
            return <ProcessPaperLayout processType={ProcessType.Deployment} busy={true} {...(props as T)} />;
        }
        return <Component {...(props as T)} processId={context.state.model.DeploymentProcessId} />;
    };
    return WithProjectDeploymentProcess;
};

// Preparation for supporting multiple processes: We currently don't have routes setup for this and injecting the scope and process id
// from the context avoids having to change the routes.
export const withProjectDeploymentProcess = <T extends WithProjectDeploymentProcessInjectedProps>(Component: React.ComponentType<T & WithProjectDeploymentProcessInjectedProps & ProcessFilter>) => {
    return withProjectDeploymentProcessId(Component);
};

export type WithProjectRunbookProcessInjectedProps = {
    processId: string;
};

export const withRunbookProcess = <T extends WithProjectRunbookProcessInjectedProps>(Component: React.ComponentType<T>) => {
    type ExternalProps = Omit<T, keyof WithProjectRunbookProcessInjectedProps>;
    const WithRunbookProcess: React.FC<ExternalProps> = (props) => {
        const context = useRunbookContext();
        if (!context.state.runbook) {
            return <ProcessPaperLayout processType={ProcessType.Runbook} busy={true} {...(props as T)} />;
        }
        if (!IsNonVcsRunbook(context.state.runbook)) {
            throw new Error("FIXME: cac-runbook: Runbook processes not supported yet for VCS runbooks");
        }
        return <Component runbookId={context.state.runbook.Id} {...(props as T)} processId={context.state.runbook.RunbookProcessId} />;
    };
    return WithRunbookProcess;
};

const specificProjectPage = pageIds.project();

export const ProcessStepsPage = withPage({ page: specificProjectPage.process.root })(withProjectDeploymentProcess(ProcessStepsLayoutLoader));
export const ProcessListLayoutPage = withPage({ page: specificProjectPage.process.root })(withProjectDeploymentProcess(ProcessListLayoutDataLoader));

const WrappedActionTemplateSelectionPage = withPage({ page: specificProjectPage.process.stepTemplates })(withProjectDeploymentProcess(ActionTemplateSelector));
const WrappedActionTemplateSelectionRunbookPage = withPage({ page: specificProjectPage.runbook.process.stepTemplates })(withRunbookProcess(ActionTemplateSelector));

const WrappedProcessListPage = withPage({ page: specificProjectPage.process.root })(ProcessList);
const WrappedProcessListRunbookPage = withPage({ page: specificProjectPage.runbook.process.root })(ProcessList);

const WrappedProcessActionDetailsPage = withPage({ page: specificProjectPage.process.step })(ProcessActionDetails);
const WrappedProcessActionDetailsRunbookPage = withPage({ page: specificProjectPage.runbook.process.step })(ProcessActionDetails);

const WrappedProcessParentStepDetailsPage = withPage({ page: specificProjectPage.process.step })(ProcessParentStepDetails);
const WrappedProcessParentStepDetailsRunbookPage = withPage({ page: specificProjectPage.runbook.process.step })(ProcessParentStepDetails);

export const EnhancedActionTemplateSelectionPage: React.FC<Omit<ActionTemplateSelectorProps, "processId" | "processContext" | "projectContext"> & { processType: ProcessType }> = ({ children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return <WrappedActionTemplateSelectionRunbookPage {...rest}>{children}</WrappedActionTemplateSelectionRunbookPage>;
    }
    return <WrappedActionTemplateSelectionPage {...rest}>{children}</WrappedActionTemplateSelectionPage>;
};

export const EnhancedProcessListPage: React.FC<ProcessListProps & { processType: ProcessType }> = ({ children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return <WrappedProcessListRunbookPage {...rest}>{children}</WrappedProcessListRunbookPage>;
    }
    return <WrappedProcessListPage {...rest}>{children}</WrappedProcessListPage>;
};

export const EnhancedProcessActionDetailsPage: React.FC<ProcessActionDetailsProps & { processType: ProcessType }> = ({ children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return <WrappedProcessActionDetailsRunbookPage {...rest}>{children}</WrappedProcessActionDetailsRunbookPage>;
    }
    return <WrappedProcessActionDetailsPage {...rest}>{children}</WrappedProcessActionDetailsPage>;
};

export const EnhancedProcessParentStepDetailsPage: React.FC<ProcessParentStepDetailsProps & { processType: ProcessType }> = ({ children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return <WrappedProcessParentStepDetailsRunbookPage {...rest}>{children}</WrappedProcessParentStepDetailsRunbookPage>;
    }
    return <WrappedProcessParentStepDetailsPage {...rest}>{children}</WrappedProcessParentStepDetailsPage>;
};
