/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { actions as dialogActions } from "~/components/Dialog/store";
import type { DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";

export class DialogLayoutConnect {
    static to<TProps extends any>(component: React.ComponentType<TProps & DialogLayoutDispatchProps>) {
        const mapGlobalActionDispatchersToProps = (dispatch: Dispatch, props: TProps): DialogLayoutDispatchProps => {
            return {
                close: () => {
                    dispatch(dialogActions.closeAll());
                },
            };
        };
        return connect(null, mapGlobalActionDispatchersToProps)(component as any) as React.ComponentType<TProps>;
    }
}

export default DialogLayoutConnect;
