import * as React from "react";
import type { AuthenticationProviderElement } from "~/client/authentication";
import Logger from "~/client/logger";
import type { LoginState } from "~/client/resources";
import { client } from "~/clientInstance";
import extensionManager from "~/extensionsManager";
import { useThemePalette } from "../../../components/Theme";
const $script = require("scriptjs");

interface AuthProviderInternalProps {
    provider: AuthenticationProviderElement;
    loginState: LoginState;
    shouldAutoSignIn: boolean;
    autoSignInProviderName: string;
    onError(error: { ErrorMessage?: string; Errors?: string[]; message?: string }): void;
    isDarkMode: boolean;
}

class AuthProviderInternal extends React.Component<AuthProviderInternalProps, {}> {
    rootNode: React.RefObject<HTMLDivElement> = React.createRef();

    async componentDidMount() {
        const { provider, loginState, shouldAutoSignIn, autoSignInProviderName, onError, isDarkMode } = this.props;

        await Promise.all(this.loadCSS(provider.CSSLinks));

        $script(
            provider.JavascriptLinks.map((link) => {
                Logger.log("Loading JS " + link);
                return client.resolve(link);
            }),
            provider.Name
        );

        $script.ready(provider.Name, () => {
            const extension = extensionManager.getExtension(provider.Name, "auth_provider");
            if (!extension) {
                Logger.error(`Could not find authentication ${provider.Name} extension`);
            }

            //Changes of dark mode won't get reflected by the extension, however this is
            //acceptable for now since we can't change the theme on the login page. Once this
            //changes, we will need to call this multiple times.
            const authProvider = extension(client, provider, loginState, onError, isDarkMode);

            if (!shouldAutoSignIn) {
                const node = this.rootNode.current;
                if (node) {
                    node.innerHTML = authProvider.LinkHtml;
                    node.addEventListener("click", authProvider.SignIn);
                }
            }

            if (shouldAutoSignIn && provider.Name === autoSignInProviderName) {
                authProvider.SignIn();
            }
        });
    }

    render() {
        return <div ref={this.rootNode} />;
    }

    private loadCSS(urls: string[]) {
        return urls.map((url) => {
            Logger.log("Loading CSS " + url);
            return new Promise<void>((resolve, reject) => {
                const linkElement = document.createElement("link");
                linkElement.setAttribute("rel", "stylesheet");
                linkElement.setAttribute("type", "text/css");
                linkElement.setAttribute("href", client.resolve(url));
                document.getElementsByTagName("head")[0].appendChild(linkElement);
                resolve();
            });
        });
    }
}

type AuthProviderProps = Omit<AuthProviderInternalProps, "isDarkMode">;

function AuthProvider(props: AuthProviderProps) {
    const [themePalette] = useThemePalette();
    return <AuthProviderInternal {...props} isDarkMode={themePalette === "dark"} />;
}

export default AuthProvider;
