/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as _ from "lodash";
import * as React from "react";
import type { ActionTemplateParameterResource, ProjectResource } from "~/client/resources";
import type { FeedResource } from "~/client/resources/feedResource";
import { FeedType } from "~/client/resources/feedResource";
import { repository } from "~/clientInstance";
import { BooleanRegex, KubernetesNameRegex, KubernetesResourceQuantityRegex, NumberRegex } from "~/components/Actions/kubernetes/kubernetesValidation";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import type { KeyValueOption } from "~/components/EditList/ExtendedKeyValueEditList";
import { ExtendedKeyValueEditList } from "~/components/EditList/ExtendedKeyValueEditList";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { FormSectionHeading } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import { CardFill, default as ExpandableFormSection } from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { BoundStringRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import { BoundSelect, default as Select } from "~/primitiveComponents/form/Select/Select";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import { PackageSelectionMode } from "../../../client/resources/packageReference";
import { PosixCapabilities } from "../../Features/kubernetes/posixCapabilitiesSuggestions";
import DeferredPackageSelector from "../../PackageSelector/DeferredPackageSelector";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import type { Capabilities, CombinedVolumeDetails, ContainerPackageDetails, ExecAction, HTTPGetAction, Probe, ResourceRequirementsDetails, SecurityContext, SELinuxOptions } from "./kubernetesDeployContainersAction";
import { LifecycleHandlerFormSection } from "./lifecycleHandlerFormSection";

export const CommandCheck = "Command";
export const HttpGetCheck = "HttpGet";
export const TcpSocketCheck = "TcpSocket";

interface ContainerState extends DataBaseComponentState {
    containerDetails: ContainerPackageDetails;
    project?: ProjectResource;
}

interface ContainerProps {
    containerDetails: ContainerPackageDetails;
    localNames: string[];
    feeds: FeedResource[];
    parameters?: ActionTemplateParameterResource[];
    projectId: string;
    volumes: CombinedVolumeDetails[];
    standalone?: boolean;
    onAdd(Binding: ContainerPackageDetails): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshFeeds(): Promise<any>;
}

class ContainerDialog extends DataBaseComponent<ContainerProps, ContainerState> {
    constructor(props: ContainerProps) {
        super(props);
        this.state = {
            containerDetails: null!,
            project: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null;

            this.setState({
                containerDetails: this.props.containerDetails!,
                project: project!,
            });
        });
    }

    save = () => {
        let valid = true;
        const binding = this.state.containerDetails;
        if (!binding.Name || (!isBound(binding.Name) && !KubernetesNameRegex.exec(binding.Name))) {
            this.setValidationErrors("The container name must consist of lower case alphanumeric characters or '-', and must start and end with an alphanumeric character.", {
                ContainerDetailsName: "The container name must consist of lower case alphanumeric characters or '-', and must start and end with an alphanumeric character.",
            });
            valid = false;
        }

        if (!binding.PackageId && binding.Properties.SelectionMode != PackageSelectionMode.Deferred) {
            this.setValidationErrors("A package ID must be selected from the feed.", { ContainerDetailsPackage: "A package ID must be selected from the feed." });
            valid = false;
        }

        this.validateEnvironmentVariablesHaveNames(binding.EnvironmentVariables);
        this.validateEnvironmentVariablesHaveNames(binding.ConfigMapEnvironmentVariables);
        this.validateEnvironmentVariablesHaveNames(binding.SecretEnvironmentVariables);
        this.validateEnvironmentVariablesHaveNames(binding.FieldRefEnvironmentVariables);
        this.validateEnvironmentFromVariablesHaveNames(binding.ConfigMapEnvFromSource);
        this.validateEnvironmentFromVariablesHaveNames(binding.SecretEnvFromSource);

        if (binding.ConfigMapEnvironmentVariables) {
            binding.ConfigMapEnvironmentVariables.forEach((p) => {
                p.valueError = p.value && p.value.trim() ? null! : "The ConfigMap name must be defined when referenced as an environment variable.";
                p.optionError = p.option && p.option.trim() ? null! : "The ConfigMap key must be defined when referenced as an environment variable.";
            });

            if (binding.ConfigMapEnvironmentVariables.find((p) => !!p.valueError || !!p.keyError || !!p.optionError)) {
                this.setValidationErrors("The ConfigMap environment variables are incorrectly configured.");
                valid = false;
            }
        }

        if (binding.SecretEnvironmentVariables) {
            binding.SecretEnvironmentVariables.forEach((p) => {
                p.valueError = p.value && p.value.trim() ? null! : "The secret name must be defined when referenced as an environment variable.";
                p.optionError = p.option && p.option.trim() ? null! : "The secret key must be defined when referenced as an environment variable.";
            });

            if (binding.SecretEnvironmentVariables.find((p) => !!p.valueError || !!p.keyError || !!p.optionError)) {
                this.setValidationErrors("The secret environment variables are incorrectly configured.");
                valid = false;
            }
        }

        if (binding.FieldRefEnvironmentVariables) {
            binding.FieldRefEnvironmentVariables.forEach((p) => {
                p.valueError = p.value && p.value.trim() ? null! : "The field path must be defined";
            });

            if (binding.FieldRefEnvironmentVariables.find((p) => !!p.valueError || !!p.keyError)) {
                this.setValidationErrors("The container and pod field environment variables are incorrectly configured.");
                valid = false;
            }
        }

        if (binding.ConfigMapEnvFromSource) {
            binding.ConfigMapEnvFromSource.forEach((p) => {
                p.keyError = p.key && p.key.trim() ? null! : "The ConfigMap name must be defined when referenced as an environment variable.";
            });

            if (binding.ConfigMapEnvFromSource.find((p) => !!p.valueError || !!p.keyError || !!p.optionError)) {
                this.setValidationErrors("The ConfigMap environment variables are incorrectly configured.");
                valid = false;
            }
        }

        if (binding.SecretEnvFromSource) {
            binding.SecretEnvFromSource.forEach((p) => {
                p.keyError = p.key && p.key.trim() ? null! : "The secret name must be defined when referenced as an environment variable.";
            });

            if (binding.SecretEnvFromSource.find((p) => !!p.valueError || !!p.keyError || !!p.optionError)) {
                this.setValidationErrors("The secret environment variables are incorrectly configured.");
                valid = false;
            }
        }

        if (binding.EnvironmentVariables) {
            if (binding.EnvironmentVariables.find((p) => !!p.valueError || !!p.keyError || !!p.optionError)) {
                this.setValidationErrors("The environment variables are incorrectly configured.");
                valid = false;
            }
        }

        if (binding.Ports) {
            binding.Ports.forEach((p) => {
                p.valueError = isBound(p.value.toString()) || (NumberRegex.exec(p.value.toString()) && parseInt(p.value.toString(), 10) >= 1 && parseInt(p.value.toString(), 10) <= 65535) ? null! : "Port numbers must be between 1 and 65535.";
            });

            if (binding.Ports.find((p) => !!p.valueError)) {
                this.setValidationErrors("The ports are incorrectly configured.");
                valid = false;
            }
        }

        if (_.get(binding, "Resources.requests.memory") && !isBound(binding.Resources.requests.memory) && !KubernetesResourceQuantityRegex.exec(binding.Resources.requests.memory)) {
            this.setValidationErrors("Container memory resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 129M, 123Mi, 1G, 1Gi.", {
                ContainerDetailsResourcesMemoryRequest: "Container memory resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 129M, 123Mi, 1G, 1Gi.",
            });
            valid = false;
        }

        if (_.get(binding, "Resources.requests.ephemeralStorage") && !isBound(binding.Resources.requests.ephemeralStorage) && !KubernetesResourceQuantityRegex.exec(binding.Resources.requests.ephemeralStorage)) {
            this.setValidationErrors("Container ephemeral storage resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 128974848, 129e6, 129M, 123Mi", {
                ContainerDetailsResourcesStorageRequest: "Container ephemeral storage resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 128974848, 129e6, 129M, 123Mi",
            });
            valid = false;
        }

        if (_.get(binding, "Resources.requests.cpu") && !isBound(binding.Resources.requests.cpu) && !KubernetesResourceQuantityRegex.exec(binding.Resources.requests.cpu)) {
            this.setValidationErrors("Container cpu resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.", {
                ContainerDetailsResourcesCpuRequest: "Container cpu resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.",
            });
            valid = false;
        }

        if (_.get(binding, "Resources.limits.nvidiaGpu") && !isBound(binding.Resources.limits.nvidiaGpu) && !KubernetesResourceQuantityRegex.exec(binding.Resources.limits.nvidiaGpu)) {
            this.setValidationErrors("Nvidia gpu resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.", {
                ContainerDetailsResourcesNvidiaGpuLimit: "Nvidia gpu resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.",
            });
            valid = false;
        }

        if (_.get(binding, "Resources.limits.amdGpu") && !isBound(binding.Resources.limits.amdGpu) && !KubernetesResourceQuantityRegex.exec(binding.Resources.limits.amdGpu)) {
            this.setValidationErrors("AMD gpu resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.", {
                ContainerDetailsResourcesAmdGpuLimit: "AMD gpu resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.",
            });
            valid = false;
        }

        if (_.get(binding, "Resources.limits.memory") && !isBound(binding.Resources.limits.memory) && !KubernetesResourceQuantityRegex.exec(binding.Resources.limits.memory)) {
            this.setValidationErrors("Container memory resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 129M, 123Mi, 1G, 1Gi.", {
                ContainerDetailsResourcesMemoryLimit: "Container memory resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 129M, 123Mi, 1G, 1Gi.",
            });
            valid = false;
        }

        if (_.get(binding, "Resources.limits.ephemeralStorage") && !isBound(binding.Resources.limits.ephemeralStorage) && !KubernetesResourceQuantityRegex.exec(binding.Resources.limits.ephemeralStorage)) {
            this.setValidationErrors("Container ephemeral storage resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 128974848, 129e6, 129M, 123Mi", {
                ContainerDetailsResourcesStorageLimit: "Container ephemeral storage resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 128974848, 129e6, 129M, 123Mi",
            });
            valid = false;
        }

        if (_.get(binding, "Resources.limits.cpu") && !isBound(binding.Resources.limits.cpu) && !KubernetesResourceQuantityRegex.exec(binding.Resources.limits.cpu)) {
            this.setValidationErrors("Container cpu resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.", {
                ContainerDetailsResourcesCpuLimit: "Container cpu resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.",
            });
            valid = false;
        }

        if (_.get(binding, "LivenessProbe.periodSeconds") && !isBound(binding.LivenessProbe.periodSeconds.toString()) && !NumberRegex.exec(binding.LivenessProbe.periodSeconds.toString())) {
            this.setValidationErrors("The period must be a whole number.", { ContainerLivenessProbePeriodSeconds: "The period must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "LivenessProbe.initialDelaySeconds") && !isBound(binding.LivenessProbe.initialDelaySeconds.toString()) && !NumberRegex.exec(binding.LivenessProbe.initialDelaySeconds.toString())) {
            this.setValidationErrors("The initial delay must be a whole number.", { ContainerLivenessProbeInitialDelaySeconds: "The initial delay must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "LivenessProbe.timeoutSeconds") && !isBound(binding.LivenessProbe.timeoutSeconds.toString()) && !NumberRegex.exec(binding.LivenessProbe.timeoutSeconds.toString())) {
            this.setValidationErrors("The timeout must be a whole number.", { ContainerLivenessProbeTimeoutSeconds: "The timeout must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "LivenessProbe.failureThreshold") && !isBound(binding.LivenessProbe.failureThreshold.toString()) && !NumberRegex.exec(binding.LivenessProbe.failureThreshold.toString())) {
            this.setValidationErrors("The failure threshold must be a whole number.", { ContainerLivenessProbeFailureThreshold: "The failure threshold must be a whole number." });
            valid = false;
        }

        if (
            (_.get(binding, "LivenessProbe.failureThreshold") || _.get(binding, "LivenessProbe.timeoutSeconds") || _.get(binding, "LivenessProbe.initialDelaySeconds") || _.get(binding, "LivenessProbe.periodSeconds")) &&
            !(_.get(binding, "LivenessProbe.type") && binding.LivenessProbe.type.trim())
        ) {
            this.setValidationErrors("The liveness check type must be specified, or all liveness fields must be cleared.", { ContainerLivenessProbeType: "The liveness check type must be specified." });
            valid = false;
        }

        if (_.get(binding, "LivenessProbe.type") === CommandCheck) {
            if (!_.get(binding, "LivenessProbe.exec.command") || binding.LivenessProbe.exec.command.filter((c) => c && c.trim()).length === 0) {
                this.setValidationErrors("The command must be specified.", { ContainerLivenessProbeExecCommand: "The command must be specified." });
                valid = false;
            }
        }

        if (_.get(binding, "LivenessProbe.type") === HttpGetCheck) {
            if (!_.get(binding, "LivenessProbe.httpGet.port") || !binding.LivenessProbe.httpGet.port.trim()) {
                this.setValidationErrors("The HTTP port must be specified.", { ContainerLivenessProbeHttpGetPort: "The HTTP port must be specified." });
                valid = false;
            } else if (
                !isBound(binding.LivenessProbe.httpGet.port) &&
                NumberRegex.exec(binding.LivenessProbe.httpGet.port.toString()) &&
                !(parseInt(binding.LivenessProbe.httpGet.port.toString(), 10) >= 1 && parseInt(binding.LivenessProbe.httpGet.port.toString(), 10) <= 65535)
            ) {
                this.setValidationErrors("The HTTP port must be a number between 1 and 65535.", { ContainerLivenessProbeHttpGetPort: "The HTTP port must be a number between 1 and 65535." });
                valid = false;
            }

            if (!_.get(binding, "LivenessProbe.httpGet.path") || !binding.LivenessProbe.httpGet.path!.trim()) {
                this.setValidationErrors("The HTTP path must be specified.", { ContainerLivenessHttpGetPath: "The HTTP path must be specified." });
                valid = false;
            }
        }

        if (_.get(binding, "LivenessProbe.type") === TcpSocketCheck) {
            if (!_.get(binding, "LivenessProbe.tcpSocket.port") || !binding.LivenessProbe.tcpSocket.port.trim()) {
                this.setValidationErrors("The TCP socket port must be specified.", { ContainerLivenessProbeTcpSocketPort: "The HTTP port must be specified." });
                valid = false;
            } else if (
                !isBound(binding.LivenessProbe.tcpSocket.port.toString()) &&
                NumberRegex.exec(binding.LivenessProbe.tcpSocket.port.toString()) &&
                !(parseInt(binding.LivenessProbe.tcpSocket.port.toString(), 10) >= 1 && parseInt(binding.LivenessProbe.tcpSocket.port.toString(), 10) <= 65535)
            ) {
                this.setValidationErrors("The HTTP port must be a number between 1 and 65535.", { ContainerLivenessProbeTcpSocketPort: "The HTTP port must be a number between 1 and 65535." });
                valid = false;
            }
        }

        if (_.get(binding, "ReadinessProbe.periodSeconds") && !isBound(binding.ReadinessProbe.periodSeconds.toString()) && !NumberRegex.exec(binding.ReadinessProbe.periodSeconds.toString())) {
            this.setValidationErrors("The period must be a whole number.", { ContainerReadinessProbePeriodSeconds: "The period must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "ReadinessProbe.initialDelaySeconds") && !isBound(binding.ReadinessProbe.initialDelaySeconds.toString()) && !NumberRegex.exec(binding.ReadinessProbe.initialDelaySeconds.toString())) {
            this.setValidationErrors("The initial delay must be a whole number.", { ContainerReadinessProbeInitialDelaySeconds: "The initial delay must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "ReadinessProbe.successThreshold") && !isBound(binding.ReadinessProbe.successThreshold.toString()) && !NumberRegex.exec(binding.ReadinessProbe.successThreshold.toString())) {
            this.setValidationErrors("The success threshold must be a whole number.", { ContainerReadinessProbeSuccessThreshold: "The success threshold must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "ReadinessProbe.timeoutSeconds") && !isBound(binding.ReadinessProbe.timeoutSeconds.toString()) && !NumberRegex.exec(binding.ReadinessProbe.timeoutSeconds.toString())) {
            this.setValidationErrors("The timeout must be a whole number.", { ContainerReadinessProbeTimeoutSeconds: "The timeout must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "ReadinessProbe.failureThreshold") && !isBound(binding.ReadinessProbe.failureThreshold.toString()) && !NumberRegex.exec(binding.ReadinessProbe.failureThreshold.toString())) {
            this.setValidationErrors("The failure threshold must be a whole number.", { ContainerReadinessProbeFailureThreshold: "The failure threshold must be a whole number." });
            valid = false;
        }

        if (
            _.get(binding, "ReadinessProbe.failureThreshold" || _.get(binding, "ReadinessProbe.timeoutSeconds") || _.get(binding, "ReadinessProbe.initialDelaySeconds") || _.get(binding, "ReadinessProbe.periodSeconds")) &&
            !(_.get(binding, "ReadinessProbe.type") && binding.ReadinessProbe.type.trim())
        ) {
            this.setValidationErrors("The readiness check type must be specified.", { ContainerReadinessProbeType: "The readiness check type must be specified, or all readiness fields must be cleared." });
            valid = false;
        }

        if (_.get(binding, "ReadinessProbe.type") === CommandCheck) {
            if (!binding.ReadinessProbe.exec.command || binding.ReadinessProbe.exec.command.filter((c) => c && c.trim()).length === 0) {
                this.setValidationErrors("The command must be specified.", { ContainerReadinessProbeExecCommand: "The command must be specified." });
                valid = false;
            }
        }

        if (_.get(binding, "ReadinessProbe.type") === HttpGetCheck) {
            if (!_.get(binding, "ReadinessProbe.httpGet.port") || !binding.ReadinessProbe.httpGet.port.trim()) {
                this.setValidationErrors("The HTTP port must be specified.", { ContainerReadinessHttpGetPort: "The HTTP port must be specified." });
                valid = false;
            } else if (
                !isBound(binding.ReadinessProbe.httpGet.port.toString()) &&
                NumberRegex.exec(binding.ReadinessProbe.httpGet.port.toString()) &&
                !(parseInt(binding.ReadinessProbe.httpGet.port.toString(), 10) >= 1 && parseInt(binding.ReadinessProbe.httpGet.port.toString(), 10) <= 65535)
            ) {
                this.setValidationErrors("The HTTP port must be a number between 1 and 65535.", { ContainerReadinessHttpGetPort: "The HTTP port must be a number between 1 and 65535." });
                valid = false;
            }

            if (!_.get(binding, "ReadinessProbe.httpGet.path") || !binding.ReadinessProbe.httpGet.path!.trim()) {
                this.setValidationErrors("The HTTP path must be specified.", { ContainerReadinessHttpGetPath: "The HTTP path must be specified." });
                valid = false;
            }
        }

        if (_.get(binding, "ReadinessProbe.type") === TcpSocketCheck) {
            if (!_.get(binding, "ReadinessProbe.tcpSocket.port") || !binding.ReadinessProbe.tcpSocket.port.trim()) {
                this.setValidationErrors("The TCP socket port must be specified.", { ContainerReadinessProbeTcpSocketPort: "The HTTP port must be specified." });
                valid = false;
            } else if (
                !isBound(binding.ReadinessProbe.tcpSocket.port.toString()) &&
                NumberRegex.exec(binding.ReadinessProbe.tcpSocket.port.toString()) &&
                !(parseInt(binding.ReadinessProbe.tcpSocket.port.toString(), 10) >= 1 && parseInt(binding.ReadinessProbe.tcpSocket.port.toString(), 10) <= 65535)
            ) {
                this.setValidationErrors("The HTTP port must be a number between 1 and 65535.", { ContainerReadinessProbeTcpSocketPort: "The HTTP port must be a number between 1 and 65535." });
                valid = false;
            }
        }

        // ***

        if (_.get(binding, "StartupProbe.periodSeconds") && !isBound(binding.StartupProbe.periodSeconds.toString()) && !NumberRegex.exec(binding.StartupProbe.periodSeconds.toString())) {
            this.setValidationErrors("The period must be a whole number.", { ContainerStartupProbePeriodSeconds: "The period must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "StartupProbe.initialDelaySeconds") && !isBound(binding.StartupProbe.initialDelaySeconds.toString()) && !NumberRegex.exec(binding.StartupProbe.initialDelaySeconds.toString())) {
            this.setValidationErrors("The initial delay must be a whole number.", { ContainerStartupProbeInitialDelaySeconds: "The initial delay must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "StartupProbe.successThreshold") && !isBound(binding.StartupProbe.successThreshold.toString()) && !NumberRegex.exec(binding.StartupProbe.successThreshold.toString())) {
            this.setValidationErrors("The success threshold must be a whole number.", { ContainerStartupProbeSuccessThreshold: "The success threshold must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "StartupProbe.timeoutSeconds") && !isBound(binding.StartupProbe.timeoutSeconds.toString()) && !NumberRegex.exec(binding.StartupProbe.timeoutSeconds.toString())) {
            this.setValidationErrors("The timeout must be a whole number.", { ContainerStartupProbeTimeoutSeconds: "The timeout must be a whole number." });
            valid = false;
        }

        if (_.get(binding, "StartupProbe.failureThreshold") && !isBound(binding.StartupProbe.failureThreshold.toString()) && !NumberRegex.exec(binding.StartupProbe.failureThreshold.toString())) {
            this.setValidationErrors("The failure threshold must be a whole number.", { ContainerStartupProbeFailureThreshold: "The failure threshold must be a whole number." });
            valid = false;
        }

        if (
            _.get(binding, "StartupProbe.failureThreshold" || _.get(binding, "StartupProbe.timeoutSeconds") || _.get(binding, "StartupProbe.initialDelaySeconds") || _.get(binding, "StartupProbe.periodSeconds")) &&
            !(_.get(binding, "StartupProbe.type") && binding.StartupProbe.type.trim())
        ) {
            this.setValidationErrors("The startup check type must be specified.", { ContainerStartupProbeType: "The startup check type must be specified, or all startup fields must be cleared." });
            valid = false;
        }

        if (_.get(binding, "StartupProbe.type") === CommandCheck) {
            if (!binding.StartupProbe.exec.command || binding.StartupProbe.exec.command.filter((c) => c && c.trim()).length === 0) {
                this.setValidationErrors("The command must be specified.", { ContainerStartupProbeExecCommand: "The command must be specified." });
                valid = false;
            }
        }

        if (_.get(binding, "StartupProbe.type") === HttpGetCheck) {
            if (!_.get(binding, "StartupProbe.httpGet.port") || !binding.StartupProbe.httpGet.port.trim()) {
                this.setValidationErrors("The HTTP port must be specified.", { ContainerStartupHttpGetPort: "The HTTP port must be specified." });
                valid = false;
            } else if (
                !isBound(binding.StartupProbe.httpGet.port.toString()) &&
                NumberRegex.exec(binding.StartupProbe.httpGet.port.toString()) &&
                !(parseInt(binding.StartupProbe.httpGet.port.toString(), 10) >= 1 && parseInt(binding.StartupProbe.httpGet.port.toString(), 10) <= 65535)
            ) {
                this.setValidationErrors("The HTTP port must be a number between 1 and 65535.", { ContainerStartupHttpGetPort: "The HTTP port must be a number between 1 and 65535." });
                valid = false;
            }

            if (!_.get(binding, "StartupProbe.httpGet.path") || !binding.StartupProbe.httpGet.path!.trim()) {
                this.setValidationErrors("The HTTP path must be specified.", { ContainerStartupHttpGetPath: "The HTTP path must be specified." });
                valid = false;
            }
        }

        if (_.get(binding, "StartupProbe.type") === TcpSocketCheck) {
            if (!_.get(binding, "StartupProbe.tcpSocket.port") || !binding.StartupProbe.tcpSocket.port.trim()) {
                this.setValidationErrors("The TCP socket port must be specified.", { ContainerStartupProbeTcpSocketPort: "The HTTP port must be specified." });
                valid = false;
            } else if (
                !isBound(binding.StartupProbe.tcpSocket.port.toString()) &&
                NumberRegex.exec(binding.StartupProbe.tcpSocket.port.toString()) &&
                !(parseInt(binding.StartupProbe.tcpSocket.port.toString(), 10) >= 1 && parseInt(binding.StartupProbe.tcpSocket.port.toString(), 10) <= 65535)
            ) {
                this.setValidationErrors("The HTTP port must be a number between 1 and 65535.", { ContainerStartupProbeTcpSocketPort: "The HTTP port must be a number between 1 and 65535." });
                valid = false;
            }
        }

        // ***

        if (binding.VolumeMounts) {
            binding.VolumeMounts.forEach((v) => {
                v.keyError = v.key ? null! : "Volumes require a name.";
                v.valueError = v.value && v.value.indexOf(":") === -1 ? null! : 'Volumes must define a mount path, and the path must not contain ":"';
            });

            if (binding.VolumeMounts.find((p) => !!p.valueError || !!p.keyError)) {
                this.setValidationErrors("The volume mounts are incorrectly configured.");
                valid = false;
            }
        }

        if (binding.Ports) {
            if (binding.Ports.find((p) => !!p.valueError)) {
                this.setValidationErrors("Port numbers must be between 1 and 65535.", { ContainerDetailsPorts: "Port numbers must be between 1 and 65535." });
                valid = false;
            }
        }

        if (binding.SecurityContext) {
            if (!isBound(binding.SecurityContext.allowPrivilegeEscalation) && binding.SecurityContext.allowPrivilegeEscalation && !BooleanRegex.exec(binding.SecurityContext.allowPrivilegeEscalation.toString().trim())) {
                this.setValidationErrors("The allow privilege escalation value must be True or False.", {
                    ContainerDetailsSecurityContextAllowPrivilegeEscalation: "The allow privilege escalation value must be True or False.",
                });
                valid = false;
            }

            if (!isBound(binding.SecurityContext.privileged) && binding.SecurityContext.privileged && !BooleanRegex.exec(binding.SecurityContext.privileged.toString().trim())) {
                this.setValidationErrors("The privileged value must be True or False.", {
                    ContainerDetailsSecurityContextPrivileged: "The privileged value must be True or False.",
                });
                valid = false;
            }

            if (!isBound(binding.SecurityContext.runAsNonRoot) && binding.SecurityContext.runAsNonRoot && !BooleanRegex.exec(binding.SecurityContext.runAsNonRoot.toString().trim())) {
                this.setValidationErrors("The run as non-root value must be True or False.", {
                    ContainerDetailsSecurityContextRunAsNonRoot: "The run as non-root value must be True or False.",
                });
                valid = false;
            }

            if (!isBound(binding.SecurityContext.runAsUser) && binding.SecurityContext.runAsUser && !NumberRegex.exec(binding.SecurityContext.runAsUser.toString().trim())) {
                this.setValidationErrors("The run as user value must be a number.", {
                    ContainerDetailsSecurityContextRunAsUser: "The run as user value must be a number.",
                });
                valid = false;
            }

            if (!isBound(binding.SecurityContext.runAsGroup) && binding.SecurityContext.runAsGroup && !NumberRegex.exec(binding.SecurityContext.runAsGroup.toString().trim())) {
                this.setValidationErrors("The run as group value must be a number.", {
                    ContainerDetailsSecurityContextRunAsGroup: "The run as group value must be a number.",
                });
                valid = false;
            }
        }

        LifecycleHandlerFormSection.Validate(binding, (msg) => {
            this.setValidationErrors(msg);
            valid = false;
        });

        if (valid) {
            return this.props.onAdd(binding);
        }

        return false;
    };

    render() {
        return (
            <OkDialogLayout onOkClick={() => this.save()} busy={this.state.busy} errors={this.errors} title={"Add Container"}>
                {this.state.containerDetails && (
                    <React.Fragment>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Image Details"
                            errorKey={"ContainerDetailsName"}
                            summary={!!this.state.containerDetails.Name ? Summary.summary(this.state.containerDetails.Name) : Summary.placeholder("Please provide a image details for the container image")}
                            help={"Provide a name and a package image for the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <DebounceText label="Name" value={this.state.containerDetails.Name!} error={this.getFieldError("ContainerDetailsName")} onChange={(Name) => this.setContainerState({ Name })} />
                            <p>
                                <strong>Package Image</strong>
                            </p>
                            {!this.props.standalone && (
                                <span>
                                    <DeferredPackageSelector
                                        packageId={this.state.containerDetails.PackageId}
                                        feedIdOrName={this.state.containerDetails.FeedId}
                                        onPackageIdChange={(PackageId) => this.setContainerState({ PackageId })}
                                        onFeedIdChange={(FeedId) => this.setContainerState({ FeedId })}
                                        localNames={this.props.localNames}
                                        projectId={this.props.projectId}
                                        feeds={this.props.feeds}
                                        refreshFeeds={this.props.refreshFeeds}
                                        feedType={[FeedType.Docker, FeedType.AwsElasticContainerRegistry]}
                                        // Deferred package selection properties
                                        parameters={this.props.parameters}
                                        packageSelectionMode={this.state.containerDetails.Properties.SelectionMode || PackageSelectionMode.Immediate}
                                        packageParameterName={this.state.containerDetails.Properties.PackageParameterName || ""}
                                        onPackageSelectionModeChange={(packageSelectionMode) => this.onPackageSelectionModeChange(packageSelectionMode)}
                                        onPackageParameterChange={(packageParameter) => this.onPackageParameterChange(packageParameter)}
                                    />
                                    <BoundStringRadioButtonGroup
                                        variableLookup={{
                                            localNames: this.props.localNames,
                                        }}
                                        resetValue={"True"}
                                        value={this.state.containerDetails.CreateFeedSecrets || "True"}
                                        onChange={(CreateFeedSecrets) => this.setContainerState({ CreateFeedSecrets })}
                                        label="Generate docker-registry secret for feed"
                                    >
                                        <RadioButton value={"True"} label="Generate docker-registry secret" />
                                        <Note>
                                            Select this option when the Kubernetes cluster requires credentials to access a Docker registry. For example, you can use this option to access private images on registries like Docker Hub. With this option
                                            selected, and if the feed has credentials, a <ExternalLink href={"KubernetesPrivateRegistries"}>docker-registry secret</ExternalLink> will be created and the pod's <code>imagePullSecrets</code> property
                                            will reference it.
                                        </Note>
                                        <RadioButton value={"False"} label="Do not generate docker-registry secret" />
                                        <Note>
                                            Select this option when the Kubernetes cluster is granted access to the docker registry by other means than a docker-registry secret. For example, most cloud providers allow a hosted Kubernetes cluster to
                                            access a hosted Docker registry via IAM rules rather than a registry secret. Examples include <ExternalLink href={"ECRonEKS"}>ECR and EKS</ExternalLink>,{" "}
                                            <ExternalLink href={"ACRonAKS"}>ACR and AKS</ExternalLink>, <ExternalLink href={"GCRonGKE"}>GCR and GKE</ExternalLink>.
                                        </Note>
                                        <Note>Note that these options only impact feeds that have credentials. For public feeds with no credentials, these options do not have any impact.</Note>
                                    </BoundStringRadioButtonGroup>
                                </span>
                            )}
                            {this.props.standalone && <DebounceText label="Image" value={this.state.containerDetails.PackageId} onChange={(PackageId) => this.setContainerState({ PackageId })} />}
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Ports"
                            errorKey={"ContainerDetailsPorts"}
                            summary={this.kubernetesPortSummary()}
                            help={"Add ports to be referenced by the target node in the associated service."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <p>
                                Containers expose network services through their ports. Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#ports">ports</ExternalLink>.
                            </p>
                            <ExtendedKeyValueEditList
                                items={() => (_.isArray(this.state.containerDetails.Ports) ? this.state.containerDetails.Ports : [])}
                                name="Port"
                                onAdd={this.repositionDialog}
                                onChange={(val) => this.setContainerState({ Ports: val })}
                                valueLabel="Port"
                                keyLabel="Name"
                                optionLabel="Protocol"
                                optionValues={[
                                    { text: "TCP", value: "TCP" },
                                    { text: "UDP", value: "UDP" },
                                ]}
                                optionReset={"TCP"}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Volume Mounts"
                            errorKey={"ContainerDetailsVolumeMounts"}
                            summary={this.kubernetesVolumeSummary()}
                            help={"Include volume mount points to be exposed on the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <p>
                                Volumes that were exposed to the containers are mounted to the container filesystem here. Learn more about{" "}
                                <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#volume-mounts">volume mounts</ExternalLink>.
                            </p>
                            <ExtendedKeyValueEditList
                                items={() => (_.isArray(this.state.containerDetails.VolumeMounts) ? this.state.containerDetails.VolumeMounts : [])}
                                name="Volume Mount"
                                onAdd={this.repositionDialog}
                                onChange={(val) => this.setContainerState({ VolumeMounts: val })}
                                keyLabel="Name"
                                getOptions={this.getVolumeOptions}
                                valueLabel="Mount path"
                                optionLabel="Sub path"
                                option2Label="Read only"
                                option2Values={[
                                    { text: "True", value: "true" },
                                    { text: "False", value: "false" },
                                ]}
                                option2Reset={"false"}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="Container Type"
                            errorKey={"ContainerDetailsType"}
                            summary={this.initContainerSummary()}
                            help={"Select the type of container to be defined."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <BoundStringRadioButtonGroup
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                resetValue={"False"}
                                value={this.state.containerDetails.InitContainer || "False"}
                                onChange={(InitContainer) => this.setContainerState({ InitContainer })}
                                label="Container Type"
                            >
                                <RadioButton value={"False"} label="Regular container" />
                                <RadioButton value={"True"} label="Init container" />
                            </BoundStringRadioButtonGroup>
                            <Note>
                                Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#container-type">container types</ExternalLink>.
                            </Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Termination message path and policy"
                            errorKey={"ContainerDetailsTerminationMessagePath|ContainerDetailsTerminationMessagePolicy"}
                            summary={this.kubernetesTerminationSummary()}
                            help={"Set the path to the file that contains the container status message"}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsTerminationMessagePath")}
                                value={_.get(this.state.containerDetails, "TerminationMessagePath") || ""}
                                onChange={(TerminationMessagePath) => this.setContainerState({ TerminationMessagePath })}
                                label="Termination message path"
                            />
                            <BoundStringRadioButtonGroup
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                error={this.getFieldError("ContainerDetailsTerminationMessagePolicy")}
                                resetValue={"File"}
                                value={_.get(this.state.containerDetails, "TerminationMessagePolicy") || "File"}
                                onChange={(TerminationMessagePolicy) => this.setContainerState({ TerminationMessagePolicy })}
                                label="Termination message policy"
                            >
                                <RadioButton value={"File"} label="File" />
                                <RadioButton value={"FallbackToLogsOnError"} label="Fallback To Logs On Error" />
                            </BoundStringRadioButtonGroup>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="Image Pull Policy"
                            errorKey={"ContainerDetailsImagePullPolicy"}
                            summary={this.imagePullPolicySummary()}
                            help={"Define how the image will be downloaded."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <BoundStringRadioButtonGroup
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                resetValue={"IfNotPresent"}
                                value={this.state.containerDetails.ImagePullPolicy || ""}
                                onChange={(ImagePullPolicy) => this.setContainerState({ ImagePullPolicy })}
                                label="Image pull policy"
                            >
                                <RadioButton value={""} label="Default" />
                                <RadioButton value={"IfNotPresent"} label="If Not Present" />
                                <RadioButton value={"Always"} label="Always" />
                                <RadioButton value={"Never"} label="Never" />
                            </BoundStringRadioButtonGroup>
                            <Note>
                                Learn more about the <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#image-pull-policy">image pull policy</ExternalLink>.
                            </Note>
                        </ExpandableFormSection>
                        <FormSectionHeading title="Resources" />
                        <p>
                            The CPU, memory and ephemeral storage resources required by the container are defined as resource requests and limits. Learn more about{" "}
                            <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#resources">resource requests and limits</ExternalLink>.
                        </p>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="CPU Requests and Limits"
                            errorKey={"ContainerDetailsResourcesCpu"}
                            summary={this.kubernetesCpuSummary()}
                            help={"Specify the CPU requests and limits. "}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsResourcesCpuRequest")}
                                value={_.get(this.state.containerDetails, "Resources.requests.cpu")}
                                onChange={(x) => this.setContainerResourcesRequestState({ cpu: x })}
                                label="CPU request"
                            />
                            <Note>If left blank, the CPU request will be set to the same value as the CPU limit. If the CPU limit is not set it will use the default value specified in the Kubernetes namespace.</Note>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsResourcesCpuLimit")}
                                value={_.get(this.state.containerDetails, "Resources.limits.cpu")}
                                onChange={(x) => this.setContainerResourcesLimitState({ cpu: x })}
                                label="CPU limit"
                            />
                            <Note>If the CPU limit is not set it will use the default value specified in the Kubernetes namespace.</Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="Memory Requests and Limits"
                            errorKey={"ContainerDetailsResourcesMemory"}
                            summary={this.kubernetesMemorySummary()}
                            help={"Specify memory requests and limits."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsResourcesMemoryRequest")}
                                value={_.get(this.state.containerDetails, "Resources.requests.memory")}
                                onChange={(x) => this.setContainerResourcesRequestState({ memory: x })}
                                label="Memory request"
                            />
                            <Note>If left blank, the memory request will be set to the same value as the memory limit. If the memory limit is not set it will use the default value specified in the Kubernetes namespace.</Note>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsResourcesMemoryLimit")}
                                value={_.get(this.state.containerDetails, "Resources.limits.memory")}
                                onChange={(x) => this.setContainerResourcesLimitState({ memory: x })}
                                label="Memory limit"
                            />
                            <Note>If this value is not set it will use the default value specified in the Kubernetes namespace.</Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="Ephemeral Storage Requests and Limits"
                            errorKey={"ContainerDetailsResourcesStorage"}
                            summary={this.kubernetesStorageSummary()}
                            help={"Specify the ephemeral storage requests and limits."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsResourcesStorageRequest")}
                                value={_.get(this.state.containerDetails, "Resources.requests.ephemeralStorage")}
                                onChange={(x) => this.setContainerResourcesRequestState({ ephemeralStorage: x })}
                                label="Ephemeral storage request"
                            />
                            <Note>
                                If left blank, the ephemeral storage request will be set to the same value as the ephemeral storage limit. If the ephemeral storage limit is not set it will use the default value specified in the Kubernetes namespace.
                            </Note>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsResourcesStorageLimit")}
                                value={_.get(this.state.containerDetails, "Resources.limits.ephemeralStorage")}
                                onChange={(x) => this.setContainerResourcesLimitState({ ephemeralStorage: x })}
                                label="Ephemeral storage limit"
                            />
                            <Note>If this value is not set it will use the default value specified in the Kubernetes namespace.</Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="GPU Requests and Limits"
                            errorKey={"ContainerDetailsResourcesGpu"}
                            summary={this.kubernetesGpuSummary()}
                            help={"Specify the GPU requests and limits. "}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsResourcesNvidiaGpuLimit")}
                                value={_.get(this.state.containerDetails, "Resources.limits.nvidiaGpu")}
                                onChange={(x) => this.setContainerResourcesLimitState({ nvidiaGpu: x })}
                                label="Nvidia GPU limit"
                            />
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("ContainerDetailsResourcesAmdGpuLimit")}
                                value={_.get(this.state.containerDetails, "Resources.limits.amdGpu")}
                                onChange={(x) => this.setContainerResourcesLimitState({ amdGpu: x })}
                                label="AMD GPU limit"
                            />
                            <Note>
                                See the <ExternalLink href="K8SGpuSchedule">Kubernetes documentation</ExternalLink> for details on scheduling GPU resources.
                            </Note>
                        </ExpandableFormSection>

                        <FormSectionHeading title="Environment Variables" />
                        <p>
                            Environment variables can be defined in the container from key/value pairs, or from data held in secret or config map resources. Learn more about{" "}
                            <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#environment-variables">environment variables</ExternalLink>.
                        </p>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="Environment Variables"
                            errorKey={"ContainerDetailsEnvironmentVariablesEnvironmentVariable"}
                            summary={this.kubernetesEnvironmentVarsSummary()}
                            help={"Define environment variables for the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <ExtendedKeyValueEditList
                                items={() => (_.isArray(this.state.containerDetails.EnvironmentVariables) ? this.state.containerDetails.EnvironmentVariables : [])}
                                name="Environment Variable"
                                onChange={(val) => this.setContainerState({ EnvironmentVariables: val })}
                                valueLabel="Value"
                                keyLabel="Name"
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="ConfigMap Environment Variables"
                            errorKey={"ContainerDetailsEnvironmentVariablesConfigMapEnvironmentVariable"}
                            summary={this.kubernetesConfigMapEnvironmentVarsSummary()}
                            help={"Define environment variables, sourced from a ConfigMap, for the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <ExtendedKeyValueEditList
                                items={() => (_.isArray(this.state.containerDetails.ConfigMapEnvironmentVariables) ? this.state.containerDetails.ConfigMapEnvironmentVariables : [])}
                                name="Individual environment variable from a configmap"
                                onAdd={this.repositionDialog}
                                onChange={(val) => this.setContainerState({ ConfigMapEnvironmentVariables: val })}
                                valueLabel="ConfigMap name"
                                keyLabel="Name"
                                optionLabel={"Key"}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                            <Note>Add an individual value from a config map as an environment variable.</Note>
                            <ExtendedKeyValueEditList
                                items={() => (_.isArray(this.state.containerDetails.ConfigMapEnvFromSource) ? this.state.containerDetails.ConfigMapEnvFromSource : [])}
                                name="All environment variables from a configmap"
                                onAdd={this.repositionDialog}
                                onChange={(val) => this.setContainerState({ ConfigMapEnvFromSource: val })}
                                valueLabel="Optional environment variable prefix"
                                keyLabel="Config map name"
                                optionLabel={"Config map is optional"}
                                optionValues={[
                                    { value: "True", text: "True" },
                                    { value: "False", text: "False" },
                                ]}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                            <Note>Add all values from a config map as environment variables, with an optional prefix on the environment variable name.</Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="Secret Environment Variables"
                            errorKey={"ContainerDetailsEnvironmentVariablesSecretEnvironmentVariable"}
                            summary={this.kubernetesSecretEnvironmentVarsSummary()}
                            help={"Define environment variables, sourced from a secret, for the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <ExtendedKeyValueEditList
                                items={() => (_.isArray(this.state.containerDetails.SecretEnvironmentVariables) ? this.state.containerDetails.SecretEnvironmentVariables : [])}
                                name="Individual environment variable from a secret"
                                onAdd={this.repositionDialog}
                                onChange={(val) => this.setContainerState({ SecretEnvironmentVariables: val })}
                                valueLabel="Secret name"
                                keyLabel="Name"
                                optionLabel={"Key"}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                            <Note>Add an individual value from a secret as an environment variable.</Note>
                            <ExtendedKeyValueEditList
                                items={() => (_.isArray(this.state.containerDetails.SecretEnvFromSource) ? this.state.containerDetails.SecretEnvFromSource : [])}
                                name="All environment variables from a secret"
                                onAdd={this.repositionDialog}
                                onChange={(val) => this.setContainerState({ SecretEnvFromSource: val })}
                                valueLabel="Optional environment variable prefix"
                                keyLabel="Secret name"
                                optionLabel={"Secret is optional"}
                                optionValues={[
                                    { value: "True", text: "True" },
                                    { value: "False", text: "False" },
                                ]}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                            <Note>Add all values from a secret as environment variables, with an optional prefix on the environment variable name.</Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            title="Pod and Container Field Environment Variables (Downstream API)"
                            errorKey={"ContainerDetailsEnvironmentVariablesFieldRefEnvironmentVariable"}
                            summary={this.kubernetesDownstreamEnvironmentVarsSummary()}
                            help={"Define environment variables, sourced from the container or pod metadata, for the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                            isExpandedByDefault={false}
                        >
                            <Note>
                                Environment variables can expose <ExternalLink href="KubernetesEnvironmentVariableExposePodInformation">Pod and Container fields</ExternalLink>. For example <code>spec.nodeName</code>
                            </Note>
                            <Note>
                                See the <ExternalLink href="KubernetesDownwardApiCapabilities">Kubernetes documentation</ExternalLink> for the fields available via the Downward API.
                            </Note>
                            <ExtendedKeyValueEditList
                                items={() => (_.isArray(this.state.containerDetails.FieldRefEnvironmentVariables) ? this.state.containerDetails.FieldRefEnvironmentVariables : [])}
                                name="Field Reference Environment Variable"
                                onChange={(val) => this.setContainerState({ FieldRefEnvironmentVariables: val })}
                                onAdd={this.repositionDialog}
                                valueLabel="Field Path"
                                keyLabel="Name"
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                        </ExpandableFormSection>

                        {this.state.containerDetails.InitContainer !== "True" && (
                            <React.Fragment>
                                <FormSectionHeading title="Liveness Probe" />
                                <p>
                                    Liveness probes are used to confirm that a container is still running and healthy. Learn more about{" "}
                                    <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#liveness-probe">liveness probes</ExternalLink>.
                                </p>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Failure Threshold"
                                    errorKey={"ContainerDetailsLivenessFailureThreshold"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "LivenessProbe.failureThreshold")
                                            ? Summary.summary(
                                                  <span>
                                                      Pod is restarted after <strong>{this.state.containerDetails.LivenessProbe.failureThreshold}</strong>
                                                      {this.state.containerDetails.LivenessProbe.failureThreshold === "1" ? <span> failure</span> : <span> consecutive failures</span>}
                                                  </span>
                                              )
                                            : Summary.default("No failure threshold specified")
                                    }
                                    help={"Set how many times the probe can fail after the pod has been started."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerLivenessProbeFailureThreshold")}
                                        value={_.get(this.state.containerDetails, "LivenessProbe.failureThreshold")}
                                        onChange={(x) => this.setContainerLivenessState({ failureThreshold: x })}
                                        label="Failure threshold"
                                        placeholder="3 (default)"
                                    />
                                    <Note>After this many consecutive failures, the pod is restarted.</Note>
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Timeout"
                                    errorKey={"ContainerDetailsLivenessTimeoutSeconds"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "LivenessProbe.timeoutSeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will wait <strong>{this.state.containerDetails.LivenessProbe.timeoutSeconds}</strong> second
                                                      {this.state.containerDetails.LivenessProbe.timeoutSeconds !== "1" && <span>s</span>} for a response
                                                  </span>
                                              )
                                            : Summary.default("No timeout specified")
                                    }
                                    help={"Specify the number of seconds to wait for a probe response."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerLivenessProbeTimeoutSeconds")}
                                        value={_.get(this.state.containerDetails, "LivenessProbe.timeoutSeconds")}
                                        onChange={(x) => this.setContainerLivenessState({ timeoutSeconds: x })}
                                        label="Timeout in seconds"
                                        placeholder="1 (default)"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Initial Delay"
                                    errorKey={"ContainerDetailsLivenessInitialDelaySeconds"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "LivenessProbe.initialDelaySeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will wait <strong>{this.state.containerDetails.LivenessProbe.initialDelaySeconds}</strong> second
                                                      {this.state.containerDetails.LivenessProbe.initialDelaySeconds !== "1" && <span>s</span>} before being initiated
                                                  </span>
                                              )
                                            : Summary.default("No initial delay specified")
                                    }
                                    help={"Set the number of seconds to wait before the probe is initiated."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerLivenessProbeInitialDelaySeconds")}
                                        value={_.get(this.state.containerDetails, "LivenessProbe.initialDelaySeconds")}
                                        onChange={(x) => this.setContainerLivenessState({ initialDelaySeconds: x })}
                                        label="Initial delay in seconds"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Period"
                                    errorKey={"ContainerDetailsLivenessPeriodSeconds"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "LivenessProbe.periodSeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will be run every <strong>{this.state.containerDetails.LivenessProbe.periodSeconds}</strong> second
                                                      {this.state.containerDetails.LivenessProbe.periodSeconds !== "1" && <span>s</span>}
                                                  </span>
                                              )
                                            : Summary.default("No period specified")
                                    }
                                    help={"How frequently should the probe be executed."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerLivenessProbePeriodSeconds")}
                                        value={_.get(this.state.containerDetails, "LivenessProbe.periodSeconds")}
                                        onChange={(x) => this.setContainerLivenessState({ periodSeconds: x })}
                                        label="Period in seconds"
                                        placeholder="10 (default)"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Liveness Probe Type"
                                    errorKey={"ContainerDetailsLivenessType"}
                                    summary={this.kubernetesLivenessProbeTypeSummary()}
                                    help={"Select the liveness probe type."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <Select
                                        error={this.getFieldError("ContainerLivenessProbeType")}
                                        value={_.get(this.state.containerDetails, "LivenessProbe.type")}
                                        items={[
                                            { text: "", value: "" },
                                            { text: "Command", value: CommandCheck },
                                            { text: "HttpGet", value: HttpGetCheck },
                                            { text: "TCP Socket", value: TcpSocketCheck },
                                        ]}
                                        label="Liveness probe type"
                                        onChange={(type) => this.setContainerLivenessState({ type: type! })}
                                    />
                                    {_.get(this.state.containerDetails, "LivenessProbe.type") === CommandCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerLivenessProbeExecCommand")}
                                                value={((_.get(this.state.containerDetails, "LivenessProbe.exec.command") as string[]) || []).join("\n")}
                                                onChange={(x) => this.setContainerLivenessCommandsState({ command: (x || "").split("\n") })}
                                                label="Health check commands"
                                                multiline={true}
                                                rows={5}
                                                rowsMax={5}
                                            />
                                            <Note>A newline-separated list of commands which are run in the container, and if the return value is zero, the container is considered to be healthy.</Note>
                                        </React.Fragment>
                                    )}
                                    {_.get(this.state.containerDetails, "LivenessProbe.type") === HttpGetCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerLivenessProbeHttpGetHost")}
                                                value={_.get(this.state.containerDetails, "LivenessProbe.httpGet.host")}
                                                onChange={(x) => this.setContainerLivenessHttpGetState({ host: x })}
                                                label="Host"
                                            />
                                            <Note>The optional host name to connect to. If not defined, this value will default to the IP address of the pod.</Note>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerLivenessProbeHttpGetPath")}
                                                value={_.get(this.state.containerDetails, "LivenessProbe.httpGet.path")}
                                                onChange={(x) => this.setContainerLivenessHttpGetState({ path: x })}
                                                label="Path"
                                            />
                                            <Note>The path to access on the HTTP server.</Note>
                                            <BoundSelect
                                                error={this.getFieldError("ContainerLivenessProbeHttpGetScheme")}
                                                value={_.get(this.state.containerDetails, "LivenessProbe.httpGet.scheme")}
                                                onChange={(x) => this.setContainerLivenessHttpGetState({ scheme: x })}
                                                label="Scheme"
                                                items={[
                                                    { value: "HTTP", text: "HTTP" },
                                                    { value: "HTTPS", text: "HTTPS" },
                                                ]}
                                                resetValue={"HTTP"}
                                            />
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerLivenessProbeHttpGetPort")}
                                                value={_.get(this.state.containerDetails, "LivenessProbe.httpGet.port")}
                                                onChange={(x) => this.setContainerLivenessHttpGetState({ port: x })}
                                                label="Port"
                                            />
                                            <Note>
                                                The port number to use when connecting to the HTTP server. Alternatively this value can be an <ExternalLink href="IANA">IANA</ExternalLink> port name.
                                            </Note>
                                            <p>
                                                <strong>HTTP Header</strong>
                                            </p>
                                            <p>Add HTTP headers to be sent with the request.</p>
                                            <ExtendedKeyValueEditList
                                                items={() => (_.isArray(_.get(this.state.containerDetails, "LivenessProbe.httpGet.httpHeaders")) ? _.get(this.state.containerDetails, "LivenessProbe.httpGet.httpHeaders") : [])}
                                                name="HTTP Header"
                                                onAdd={this.repositionDialog}
                                                onChange={(val) => this.setContainerLivenessHttpGetState({ httpHeaders: val })}
                                                valueLabel="Value"
                                                keyLabel="Name"
                                                hideBindOnKey={false}
                                                projectId={this.props.projectId}
                                                addToTop={true}
                                            />
                                        </React.Fragment>
                                    )}
                                    {_.get(this.state.containerDetails, "LivenessProbe.type") === TcpSocketCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerLivenessProbeTcpSocketHost")}
                                                value={_.get(this.state.containerDetails, "LivenessProbe.tcpSocket.host")}
                                                onChange={(x) => this.setContainerLivenessSocketState({ host: x })}
                                                label="Host"
                                            />
                                            <Note>The optional host name to connect to. If not defined, this value will default to the IP address of the pod.</Note>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerLivenessProbeTcpSocketPort")}
                                                value={_.get(this.state.containerDetails, "LivenessProbe.tcpSocket.port")}
                                                onChange={(x) => this.setContainerLivenessSocketState({ port: x })}
                                                label="Port"
                                            />
                                            <Note>
                                                The port number to use when connecting to the container. Alternatively this value can be an <ExternalLink href="IANA">IANA</ExternalLink> port name.
                                            </Note>
                                        </React.Fragment>
                                    )}
                                </ExpandableFormSection>

                                <FormSectionHeading title="Startup Probe" />
                                <p>
                                    Startup probes are useful for delaying liveness probes until the container has successfully started. Learn more about{" "}
                                    <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#startup-probe">startup probes</ExternalLink>.
                                </p>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Success threshold"
                                    errorKey={"ContainerDetailsStartupSuccessThreshold"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "StartupProbe.successThreshold")
                                            ? Summary.summary(
                                                  <span>
                                                      Container is considered ready after <strong>{this.state.containerDetails.StartupProbe.successThreshold}</strong>
                                                      {this.state.containerDetails.StartupProbe.successThreshold === "1" ? <span> successes</span> : <span> consecutive successes</span>}
                                                  </span>
                                              )
                                            : Summary.default("No success threshold specified")
                                    }
                                    help={"Specify how many consecutive times the probe must succeed for the container to be considered ready after a failure."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerStartupProbeSuccessThreshold")}
                                        value={_.get(this.state.containerDetails, "StartupProbe.successThreshold")}
                                        onChange={(x) => this.setContainerStartupState({ successThreshold: x })}
                                        label="Success threshold"
                                        placeholder="1 (default)"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Failure Threshold"
                                    errorKey={"ContainerDetailsStartupFailureThreshold"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "StartupProbe.failureThreshold")
                                            ? Summary.summary(
                                                  <span>
                                                      Pod is restarted after <strong>{this.state.containerDetails.StartupProbe.failureThreshold}</strong>
                                                      {this.state.containerDetails.StartupProbe.failureThreshold === "1" ? <span> failure</span> : <span> consecutive failures</span>}
                                                  </span>
                                              )
                                            : Summary.default("No failure threshold specified")
                                    }
                                    help={"Set how many times the probe can fail after the pod has been started."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerStartupProbeFailureThreshold")}
                                        value={_.get(this.state.containerDetails, "StartupProbe.failureThreshold")}
                                        onChange={(x) => this.setContainerStartupState({ failureThreshold: x })}
                                        label="Failure threshold"
                                        placeholder="3 (default)"
                                    />
                                    <Note>After this many consecutive failures, the pod is restarted.</Note>
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Timeout"
                                    errorKey={"ContainerDetailsStartupTimeoutSeconds"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "StartupProbe.timeoutSeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will wait <strong>{this.state.containerDetails.StartupProbe.timeoutSeconds}</strong> second
                                                      {this.state.containerDetails.StartupProbe.timeoutSeconds !== "1" && <span>s</span>} for a response
                                                  </span>
                                              )
                                            : Summary.default("No timeout specified")
                                    }
                                    help={"Specify the number of seconds to wait for a probe response."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerStartupProbeTimeoutSeconds")}
                                        value={_.get(this.state.containerDetails, "StartupProbe.timeoutSeconds")}
                                        onChange={(x) => this.setContainerStartupState({ timeoutSeconds: x })}
                                        label="Timeout in seconds"
                                        placeholder="1 (default)"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Initial Delay"
                                    errorKey={"ContainerDetailsStartupInitialDelaySeconds"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "StartupProbe.initialDelaySeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will wait <strong>{this.state.containerDetails.StartupProbe.initialDelaySeconds}</strong> second
                                                      {this.state.containerDetails.StartupProbe.initialDelaySeconds !== "1" && <span>s</span>} before being initiated
                                                  </span>
                                              )
                                            : Summary.default("No initial delay specified")
                                    }
                                    help={"Set the number of seconds to wait before the probe is initiated."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerStartupProbeInitialDelaySeconds")}
                                        value={_.get(this.state.containerDetails, "StartupProbe.initialDelaySeconds")}
                                        onChange={(x) => this.setContainerStartupState({ initialDelaySeconds: x })}
                                        label="Initial delay in seconds"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Period"
                                    errorKey={"ContainerDetailsStartupPeriodSeconds"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "StartupProbe.periodSeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will be run every <strong>{this.state.containerDetails.StartupProbe.periodSeconds}</strong> second
                                                      {this.state.containerDetails.StartupProbe.periodSeconds !== "1" && <span>s</span>}
                                                  </span>
                                              )
                                            : Summary.default("No period specified")
                                    }
                                    help={"How frequently should the probe be executed."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerStartupProbePeriodSeconds")}
                                        value={_.get(this.state.containerDetails, "StartupProbe.periodSeconds")}
                                        onChange={(x) => this.setContainerStartupState({ periodSeconds: x })}
                                        label="Period in seconds"
                                        placeholder="10 (default)"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Startup Probe Type"
                                    errorKey={"ContainerDetailsStartupType"}
                                    summary={this.kubernetesStartupProbeTypeSummary()}
                                    help={"Select the startup probe type."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <Select
                                        error={this.getFieldError("ContainerStartupProbeType")}
                                        value={_.get(this.state.containerDetails, "StartupProbe.type")}
                                        items={[
                                            { text: "", value: "" },
                                            { text: "Command", value: CommandCheck },
                                            { text: "HttpGet", value: HttpGetCheck },
                                            { text: "TCP Socket", value: TcpSocketCheck },
                                        ]}
                                        label="Startup probe type"
                                        onChange={(type) => this.setContainerStartupState({ type: type! })}
                                    />
                                    {_.get(this.state.containerDetails, "StartupProbe.type") === CommandCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerStartupProbeExecCommand")}
                                                value={((_.get(this.state.containerDetails, "StartupProbe.exec.command") as string[]) || []).join("\n")}
                                                onChange={(x) => this.setContainerStartupCommandsState({ command: (x || "").split("\n") })}
                                                label="Health check commands"
                                                multiline={true}
                                                rows={5}
                                                rowsMax={5}
                                            />
                                            <Note>A newline-separated list of commands which are run in the container, and if the return value is zero, the container is considered to be healthy.</Note>
                                        </React.Fragment>
                                    )}
                                    {_.get(this.state.containerDetails, "StartupProbe.type") === HttpGetCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerStartupProbeHttpGetHost")}
                                                value={_.get(this.state.containerDetails, "StartupProbe.httpGet.host")}
                                                onChange={(x) => this.setContainerStartupHttpGetState({ host: x })}
                                                label="Host"
                                            />
                                            <Note>The optional host name to connect to. If not defined, this value will default to the IP address of the pod.</Note>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerStartupProbeHttpGetPath")}
                                                value={_.get(this.state.containerDetails, "StartupProbe.httpGet.path")}
                                                onChange={(x) => this.setContainerStartupHttpGetState({ path: x })}
                                                label="Path"
                                            />
                                            <Note>The path to access on the HTTP server.</Note>
                                            <BoundSelect
                                                error={this.getFieldError("ContainerStartupProbeHttpGetScheme")}
                                                value={_.get(this.state.containerDetails, "StartupProbe.httpGet.scheme")}
                                                onChange={(x) => this.setContainerStartupHttpGetState({ scheme: x })}
                                                label="Scheme"
                                                items={[
                                                    { value: "HTTP", text: "HTTP" },
                                                    { value: "HTTPS", text: "HTTPS" },
                                                ]}
                                                resetValue={"HTTP"}
                                            />
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerStartupProbeHttpGetPort")}
                                                value={_.get(this.state.containerDetails, "StartupProbe.httpGet.port")}
                                                onChange={(x) => this.setContainerStartupHttpGetState({ port: x })}
                                                label="Port"
                                            />
                                            <Note>
                                                The port number to use when connecting to the HTTP server. Alternatively this value can be an <ExternalLink href="IANA">IANA</ExternalLink> port name.
                                            </Note>
                                            <p>
                                                <strong>HTTP Header</strong>
                                            </p>
                                            <p>Add HTTP headers to be sent with the request.</p>
                                            <ExtendedKeyValueEditList
                                                items={() => (_.isArray(_.get(this.state.containerDetails, "StartupProbe.httpGet.httpHeaders")) ? _.get(this.state.containerDetails, "StartupProbe.httpGet.httpHeaders") : [])}
                                                name="HTTP Header"
                                                onAdd={this.repositionDialog}
                                                onChange={(val) => this.setContainerStartupHttpGetState({ httpHeaders: val })}
                                                valueLabel="Value"
                                                keyLabel="Name"
                                                hideBindOnKey={false}
                                                projectId={this.props.projectId}
                                                addToTop={true}
                                            />
                                        </React.Fragment>
                                    )}
                                    {_.get(this.state.containerDetails, "StartupProbe.type") === TcpSocketCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerStartupProbeTcpSocketHost")}
                                                value={_.get(this.state.containerDetails, "StartupProbe.tcpSocket.host")}
                                                onChange={(x) => this.setContainerStartupSocketState({ host: x })}
                                                label="Host"
                                            />
                                            <Note>The optional host name to connect to. If not defined, this value will default to the IP address of the pod.</Note>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerStartupProbeTcpSocketPort")}
                                                value={_.get(this.state.containerDetails, "StartupProbe.tcpSocket.port")}
                                                onChange={(x) => this.setContainerStartupSocketState({ port: x })}
                                                label="Port"
                                            />
                                            <Note>
                                                The port number to use when connecting to the container. Alternatively this value can be an <ExternalLink href="IANA">IANA</ExternalLink> port name.
                                            </Note>
                                        </React.Fragment>
                                    )}
                                </ExpandableFormSection>

                                <FormSectionHeading title="Readiness probe" />
                                <p>
                                    Readiness probes are used to confirm that a container has started. Learn more about{" "}
                                    <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#readiness-probe">readiness probes</ExternalLink>.
                                </p>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Success threshold"
                                    errorKey={"ContainerDetailsReadinessSuccessThreshold"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "ReadinessProbe.successThreshold")
                                            ? Summary.summary(
                                                  <span>
                                                      Container is considered ready after <strong>{this.state.containerDetails.ReadinessProbe.successThreshold}</strong>
                                                      {this.state.containerDetails.ReadinessProbe.successThreshold === "1" ? <span> successes</span> : <span> consecutive successes</span>}
                                                  </span>
                                              )
                                            : Summary.default("No success threshold specified")
                                    }
                                    help={"Specify how many consecutive times the probe must succeed for the container to be considered ready after a failure."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerReadinessProbeSuccessThreshold")}
                                        value={_.get(this.state.containerDetails, "ReadinessProbe.successThreshold")}
                                        onChange={(x) => this.setContainerReadinessState({ successThreshold: x })}
                                        label="Success threshold"
                                        placeholder="1 (default)"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Failure threshold"
                                    errorKey={"ContainerDetailsReadinessFailureThreshold"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "ReadinessProbe.failureThreshold")
                                            ? Summary.summary(
                                                  <span>
                                                      Pod is marked unready after <strong>{this.state.containerDetails.ReadinessProbe.failureThreshold}</strong>
                                                      {this.state.containerDetails.ReadinessProbe.failureThreshold === "1" ? <span> failure</span> : <span> consecutive failures</span>}
                                                  </span>
                                              )
                                            : Summary.default("No failure threshold specified")
                                    }
                                    help={"Set how many times the probe can fail after the pod has been started."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerReadinessProbeFailureThreshold")}
                                        value={_.get(this.state.containerDetails, "ReadinessProbe.failureThreshold")}
                                        onChange={(x) => this.setContainerReadinessState({ failureThreshold: x })}
                                        label="Failure threshold"
                                        placeholder={"3 (default)"}
                                    />
                                    <Note>After this many failures, the pod will restart.</Note>
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Timeout"
                                    errorKey={"ContainerDetailsReadinessTimeoutSeconds"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "ReadinessProbe.timeoutSeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will wait <strong>{this.state.containerDetails.ReadinessProbe.timeoutSeconds}</strong> second
                                                      {this.state.containerDetails.ReadinessProbe.timeoutSeconds !== "1" && <span>s</span>} for a response
                                                  </span>
                                              )
                                            : Summary.default("No timeout specified")
                                    }
                                    help={"Specify the number of seconds to wait for a probe response."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerReadinessProbeTimeoutSeconds")}
                                        value={_.get(this.state.containerDetails, "ReadinessProbe.timeoutSeconds")}
                                        onChange={(x) => this.setContainerReadinessState({ timeoutSeconds: x })}
                                        label="Timeout in seconds"
                                        placeholder={"1 (default)"}
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Initial Delay"
                                    errorKey={"ContainerDetailsReadiness"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "ReadinessProbe.initialDelaySeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will wait <strong>{this.state.containerDetails.ReadinessProbe.initialDelaySeconds}</strong> second
                                                      {this.state.containerDetails.ReadinessProbe.initialDelaySeconds !== "1" && <span>s</span>} before being initiated
                                                  </span>
                                              )
                                            : Summary.default("No initial delay specified")
                                    }
                                    help={"Specify the number of seconds to wait before the probe is initiated."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerReadinessProbeInitialDelaySeconds")}
                                        value={_.get(this.state.containerDetails, "ReadinessProbe.initialDelaySeconds")}
                                        onChange={(x) => this.setContainerReadinessState({ initialDelaySeconds: x })}
                                        label="Initial delay in seconds"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Period"
                                    errorKey={"ContainerDetailsReadinessPeriodSeconds"}
                                    summary={
                                        !!_.get(this.state.containerDetails, "ReadinessProbe.periodSeconds")
                                            ? Summary.summary(
                                                  <span>
                                                      Probe will be run every <strong>{this.state.containerDetails.ReadinessProbe.periodSeconds}</strong> second
                                                      {this.state.containerDetails.ReadinessProbe.periodSeconds !== "1" && <span>s</span>}
                                                  </span>
                                              )
                                            : Summary.default("No period specified")
                                    }
                                    help={"Specify how frequently the probe is executed."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        error={this.getFieldError("ContainerReadinessProbePeriodSeconds")}
                                        value={_.get(this.state.containerDetails, "ReadinessProbe.periodSeconds")}
                                        onChange={(x) => this.setContainerReadinessState({ periodSeconds: x })}
                                        label="Period in seconds"
                                        placeholder="10 (default)"
                                    />
                                </ExpandableFormSection>
                                <ExpandableFormSection
                                    isExpandedByDefault={false}
                                    title="Readiness Probe Type"
                                    errorKey={"ContainerDetailsReadinessType"}
                                    summary={this.kubernetesReadinessProbeTypeSummary()}
                                    help={"Select the readiness probe type."}
                                    fillCardWidth={CardFill.FillAll}
                                    forceMobileBehaviour={true}
                                >
                                    <Select
                                        error={this.getFieldError("ContainerReadinessProbeType")}
                                        value={_.get(this.state.containerDetails, "ReadinessProbe.type")}
                                        items={[
                                            { text: "", value: "" },
                                            { text: "Command", value: CommandCheck },
                                            { text: "HttpGet", value: HttpGetCheck },
                                            { text: "TCP Socket", value: TcpSocketCheck },
                                        ]}
                                        label="Readiness probe type"
                                        onChange={(type) => this.setContainerReadinessState({ type: type! })}
                                    />
                                    {_.get(this.state.containerDetails, "ReadinessProbe.type") === CommandCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerReadinessProbeExecCommand")}
                                                value={((_.get(this.state.containerDetails, "ReadinessProbe.exec.command") as string[]) || []).join("\n")}
                                                onChange={(x) => this.setContainerReadinessCommandsState({ command: (x || "").split("\n") })}
                                                label="Health check commands"
                                                multiline={true}
                                                rows={5}
                                                rowsMax={5}
                                            />
                                            <Note>A newline-separated list of commands which are run in the container, and if the return value is zero, the container is considered to be healthy.</Note>
                                        </React.Fragment>
                                    )}
                                    {_.get(this.state.containerDetails, "ReadinessProbe.type") === HttpGetCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerReadinessProbeHttpGetHost")}
                                                value={_.get(this.state.containerDetails, "ReadinessProbe.httpGet.host")}
                                                onChange={(x) => this.setContainerReadinessHttpGetState({ host: x })}
                                                label="Host"
                                            />
                                            <Note>The optional host name to connect to. If not defined, this value will default to the IP address of the pod.</Note>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerReadinessProbeHttpGetPath")}
                                                value={_.get(this.state.containerDetails, "ReadinessProbe.httpGet.path")}
                                                onChange={(x) => this.setContainerReadinessHttpGetState({ path: x })}
                                                label="Path"
                                            />
                                            <Note>The path to access on the HTTP server.</Note>
                                            <BoundSelect
                                                error={this.getFieldError("ContainerReadinessProbeHttpGetScheme")}
                                                value={_.get(this.state.containerDetails, "ReadinessProbe.httpGet.scheme")}
                                                onChange={(x) => this.setContainerReadinessHttpGetState({ scheme: x })}
                                                label="Scheme"
                                                items={[
                                                    { value: "HTTP", text: "HTTP" },
                                                    { value: "HTTPS", text: "HTTPS" },
                                                ]}
                                                resetValue={"HTTP"}
                                            />
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerReadinessProbeHttpGetPort")}
                                                value={_.get(this.state.containerDetails, "ReadinessProbe.httpGet.port")}
                                                onChange={(x) => this.setContainerReadinessHttpGetState({ port: x })}
                                                label="Port"
                                            />
                                            <Note>
                                                The port number to use when connecting to the HTTP server. Alternatively this value can be an <ExternalLink href="IANA">IANA</ExternalLink> port name.
                                            </Note>
                                            <p>
                                                <strong>HTTP Header</strong>
                                            </p>
                                            <p>Add HTTP headers to be sent with the request.</p>
                                            <ExtendedKeyValueEditList
                                                items={() => (_.isArray(_.get(this.state.containerDetails, "ReadinessProbe.httpGet.httpHeaders")) ? _.get(this.state.containerDetails, "ReadinessProbe.httpGet.httpHeaders") : [])}
                                                name="HTTP Header"
                                                onAdd={this.repositionDialog}
                                                onChange={(val) => this.setContainerReadinessHttpGetState({ httpHeaders: val })}
                                                valueLabel="Value"
                                                keyLabel="Name"
                                                hideBindOnKey={false}
                                                projectId={this.props.projectId}
                                                addToTop={true}
                                            />
                                        </React.Fragment>
                                    )}
                                    {_.get(this.state.containerDetails, "ReadinessProbe.type") === TcpSocketCheck && (
                                        <React.Fragment>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerReadinessProbeTcpSocketHost")}
                                                value={_.get(this.state.containerDetails, "ReadinessProbe.tcpSocket.host")}
                                                onChange={(x) => this.setContainerReadinessSocketState({ host: x })}
                                                label="Host"
                                            />
                                            <Note>The optional host name to connect to. If not defined, this value will default to the IP address of the pod.</Note>
                                            <VariableLookupText
                                                localNames={this.props.localNames}
                                                error={this.getFieldError("ContainerReadinessProbeTcpSocketPort")}
                                                value={_.get(this.state.containerDetails, "ReadinessProbe.tcpSocket.port")}
                                                onChange={(x) => this.setContainerReadinessSocketState({ port: x })}
                                                label="Port"
                                            />
                                            <Note>
                                                The port number to use when connecting to the container. Alternatively this value can be an <ExternalLink href="IANA">IANA</ExternalLink> port name.
                                            </Note>
                                        </React.Fragment>
                                    )}
                                </ExpandableFormSection>
                                <FormSectionHeading title="Lifecycle Hooks" />
                                <p>
                                    Lifecycles describe actions that the management system should take in response to container lifecycle events. Learn more about{" "}
                                    <ExternalLink href="https://kubernetes.io/docs/concepts/containers/container-lifecycle-hooks">container lifecycle events</ExternalLink>.
                                </p>
                                <LifecycleHandlerFormSection
                                    hookType={"PostStart"}
                                    handler={this.state.containerDetails.Lifecycle ? this.state.containerDetails.Lifecycle.PostStart : null!}
                                    onChange={(o) => this.setState((c) => ({ containerDetails: { ...c!.containerDetails, Lifecycle: { ...(c!.containerDetails.Lifecycle || {}), PostStart: { ...o } } } }))}
                                />

                                <LifecycleHandlerFormSection
                                    hookType={"PreStop"}
                                    handler={this.state.containerDetails.Lifecycle ? this.state.containerDetails.Lifecycle.PreStop : null!}
                                    onChange={(o) => this.setState((c) => ({ containerDetails: { ...c!.containerDetails, Lifecycle: { ...(c!.containerDetails.Lifecycle || {}), PreStop: { ...o } } } }))}
                                />
                            </React.Fragment>
                        )}

                        <FormSectionHeading title="Command" />
                        <p>
                            The command used to launch the container, or the arguments passed to the command, can be defined or overwritten here. Learn more about{" "}
                            <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#command">commands</ExternalLink>.
                        </p>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="Command"
                            errorKey={"ContainerDetailsCommand"}
                            summary={this.kubernetesCommandSummary()}
                            help={"Enter the command to run when launching the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={(_.isArray(this.state.containerDetails.Command) ? this.state.containerDetails.Command : []).join("\n")}
                                onChange={(x) => this.setContainerState({ Command: (x || "").split("\n") })}
                                rows={5}
                                rowsMax={5}
                                multiline={true}
                                label="Command"
                            />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={false}
                            title="Command arguments"
                            errorKey={"ContainerDetailsCommandArgs"}
                            summary={this.kubernetesCommandArgumentsSummary()}
                            help={"Enter command arguments."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={(_.isArray(this.state.containerDetails.Args) ? this.state.containerDetails.Args : []).join("\n")}
                                onChange={(x) => this.setContainerState({ Args: (x || "").split("\n") })}
                                label="Command arguments"
                                multiline={true}
                                rows={5}
                                rowsMax={5}
                            />
                        </ExpandableFormSection>
                        <FormSectionHeading title="Security Context" />
                        <p>
                            The security context defines privilege and access control settings for a container. Learn more about{" "}
                            <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#security-contexts">security contexts</ExternalLink>.
                        </p>
                        {(_.get(this.state.containerDetails, "SecurityContext.privileged") || "False").toString().toUpperCase().trim() !== "TRUE" && (
                            <ExpandableFormSection
                                isExpandedByDefault={this.state.containerDetails.IsNew}
                                title="Privilege escalation"
                                errorKey={"ContainerDetailsSecurityContextAllowPrivilegeEscalation"}
                                error={this.getFieldError("ContainerDetailsSecurityContextAllowPrivilegeEscalation")}
                                summary={this.kubernetesSecurityContextAllowPrivilegeEscalationSummary()}
                                help={"Allow a container to gain more privileges than its parent."}
                                fillCardWidth={CardFill.FillAll}
                                forceMobileBehaviour={true}
                            >
                                <BoundStringRadioButtonGroup
                                    variableLookup={{
                                        localNames: this.props.localNames,
                                    }}
                                    resetValue={"False"}
                                    value={_.get(this.state.containerDetails, "SecurityContext.allowPrivilegeEscalation") || "False"}
                                    onChange={(x) => this.setContainerSecurityContextState({ allowPrivilegeEscalation: x })}
                                    label="Privilege escalation"
                                >
                                    <RadioButton value={"True"} label="Allow privilege escalation" />
                                    <RadioButton value={"False"} label="Do not allow privilege escalation" />
                                </BoundStringRadioButtonGroup>
                            </ExpandableFormSection>
                        )}
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Privileged mode"
                            errorKey={"ContainerDetailsSecurityContextPrivileged"}
                            error={this.getFieldError("ContainerDetailsSecurityContextPrivileged")}
                            summary={this.kubernetesSecurityContextPrivilegedSummary()}
                            help={"Run container in privileged mode."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <BoundStringRadioButtonGroup
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                resetValue={"False"}
                                value={_.get(this.state.containerDetails, "SecurityContext.privileged") || "False"}
                                onChange={(x) => {
                                    this.setContainerSecurityContextState({ privileged: x });
                                    /*
                             privileged implies allowPrivilegeEscalation, and if privileged
                             is true you can not set allowPrivilegeEscalation to anything other than true.
                             So if we are setting privileged to true, clear out the allowPrivilegeEscalation
                             setting.
                            */
                                    if (x.toUpperCase().trim() === "TRUE") {
                                        this.setContainerSecurityContextState({ allowPrivilegeEscalation: "" });
                                    }
                                }}
                                label="Privileged mode"
                            >
                                <RadioButton value={"True"} label="Enable privileged mode" />
                                <RadioButton value={"False"} label="Do not enable privileged mode" />
                            </BoundStringRadioButtonGroup>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Read only root file system"
                            errorKey={"ContainerDetailsSecurityContextReadOnlyRootFilesystem"}
                            error={this.getFieldError("ContainerDetailsSecurityContextReadOnlyRootFilesystem")}
                            summary={this.kubernetesSecurityContextReadOnlyRootFilesystemSummary()}
                            help={"Require that containers run with a read-only root filesystem."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <BoundStringRadioButtonGroup
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                resetValue={"False"}
                                value={_.get(this.state.containerDetails, "SecurityContext.readOnlyRootFilesystem") || "False"}
                                onChange={(x) => this.setContainerSecurityContextState({ readOnlyRootFilesystem: x })}
                                label="Read only root file system"
                            >
                                <RadioButton value={"True"} label="Enable read only root file system" />
                                <RadioButton value={"False"} label="Do not enable read only root file system" />
                            </BoundStringRadioButtonGroup>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Run as non-root"
                            errorKey={"ContainerDetailsSecurityContextRunAsNonRoot"}
                            error={this.getFieldError("ContainerDetailsSecurityContextRunAsNonRoot")}
                            summary={this.kubernetesSecurityContextRunAsNonRootSummary()}
                            help={"Require that containers run as a non-root user."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <BoundStringRadioButtonGroup
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                resetValue={"False"}
                                value={_.get(this.state.containerDetails, "SecurityContext.runAsNonRoot") || "False"}
                                onChange={(x) => this.setContainerSecurityContextState({ runAsNonRoot: x })}
                                label="Run as non-root"
                            >
                                <RadioButton value={"True"} label="Enable run as non-root" />
                                <RadioButton value={"False"} label="Do not enable run as non-root" />
                            </BoundStringRadioButtonGroup>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Run as user"
                            errorKey={"ContainerDetailsSecurityContextRunAsUser"}
                            error={this.getFieldError("ContainerDetailsSecurityContextRunAsUser")}
                            summary={this.kubernetesSecurityContextRunAsUserSummary()}
                            help={"The UID to run the entrypoint of the container process."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={_.get(this.state.containerDetails, "SecurityContext.runAsUser") || ""}
                                onChange={(x) => this.setContainerSecurityContextState({ runAsUser: x })}
                                error={this.getFieldError("ContainerDetailsSecurityContextRunAsUser")}
                                label="Run as user ID"
                            />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="Run as group"
                            errorKey={"ContainerDetailsSecurityContextRunAsGroup"}
                            error={this.getFieldError("ContainerDetailsSecurityContextRunAsGroup")}
                            summary={this.kubernetesSecurityContextRunAsGroupSummary()}
                            help={"The GID to run the entrypoint of the container process. Introduced in Kubernetes 1.10."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={_.get(this.state.containerDetails, "SecurityContext.runAsGroup") || ""}
                                onChange={(x) => this.setContainerSecurityContextState({ runAsGroup: x })}
                                error={this.getFieldError("ContainerDetailsSecurityContextRunAsGroup")}
                                label="Run as group ID"
                            />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="POSIX capabilities"
                            errorKey={"ContainerDetailsSecurityContextCapabilities"}
                            error={this.getFieldError("ContainerDetailsSecurityContextCapabilities")}
                            summary={this.kubernetesSecurityContextCapabilitiesSummary()}
                            help={"POSIX capabilities to add or drop from the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <ExtendedKeyValueEditList
                                items={() => {
                                    return (((_.get(this.state.containerDetails, "SecurityContext.capabilities.add") as string[]) || ([] as string[])).map((y) => {
                                        return {
                                            key: y!,
                                            value: null!,
                                            option: null!,
                                            option2: null!,
                                        };
                                    }) as unknown) as KeyValueOption[];
                                }}
                                name="Added POSIX Capability"
                                verb="Define"
                                onChange={(x) => {
                                    this.setContainerSecurityContextCapabilitiesState({
                                        add: x.map((y) => y.key.trim()).filter((y) => y),
                                    });
                                }}
                                keyLabel="Capability"
                                getOptions={this.getCapabilities}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                            <ExtendedKeyValueEditList
                                items={() => {
                                    return (((_.get(this.state.containerDetails, "SecurityContext.capabilities.drop") as string[]) || ([] as string[])).map((y) => {
                                        return {
                                            key: y!,
                                            value: null!,
                                            option: null!,
                                            option2: null!,
                                        };
                                    }) as unknown) as KeyValueOption[];
                                }}
                                name="Dropped POSIX Capability"
                                verb="Define"
                                onChange={(x) => {
                                    this.setContainerSecurityContextCapabilitiesState({
                                        drop: x.map((y) => y.key.trim()).filter((y) => y),
                                    });
                                }}
                                keyLabel="Capability"
                                getOptions={this.getCapabilities}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.containerDetails.IsNew}
                            title="SELinux options"
                            errorKey={"ContainerDetailsSecurityContextSELinuxOptions"}
                            error={this.getFieldError("ContainerDetailsSecurityContextSELinuxOptions")}
                            summary={this.kubernetesSecurityContextSELinuxOptionsSummary()}
                            help={"The SELinux context to be applied to the container."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={_.get(this.state.containerDetails, "SecurityContext.seLinuxOptions.level")}
                                onChange={(level) => this.setContainerSecurityContextSELinuxOptionsState({ level })}
                                label="SELinux level"
                            />
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={_.get(this.state.containerDetails, "SecurityContext.seLinuxOptions.role")}
                                onChange={(role) => this.setContainerSecurityContextSELinuxOptionsState({ role })}
                                label="SELinux role"
                            />
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={_.get(this.state.containerDetails, "SecurityContext.seLinuxOptions.type")}
                                onChange={(type) => this.setContainerSecurityContextSELinuxOptionsState({ type })}
                                label="SELinux type"
                            />
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={_.get(this.state.containerDetails, "SecurityContext.seLinuxOptions.user")}
                                onChange={(user) => this.setContainerSecurityContextSELinuxOptionsState({ user })}
                                label="SELinux user"
                            />
                        </ExpandableFormSection>
                    </React.Fragment>
                )}
            </OkDialogLayout>
        );
    }

    private onPackageSelectionModeChange(packageSelectionMode: PackageSelectionMode) {
        this.setContainerPropertiesState({
            SelectionMode: packageSelectionMode,
            PackageParameterName: "",
        });
    }

    private onPackageParameterChange(packageParameter: string) {
        this.setContainerState({ PackageId: null! });
        this.setContainerPropertiesState({ PackageParameterName: packageParameter });
    }

    private setContainerState<K extends keyof ContainerPackageDetails>(state: Pick<ContainerPackageDetails, K>, callback?: () => void) {
        this.repositionDialog();
        this.setChildState1("containerDetails", state, callback);
    }

    private setContainerPropertiesState<K extends keyof ScriptPackageProperties>(state: Pick<ScriptPackageProperties, K>, callback?: () => void) {
        this.repositionDialog();
        this.setChildState2("containerDetails", "Properties", state, callback);
    }

    private setContainerResourcesLimitState<K extends keyof ResourceRequirementsDetails>(state: Pick<ResourceRequirementsDetails, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureResources();
        this.setChildState3("containerDetails", "Resources", "limits", state, callback);
    }

    private setContainerResourcesRequestState<K extends keyof ResourceRequirementsDetails>(state: Pick<ResourceRequirementsDetails, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureResources();
        this.setChildState3("containerDetails", "Resources", "requests", state, callback);
    }

    private setContainerLivenessState<K extends keyof Probe>(state: Pick<Probe, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState2("containerDetails", "LivenessProbe", state, callback);
    }

    private setContainerLivenessCommandsState<K extends keyof ExecAction>(state: Pick<ExecAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "LivenessProbe", "exec", state, callback);
    }

    private setContainerLivenessHttpGetState<K extends keyof HTTPGetAction>(state: Pick<HTTPGetAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "LivenessProbe", "httpGet", state, callback);
    }

    private setContainerLivenessSocketState<K extends keyof HTTPGetAction>(state: Pick<HTTPGetAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "LivenessProbe", "tcpSocket", state, callback);
    }

    private setContainerReadinessState<K extends keyof Probe>(state: Pick<Probe, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState2("containerDetails", "ReadinessProbe", state, callback);
    }

    private setContainerReadinessCommandsState<K extends keyof ExecAction>(state: Pick<ExecAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "ReadinessProbe", "exec", state, callback);
    }

    private setContainerReadinessHttpGetState<K extends keyof HTTPGetAction>(state: Pick<HTTPGetAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "ReadinessProbe", "httpGet", state, callback);
    }

    private setContainerReadinessSocketState<K extends keyof HTTPGetAction>(state: Pick<HTTPGetAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "ReadinessProbe", "tcpSocket", state, callback);
    }

    private setContainerStartupState<K extends keyof Probe>(state: Pick<Probe, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState2("containerDetails", "StartupProbe", state, callback);
    }

    private setContainerStartupCommandsState<K extends keyof ExecAction>(state: Pick<ExecAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "StartupProbe", "exec", state, callback);
    }

    private setContainerStartupHttpGetState<K extends keyof HTTPGetAction>(state: Pick<HTTPGetAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "StartupProbe", "httpGet", state, callback);
    }

    private setContainerStartupSocketState<K extends keyof HTTPGetAction>(state: Pick<HTTPGetAction, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureProbes();
        this.setChildState3("containerDetails", "StartupProbe", "tcpSocket", state, callback);
    }

    private setContainerSecurityContextState<K extends keyof SecurityContext>(state: Pick<SecurityContext, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureSecurityContext();
        this.setChildState2("containerDetails", "SecurityContext", state, callback);
    }

    private setContainerSecurityContextSELinuxOptionsState<K extends keyof SELinuxOptions>(state: Pick<SELinuxOptions, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureSecurityContext();
        this.setChildState3("containerDetails", "SecurityContext", "seLinuxOptions", state, callback);
    }

    private setContainerSecurityContextCapabilitiesState<K extends keyof Capabilities>(state: Pick<Capabilities, K>, callback?: () => void) {
        this.repositionDialog();
        this.configureSecurityContext();
        this.setChildState3("containerDetails", "SecurityContext", "capabilities", state, callback);
    }

    /**
     * A safety check in case the container details did not initialise the probes properties
     */
    private configureProbes() {
        if (!this.state.containerDetails.LivenessProbe) {
            this.setChildState1("containerDetails", {
                LivenessProbe: {
                    failureThreshold: "",
                    initialDelaySeconds: "",
                    periodSeconds: "",
                    successThreshold: "",
                    timeoutSeconds: "",
                    type: "",
                    exec: {
                        command: [],
                    },
                    httpGet: {
                        host: "",
                        path: "",
                        port: "",
                        scheme: "",
                        httpHeaders: [],
                    },
                    tcpSocket: {
                        host: "",
                        port: "",
                    },
                },
            });
        }

        if (!this.state.containerDetails.ReadinessProbe) {
            this.setChildState1("containerDetails", {
                ReadinessProbe: {
                    failureThreshold: "",
                    initialDelaySeconds: "",
                    periodSeconds: "",
                    successThreshold: "",
                    timeoutSeconds: "",
                    type: "",
                    exec: {
                        command: [],
                    },
                    httpGet: {
                        host: "",
                        path: "",
                        port: "",
                        scheme: "",
                        httpHeaders: [],
                    },
                    tcpSocket: {
                        host: "",
                        port: "",
                    },
                },
            });
        }

        if (!this.state.containerDetails.StartupProbe) {
            this.setChildState1("containerDetails", {
                StartupProbe: {
                    failureThreshold: "",
                    initialDelaySeconds: "",
                    periodSeconds: "",
                    successThreshold: "",
                    timeoutSeconds: "",
                    type: "",
                    exec: {
                        command: [],
                    },
                    httpGet: {
                        host: "",
                        path: "",
                        port: "",
                        scheme: "",
                        httpHeaders: [],
                    },
                    tcpSocket: {
                        host: "",
                        port: "",
                    },
                },
            });
        }
    }

    /**
     * A safety check in case the container details did not initialise the Resources property
     */
    private configureResources() {
        if (!this.state.containerDetails.Resources) {
            this.setChildState1("containerDetails", {
                Resources: {
                    requests: {
                        memory: "",
                        cpu: "",
                        ephemeralStorage: "",
                        nvidiaGpu: "",
                        amdGpu: "",
                        storage: "",
                    },
                    limits: {
                        memory: "",
                        cpu: "",
                        ephemeralStorage: "",
                        nvidiaGpu: "",
                        amdGpu: "",
                        storage: "",
                    },
                },
            });
        }
    }

    /**
     * A safety check in case the container details did not initialise the SecurityContext property
     */
    private configureSecurityContext() {
        if (!this.state.containerDetails.SecurityContext) {
            this.setChildState1("containerDetails", {
                SecurityContext: {
                    allowPrivilegeEscalation: "",
                    privileged: "",
                    readOnlyRootFilesystem: "",
                    runAsGroup: "",
                    runAsNonRoot: "",
                    runAsUser: "",
                    capabilities: {
                        add: [],
                        drop: [],
                    },
                    seLinuxOptions: {
                        level: "",
                        role: "",
                        user: "",
                        type: "",
                    },
                },
            });
        }

        if (!this.state.containerDetails.SecurityContext.capabilities) {
            this.setChildState2("containerDetails", "SecurityContext", {
                capabilities: {
                    add: [],
                    drop: [],
                },
            });
        }

        if (!this.state.containerDetails.SecurityContext.seLinuxOptions) {
            this.setChildState2("containerDetails", "SecurityContext", {
                seLinuxOptions: {
                    level: "",
                    role: "",
                    user: "",
                    type: "",
                },
            });
        }
    }

    private getVolumeOptions = async (searchText: string) => {
        const results = _.chain(this.props.volumes)
            .filter((v) => !!v && !!v.Name)
            .filter((v) => !searchText || v.Name.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        const itemsToTake = 7;
        return {
            items: results.slice(0, itemsToTake).map((f) => ({ Id: f.Name, Name: f.Name })),
            containsAllResults: results.length <= itemsToTake,
        };
    };

    /**
     * https://github.com/mui-org/material-ui/issues/1676
     * https://github.com/mui-org/material-ui/issues/5793
     * When adding or removing items from a list, the dialog needs to be repositioned, otherwise
     * the list may disappear off the screen. A resize event is the commonly suggested workaround.
     */
    private repositionDialog() {
        window.dispatchEvent(new Event("resize"));
    }

    private initContainerSummary() {
        if (this.state.containerDetails.InitContainer === "True") {
            return Summary.summary("Configure an init container");
        }
        return Summary.summary("Configure a regular container");
    }

    private imagePullPolicySummary() {
        if (this.state.containerDetails.ImagePullPolicy) {
            return Summary.summary(
                <span>
                    Using image pull policy <strong>{this.state.containerDetails.ImagePullPolicy}</strong>
                </span>
            );
        }
        return Summary.default("Image pull policy is undefined");
    }

    private kubernetesPortSummary() {
        if (this.state.containerDetails.Ports && _.isArray(this.state.containerDetails.Ports) && this.state.containerDetails.Ports.length !== 0) {
            return Summary.summary(
                <span>
                    {_.chain(this.state.containerDetails.Ports)
                        .flatMap((rule: any) => [
                            <span>
                                Exposing port <strong>{rule.value}</strong>/<strong>{rule.option || "TCP"}</strong>
                                {rule.key && (
                                    <span>
                                        {" "}
                                        called <strong>{rule.key}</strong>
                                    </span>
                                )}
                            </span>,
                            <br />,
                        ])
                        .slice(0, -1)
                        .value()}
                </span>
            );
        }
        return Summary.default("No exposed container ports included");
    }

    private kubernetesTerminationSummary() {
        if (_.get(this.state.containerDetails, "TerminationMessagePath")) {
            return Summary.summary(<span>Termination message path set to {_.get(this.state.containerDetails, "TerminationMessagePath")}</span>);
        }
        return Summary.default("No termination message path defined");
    }

    private kubernetesCpuSummary() {
        const summaryElements = [];
        if (_.get(this, "state.containerDetails.Resources.requests.cpu") && this.state.containerDetails.Resources.requests.cpu.trim()) {
            summaryElements.push(
                <span>
                    Requesting <strong>{this.state.containerDetails.Resources.requests.cpu}</strong> of CPU resources.{" "}
                </span>
            );
        }
        if (_.get(this, "state.containerDetails.Resources.limits.cpu") && this.state.containerDetails.Resources.limits.cpu.trim()) {
            if (!_.isEmpty(summaryElements)) {
                summaryElements.push(<span> </span>);
            }
            summaryElements.push(
                <span>
                    Limiting CPU resources to <strong>{this.state.containerDetails.Resources.limits.cpu}</strong>.
                </span>
            );
        }
        return !_.isEmpty(summaryElements) ? Summary.summary(summaryElements) : Summary.default("No CPU resources or limits specified");
    }

    private kubernetesGpuSummary() {
        const summaryElements = [];
        if (_.get(this, "state.containerDetails.Resources.limits.amdGpu") && this.state.containerDetails.Resources.limits.amdGpu.trim()) {
            summaryElements.push(
                <span>
                    Limiting AMD GPU resources to <strong>{this.state.containerDetails.Resources.limits.amdGpu}</strong>.
                </span>
            );
        }
        if (_.get(this, "state.containerDetails.Resources.limits.nvidiaGpu") && this.state.containerDetails.Resources.limits.nvidiaGpu.trim()) {
            if (!_.isEmpty(summaryElements)) {
                summaryElements.push(<span> </span>);
            }
            summaryElements.push(
                <span>
                    Limiting Nvidia GPU resources to <strong>{this.state.containerDetails.Resources.limits.nvidiaGpu}</strong>.
                </span>
            );
        }
        return !_.isEmpty(summaryElements) ? Summary.summary(summaryElements) : Summary.default("No GPU limits specified");
    }

    private kubernetesMemorySummary() {
        const summaryElements = [];
        if (_.get(this, "state.containerDetails.Resources.requests.memory") && this.state.containerDetails.Resources.requests.memory.trim()) {
            summaryElements.push(
                <span>
                    Requesting <strong>{this.state.containerDetails.Resources.requests.memory}</strong> of memory.{" "}
                </span>
            );
        }
        if (_.get(this, "state.containerDetails.Resources.limits.memory") && this.state.containerDetails.Resources.limits.memory.trim()) {
            if (!_.isEmpty(summaryElements)) {
                summaryElements.push(<span> </span>);
            }
            summaryElements.push(
                <span>
                    Limiting memory to <strong>{this.state.containerDetails.Resources.limits.memory}</strong>.
                </span>
            );
        }
        return !_.isEmpty(summaryElements) ? Summary.summary(summaryElements) : Summary.default("No memory requests or limits specified");
    }

    private kubernetesStorageSummary() {
        const summaryElements = [];
        if (_.get(this, "state.containerDetails.Resources.requests.ephemeralStorage") && this.state.containerDetails.Resources.requests.ephemeralStorage.trim()) {
            summaryElements.push(
                <span>
                    Requesting <strong>{this.state.containerDetails.Resources.requests.ephemeralStorage}</strong> of ephemeral storage.{" "}
                </span>
            );
        }
        if (_.get(this, "state.containerDetails.Resources.limits.ephemeralStorage") && this.state.containerDetails.Resources.limits.ephemeralStorage.trim()) {
            if (!_.isEmpty(summaryElements)) {
                summaryElements.push(<span> </span>);
            }
            summaryElements.push(
                <span>
                    Limiting ephemeral storage to <strong>{this.state.containerDetails.Resources.limits.ephemeralStorage}</strong>.
                </span>
            );
        }
        return !_.isEmpty(summaryElements) ? Summary.summary(summaryElements) : Summary.default("No ephemeral storage resources requests or limits specified");
    }

    private kubernetesConfigMapEnvironmentVarsSummary() {
        const summaries = _.chain(_.get(this, "state.containerDetails.ConfigMapEnvironmentVariables") || [])
            .flatMap((envVar: KeyValueOption) => [
                <span>
                    Setting environment variable <strong>{envVar.key}</strong> to value <strong>{envVar.option}</strong> from config map <strong>{envVar.value}</strong>
                </span>,
                <br />,
            ])
            .concat(
                (_.get(this, "state.containerDetails.ConfigMapEnvFromSource") || []).flatMap((envVar: KeyValueOption) => [
                    <span>
                        Setting environment variables from {_.lowerCase(envVar.option) === "true" && <span>an optional </span>}config map <strong>{envVar.key}</strong>
                        {envVar.value && (
                            <span>
                                {" "}
                                with a prefix of <strong>{envVar.value}</strong>
                            </span>
                        )}
                    </span>,
                    <br />,
                ])
            )
            .slice(0, -1)
            .value();

        return summaries.length == 0 ? Summary.default("No environment variables from config map entries included") : Summary.summary(<span>{summaries}</span>);
    }

    private kubernetesSecretEnvironmentVarsSummary() {
        const summaries = _.chain(_.get(this, "state.containerDetails.SecretEnvironmentVariables") || [])
            .flatMap((envVar: KeyValueOption) => [
                <span>
                    Setting environment variable <strong>{envVar.key}</strong> to value <strong>{envVar.option}</strong> from secret <strong>{envVar.value}</strong>
                </span>,
                <br />,
            ])
            .concat(
                (_.get(this, "state.containerDetails.SecretEnvFromSource") || []).flatMap((envVar: KeyValueOption) => [
                    <span>
                        Setting environment variables from {_.lowerCase(envVar.option) === "true" && <span>an optional </span>}secret <strong>{envVar.key}</strong>
                        {envVar.value && (
                            <span>
                                {" "}
                                with a prefix of <strong>{envVar.value}</strong>
                            </span>
                        )}
                    </span>,
                    <br />,
                ])
            )
            .slice(0, -1)
            .value();

        return summaries.length == 0 ? Summary.default("No environment variables from secret entries included") : Summary.summary(<span>{summaries}</span>);
    }

    private kubernetesDownstreamEnvironmentVarsSummary() {
        if (_.get(this, "state.containerDetails.FieldRefEnvironmentVariables") && this.state.containerDetails.FieldRefEnvironmentVariables.length !== 0) {
            return Summary.summary(
                <span>
                    {_.chain(this.state.containerDetails.FieldRefEnvironmentVariables)
                        .flatMap((envVar) => [
                            <span>
                                Setting environment variable <strong>{envVar.key}</strong> to value of <strong>{envVar.value}</strong>
                            </span>,
                            <br />,
                        ])
                        .slice(0, -1)
                        .value()}
                </span>
            );
        }
        return Summary.default("No pod or container field environment variables included");
    }

    private kubernetesEnvironmentVarsSummary() {
        if (_.get(this, "state.containerDetails.EnvironmentVariables") && this.state.containerDetails.EnvironmentVariables.length !== 0) {
            return Summary.summary(
                <span>
                    {_.chain(this.state.containerDetails.EnvironmentVariables)
                        .flatMap((envVar) => [
                            <span>
                                Setting environment variable <strong>{envVar.key}</strong> to <strong>{envVar.value}</strong>
                            </span>,
                            <br />,
                        ])
                        .slice(0, -1)
                        .value()}
                </span>
            );
        }
        return Summary.default("No environment variables included");
    }

    private kubernetesVolumeSummary() {
        if (_.get(this, "state.containerDetails.VolumeMounts") && this.state.containerDetails.VolumeMounts.length !== 0) {
            return Summary.summary(
                <span>
                    {_.chain(this.state.containerDetails.VolumeMounts)
                        .flatMap((volMount) => [
                            <span>
                                Mounting{" "}
                                <strong>
                                    {volMount.key}
                                    {volMount.option && <span>/{volMount.option}</span>}
                                </strong>
                                <span>
                                    {" "}
                                    under path <strong>{volMount.value}</strong>
                                </span>
                                <span>
                                    {" "}
                                    with Read-Only = <strong>{volMount.option2}</strong>
                                </span>
                            </span>,
                            <br />,
                        ])
                        .slice(0, -1)
                        .value()}
                </span>
            );
        }
        return Summary.default("No volume mounts included");
    }

    private kubernetesLivenessProbeTypeSummary() {
        if (_.get(this, "state.containerDetails.LivenessProbe.type")) {
            return Summary.summary(
                <span>
                    {this.state.containerDetails.LivenessProbe.type === CommandCheck && this.state.containerDetails.LivenessProbe.exec.command && (
                        <span>
                            Command: <strong>{this.state.containerDetails.LivenessProbe.exec.command.join(" ")}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.LivenessProbe.type === HttpGetCheck && this.state.containerDetails.LivenessProbe.httpGet.host && (
                        <span>
                            HTTP host: <strong>{this.state.containerDetails.LivenessProbe.httpGet.host}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.LivenessProbe.type === HttpGetCheck && this.state.containerDetails.LivenessProbe.httpGet.path && (
                        <span>
                            HTTP path: <strong>{this.state.containerDetails.LivenessProbe.httpGet.path}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.LivenessProbe.type === HttpGetCheck && this.state.containerDetails.LivenessProbe.httpGet.port && (
                        <span>
                            HTTP port: <strong>{this.state.containerDetails.LivenessProbe.httpGet.port}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.LivenessProbe.type === HttpGetCheck && this.state.containerDetails.LivenessProbe.httpGet.scheme && (
                        <span>
                            HTTP scheme: <strong>{this.state.containerDetails.LivenessProbe.httpGet.scheme}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.LivenessProbe.type === HttpGetCheck && this.state.containerDetails.LivenessProbe.httpGet.httpHeaders && this.state.containerDetails.LivenessProbe.httpGet.httpHeaders.length !== 0 && (
                        <span>
                            HTTP headers:
                            {_.chain(this.state.containerDetails.LivenessProbe.httpGet.httpHeaders)
                                .flatMap((header) => [
                                    <span>
                                        <strong>{header.key}</strong> = <strong>{header.value}</strong>
                                    </span>,
                                    <span>, </span>,
                                ])
                                .slice(0, -1)
                                .value()}
                        </span>
                    )}
                    {this.state.containerDetails.LivenessProbe.type === TcpSocketCheck && this.state.containerDetails.LivenessProbe.tcpSocket.host && (
                        <span>
                            TCP host: <strong>{this.state.containerDetails.LivenessProbe.tcpSocket.host}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.LivenessProbe.type === TcpSocketCheck && this.state.containerDetails.LivenessProbe.tcpSocket.port && (
                        <span>
                            TCP port: <strong>{this.state.containerDetails.LivenessProbe.tcpSocket.port}</strong>{" "}
                        </span>
                    )}
                </span>
            );
        }
        return Summary.default("No liveness probe type selected");
    }

    private kubernetesStartupProbeTypeSummary() {
        if (_.get(this, "state.containerDetails.StartupProbe.type")) {
            return Summary.summary(
                <span>
                    {this.state.containerDetails.StartupProbe.type === CommandCheck && this.state.containerDetails.StartupProbe.exec.command && (
                        <span>
                            Command: <strong>{this.state.containerDetails.StartupProbe.exec.command.join(" ")}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.StartupProbe.type === HttpGetCheck && this.state.containerDetails.StartupProbe.httpGet.host && (
                        <span>
                            HTTP host: <strong>{this.state.containerDetails.StartupProbe.httpGet.host}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.StartupProbe.type === HttpGetCheck && this.state.containerDetails.StartupProbe.httpGet.path && (
                        <span>
                            HTTP path: <strong>{this.state.containerDetails.StartupProbe.httpGet.path}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.StartupProbe.type === HttpGetCheck && this.state.containerDetails.StartupProbe.httpGet.port && (
                        <span>
                            HTTP port: <strong>{this.state.containerDetails.StartupProbe.httpGet.port}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.StartupProbe.type === HttpGetCheck && this.state.containerDetails.StartupProbe.httpGet.scheme && (
                        <span>
                            HTTP scheme: <strong>{this.state.containerDetails.StartupProbe.httpGet.scheme}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.StartupProbe.type === HttpGetCheck && this.state.containerDetails.StartupProbe.httpGet.httpHeaders && this.state.containerDetails.StartupProbe.httpGet.httpHeaders.length !== 0 && (
                        <span>
                            HTTP headers:
                            {_.chain(this.state.containerDetails.StartupProbe.httpGet.httpHeaders)
                                .flatMap((header) => [
                                    <span>
                                        <strong>{header.key}</strong> = <strong>{header.value}</strong>
                                    </span>,
                                    <span>, </span>,
                                ])
                                .slice(0, -1)
                                .value()}
                        </span>
                    )}
                    {this.state.containerDetails.StartupProbe.type === TcpSocketCheck && this.state.containerDetails.StartupProbe.tcpSocket.host && (
                        <span>
                            TCP host: <strong>{this.state.containerDetails.StartupProbe.tcpSocket.host}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.StartupProbe.type === TcpSocketCheck && this.state.containerDetails.StartupProbe.tcpSocket.port && (
                        <span>
                            TCP port: <strong>{this.state.containerDetails.StartupProbe.tcpSocket.port}</strong>{" "}
                        </span>
                    )}
                </span>
            );
        }
        return Summary.default("No startup probe type selected");
    }

    private kubernetesReadinessProbeTypeSummary() {
        if (_.get(this, "state.containerDetails.ReadinessProbe.type")) {
            return Summary.summary(
                <span>
                    {this.state.containerDetails.ReadinessProbe.type === CommandCheck && this.state.containerDetails.ReadinessProbe.exec.command && (
                        <span>
                            Command: <strong>{this.state.containerDetails.ReadinessProbe.exec.command.join(" ")}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.ReadinessProbe.type === HttpGetCheck && this.state.containerDetails.ReadinessProbe.httpGet.host && (
                        <span>
                            HTTP host: <strong>{this.state.containerDetails.ReadinessProbe.httpGet.host}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.ReadinessProbe.type === HttpGetCheck && this.state.containerDetails.ReadinessProbe.httpGet.path && (
                        <span>
                            HTTP path: <strong>{this.state.containerDetails.ReadinessProbe.httpGet.path}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.ReadinessProbe.type === HttpGetCheck && this.state.containerDetails.ReadinessProbe.httpGet.port && (
                        <span>
                            HTTP port: <strong>{this.state.containerDetails.ReadinessProbe.httpGet.port}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.ReadinessProbe.type === HttpGetCheck && this.state.containerDetails.ReadinessProbe.httpGet.scheme && (
                        <span>
                            HTTP scheme: <strong>{this.state.containerDetails.ReadinessProbe.httpGet.scheme}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.ReadinessProbe.type === HttpGetCheck && this.state.containerDetails.ReadinessProbe.httpGet.httpHeaders && this.state.containerDetails.ReadinessProbe.httpGet.httpHeaders.length !== 0 && (
                        <span>
                            HTTP headers:
                            {_.chain(this.state.containerDetails.ReadinessProbe.httpGet.httpHeaders)
                                .flatMap((header) => [
                                    <span>
                                        <strong>{header.key}</strong> = <strong>{header.value}</strong>
                                    </span>,
                                    <span>, </span>,
                                ])
                                .slice(0, -1)
                                .value()}
                        </span>
                    )}
                    {this.state.containerDetails.ReadinessProbe.type === TcpSocketCheck && this.state.containerDetails.ReadinessProbe.tcpSocket.host && (
                        <span>
                            TCP host: <strong>{this.state.containerDetails.ReadinessProbe.tcpSocket.host}</strong>{" "}
                        </span>
                    )}
                    {this.state.containerDetails.ReadinessProbe.type === TcpSocketCheck && this.state.containerDetails.ReadinessProbe.tcpSocket.port && (
                        <span>
                            TCP port: <strong>{this.state.containerDetails.ReadinessProbe.tcpSocket.port}</strong>{" "}
                        </span>
                    )}
                </span>
            );
        }
        return Summary.default("No readiness probe type selected");
    }

    private kubernetesCommandSummary() {
        if (_.get(this, "state.containerDetails.Command") && this.state.containerDetails.Command.filter((c) => c && c.trim()).length !== 0) {
            return Summary.summary(
                <span>
                    Overriding container command with <strong>{this.state.containerDetails.Command.filter((c) => c && c.trim()).join(" ")}</strong>
                </span>
            );
        }
        return Summary.default("No command provided");
    }

    private kubernetesCommandArgumentsSummary() {
        if (_.get(this, "state.containerDetails.Args") && this.state.containerDetails.Args.filter((c) => c && c.trim()).length !== 0) {
            return Summary.summary(
                <span>
                    Pass the arguments <strong>{this.state.containerDetails.Args.filter((c) => c && c.trim()).join(" ")}</strong>
                </span>
            );
        }
        return Summary.default("No command arguments provided");
    }

    private kubernetesSecurityContextAllowPrivilegeEscalationSummary() {
        const setting = _.get(this.state.containerDetails, "SecurityContext.allowPrivilegeEscalation");

        if (!setting || setting === "False") {
            return Summary.default(<span>Privilege escalation not enabled.</span>);
        }

        if (setting === "True") {
            return Summary.summary(<span>Privilege escalation enabled.</span>);
        }

        return Summary.summary(
            <span>
                Privilege escalation set to <strong>{setting}</strong>.
            </span>
        );
    }

    private kubernetesSecurityContextPrivilegedSummary() {
        const setting = _.get(this.state.containerDetails, "SecurityContext.privileged");

        if (!setting || setting === "False") {
            return Summary.default(<span>Privileged mode not enabled.</span>);
        }

        if (setting === "True") {
            return Summary.summary(<span>Privileged mode enabled.</span>);
        }

        return Summary.summary(
            <span>
                Privileged mode set to <strong>{setting}</strong>.
            </span>
        );
    }

    private kubernetesSecurityContextReadOnlyRootFilesystemSummary() {
        const setting = _.get(this.state.containerDetails, "SecurityContext.readOnlyRootFilesystem");

        if (!setting || setting === "False") {
            return Summary.default(<span>Read only root file system not enabled.</span>);
        }

        if (setting === "True") {
            return Summary.summary(<span>Read only root file system enabled.</span>);
        }

        return Summary.summary(
            <span>
                Read only root file system set to <strong>{setting}</strong>.
            </span>
        );
    }

    private kubernetesSecurityContextRunAsNonRootSummary() {
        const setting = _.get(this.state.containerDetails, "SecurityContext.runAsNonRoot");

        if (!setting || setting === "False") {
            return Summary.default(<span>Run as non-root not enabled.</span>);
        }

        if (setting === "True") {
            return Summary.summary(<span>Run as non-root enabled.</span>);
        }

        return Summary.summary(
            <span>
                Run as non-root set to <strong>{setting}</strong>.
            </span>
        );
    }

    private kubernetesSecurityContextRunAsUserSummary() {
        const setting: string = _.get(this.state.containerDetails, "SecurityContext.runAsUser");

        if (!setting || !setting.trim()) {
            return Summary.default(<span>Run as user not defined.</span>);
        }

        return Summary.summary(
            <span>
                Run as user set to <strong>{setting}</strong>.
            </span>
        );
    }

    private kubernetesSecurityContextRunAsGroupSummary() {
        const setting: string = _.get(this.state.containerDetails, "SecurityContext.runAsGroup");

        if (!setting || !setting.trim()) {
            return Summary.default(<span>Run as group not defined.</span>);
        }

        return Summary.summary(
            <span>
                Run as group set to <strong>{setting}</strong>.
            </span>
        );
    }

    private kubernetesSecurityContextSELinuxOptionsSummary() {
        const setting: SELinuxOptions = _.get(this.state.containerDetails, "SecurityContext.seLinuxOptions");

        if (!setting || (!setting.level && !setting.role && !setting.type && !setting.user)) {
            return Summary.default(<span>No SELinux options defined</span>);
        }

        return Summary.summary(
            <span>
                Setting the{" "}
                {setting.level && (
                    <span>
                        level to <strong>{setting.level}</strong>
                        {(setting.role || setting.type || setting.user) && <span>, </span>}
                    </span>
                )}
                {setting.role && (
                    <span>
                        role to <strong>{setting.role}</strong>
                        {(setting.type || setting.user) && <span>, </span>}
                    </span>
                )}
                {setting.type && (
                    <span>
                        type to <strong>{setting.type}</strong>
                        {setting.user && <span>, </span>}
                    </span>
                )}
                {setting.user && (
                    <span>
                        type to <strong>{setting.user}</strong>
                    </span>
                )}
            </span>
        );
    }

    private kubernetesSecurityContextCapabilitiesSummary() {
        const setting: Capabilities = _.get(this.state.containerDetails, "SecurityContext.capabilities");

        if (!setting || ((setting.add || []).length === 0 && (setting.drop || []).length === 0)) {
            return Summary.default(<span>No POSIX capabilities added or dropped.</span>);
        }

        if ((setting.add || []).length !== 0 && (setting.drop || []).length === 0) {
            return Summary.summary(
                <span>
                    Added the POSIX capabilities <strong>{setting.add.join(", ")}</strong>
                </span>
            );
        }

        if ((setting.add || []).length === 0 && (setting.drop || []).length !== 0) {
            return Summary.summary(
                <span>
                    Dropped the POSIX capabilities <strong>{setting.drop.join(", ")}</strong>
                </span>
            );
        }

        return Summary.summary(
            <span>
                Added the <strong>{(setting.add || []).join(", ")}</strong> and dropped the <strong>{(setting.drop || []).join(", ")}</strong> POSIX capabilities
            </span>
        );
    }

    private getCapabilities = async (searchText: string) => {
        const results = _.chain(PosixCapabilities)
            .filter((v) => !!v)
            .filter((v) => !searchText || v.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        const itemsToTake = 7;
        return {
            items: results.slice(0, itemsToTake).map((f) => ({ Id: f, Name: f })),
            containsAllResults: results.length <= itemsToTake,
        };
    };

    private validateEnvironmentVariablesHaveNames(envVars: KeyValueOption[]) {
        if (envVars) {
            envVars.forEach((p) => {
                p.keyError = p.key && p.key.trim() ? null! : "The environment variable needs a name.";
            });
        }
    }

    private validateEnvironmentFromVariablesHaveNames(envVars: KeyValueOption[]) {
        if (envVars) {
            envVars.forEach((p) => {
                p.keyError = p.key && p.key.trim() ? null! : "The resource name needs to be defined.";
            });
        }
    }
}

export default ContainerDialog;
