import type { ProjectResource, ReleaseTemplateResource, GitBranchResource } from "~/client/resources";
import type { Client } from "../client";

export class BranchesRepository {
    constructor(private readonly client: Client) {
        this.client = client;
    }

    getTemplate(branch: GitBranchResource, channelId: string) {
        return this.client.get<ReleaseTemplateResource>(branch.Links["ReleaseTemplate"], { channel: channelId });
    }

    createBranch(project: ProjectResource, newBranchName: String, currentBranchName: String): Promise<GitBranchResource> {
        return this.client.post(project.Links.Branches, { NewBranchName: newBranchName, CurrentBranchName: currentBranchName });
    }
}

export default BranchesRepository;
