import * as React from "react";
import { ProcessType } from "~/client/resources";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import BranchSelector from "../../BranchSelector/BranchSelector";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import styles from "../CustomPaperLayouts/style.module.less";
import { ProjectContextPaperLayout } from "./ProjectContextPaperLayout";

interface ProcessPaperLayoutProps extends PaperLayoutProps {
    processType: ProcessType;
}

export const ProcessPaperLayout: React.FC<ProcessPaperLayoutProps> = ({ disableAnimations = true, children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return (
            <RunbooksPaperLayout disableAnimations={disableAnimations} fullWidth={true} {...rest}>
                {children}
            </RunbooksPaperLayout>
        );
    } else {
        return (
            <ProjectContextPaperLayout disableAnimations={disableAnimations} fullWidth={true} {...rest} sectionControl={renderBranchSelector(rest.sectionControl)}>
                {children}
            </ProjectContextPaperLayout>
        );
    }
};

const renderBranchSelector = (sectionControl: React.ReactNode) => {
    return (
        <div className={styles.sectionControlContainer}>
            <BranchSelector></BranchSelector>
            {sectionControl}
        </div>
    );
};
