import React, { useCallback } from "react";
import GitRefDropDown, { RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import type { GitRefResource, ProjectResource } from "~/client/resources";
import { HasVersionControlledPersistenceSettings } from "~/client/resources";
import type { GitBranch, GitRef, GitReference } from "~/client/resources/versionControlledResource";
import { isGitBranch, isGitTag, toGitBranch } from "~/client/resources/versionControlledResource";
import { repository } from "~/clientInstance";
import { buildBranchNamesList, getTextValuePairForBranch } from "~/utils/BranchHelpers/BranchHelpers";

interface GitRefSelectorProps extends WithProjectContextInjectedProps {
    selectedGitRef?: GitReference;
    onChange: (ref: GitReference) => void;
    onBranchCreated?: (branch: GitBranch) => void;
    disabled?: boolean;
    style?: "grey" | "white";
}

const ensureSelectedAndDefaultItemExists = async (selectedGitRef: GitRef, collection: GitRefOption[], project: Readonly<ProjectResource>): Promise<GitRefOption[]> => {
    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const vcsPersistenceSettings = project.PersistenceSettings;

    let items: GitRefOption[] = collection;
    if (selectedGitRef && isGitBranch(selectedGitRef)) {
        if (!items.find((item) => item.value === selectedGitRef)) {
            const branchResource = await repository.Projects.tryGetBranch(project, selectedGitRef);
            // It may be that the current branch has been deleted directly in the repo. Therefore, only add it if it was found.
            if (branchResource !== null) {
                items = [getTextValuePairForBranch(branchResource, vcsPersistenceSettings), ...items];
            }
        }
    }

    if (!items.find((item) => item.value === vcsPersistenceSettings.DefaultBranch || item.value === toGitBranch(vcsPersistenceSettings.DefaultBranch))) {
        // It may be that the default branch has been deleted directly in the repo. Therefore, only add it if it was found.
        const branchResource = await repository.Projects.tryGetBranch(project, vcsPersistenceSettings.DefaultBranch);
        if (branchResource !== null) {
            items = [getTextValuePairForBranch(branchResource, vcsPersistenceSettings), ...items];
        }
    }

    return items;
};

const GitRefSelector: React.FC<GitRefSelectorProps> = (props) => {
    const project = useProjectContext().state.model;
    const [gitRef, setGitRef] = React.useState(props.selectedGitRef);
    const [branchList, setBranchList] = React.useState<GitRefOption[] | undefined>(undefined);
    const [tagList, setTagList] = React.useState<GitRefOption[] | undefined>(undefined);
    const [totalBranchCount, setTotalBranchCount] = React.useState<number>(0);
    const [totalTagCount, setTotalTagCount] = React.useState<number>(0);
    const [busy, setBusy] = React.useState<boolean>(false);

    const [refType, setRefType] = React.useState<RefTypes>(() => {
        if (isGitTag(props.selectedGitRef?.GitRef)) {
            return RefTypes.Tags;
        }
        return RefTypes.Branches;
    });

    const onCreateBranch = async (newBranch: GitBranch) => {
        await props.projectContext.state.projectContextRepository.Branches.createBranch(project, newBranch, props.projectContext.state.gitRef?.Name ?? "");
        branchSelected({ value: newBranch, text: "" });
        props.onBranchCreated?.(newBranch);
    };

    const branchSelected = (gitRef: GitRefOption) => {
        setGitRef({ GitRef: gitRef.value });
        props.onChange({ GitRef: gitRef.value });
    };

    const search = async (value: string) => {
        if (refType == RefTypes.Tags) {
            const tagResources = await repository.Projects.searchTags(project, value);
            const items = tagResources.Items.map((t) => ({
                text: t.Name,
                value: t.CanonicalName,
            }));
            return items;
        }

        // Fallback to branches
        const branchResources = await repository.Projects.searchBranches(project, value);
        return buildBranchNamesList(branchResources.Items, project);
    };

    const loadTags = useCallback(async () => {
        const tagResources = await repository.Projects.getTags(project);
        const items = tagResources.Items.map((t) => ({
            text: t.Name,
            value: t.CanonicalName,
        }));
        setTagList(items);
        setTotalTagCount(tagResources.TotalResults);
    }, [project, setTagList, setTotalTagCount]);

    const loadBranches = useCallback(
        async (gitRef: GitRefResource) => {
            const branchResources = await repository.Projects.getBranches(project);

            // Note: there is a chance that the resource passed to us isn't in the collection returned by getBranches, as it only returns the latest `n` active branches
            // Note: if it isn't in the collection retrieved, we will insert it here
            setBranchList(await ensureSelectedAndDefaultItemExists(gitRef.CanonicalName, buildBranchNamesList(branchResources.Items, project), project));
            setTotalBranchCount(branchResources.TotalResults);
        },
        [project, setBranchList, setTotalBranchCount]
    );

    React.useEffect(() => {
        setGitRef({ GitRef: props.projectContext.state.gitRef?.CanonicalName });
    }, [props.projectContext.state.gitRef]);

    const refresh = async (refreshFor: RefTypes) => {
        setBusy(true);
        if (refreshFor == RefTypes.Branches) {
            const selectedGitRef = props.projectContext.state.gitRef;
            if (!selectedGitRef) return;
            await loadBranches(selectedGitRef);
        } else if (refreshFor == RefTypes.Tags) {
            await loadTags();
        }
        setBusy(false);
    };

    React.useEffect(() => {
        async function fetchGitRefs(gitRef: GitRefResource) {
            setBusy(true);
            if (isGitTag(gitRef.CanonicalName)) {
                await loadTags();
            } else {
                await loadBranches(gitRef);
            }
            setBusy(false);
        }

        if (project && project.IsVersionControlled && props.projectContext.state.gitRef) {
            fetchGitRefs(props.projectContext.state.gitRef);
        }
    }, [loadTags, loadBranches, project, props.projectContext.state.gitRef]);

    const onRefTypeChanged = async (refType: RefTypes) => {
        setRefType(refType);
        await refresh(refType);
    };

    const list = refType === RefTypes.Tags ? tagList : branchList;
    const totalCount = refType === RefTypes.Tags ? totalTagCount : totalBranchCount;
    return (
        <>
            {/* This will eventually be tabs, allowing selecting a branch or tag */}
            <GitRefDropDown
                isBusySearching={busy}
                style={props.style}
                value={gitRef}
                items={list}
                totalItems={totalCount}
                onChange={branchSelected}
                onRequestRefresh={() => refresh(refType)}
                onFilterChanged={search}
                disabled={props.disabled}
                onCreateBranch={onCreateBranch}
                projectId={project.Id}
                refType={refType}
                onRefTypeChanged={onRefTypeChanged}
            />
        </>
    );
};

export default GitRefSelector;
