import * as _ from "lodash";
import * as React from "react";
import type { ProjectResource } from "../../../client/resources";
import { repository } from "../../../clientInstance";
import Note from "../../../primitiveComponents/form/Note/Note";
import { DataBaseComponent } from "../../DataBaseComponent";
import type { DataBaseComponentState } from "../../DataBaseComponent";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import type { KeyValueOption } from "../../EditList/ExtendedKeyValueEditList";
import StringExtendedKeyValueEditList, { ExtendedKeyValueEditList } from "../../EditList/ExtendedKeyValueEditList";
import StringKeyValueEditList from "../../EditList/KeyValueEditList";
import ExternalLink from "../../Navigation/ExternalLink";
import isBound from "../../form/BoundField/isBound";
import { CardFill, default as ExpandableFormSection } from "../../form/Sections/ExpandableFormSection";
import Summary from "../../form/Sections/Summary";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { DataSource, LabelSelector, Metadata, PersistentVolumeClaimInstanceDetails, PvcSpec, ResourceRequirementsDetails } from "./kubernetesDeployContainersAction";
import { Dns1132Regex, KubernetesNameRegex, KubernetesResourceQuantityRegex } from "./kubernetesValidation";

interface PersistentVolumeClaimState extends DataBaseComponentState {
    persistentVolumeClaimDetails: PersistentVolumeClaimInstanceDetails | null;
    project: ProjectResource | null;
}

interface PersistentVolumeClaimProps {
    persistentVolumeClaimDetails: PersistentVolumeClaimInstanceDetails;
    localNames: string[];
    projectId: string;
    standalone?: boolean;

    onAdd(Binding: PersistentVolumeClaimInstanceDetails): boolean;

    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

class PersistentVolumeClaimDialog extends DataBaseComponent<PersistentVolumeClaimProps, PersistentVolumeClaimState> {
    constructor(props: PersistentVolumeClaimProps) {
        super(props);
        this.state = {
            persistentVolumeClaimDetails: null,
            project: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null;

            this.setState({
                persistentVolumeClaimDetails: this.props.persistentVolumeClaimDetails,
                project: project,
            });
        });
    }

    save = () => {
        const binding = this.state.persistentVolumeClaimDetails;

        if (binding === null) {
            throw "binding must not be null";
        }

        let valid = true;

        if (!binding.Metadata?.Name || (!isBound(binding.Metadata?.Name) && !KubernetesNameRegex.exec(binding.Metadata?.Name))) {
            this.setValidationErrors("The persistent volume claim name must consist of lower case alphanumeric characters or '-', and must start and end with an alphanumeric character.", {
                PersistentVolumeClaimName: "The persistent volume claim name must consist of lower case alphanumeric characters or '-', and must start and end with an alphanumeric character.",
            });
            valid = false;
        }

        const filteredAccessModes = binding.Spec?.AccessModes?.filter((a: string) => _.trim(a)) || [];
        const validAccessModes = ["ReadWriteOnce", "ReadWriteMany", "ReadOnlyMany"];
        const validVolumeMode = ["Block", "Filesystem"];

        if (filteredAccessModes.length === 0) {
            this.setValidationErrors("An access mode must be defined", {
                PersistentVolumeClaimAccessModes: "An access mode must be defined",
            });
            valid = false;
        } else if (!filteredAccessModes.every((a) => isBound(a) || validAccessModes.some((v) => v.toLowerCase() === a.toLowerCase().trim()))) {
            this.setValidationErrors("All access modes must be ReadWriteOnce, ReadWriteMany, or ReadOnlyMany", {
                PersistentVolumeClaimAccessModes: "All access modes must be ReadWriteOnce, ReadWriteMany, or ReadOnlyMany",
            });
            valid = false;
        }

        if (binding.Spec?.Resources?.limits?.storage && !isBound(binding.Spec.Resources.limits.storage) && !KubernetesResourceQuantityRegex.exec(binding.Spec.Resources.limits.storage)) {
            this.setValidationErrors("Container storage resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.", {
                PersistentVolumeClaimResourcesStorageLimit: "Container cpu resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.",
            });
            valid = false;
        }

        if (!binding.Spec?.Resources?.limits?.storage && !binding.Spec?.Resources?.requests?.storage) {
            this.setValidationErrors("Container storage resource limits or requests are required", {
                PersistentVolumeClaimResourcesStorageLimit: "Container storage resource limits or requests are required",
                PersistentVolumeClaimResourcesStorageRequest: "Container storage resource limits or requests are required",
            });
            valid = false;
        }

        if (binding.Spec?.Resources?.requests?.storage && !isBound(binding.Spec.Resources.requests.storage) && !KubernetesResourceQuantityRegex.exec(binding.Spec.Resources.requests.storage)) {
            this.setValidationErrors("Container storage resource requests must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.", {
                PersistentVolumeClaimResourcesStorageRequest: "Container cpu resource limits must match the regular expression '^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$', e.g. 200m, 0.1.",
            });
            valid = false;
        }

        if (binding.Spec?.StorageClassName && !isBound(binding.Spec.StorageClassName) && !Dns1132Regex.exec(binding.Spec.StorageClassName)) {
            this.setValidationErrors("Container storage class name must match the regular expression " + Dns1132Regex, {
                PersistentVolumeClaimStorageClassName: "Container storage class name must match the regular expression " + Dns1132Regex,
            });
            valid = false;
        }

        if (binding.Spec?.VolumeMode && !isBound(binding.Spec.VolumeMode) && !validVolumeMode.some((v) => v.toLowerCase() == binding.Spec?.VolumeMode?.toLowerCase()?.trim())) {
            this.setValidationErrors("Volume access modes must be either Block or Filesystem", {
                PersistentVolumeClaimVolumeMode: "Volume access modes must be either Block or Filesystem",
            });
            valid = false;
        }

        if (valid) {
            return this.props.onAdd(binding);
        }

        return false;
    };

    render() {
        return (
            <OkDialogLayout onOkClick={() => this.save()} busy={this.state.busy} errors={this.errors} title={"Add Persistent Volume Claim"}>
                {this.state.persistentVolumeClaimDetails && (
                    <React.Fragment>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.persistentVolumeClaimDetails.IsNew}
                            title="Persistent Volume Claim Metadata"
                            errorKey={"PersistentVolumeClaimMetadata"}
                            summary={this.metadataSummary()}
                            help={"Provide the metadata for the persistent volume claim."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                label="Name"
                                value={this.state.persistentVolumeClaimDetails.Metadata?.Name || ""}
                                error={this.getFieldError("PersistentVolumeClaimName")}
                                onChange={(Name) => this.setPersistentVolumeClaimStateMetadata({ Name })}
                            />
                            <Note>The persistent volume claim name.</Note>
                            <StringKeyValueEditList
                                items={JSON.stringify(this.state.persistentVolumeClaimDetails.Metadata?.LabelsRaw)}
                                name="Label"
                                separator=""
                                onChange={(LabelsRaw) => this.setPersistentVolumeClaimStateMetadata({ LabelsRaw: JSON.parse(LabelsRaw) })}
                                valueLabel="Value"
                                keyLabel="Name"
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                            />
                            <Note>The labels associated with the persistent volume claim.</Note>
                            <StringExtendedKeyValueEditList
                                items={JSON.stringify(this.state.persistentVolumeClaimDetails.Metadata?.AnnotationsRaw)}
                                name="Annotation"
                                onChange={(AnnotationsRaw) => this.setPersistentVolumeClaimStateMetadata({ AnnotationsRaw: JSON.parse(AnnotationsRaw) })}
                                valueLabel="Value"
                                keyLabel="Name"
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                            />
                            <Note>The annotations associated with the persistent volume claim.</Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.persistentVolumeClaimDetails.IsNew}
                            title="Persistent Volume Claim Details"
                            errorKey={"PersistentVolumeClaimDetails"}
                            summary={this.specSummary()}
                            help={"Provide the details of the persistent volume claim."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                label="Volume name"
                                value={this.state.persistentVolumeClaimDetails.Spec?.VolumeName || ""}
                                error={this.getFieldError("PersistentVolumeClaimVolumeName")}
                                onChange={(VolumeName) => this.setPersistentVolumeClaimState({ VolumeName })}
                            />
                            <Note>The binding reference to the persistent volume backing this claim.</Note>
                            <VariableLookupText
                                label="Volume mode"
                                value={this.state.persistentVolumeClaimDetails.Spec?.VolumeMode || ""}
                                error={this.getFieldError("PersistentVolumeClaimVolumeMode")}
                                onChange={(VolumeMode) => this.setPersistentVolumeClaimState({ VolumeMode })}
                            />
                            <Note>
                                Defines what type of volume is required by the claim. A value of <code>Filesystem</code> is implied when not included in claim spec. The other option is <code>Block</code>.{" "}
                                <ExternalLink href="KubernetesPVCVolumeModes">More information</ExternalLink>.
                            </Note>
                            <VariableLookupText
                                label="Storage class name"
                                value={this.state.persistentVolumeClaimDetails.Spec?.StorageClassName || ""}
                                error={this.getFieldError("PersistentVolumeClaimStorageClassName")}
                                onChange={(StorageClassName) => this.setPersistentVolumeClaimState({ StorageClassName })}
                            />
                            <Note>
                                Name of the storage class required by the claim. <ExternalLink href="KubernetesPVCClasses">More information</ExternalLink>.
                            </Note>
                            <VariableLookupText
                                label="Access modes"
                                multiline={true}
                                value={(this.state.persistentVolumeClaimDetails.Spec?.AccessModes || []).join("\n")}
                                error={this.getFieldError("PersistentVolumeClaimAccessModes")}
                                onChange={(AccessModes) => this.setPersistentVolumeClaimState({ AccessModes: AccessModes.split("\n") })}
                            />
                            <Note>
                                A newline separated listed of access modes contains the desired access modes the volume should have. Supported values are <code>ReadOnlyMany</code>, <code>ReadWriteOnce</code>, and <code>ReadWriteMany</code>.{" "}
                                <ExternalLink href="KubernetesPVCAccessModes">More information</ExternalLink>.
                            </Note>
                            <ExtendedKeyValueEditList
                                items={() => this.state.persistentVolumeClaimDetails?.Spec?.Selector?.MatchExpressions || []}
                                name="Label selector"
                                onChange={(val) => {
                                    this.setMatchExpressions({ MatchExpressions: val });
                                    this.repositionDialog();
                                }}
                                valueLabel="Operator"
                                optionLabel="Values"
                                keyLabel="Key"
                                optionMultiline={true}
                                valueValues={[
                                    { text: "In", value: "In" },
                                    { text: "NotIn", value: "NotIn" },
                                    { text: "Exists", value: "Exists" },
                                    { text: "DoesNotExist", value: "DoesNotExist" },
                                ]}
                                hideBindOnKey={false}
                                projectId={this.props.projectId}
                                addToTop={true}
                                onAdd={this.repositionDialog}
                            />
                            <Note>A label query over volumes to consider for binding.</Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.persistentVolumeClaimDetails.IsNew}
                            title="Storage Requests and Limits"
                            errorKey={"PersistentVolumeClaimResourcesStorage"}
                            summary={this.persistentVolumeClaimStorageSummary()}
                            help={"Specify the storage requests and limits."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("PersistentVolumeClaimResourcesStorageRequest")}
                                value={_.get(this.state.persistentVolumeClaimDetails, "Spec.Resources.requests.storage")}
                                onChange={(x) => this.setPersistentVolumeClaimResourcesRequestState({ storage: x })}
                                label="Storage request"
                            />
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("PersistentVolumeClaimResourcesStorageLimit")}
                                value={_.get(this.state.persistentVolumeClaimDetails, "Spec.Resources.limits.storage")}
                                onChange={(x) => this.setPersistentVolumeClaimResourcesLimitState({ storage: x })}
                                label="Storage limit"
                            />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            isExpandedByDefault={this.state.persistentVolumeClaimDetails.IsNew}
                            title="Data Source"
                            errorKey={"PersistentVolumeClaimDataSource"}
                            summary={this.dataSourceSummary()}
                            help={"Specifies the source of the data from where the newly created PVC will be initialized."}
                            fillCardWidth={CardFill.FillAll}
                            forceMobileBehaviour={true}
                        >
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("PersistentVolumeClaimDataSourceApiGroup")}
                                value={_.get(this.state.persistentVolumeClaimDetails, "Spec.DataSource.ApiGroup")}
                                onChange={(x) => this.setPersistentVolumeClaimDataSourceState({ ApiGroup: x })}
                                label="API group"
                            />
                            <Note>
                                The group for resource being referenced. Kubernetes supports only <code>snapshot.storage.k8s.io</code>.
                            </Note>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("PersistentVolumeClaimDataSourceKind")}
                                value={_.get(this.state.persistentVolumeClaimDetails, "Spec.DataSource.Kind")}
                                onChange={(x) => this.setPersistentVolumeClaimDataSourceState({ Kind: x })}
                                label="Kind"
                            />
                            <Note>
                                The resource being referenced. Kubernetes supports only <code>VolumeSnapshot</code>.
                            </Note>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                error={this.getFieldError("PersistentVolumeClaimDataSourceName")}
                                value={_.get(this.state.persistentVolumeClaimDetails, "Spec.DataSource.Name")}
                                onChange={(x) => this.setPersistentVolumeClaimDataSourceState({ Name: x })}
                                label="Name"
                            />
                            <Note>
                                The <code>VolumeSnapshot</code> resource name.
                            </Note>
                        </ExpandableFormSection>
                    </React.Fragment>
                )}
            </OkDialogLayout>
        );
    }

    /**
     * https://github.com/mui-org/material-ui/issues/1676
     * https://github.com/mui-org/material-ui/issues/5793
     * When adding or removing items from a list, the dialog needs to be repositioned, otherwise
     * the list may disappear off the screen. A resize event is the commonly suggested workaround.
     */
    private repositionDialog() {
        window.dispatchEvent(new Event("resize"));
    }

    private setPersistentVolumeClaimStateMetadata<K extends keyof Metadata>(state: Pick<Metadata, K>, callback?: () => void) {
        this.repositionDialog();
        this.setChildState2("persistentVolumeClaimDetails", "Metadata", state, callback);
    }

    private setPersistentVolumeClaimState<K extends keyof PvcSpec>(state: Pick<PvcSpec, K>, callback?: () => void) {
        this.repositionDialog();
        this.setChildState2("persistentVolumeClaimDetails", "Spec", state, callback);
    }

    private setPersistentVolumeClaimDataSourceState<K extends keyof DataSource>(state: Pick<DataSource, K>, callback?: () => void) {
        this.repositionDialog();
        this.setChildState3("persistentVolumeClaimDetails", "Spec", "DataSource", state, callback);
    }

    private setPersistentVolumeClaimResourcesLimitState<K extends keyof ResourceRequirementsDetails>(state: Pick<ResourceRequirementsDetails, K>, callback?: () => void) {
        this.repositionDialog();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type instantiation is excessively deep and possibly infinite
        this.setChildState4("persistentVolumeClaimDetails", "Spec", "Resources", "limits", state, callback);
    }

    private setPersistentVolumeClaimResourcesRequestState<K extends keyof ResourceRequirementsDetails>(state: Pick<ResourceRequirementsDetails, K>, callback?: () => void) {
        this.repositionDialog();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type instantiation is excessively deep and possibly infinite
        this.setChildState4("persistentVolumeClaimDetails", "Spec", "Resources", "requests", state, callback);
    }

    private setMatchExpressions<K extends keyof LabelSelector>(state: Pick<LabelSelector, K>, callback?: () => void) {
        this.setChildState3("persistentVolumeClaimDetails", "Spec", "Selector", state);
    }

    private dataSourceSummary() {
        const apiGroup = (_.get(this.state.persistentVolumeClaimDetails, "Spec.DataSource.ApiGroup") || "").trim();
        const name = (_.get(this.state.persistentVolumeClaimDetails, "Spec.DataSource.Name") || "").trim();
        const kind = (_.get(this.state.persistentVolumeClaimDetails, "Spec.DataSource.Kind") || "").trim();

        if (apiGroup || name || kind) {
            return Summary.summary(
                <span>
                    Volume will be based on resource with{" "}
                    {apiGroup && (
                        <span>
                            API group of <strong>{apiGroup}</strong>
                            {kind || name ? ", " : ""}
                        </span>
                    )}
                    {name && (
                        <span>
                            name of <strong>{name}</strong>
                            {kind ? ", " : ""}
                        </span>
                    )}
                    {kind && (
                        <span>
                            {apiGroup || name ? "and " : ""}kind of <strong>{kind}</strong>
                        </span>
                    )}
                </span>
            );
        }

        return Summary.default("No data source specified");
    }

    private specSummary() {
        const name = (_.get(this.state.persistentVolumeClaimDetails, "Spec.VolumeName") || "").trim();
        const volumeMode = (_.get(this.state.persistentVolumeClaimDetails, "Spec.VolumeMode") || "").trim();
        const storageClassName = (_.get(this.state.persistentVolumeClaimDetails, "Spec.StorageClassName") || "").trim();
        const accessModes = (_.get(this.state.persistentVolumeClaimDetails, "Spec.AccessModes") || []).filter((a: string) => _.trim(a));
        const matchExpressions = _.get(this.state.persistentVolumeClaimDetails, "Spec.Selector.MatchExpressions") || [];

        if (name || volumeMode || storageClassName || accessModes.length !== 0 || matchExpressions.length !== 0) {
            return Summary.summary(
                <span>
                    Persistent volume claim with{" "}
                    {name && (
                        <span>
                            a volume name of <strong>{name}</strong>
                            {volumeMode || storageClassName || accessModes.length !== 0 || matchExpressions.length !== 0 ? ", " : ""}
                        </span>
                    )}
                    {volumeMode && (
                        <span>
                            a volume mode of <strong>{volumeMode}</strong>
                            {storageClassName || accessModes.length !== 0 || matchExpressions.length !== 0 ? ", " : ""}
                        </span>
                    )}
                    {storageClassName && (
                        <span>
                            a storage class name of <strong>{storageClassName}</strong>
                            {accessModes.length !== 0 || matchExpressions.length !== 0 ? ", " : ""}
                        </span>
                    )}
                    {accessModes.length !== 0 && (
                        <span>
                            {accessModes.length > 1 ? "" : "an "}access mode{accessModes.length > 1 ? "s" : ""} of <strong>{accessModes.join("; ")}</strong>
                            {matchExpressions.length !== 0 ? ", " : ""}
                        </span>
                    )}
                    {matchExpressions.length !== 0 && (
                        <span>
                            {name || volumeMode || storageClassName || accessModes.length !== 0 ? "and " : ""}
                            label {matchExpressions.length > 1 ? "" : "a "}selector{matchExpressions > 1 ? "s" : ""} of{" "}
                            <strong>
                                {matchExpressions.map((l: KeyValueOption, idx1: number) => (
                                    <span>
                                        {l.key} {l.value} {l.option}
                                        {idx1 < (matchExpressions.length || 0) - 1 ? "; " : ""}
                                    </span>
                                ))}
                            </strong>
                        </span>
                    )}
                </span>
            );
        }

        return Summary.default("No persistent volume claim details specified");
    }

    private metadataSummary() {
        const name = (_.get(this.state.persistentVolumeClaimDetails, "Metadata.Name") || "").trim();
        const labels = _.get(this.state.persistentVolumeClaimDetails, "Metadata.LabelsRaw") || [];
        const annotations = _.get(this.state.persistentVolumeClaimDetails, "Metadata.AnnotationsRaw") || [];

        if (name || labels.length !== 0 || annotations.length !== 0) {
            return Summary.summary(
                <span>
                    Persistent volume claim{" "}
                    {name && (
                        <span>
                            called <strong>{name}</strong>
                            {labels.length !== 0 || annotations.length !== 0 ? " " : ""}
                        </span>
                    )}
                    {labels.length !== 0 && (
                        <span>
                            with label{_.keys(labels).length > 1 ? <span>s</span> : ""}{" "}
                            {_.keys(labels).map((l, idx1) => (
                                <span>
                                    <strong>
                                        {l}: {_.get(labels, l)}
                                    </strong>
                                    {idx1 < (_.keys(labels).length || 0) - 1 ? ", " : ""}
                                </span>
                            ))}
                            {annotations.length !== 0 ? " " : ""}
                        </span>
                    )}
                    {annotations.length !== 0 && (
                        <span>
                            {labels ? "and " : "with "}annotation{_.keys(annotations).length > 1 ? <span>s</span> : ""}{" "}
                            {annotations.map((l: KeyValueOption, idx1: number) => (
                                <span>
                                    <strong>
                                        {l.key}: {l.value}
                                    </strong>
                                    {idx1 < (_.keys(annotations).length || 0) - 1 ? ", " : ""}
                                </span>
                            ))}
                        </span>
                    )}
                </span>
            );
        }

        return Summary.default("No data source specified");
    }

    private persistentVolumeClaimStorageSummary() {
        const summaryElements = [];
        const request = (_.get(this.state.persistentVolumeClaimDetails, "Spec.Resources.requests.storage") || "").trim();
        const limit = (_.get(this.state.persistentVolumeClaimDetails, "Spec.Resources.limits.storage") || "").trim();

        if (request) {
            summaryElements.push(
                <span>
                    Requesting <strong>{request}</strong> of storage.{" "}
                </span>
            );
        }
        if (limit) {
            if (!_.isEmpty(summaryElements)) {
                summaryElements.push(<span> </span>);
            }
            summaryElements.push(
                <span>
                    Limiting storage to <strong>{limit}</strong>.
                </span>
            );
        }
        return !_.isEmpty(summaryElements) ? Summary.summary(summaryElements) : Summary.default("No storage resources requests or limits specified");
    }
}

export default PersistentVolumeClaimDialog;
