/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import { Card, Collapse } from "@material-ui/core";
import * as React from "react";
import type { ExpandableProps } from "~/components/Expandable/Expandable";
import Expandable from "~/components/Expandable/Expandable";
import { CardTitle } from "~/components/form/Sections";
import styles from "./style.module.less";

interface SimpleExpanderProps {
    error?: string;
    title: JSX.Element;
    onDidExpand?: (expanded: boolean) => void;
}

type Props = SimpleExpanderProps & ExpandableProps;

//eslint-disable-next-line react/no-unsafe
export class SimpleExpanderInternal extends React.Component<Props> {
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.isExpanded !== nextProps.isExpanded) {
            if (nextProps.onDidExpand) {
                nextProps.onDidExpand(nextProps.isExpanded);
            }
        }
    }

    // we can't just use actAsExpander on card Title because it eats the click
    // and we can't add links to the title in help text or our show more info link
    handleExpand = (e: React.MouseEvent) => {
        e.preventDefault();
        const isExpanded = !this.props.isExpanded;
        this.props.onExpandedChanged(isExpanded);
    };

    renderChild = (child: React.ReactElement): React.ReactNode => {
        if (!child) {
            return child;
        }
        const children = child.props && child.props.children ? { children: this.renderChildren(child.props.children) } : null;

        const props = children ? { ...child.props, ...children } : child.props;
        return React.isValidElement(child) ? React.cloneElement(child, props) : child;
    };

    renderChildren = (children: React.ReactNode) => {
        return React.Children.map(children as React.ReactElement, this.renderChild);
    };

    render() {
        return (
            <Card className={styles.formExpander}>
                <CardTitle title={this.props.title} onToggleExpand={this.handleExpand} isExpanded={this.props.isExpanded} />
                <Collapse in={this.props.isExpanded} timeout="auto" unmountOnExit={true}>
                    <div className={styles.cardMedia}>{this.renderChildren(this.props.children)}</div>
                </Collapse>
            </Card>
        );
    }
}

const SimpleExpander = Expandable(SimpleExpanderInternal);
export default SimpleExpander;
