import * as React from "react";
import { withTheme } from "~/components/Theme";
import ToolTip from "../../../primitiveComponents/dataDisplay/ToolTip/index";

interface MissingVariablesIcon {
    show: boolean;
}

const MissingVariablesIcon: React.StatelessComponent<MissingVariablesIcon> = (props) => {
    if (!props.show) {
        return null;
    }

    return withTheme((theme) => (
        <ToolTip content={`Some of the required variable values have not been set`}>
            <em className={"fa fa-warning"} style={{ color: theme.alertText }} />
        </ToolTip>
    ));
};

export default MissingVariablesIcon;
