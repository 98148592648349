import type { InputJsonSchema } from "@octopusdeploy/step-runtime-inputs";
import { importFromDynamicExpression } from "~/client/importFromDynamicExpression";
import type { StepPackage, StepPackageDetailsResponse, StepUI } from "~/client/resources/stepPackage";
import type { Client } from "../client";
import type { StepPackageDeploymentTargetType } from "../resources/stepPackageDeploymentTargetType";

class StepPackageDeploymentTargetRepository {
    constructor(private readonly client: Client) {}

    async getStepPackageDeploymentTargetTypes(): Promise<StepPackageDeploymentTargetType[]> {
        const deploymentTargetsLink = this.client.getLink("StepPackageDeploymentTargetTypes");
        return await this.client.get<StepPackageDeploymentTargetType[]>(deploymentTargetsLink);
    }

    async getLatestStepPackageByDeploymentTargetType(deploymentTargetTypeId: string): Promise<StepPackage> {
        const response = await this.client.get<StepPackageDetailsResponse>(`/api/steps/deploymenttargets/${deploymentTargetTypeId}/latest-version`);

        if (!response) {
            throw new Error(`No deployment target was found with id ${deploymentTargetTypeId}.`);
        }

        return this.resolveStepPackageDetails(response);
    }

    async getStepPackageByDeploymentTargetType(deploymentTargetTypeId: string, version: string): Promise<StepPackage> {
        const response = await this.client.get<StepPackageDetailsResponse>(`/api/steps/deploymenttargets/${deploymentTargetTypeId}/${version}`);

        if (!response) {
            throw new Error(`No deployment target was found with id ${deploymentTargetTypeId} and version ${version}.`);
        }

        return this.resolveStepPackageDetails(response);
    }

    async resolveStepPackageDetails(stepPackage: StepPackageDetailsResponse): Promise<StepPackage> {
        const schema = await this.client.get<InputJsonSchema>(stepPackage.Links.Schema);
        const stepUI = await this.resolveStepPackageUI(stepPackage);

        return {
            name: stepPackage.Name,
            description: stepPackage.Description,
            version: stepPackage.Version,
            stepUI: stepUI,
            schema: schema,
            prerelease: stepPackage.Prerelease,
            releaseNotesUrl: stepPackage.ReleaseNotesUrl,
            executionLocation: stepPackage.ExecutionLocation,
            requiresTargetRole: stepPackage.RequiresTargetRole,
        };
    }

    async resolveStepPackageUI(stepPackage: StepPackageDetailsResponse): Promise<unknown> {
        const stepUIModule = await importFromDynamicExpression(stepPackage.Links.StepUI);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const convertedStepUIModule = stepUIModule as { default: StepUI };
        return convertedStepUIModule.default;
    }
}

export default StepPackageDeploymentTargetRepository;
