/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { Cancelable } from "lodash";
import { throttle } from "lodash";
import * as React from "react";
import EventListener, { withOptions } from "react-event-listener";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { LinksMenu } from "~/components/LinksMenu/LinksMenu";
import { LinksMenuButton } from "~/components/LinksMenu/LinksMenuButton";
import { hasActiveDescendants, isMenuGroup } from "~/components/LinksMenu/MenuNode";
import type { MenuNode } from "~/components/LinksMenu/MenuNode";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
import styles from "./style.module.less";

interface PriorityNavigationProps {
    className: string;
    activeItemClassName: string;
    navigationItems: MenuNode[];
    maxNavigationItems: number;
    showHamburgerIcon?: boolean;
}

interface PriorityNavigationState {
    priorityItems: MenuNode[];
    moreItems: MenuNode[];
    isMoreMenuOpen: boolean;
    hasMeasurements: boolean;
    availableWidth?: number | null;
}

class PriorityNavigation extends BaseComponent<PriorityNavigationProps, PriorityNavigationState> {
    widthsArray: number[] = [];
    navigation: HTMLElement = undefined!;
    onResizeThrottled: (() => void) & Cancelable;
    containerElement: HTMLElement = undefined!;
    lastKnownContainerWidth: number = undefined!;

    constructor(props: PriorityNavigationProps) {
        super(props);

        this.updateNavigation = this.updateNavigation.bind(this);
        this.howManyItemsInMenuArray = this.howManyItemsInMenuArray.bind(this);
        this.onResizeThrottled = throttle(this.onResize, 100);

        this.state = {
            hasMeasurements: false,
            isMoreMenuOpen: false,
            priorityItems: this.props.navigationItems,
            moreItems: [],
            availableWidth: null,
        };
    }

    componentDidMount() {
        //Get width of all items in navigation menu
        this.widthsArray = Array.prototype.slice.call(this.navigation.children).map((item: HTMLElement) => item.getBoundingClientRect().width);
        this.setState({ hasMeasurements: true }, () => this.updateNavigation(this.props));
    }

    UNSAFE_componentWillReceiveProps(nextProps: PriorityNavigationProps) {
        this.updateNavigation(nextProps);
    }

    render() {
        const { priorityItems, moreItems } = this.state;

        // Do first pass to measure all items
        if (!this.state.hasMeasurements) {
            return (
                <nav className={this.props.className}>
                    <ul ref={this.setNavigation}>
                        {priorityItems.map((item, index) => (
                            <RoutedNavItem key={`navItem-${index}`} item={item} activeItemClassName={this.props.activeItemClassName} />
                        ))}
                        <LinksMenuButton activeItemClassName={this.props.activeItemClassName} label="More" moreItems={this.state.moreItems} />
                    </ul>
                </nav>
            );
        }

        // Measure the container to find out available width
        if (this.state.availableWidth === null) {
            // This div will live in the document for a brief moment, just long
            // enough for it to mount. We then use it to calculate its width, and
            // replace it immediately with the underlying component.
            return <div style={{ flexGrow: 1, width: "100%" }} ref={this.setMeasurementDiv} />;
        }

        return (
            <nav className={`${this.props.className} ${styles.container}`}>
                <EventListener target="window" onResize={withOptions(this.onResizeThrottled, { passive: true })} />
                <ul>
                    {priorityItems.map((item: MenuNode, index: number) => (
                        <RoutedNavItem key={`navItem-${index}`} item={item} activeItemClassName={this.props.activeItemClassName} />
                    ))}
                    {moreItems.length > 0 && <LinksMenuButton activeItemClassName={this.props.activeItemClassName} icon={this.props.showHamburgerIcon && priorityItems.length === 0 ? "fa fa-bars" : undefined} label="More" moreItems={moreItems} />}
                </ul>
            </nav>
        );
    }

    private howManyItemsInMenuArray(array: number[], outerWidth: number | null | undefined, initialWidth: number, maximumNumberInNav: number) {
        let total = initialWidth;
        for (let i = 0; i < array.length; i++) {
            if (i > maximumNumberInNav) {
                return maximumNumberInNav;
            }

            total += array[i];

            if (total > (outerWidth || 0)) {
                return i;
            }
        }

        return array.length;
    }

    private updateNavigation(props: PriorityNavigationProps) {
        if (!(this.state.hasMeasurements && this.state.availableWidth !== null)) {
            return;
        }

        const arrayAmount = this.howManyItemsInMenuArray(this.widthsArray, this.state.availableWidth, this.widthsArray[this.widthsArray.length - 1], props.maxNavigationItems);
        const navItemsCopy = props.navigationItems;
        const priorityItems = navItemsCopy.slice(0, arrayAmount);

        const moreItems = priorityItems.length !== navItemsCopy.length ? navItemsCopy.slice(arrayAmount, navItemsCopy.length) : [];

        this.setState({
            priorityItems,
            moreItems,
        });
    }

    private onResize = () => {
        if (this.containerElement.offsetWidth === this.lastKnownContainerWidth) {
            return; // Nothing changed
        }

        this.setState({ availableWidth: null });
    };

    private setMeasurementDiv = (el: HTMLDivElement) => {
        if (!el) {
            return;
        }
        this.containerElement = el.parentNode as HTMLElement;
        this.lastKnownContainerWidth = this.containerElement.offsetWidth;

        this.setState(
            {
                availableWidth: el.offsetWidth,
            },
            () => this.updateNavigation(this.props)
        );
    };

    private setNavigation = (el: HTMLUListElement) => {
        this.navigation = el;
    };
}

interface NavItemComponentProps {
    item: MenuNode;
    activeItemClassName: string;
}

type NavItemProps = NavItemComponentProps & RouteComponentProps<{ spaceId: string }>;

const NavItem: React.SFC<NavItemProps> = (props: NavItemProps) => {
    const item = props.item;

    if (isMenuGroup(item)) {
        const isActive = hasActiveDescendants(props.location.pathname, props.match.params.spaceId, item);
        return (
            <li>
                <LinksMenu activeItemClassName={isActive ? props.activeItemClassName : undefined} items={item.children} label={item.label} />
            </li>
        );
    }

    return (
        <li>
            <InternalNavLink activeClassName={props.activeItemClassName} to={item.url} exact={item.exact} title={item.title} className={item.linkClassName}>
                {item.icon}
                <span>{item.text}</span>
                {item.accessoryView}
            </InternalNavLink>
        </li>
    );
};

const RoutedNavItem = withRouter(NavItem);

export default PriorityNavigation;
