import type { AccountReference, EmptyInitialValue } from "@octopusdeploy/step-inputs";
import type { RuntimeAccountSelection } from "@octopusdeploy/step-runtime-inputs";
import { emptyInitialValue } from "../../emptyInitialValue";

export function convertToRuntimeAccountSelection(accountSelection: AccountReference | EmptyInitialValue): RuntimeAccountSelection {
    if (accountSelection === null || accountSelection === undefined || accountSelection === emptyInitialValue) {
        return "";
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (accountSelection as unknown) as RuntimeAccountSelection;
}

export function convertFromRuntimeAccountSelection(accountSelection: RuntimeAccountSelection): AccountReference {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (accountSelection as unknown) as AccountReference;
}
