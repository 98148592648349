/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-imports */

import { ListItem } from "material-ui";
import * as React from "react";
import { withTheme } from "~/components/Theme";

const BorderedListItem: React.SFC<any> = (props) =>
    withTheme((theme) => (
        <ListItem
            style={{
                border: "1px solid",
                borderColor: theme.divider,
                borderRadius: "3px",
                margin: "16px 0",
            }}
            {...props}
        >
            {props.children}
        </ListItem>
    ));

export default BorderedListItem;
