import * as React from "react";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { GetPrimaryPackageReference, InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "~/client/resources";
import { ActionExecutionLocation } from "~/client/resources/actionExecutionLocation";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { BundledToolsProperties } from "~/components/BundledTools/BundledToolsEditBase";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import Summary from "~/components/form/Sections/Summary";
import { CalloutType, default as Callout } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { StringRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
import type ActionProperties from "../../../client/resources/actionProperties";
import type { DataContext, MetadataTypeCollection } from "../../../client/resources/dynamicFormResources";
import { containerRegistryFeedTypes, FeedType } from "../../../client/resources/feedResource";
import { PackageAcquisitionLocation } from "../../../client/resources/packageAcquisitionLocation";
import { repository } from "../../../clientInstance";
import { default as CodeEditor, TextFormat } from "../../CodeEditor/CodeEditor";
import OpenDialogButton from "../../Dialog/OpenDialogButton";
import ConfigurationTransformsEdit from "../../Features/dotnetConfigurationTransforms/dotnetConfigurationTransforms";
import StructuredConfigurationVariablesEdit from "../../Features/structuredConfigurationVariables/structuredConfigurationVariables";
import SubstituteInFilesEdit from "../../Features/substituteInFiles/substituteInFiles";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import type { KeyedItemProps } from "../../KeyAccessProvider/types";
import SourceCodeDialog from "../../SourceCodeDialog";
import { VariableLookupText } from "../../form/VariableLookupText";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import pluginRegistry from "../pluginRegistry";
import type { ActionEditProps } from "../pluginRegistry";
import { AzureBundledToolsForCustomScriptsEdit } from "./azureBundledTools";

interface AzureAppServiceActionSummaryState {
    feedName: string;
}

class AzureAppServiceActionSummary extends BaseComponent<ActionSummaryProps, AzureAppServiceActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (
            <div>
                {"Deploy an Azure Web App with Zip Deploy"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

interface AzureAppServiceProperties extends BundledToolsProperties {
    "Octopus.Action.Azure.DeploymentType": string;

    "Octopus.Action.Azure.DeploymentSlot": string;
    "Octopus.Action.Azure.AppSettings": string;
    "Octopus.Action.Azure.ConnectionStrings": string;

    "Octopus.Action.EnabledFeatures": string;
    "Octopus.Action.SubstituteInFiles.TargetFiles": string;
    "Octopus.Action.SubstituteInFiles.OutputEncoding": string;
    "Octopus.Action.Package.JsonConfigurationVariablesTargets": string;
    "Octopus.Action.Package.AutomaticallyRunConfigurationTransformationFiles": string;
    "Octopus.Action.Package.AdditionalXmlConfigurationTransforms": string;
}

enum DeploymentType {
    Package = "Package",
    Container = "Container",
}

type AzureAppServiceEditProps = ActionEditProps<AzureAppServiceProperties>;
type AzureAppServiceEditInternalProps = AzureAppServiceEditProps & ActionWithFeeds & KeyedItemProps;

export const AzureAppServiceEdit: React.FC<AzureAppServiceEditProps> = (props) => {
    const itemKey = useKeyedItemAccess();

    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();

    return <AzureAppServiceEditInternal {...props} itemKey={itemKey} feeds={feeds} refreshFeeds={refreshFeeds} />;
};

class AzureAppServiceEditInternal extends BaseComponent<AzureAppServiceEditInternalProps> {
    constructor(props: AzureAppServiceEditInternalProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds, this.props.itemKey));

        const properties: ActionProperties = {};
        if (!this.props.properties["Octopus.Action.Azure.DeploymentType"]) {
            properties["Octopus.Action.Azure.DeploymentType"] = DeploymentType.Package;
        }
        // This was the first step where we decided to move away from showing the 'Configure Features' UI.
        // Instead, we hard-wire the enabled features here, and always the display the UI for them below
        properties["Octopus.Action.EnabledFeatures"] = "Octopus.Features.JsonConfigurationVariables,Octopus.Features.ConfigurationTransforms,Octopus.Features.SubstituteInFiles";
        this.props.setProperties(properties, true);
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);

        return (
            <div>
                <AzureBundledToolsForCustomScriptsEdit {...this.props} />
                <FormSectionHeading title="Deployment" />
                <ExpandableFormSection
                    errorKey="package"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds, this.props.itemKey)}
                    help={<span>Select the package containing your application.</span>}
                >
                    <StringRadioButtonGroup value={this.props.properties["Octopus.Action.Azure.DeploymentType"]} label="Deployment type" onChange={this.packageOrContainerImageChanged}>
                        <RadioButton value="Package" label="Deploy from a zip, Java WAR, or NuGet package" isDefault={true} />
                        <RadioButton value="Container" label="Deploy from a container image" />
                    </StringRadioButtonGroup>

                    <DeferredPackageSelector
                        feedType={this.availableFeedTypes()}
                        packageId={pkg.PackageId}
                        feedIdOrName={pkg.FeedId}
                        onPackageIdChange={this.packageIdChanged}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.props.feeds}
                        localNames={this.props.localNames}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Azure.DeploymentSlot" isExpandedByDefault={this.props.expandedByDefault} title="Deployment Slot" summary={this.slotSummary()} help={<span>Enter the slot to be deployed to</span>}>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Azure.DeploymentSlot"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.DeploymentSlot"]: x })}
                        label="Deployment Slot"
                    />
                    <Note>
                        Slots allow deploying different versions of your app to the same app service. <ExternalLink href="AzureAppServiceDeploymentSlot">Learn more</ExternalLink>.<br />
                        Octopus variable expressions can be used. e.g. <code>{`#{DeploymentSlot}`}</code>
                    </Note>
                    <Note>
                        Slots can also be configured on <ExternalLink href="AzureWebAppDeploymentTargets">Azure Web App deployment targets</ExternalLink>. If a slot is defined on both target and step, the target takes precedence.
                    </Note>
                </ExpandableFormSection>
                <FormSectionHeading title="App Service Configuration" />
                <ExpandableFormSection
                    errorKey="appSettings"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Application Settings"
                    summary={this.appSettingsSummary()}
                    help={<span>Provide App Service Application Settings you wish to be set</span>}
                >
                    <CodeEditor value={this.props.properties["Octopus.Action.Azure.AppSettings"]} language={TextFormat.JSON} allowFullScreen={false} readOnly={true} />
                    <OpenDialogButton
                        label={this.props.properties["Octopus.Action.Azure.AppSettings"] ? "Edit Source Code" : "Add Source Code"}
                        wideDialog={true}
                        renderDialog={(openProps) => (
                            <SourceCodeDialog
                                open={openProps.open}
                                close={openProps.closeDialog}
                                value={this.props.properties["Octopus.Action.Azure.AppSettings"]}
                                validate={this.validateTemplate}
                                autocomplete={[]}
                                saveDone={async (value) => {
                                    this.props.setProperties({ ["Octopus.Action.Azure.AppSettings"]: value });
                                }}
                                language={TextFormat.JSON}
                            />
                        )}
                    />
                    <Note>
                        The App Settings should be provided as JSON, using the same schema as the <ExternalLink href="AzureAppServiceAppSettingsBulkEdit">bulk edit in the Azure portal.</ExternalLink>&nbsp;Octopus variable-binding syntax may be used.
                    </Note>
                    <Note>
                        Example:
                        <br />
                        <code>
                            {`[`}
                            <br />
                            &nbsp;&nbsp;&nbsp;{"{"}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"name": "Greeting",`}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"value": "#{Greeting}",`}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"slotSetting": false`}
                            <br />
                            &nbsp;&nbsp;&nbsp;{`},`}
                            <br />
                            &nbsp;&nbsp;&nbsp;{`{`}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"name": "Environment",`}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"value": "#{Octopus.Environment.Name}",`}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"slotSetting": false`}
                            <br />
                            &nbsp;&nbsp;&nbsp;{`}`}
                            <br />
                            {`]`}
                        </code>
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="connectionStrings"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Connection Strings"
                    summary={this.connectionStringsSummary()}
                    help={<span>Provide connection strings you wish to be set</span>}
                >
                    <CodeEditor value={this.props.properties["Octopus.Action.Azure.ConnectionStrings"]} language={TextFormat.JSON} allowFullScreen={false} readOnly={true} />
                    <OpenDialogButton
                        label={this.props.properties["Octopus.Action.Azure.ConnectionStrings"] ? "Edit Source Code" : "Add Source Code"}
                        wideDialog={true}
                        renderDialog={(openProps) => (
                            <SourceCodeDialog
                                open={openProps.open}
                                close={openProps.closeDialog}
                                value={this.props.properties["Octopus.Action.Azure.ConnectionStrings"]}
                                validate={this.validateTemplate}
                                autocomplete={[]}
                                saveDone={async (value) => {
                                    this.props.setProperties({ ["Octopus.Action.Azure.ConnectionStrings"]: value });
                                }}
                                language={TextFormat.JSON}
                            />
                        )}
                    />
                    <Note>
                        The connection strings should be provided as JSON, using the same schema as the <ExternalLink href="AzureAppServiceAppSettingsBulkEdit">bulk edit in the Azure portal.</ExternalLink>&nbsp;Octopus variable-binding syntax may be
                        used.
                    </Note>
                    <Note>
                        Example:
                        <br />
                        <code>
                            {`[`}
                            <br />
                            &nbsp;&nbsp;&nbsp;{"{"}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"name": "AcmeDB",`}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"value": "#{Acme.Database.ConnectionString}",`}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"type": "SQLAzure",`}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`"slotSetting": false`}
                            <br />
                            &nbsp;&nbsp;&nbsp;{`}`}
                            <br />
                            {`]`}
                        </code>
                    </Note>
                </ExpandableFormSection>
                {this.configurationFileSections()}
            </div>
        );
    }

    packageOrContainerImageChanged = (deploymentType: string) => {
        this.props.setProperties({ ["Octopus.Action.Azure.DeploymentType"]: deploymentType });
        this.props.setPackages(
            SetPrimaryPackageReference(
                {
                    AcquisitionLocation: deploymentType === DeploymentType.Container ? PackageAcquisitionLocation.NotAcquired : PackageAcquisitionLocation.Server,
                },
                this.props.packages
            )
        );
    };

    packageIdChanged = (packageId: string) => {
        this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages));
    };

    private configurationFileSections() {
        // We can't offer configuration file capabilities for container images
        //
        const packageOrContainer = this.props.properties["Octopus.Action.Azure.DeploymentType"];
        if (packageOrContainer === DeploymentType.Container) {
            return "";
        }
        return (
            <div>
                <FormSectionHeading title="Configuration files" />
                <SubstituteInFilesEdit {...this.props} />
                <StructuredConfigurationVariablesEdit {...this.props} />
                <ConfigurationTransformsEdit {...this.props} />
            </div>
        );
    }

    private appSettingsSummary() {
        const appSettingsJson = this.props.properties["Octopus.Action.Azure.AppSettings"];
        if (!appSettingsJson || !appSettingsJson.trim()) {
            return Summary.placeholder("No application settings configured");
        }
        return Summary.summary("Application settings configured");
    }

    private connectionStringsSummary() {
        const connectionStringsJson = this.props.properties["Octopus.Action.Azure.ConnectionStrings"];
        if (!connectionStringsJson || !connectionStringsJson.trim()) {
            return Summary.placeholder("No connection strings configured");
        }
        return Summary.summary("Connection strings configured");
    }

    private availableFeedTypes() {
        const packageOrContainer = this.props.properties["Octopus.Action.Azure.DeploymentType"];
        switch (packageOrContainer) {
            case DeploymentType.Container:
                return containerRegistryFeedTypes();
            default:
                return [FeedType.BuiltIn, FeedType.Nuget, FeedType.Maven];
        }
    }

    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };

    private slotSummary() {
        const slot = this.props.properties["Octopus.Action.Azure.DeploymentSlot"];

        if (!slot) {
            return Summary.placeholder("No deployment slot configured");
        }

        return Summary.summary(slot);
    }

    validateTemplate = async (value: string) => {
        try {
            await this.getMetadata(value);
        } catch (err) {
            return err;
        }
        return null;
    };

    getMetadata = async (value: string): Promise<{ Metadata: MetadataTypeCollection; Values: DataContext }> => {
        return await repository.CloudTemplates.getMetadata(value, "AzureAppService");
    };
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzureAppService",
    summary: (properties, targetRolesAsCSV, packages) => <AzureAppServiceActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: AzureAppServiceEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => {
        return TargetRoles.Required;
    },
    hasPackages: (action) => true,
    getInitialProperties: () => {
        return {
            OctopusUseBundledTooling: "False",
        };
    },
});
