import MaterialDrawer from "@material-ui/core/Drawer";
import type { ReactNode } from "react";
import * as React from "react";
import ActionList from "../../../components/ActionList";
import { ActionButton, ActionButtonType } from "../../../components/Button";
import styles from "./style.module.less";

type Props = {
    children: ReactNode;
    open: boolean;
    actionName: string;
    onClose: () => void;
    onOkClick: () => void;
};

export const Drawer = ({ children, open, onClose, onOkClick, actionName }: Props) => {
    return (
        <MaterialDrawer classes={{ paper: styles.container }} anchor="right" open={open} onClose={onClose}>
            <header className={styles.header}>
                <h2 className={styles.title}>{actionName}</h2>
                <ActionList
                    actions={[
                        <ActionButton type={ActionButtonType.Secondary} label="Cancel" accessibleName={`Cancel ${actionName}`} onClick={onClose} />,
                        <ActionButton type={ActionButtonType.Primary} label="Ok" accessibleName={`Confirm ${actionName}`} onClick={onOkClick} />,
                    ]}
                />
            </header>

            <section className={styles.content}>{children}</section>
        </MaterialDrawer>
    );
};
