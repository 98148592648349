// This is in a separate file to avoid circular dependencies. Play carefully if you move these :)

import type { ActionTemplateResource, ChannelResource, EnvironmentResource, WorkerPoolResource } from "~/client/resources";
import type { TagIndex } from "~/components/tenantTagsets";
import type { EnvironmentOption, RunOn } from "./types";

export type FailedPermissionCheck = {
    type: "No Permission";
};

export function isFailedPermissionCheck<T>(value: T | FailedPermissionCheck | undefined | null): value is FailedPermissionCheck {
    if (value === undefined || value === null) {
        return false;
    }
    return "type" in value && value.type === "No Permission";
}

export interface ProcessStepLookupState {
    environments: EnvironmentResource[];
    machineRoles: string[];
    availableWorkerPools: WorkerPoolResource[];
    tagIndex: TagIndex;
    actionTemplate: ActionTemplateResource | null | FailedPermissionCheck;
    channels: ChannelResource[];
}

export interface ProcessStepActionState {
    pageTitle: string;
    isBuiltInWorkerEnabled: boolean;
    actionTypeName: string;
    runOn: RunOn | null;
    environmentOption: EnvironmentOption;
}
