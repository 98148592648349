/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { Permission } from "~/client/resources";
import type { ProjectResource, ReleaseResource, ChannelResource } from "~/client/resources";
import { repository } from "~/clientInstance";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import type { ResourceCollection } from "../../../../client/resources/resourceCollection";
import { Callout, CalloutType } from "../../../../primitiveComponents/dataDisplay/Callout/Callout";
import MissingProcessStepsMessage from "./MissingProcessStepsMessage";
import Onboarding from "./Onboarding";
import ReleasesTable from "./ReleasesTable";

interface ReleasesState extends DataBaseComponentState {
    project: ProjectResource;
    releases: ResourceCollection<ReleaseResource>;
    channels: ChannelResource[];
    hasDeploymentProcess: boolean;
    selectedChannel: string;
    versionFilter: string;
}

type ReleasesProps = RouteComponentProps<ProjectRouteParams>;

interface ReleasesPropsInternal extends ReleasesProps, WithProjectContextInjectedProps {
    dispatchAction: AnalyticActionDispatcher;
}

class ReleasesInternal extends DataBaseComponent<ReleasesPropsInternal, ReleasesState> {
    constructor(props: ReleasesPropsInternal) {
        super(props);
        this.state = {
            project: null!,
            releases: null!,
            channels: null!,
            hasDeploymentProcess: false,
            selectedChannel: null!,
            versionFilter: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project, projectContextRepository } = this.props.projectContext.state;
            await RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);
            const [releases, channels] = await Promise.all([repository.Projects.getReleases(project), repository.Projects.getChannels(project)]);

            const deploymentProc = !project.IsVersionControlled && (await projectContextRepository.DeploymentProcesses.get());
            const hasDeploymentProcess: boolean = deploymentProc && deploymentProc.Steps.length > 0;

            this.setState({
                project,
                releases,
                channels: channels.Items,
                hasDeploymentProcess,
            });
        });
    }

    isFiltering() {
        return !!this.state.selectedChannel || !!this.state.versionFilter;
    }

    renderBody() {
        if (!this.state.hasDeploymentProcess && !this.state.project?.IsVersionControlled) {
            return <MissingProcessStepsMessage project={this.state.project} />;
        }

        return this.isFiltering() || this.state.releases.Items.length > 0 ? (
            <ReleasesTable
                releases={this.state.releases}
                channels={this.state.channels}
                onChannelFilterChange={async (selectedChannel) => {
                    this.setState({ selectedChannel }, async () => {
                        await this.refreshReleases();
                    });
                }}
                onVersionFilterChange={async (versionFilter) => {
                    this.setState({ versionFilter }, async () => {
                        await this.refreshReleases();
                    });
                }}
                {...this.props}
            />
        ) : (
            <Onboarding project={this.state.project} />
        );
    }

    render() {
        return (
            <PaperLayout
                busy={this.state.busy}
                errors={this.errors}
                title="Releases"
                breadcrumbTitle={this.state.project?.Name}
                sectionControl={
                    (this.state.hasDeploymentProcess || this.state.project?.IsVersionControlled) && (
                        <PermissionCheck permission={Permission.ReleaseCreate} project={this.state.project && this.state.project.Id} tenant="*">
                            <NavigationButton
                                type={NavigationButtonType.Primary}
                                label="Create release"
                                href={`${this.props.match.url}/create`}
                                disabled={this.state.project && this.state.project.IsDisabled}
                                onClick={() => this.props.dispatchAction("Create a release", { action: Action.Add, resource: "Create Release" })}
                            />
                        </PermissionCheck>
                    )
                }
            >
                {this.state.project && this.state.project.IsDisabled && (
                    <Callout type={CalloutType.Warning} title="Warning">
                        This project is currently disabled, so releases cannot be created.
                    </Callout>
                )}
                {this.state.releases && this.renderBody()}
            </PaperLayout>
        );
    }

    private refreshReleases = async () => {
        const selectedChannel = this.state.selectedChannel;
        const searchByVersion = this.state.versionFilter;
        await this.doBusyTask(async () => {
            const releases = await (selectedChannel ? repository.Channels.getReleases(this.state!.channels.find((c) => c.Id === selectedChannel)!, { searchByVersion }) : repository.Projects.getReleases(this.state.project, { searchByVersion }));
            this.setState({ releases });
        });
    };
}

export function Releases(props: ReleasesProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useAnalyticActionDispatch(projectContext.state.model.Id);

    return <ReleasesInternal {...props} projectContext={projectContext} dispatchAction={dispatchAction} />;
}
