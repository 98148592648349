/* eslint-disable no-restricted-imports */

import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ActionButton, { ActionButtonType } from "~/components/Button";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";

interface IPanel {
    title: React.ReactNode;
    element: React.ReactElement;
}

export function usePanelStack<T>(initialPanel: T) {
    const [panels, setPanels] = React.useState<T[]>([initialPanel]);

    const openPanel = React.useCallback(
        (panel: T) => {
            setPanels((prev) => [...prev, panel]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setPanels]
    );

    const closePanel = React.useCallback(() => {
        setPanels((prev) => {
            if (prev.length > 1) {
                return prev.slice(0, prev.length - 1);
            }
            return prev;
        });
    }, [setPanels]);

    return {
        openPanel,
        closePanel,
        panels,
        panel: panels[panels.length - 1],
        canNavigateBack: panels.length > 1,
    };
}

export interface PanelActions<T> {
    closePanel: () => void;
    openPanel: (panel: T) => void;
}

export type PanelStackRenderProps<T> = PanelActions<T>;

export type RenderPanelDefinition = {
    title: React.ReactNode;
    element: React.ReactNode;
};

export interface PanelStackProps<T> {
    onOpenPanel: (panel: T) => void;
    onClosePanel: () => void;
    canNavigateBack: boolean;
    renderPanel: (panel: T) => RenderPanelDefinition;
    panel: T;
}

const useTitleBarStyles = makeStyles((theme) => ({
    root: (props: { octopusTheme: OctopusTheme }) => ({
        backgroundColor: props.octopusTheme.paper1,
        height: "48/16rem", // This matches our NavBar.navbar height
        display: "flex",
        flexDdirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `0.0625rem solid ${props.octopusTheme.divider}`,
    }),
    rowSpaced: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 1rem",
    },
    title: {
        flexGrow: 1,
        fontSize: "1.25rem",
        "& > h1, h2": {
            fontSize: "1.25rem",
            margin: 0,
            padding: 0,
        },
    },
}));

interface PanelStackTitleBarProps<T> extends PanelActions<T> {
    title: React.ReactNode;
    canNavigateBack: boolean;
}

function PanelStackTitleBar<T>(props: PanelStackTitleBarProps<T>) {
    const octopusTheme = useOctopusTheme();
    const classes = useTitleBarStyles({ octopusTheme });

    return (
        <div className={classes.root}>
            <div className={classes.rowSpaced}>
                <div className={classes.title}>
                    <ActionButton disabled={!props.canNavigateBack} onClick={props.closePanel} label={"Back"} type={ActionButtonType.Ternary} style={{ display: "inline-block" }} />
                    <h2>{props.title}</h2>
                </div>
            </div>
        </div>
    );
}

export function PanelStack<T>(props: PanelStackProps<T>) {
    const { element, title } = props.renderPanel(props.panel);

    return (
        <div style={{ flex: 1 }}>
            <PanelStackTitleBar canNavigateBack={props.canNavigateBack} title={title} closePanel={props.onClosePanel} openPanel={props.onOpenPanel} />
            {element}
        </div>
    );
}

export default PanelStack;
