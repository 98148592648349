import last from "lodash/last";
import React from "react";
import { useSelector } from "react-redux";
import v4 from "uuid/v4";
import type { Client } from "~/client";
import type IPageWrapper from "../../../../utils/pageId";
import { pageConfigSelector } from "../../../PageLayout/reducers/pageLayoutArea";
import type { PageSessionId, TrackedRequest } from "./types";

export type TrackedRequestsForPageSession = {
    pageSessionId: PageSessionId;
    page: IPageWrapper;
    trackedRequests: ReadonlyArray<TrackedRequest>;
};

export const useMiniProfilerTrackedRequests = (client: Client, enabled: boolean) => {
    const [trackedRequests, setTrackedRequests] = React.useState<TrackedRequestsForPageSession[]>([]);
    const { page } = useSelector(pageConfigSelector);

    React.useEffect(() => {
        return client.subscribeToResponses("miniProfiler", (details) => {
            if (details.miniProfileId.length > 0) {
                if (enabled) {
                    setTrackedRequests((prev) => {
                        const trackedRequest = { originalRequestUrl: details.url, profilerId: details.miniProfileId[details.miniProfileId.length - 1] };
                        const lastPage = last(prev);

                        if (!lastPage || lastPage.page.Id !== page.Id) {
                            return [...prev, { pageSessionId: v4(), page: { ...page }, trackedRequests: [trackedRequest] }];
                        }

                        return [...prev.slice(0, prev.length - 1), { ...lastPage, trackedRequests: [...lastPage.trackedRequests, trackedRequest] }];
                    });
                }
            }
        });
    }, [client, enabled, page]);

    React.useEffect(() => console.log(trackedRequests), [trackedRequests]);

    return { trackedRequests, clearTrackedRequests: () => setTrackedRequests([]) };
};
