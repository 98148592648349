import type { ProjectResource, GitBranchResource } from "~/client/resources";
import type { Client } from "../client";
import { BranchesRepository } from "./branchesRepository";
import { DeploymentProcessRepository } from "./deploymentProcessRepository";
import DeploymentSettingsRepository from "./deploymentSettingsRepository";
import { VcsRunbookRepository } from "./vcsRunbookRepository";

class ProjectContextRepository {
    DeploymentProcesses: DeploymentProcessRepository;
    DeploymentSettings: DeploymentSettingsRepository;
    Runbooks: VcsRunbookRepository;
    Branches: BranchesRepository;

    constructor(client: Client, project: ProjectResource, branch: GitBranchResource | undefined) {
        this.DeploymentProcesses = new DeploymentProcessRepository(client, project, branch);
        this.DeploymentSettings = new DeploymentSettingsRepository(client, project, branch);
        this.Runbooks = new VcsRunbookRepository(client, project, branch);
        this.Branches = new BranchesRepository(client);
    }
}

export default ProjectContextRepository;
