import * as React from "react";
import type { ProjectOrSummaryResource } from "~/client/resources";
import { ChipIcon } from "~/components/Chips";
import { MissingChip, ProjectChip } from "~/components/Chips/index";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FormFieldProps } from "~/components/form";
import type { ComponentProps } from "~/utils/types";
import Lookup from "../Lookup";

interface ProjectMultiSelectProps extends FormFieldProps<string[]> {
    items: ProjectOrSummaryResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}

const ProjectTypedMultiSelect = MultiSelect<ProjectOrSummaryResource>();
type RenderChip = ComponentProps<typeof ProjectTypedMultiSelect>["renderChip"];

export const ProjectMultiSelect: React.FC<ProjectMultiSelectProps> = (props) => {
    const chipRenderer: RenderChip = (r, onRequestDelete: () => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <ProjectChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} project={item} />}
                renderFallback={<MissingChip type={ChipIcon.Project} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };
    return <ProjectTypedMultiSelect fieldName="projects" renderChip={chipRenderer} accessibleName={props.accessibleName} {...props} items={props.items} />;
};
