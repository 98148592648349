import type { Dispatch, SetStateAction } from "react";
import { useState, useCallback, useEffect } from "react";
import Logger from "~/client/logger";

function setLocalStorageValue(key: string, value: string) {
    try {
        window.localStorage.setItem(key, value);
    } catch (error) {
        Logger.warn(error);
    }
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            Logger.warn(error);
            return initialValue;
        }
    });

    const setValue: SetValue<T> = (value) => {
        if (typeof value === "function") {
            setStoredValue((prev) => {
                //We need to cast here since there is a bug in typescript around generics and type guards
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const next = (value as (value: T) => T)(prev);
                setLocalStorageValue(key, JSON.stringify(next));
                return next;
            });
        } else {
            const valueToStore = value;
            setStoredValue(valueToStore);
            setLocalStorageValue(key, JSON.stringify(valueToStore));
        }
    };

    const updateStoredValueFromOtherStorageEvents = useCallback(
        (event: StorageEvent) => {
            if (event.storageArea === localStorage) {
                if (event.key === key) {
                    if (event.newValue) {
                        setStoredValue(JSON.parse(event.newValue));
                    }
                }
            }
        },
        [key]
    );

    // Storage events don't get fired in the page that raised them.
    // We therefore make sure our local state in other tabs are reflective
    // of what has been changed in local storage in other tabs.
    useEffect(() => {
        window.addEventListener("storage", updateStoredValueFromOtherStorageEvents, true);
        return () => window.removeEventListener("storage", updateStoredValueFromOtherStorageEvents, true);
    }, [updateStoredValueFromOtherStorageEvents]);

    return [storedValue, setValue];
}

export default useLocalStorage;
export { useLocalStorage };
