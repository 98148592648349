import React = require("react");
import { Summary } from "~/components/form";

export function pluraliseSummary(count: number, prefix: string, used: string, notUsed: string, type: string, notVisibleCount: number) {
    const plural = count + notVisibleCount === 1 ? "" : "s";
    const suffix = notVisibleCount > 0 ? `, ${notVisibleCount} of which you do not have permission to see` : "";
    return count > 0
        ? Summary.summary(
              <span>
                  {prefix} {used} used in <b>{count + notVisibleCount}</b> {type}
                  {plural}
                  {suffix}
              </span>
          )
        : notVisibleCount > 0
        ? Summary.placeholder(`${prefix} ${used} used in ${notVisibleCount} ${type}${notVisibleCount === 1 ? "" : "s"} that you do not have permission to see`)
        : Summary.placeholder(`${prefix} ${notUsed} used in any ${type}s${suffix}`);
}

export function pluraliseHelp(count: number, prefix: string, used: string, notUsed: string, type: string, notVisibleCount: number) {
    const plural = count === 1 ? "" : "s";
    const suffix = notVisibleCount > 0 ? `, as well as ${notVisibleCount} ${type}${notVisibleCount === 1 ? "" : "s"} that you do not have permission to see` : "";
    return count > 0
        ? `${prefix} ${used} used in the following ${type}${plural}${suffix}`
        : notVisibleCount > 0
        ? `${prefix} ${used} used in ${notVisibleCount} ${type}${notVisibleCount === 1 ? "" : "s"} that you do not have permission to see`
        : `${prefix} ${notUsed} used in any ${type}s${suffix}`;
}
