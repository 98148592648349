/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DynamicExtensionsFeaturesMetadataResource } from "~/client/resources/dynamicExtensionsFeaturesMetadataResource";
import type { DynamicExtensionsScriptsResource } from "~/client/resources/dynamicExtensionsScriptsResource";
import ScriptAdapter from "./scriptAdapter";

export type IOctopusEvent = {};

export enum OctopusEventName {
    HttpRequest = "HttpRequest",
    HttpResponse = "HttpResponse",
    HttpErrorResponse = "HttpErrorResponse",
}

export interface OctopusHttpRequestEvent extends IOctopusEvent {
    correlationId: number;
    url: string;
    method: string;
}

export interface OctopusHttpResponseEvent extends IOctopusEvent {
    correlationId: number;
    url: string;
    method: string;
    statusCode: number;
}

export interface OctopusHttpErrorResponseEvent extends OctopusHttpResponseEvent {
    correlationId: number;
    errorMessage: string;
    errors: string[];
}

export type OctopusEvent = OctopusHttpRequestEvent | OctopusHttpResponseEvent | OctopusHttpErrorResponseEvent;

export type EventHandler = (arg: OctopusEvent) => void;

export class OctopusEventSubscriptions {
    handlers: EventHandler[] = [];

    public publish(octopusEvent: OctopusEvent): void {
        this.handlers.map((handler) => {
            try {
                handler(octopusEvent);
            } catch (e) {
                console.error("Error while handling event:");
                console.error(octopusEvent);
                console.error(e);
            }
        });
    }

    public subscribe(handler: EventHandler): () => void {
        this.handlers.push(handler);
        return () => this.unsubscribe(handler);
    }

    unsubscribe(handler: EventHandler): void {
        const handlerIndex = this.handlers.indexOf(handler);
        if (handlerIndex < 0) {
            return;
        }
        this.handlers = this.handlers.splice(handlerIndex, 1);
    }
}

class OctopusDynamicExtensions {
    subscriptions: { [key: string]: OctopusEventSubscriptions } = {};
    scripts: DynamicExtensionsScriptsResource = undefined!;
    metadata: DynamicExtensionsFeaturesMetadataResource = undefined!;
    apiDocumentRootUrl: string = undefined!;

    public publishEvent<TEvent extends OctopusEvent>(eventName: OctopusEventName, octopusEvent: TEvent): void {
        if (this.subscriptions[eventName]) {
            this.subscriptions[eventName].publish(octopusEvent);
        }
    }

    public setConfiguration(settings: DynamicExtensionsScriptsResource, metadata: DynamicExtensionsFeaturesMetadataResource): void {
        this.scripts = settings;
        this.metadata = metadata;
    }

    public setApiDocumentRootUrl(url: string): void {
        this.apiDocumentRootUrl = url;
    }

    public createScriptAdapter(): ScriptAdapter {
        return new ScriptAdapter(this.subscriptions, this.scripts, this.metadata, this.apiDocumentRootUrl);
    }
}

const octopusDynamicExtensions = new OctopusDynamicExtensions();

export { octopusDynamicExtensions };
export default octopusDynamicExtensions;
