import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import type { GoogleCloudAuthenticationProperties } from "~/components/Actions/googleCloud/googleCloudAuthenticationComponent";
import GoogleCloudAuthenticationComponent from "~/components/Actions/googleCloud/googleCloudAuthenticationComponent";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import Note from "../../../primitiveComponents/form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink";
import { SupportedLanguage } from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";
import { FormSection, FormSectionHeading } from "../../form";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import type { ScriptProperties } from "../script/scriptAction";
import { ScriptActionEdit } from "../script/scriptAction";

type GoogleCloudScriptActionEditState = {};

interface GoogleCloudRunScriptProperties extends ScriptProperties, GoogleCloudAuthenticationProperties {}

class GoogleCloudRunScriptActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>Run a script with the gcloud CLI.</div>;
    }
}

export class GoogleCloudRunScriptActionEdit extends BaseComponent<ActionEditProps<GoogleCloudRunScriptProperties, ScriptPackageProperties>, GoogleCloudScriptActionEditState> {
    constructor(props: ActionEditProps<GoogleCloudRunScriptProperties, ScriptPackageProperties>) {
        super(props);
    }

    render() {
        return (
            <div>
                <FormSection title="Google Tools" help="This step uses tooling pre-installed on the worker" includeBorder={true}>
                    <Note>
                        Scripts executed in this step may need to use the Google Cloud CLI to authenticate to Google or perform other actions. Learn more about <ExternalLink href="GoogleTools">Google Tools</ExternalLink>.
                    </Note>
                </FormSection>

                <FormSectionHeading title="Google Cloud Platform" />

                <GoogleCloudAuthenticationComponent
                    projectId={this.props.projectId}
                    properties={this.props.properties}
                    packages={this.props.packages}
                    plugin={this.props.plugin}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    getFieldError={this.props.getFieldError}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                />

                <ScriptActionEdit
                    plugin={this.props.plugin}
                    projectId={this.props.projectId}
                    packages={this.props.packages}
                    localNames={this.props.localNames}
                    properties={this.props.properties}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    getFieldError={this.props.getFieldError}
                    supportedLanguages={SupportedLanguage.PowerShellAndBash}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                    parameters={this.props.parameters}
                />
            </div>
        );
    }
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.GoogleCloudScripting",
    summary: (properties, targetRolesAsCSV) => <GoogleCloudRunScriptActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: GoogleCloudRunScriptActionEdit,
    canHaveChildren: () => true,
    canBeChild: true,
    targetRoleOption: () => TargetRoles.Optional,
    hasPackages: () => false,
    getInitialProperties: () => ({
        "Octopus.Action.GoogleCloud.UseVMServiceAccount": "True",
        "Octopus.Action.GoogleCloud.ImpersonateServiceAccount": "False",
    }),
    features: {
        optional: ["Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
});
