import type { AccountResource, ActionTemplateParameterResource } from "~/client/resources";
import type { FeedResource } from "~/client/resources/feedResource";
import type { PackageReference } from "~/client/resources/packageReference";
import type { NameOrIdKey } from "~/components/KeyAccessProvider/types";

export type SetPackages = (packages: (previous: Array<PackageReference>) => Array<PackageReference>) => void;

interface CommonStepInputDependencies {
    setPackages: SetPackages;
    feeds: FeedResource[];
    refreshFeeds: () => Promise<void>;
    itemKey: NameOrIdKey;
    accounts: AccountResource[];
    refreshAccounts: () => Promise<unknown>;
}

export interface ProjectStepInputDependencies extends CommonStepInputDependencies {
    projectId: string;
}

export interface ActionTemplateStepInputDependencies extends CommonStepInputDependencies {
    parameters: ActionTemplateParameterResource[];
    localNames: string[];
}

export type StepInputDependencies = ProjectStepInputDependencies | ActionTemplateStepInputDependencies;

export function isProjectStepInputDependencies(dependencies: StepInputDependencies): dependencies is ProjectStepInputDependencies {
    const key: keyof ProjectStepInputDependencies = "projectId";
    return key in dependencies;
}
