import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useAccountsFromContext, useRefreshAccountsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessAccountsContextProvider";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { RunOn } from "~/areas/projects/components/Process/types";
import { useProjectContext } from "~/areas/projects/context";
import type { IProcessResource } from "~/client/resources";
import type ActionProperties from "~/client/resources/actionProperties";
import type { PackageReference } from "~/client/resources/packageReference";
import type StepPackageInputs from "~/client/resources/stepPackageInputs";
import { ActionPropertiesEditor } from "~/components/ActionPropertiesEditor/ActionPropertiesEditor";
import { enforceNewActionFeatures } from "~/components/ActionPropertiesEditor/enforceNewActionFeatures";
import type { ActionPlugin, AdditionalActions } from "~/components/Actions/pluginRegistry";
import type { Errors } from "~/components/DataBaseComponent";
import { useKeyedItemAccess } from "~/components/KeyAccessProvider/KeyedItemAccessProvider";
import type { ProjectStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";

interface ProjectActionEditorProps {
    projectId: string;
    plugin: ActionPlugin;
    inputs: StepPackageInputs;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    runOn: RunOn;
    additionalActions: AdditionalActions;
    errors: Errors | undefined; // only used for shouldComponentUpdate
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    getFieldError(field: string): string;
    setInputs(inputs: StepPackageInputs, callback?: () => void): void;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshRunOn(): void;
    getProcessResource(): Readonly<IProcessResource>;
    isNew: boolean;
}

export function ProjectActionPropertiesEditor(props: ProjectActionEditorProps) {
    const { setPackages, packages } = props;
    const projectContext = useProjectContext();
    useEffect(() => {
        const properties = { ...props.properties };

        const newActionFeatures = enforceNewActionFeatures(props.plugin, properties, props.isNew, false);
        if (newActionFeatures.length > 0) {
            // We don't assign empty features, or this causes existing steps to trigger the "unsaved changes" dialog.
            properties["Octopus.Action.EnabledFeatures"] = newActionFeatures;
        }

        props.setProperties(properties, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const projectId = projectContext.state.model.Id;

    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const itemKey = useKeyedItemAccess();
    const accounts = useAccountsFromContext();
    const refreshAccounts = useRefreshAccountsFromContext();
    const stableSetPackages = useCallback((getUpdatedPackages: (prev: Array<PackageReference>) => Array<PackageReference>) => setPackages(getUpdatedPackages(packages)), [packages, setPackages]);
    const stableRefreshFeeds = useCallback(async () => {
        await refreshFeeds();
    }, [refreshFeeds]);
    const stableRefreshAccounts = useCallback(async () => {
        await refreshAccounts();
    }, [refreshAccounts]);
    const inputDependencies = useMemo<ProjectStepInputDependencies>(
        () => ({
            projectId,
            feeds,
            refreshFeeds: stableRefreshFeeds,
            setPackages: stableSetPackages,
            itemKey,
            accounts,
            refreshAccounts: stableRefreshAccounts,
        }),
        [feeds, itemKey, projectId, stableRefreshFeeds, stableSetPackages, accounts, stableRefreshAccounts]
    );

    if (!props.plugin.edit) {
        throw new Error(`Plugin ${props.plugin.actionType} is missing 'Edit' property`);
    }

    return (
        <ActionPropertiesEditor
            inputDependencies={inputDependencies}
            plugin={props.plugin}
            inputs={props.inputs}
            properties={props.properties}
            packages={props.packages}
            doBusyTask={props.doBusyTask}
            busy={props.busy}
            runOn={props.runOn}
            setInputs={props.setInputs}
            setProperties={props.setProperties}
            setPackages={props.setPackages}
            additionalActions={props.additionalActions}
            getFieldError={props.getFieldError}
            errors={props.errors}
            expandedByDefault={props.expandedByDefault}
            refreshRunOn={props.refreshRunOn}
            getProcessResource={props.getProcessResource}
        />
    );
}
