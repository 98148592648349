/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { SpaceScopedResource } from "~/client/resources/spaceScopedResource";
import { typeSafeHasOwnProperty } from "../utils";
import type { ReleasePackageVersionBuildInformationResource } from "./packageResource";
import type ResourceWithId from "./resource";
import type { RunbookSnapshotResource } from "./runbookSnapshotResource";
import type { IHaveGitReference } from "./versionControlledResource";

export interface SelectedPackage {
    ActionName: string;
    PackageReferenceName?: string;
    Version: string;
}

// TODO: Add concrete links
export interface ReleaseResource extends ISnapshotResource, SpaceScopedResource, ResourceWithId {
    ChannelId: string;
    Version: string;
    ReleaseNotes: string;
    IgnoreChannelRules: boolean;
    ProjectDeploymentProcessSnapshotId: string;
    BuildInformation: ReleasePackageVersionBuildInformationResource[];
}

export interface ISnapshotResource extends ResourceWithId, IHaveGitReference {
    Assembled: string;
    ProjectId: string;
    ProjectVariableSetSnapshotId: string;
    LibraryVariableSetSnapshotIds: string[];
    SelectedPackages: SelectedPackage[];
}

export function isReleaseResource(resource: ISnapshotResource): resource is NonNullable<ReleaseResource> {
    if (resource === undefined || resource === null) {
        return false;
    }

    const converted = resource as ReleaseResource;
    return converted.Version !== undefined && typeSafeHasOwnProperty(converted, "Version");
}

export function isRunbookSnapshotResource(resource: ISnapshotResource): resource is NonNullable<RunbookSnapshotResource> {
    if (resource === undefined || resource === null) {
        return false;
    }

    const converted = resource as RunbookSnapshotResource;
    return converted.Name !== undefined && typeSafeHasOwnProperty(converted, "Name");
}
