import React from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import { HasVersionControlledPersistenceSettings } from "~/client/resources";
import { toGitBranch } from "~/client/resources/versionControlledResource";
import type { GitReference } from "~/client/resources/versionControlledResource";
import ActionButton, { ActionButtonType } from "~/components/Button";
import { Note } from "~/components/form";
import GitRefSelector from "../../GitRefSelector/GitRefSelector";

interface Props {
    value?: GitReference;
    onChange: (gitRef: GitReference) => void;
    canResetToDefaultBranch?: boolean;
    disabled?: boolean;
}

const GitRefFormSection: React.FC<Props> = ({ disabled, value, onChange, canResetToDefaultBranch }) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const dispatchAction = useAnalyticSimpleActionDispatch();
    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");

    const defaultBranch = toGitBranch(project.PersistenceSettings.DefaultBranch);
    const initialVcsRef = {
        GitRef: defaultBranch,
    };
    const [gitRef, setGitRef] = React.useState<GitReference>(value ?? initialVcsRef);

    const setValue = (value: GitReference) => {
        setGitRef(value);
        onChange(value);
    };

    const handleOnClick = () => {
        dispatchAction("Reset to Default Branch");
        setValue(initialVcsRef);
    };

    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    return (
        <>
            <GitRefSelector style={"white"} key={`${gitRef.GitRef}-${gitRef.GitCommit}`} selectedGitRef={gitRef} onChange={setValue} projectContext={projectContext} disabled={disabled} />
            {canResetToDefaultBranch && (
                <>
                    <ActionButton label={`Reset to ${project.PersistenceSettings.DefaultBranch} (Default) Branch`} type={ActionButtonType.Ternary} disabled={gitRef === defaultBranch || disabled} onClick={handleOnClick} />
                    <Note>This release will snapshot the deployment process from the head of the branch selected.</Note>
                </>
            )}
        </>
    );
};

export default GitRefFormSection;
