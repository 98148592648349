/* eslint-disable no-restricted-imports */

import FlatButton from "material-ui/FlatButton";
import React from "react";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";
import styles from "./styles.module.less";
import type { SharedButtonProps } from "./types";

interface IconButtonWithToolTipProps extends SharedButtonProps {
    icon: JSX.Element;
}

export const IconButtonWithToolTip: React.FC<IconButtonWithToolTipProps> = ({ toolTipContent, disabled, tabIndex, onClick, buttonRef, style, icon }) => {
    return (
        <ToolTip content={toolTipContent}>
            <FlatButton className={styles.materialUIIcon} disabled={disabled} tabIndex={tabIndex} onClick={onClick} ref={buttonRef} label={icon} style={style} />
        </ToolTip>
    );
};

export default IconButtonWithToolTip;
