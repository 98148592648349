/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { sortBy } from "lodash";
import * as React from "react";
import type { ProcessType } from "~/client/resources";
import { ProcessTypeAliasMap } from "~/client/resources";
import type { Form, VariableValue } from "../../../../../client/resources/form";
import FormResourceInput from "../../../../../components/FormResourceInput/FormResourceInput";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import Summary from "../../../../../components/form/Sections/Summary";

interface PromptVariablesProps {
    form: Form;
    onParameterChanged(info: any): any;
    processType: ProcessType;
}

const PromptVariables: React.StatelessComponent<PromptVariablesProps> = (props) => {
    return (
        <div>
            {props.form && props.form.Elements.length > 0 && (
                <ExpandableFormSection title="Parameters" errorKey="parameters" isExpandedByDefault={true} help={`Parameters will apply to all ${ProcessTypeAliasMap[props.processType].alias.plural}`} summary={buildSummary()}>
                    <div>
                        {sortBy(props.form.Elements, [(x) => (x.Control as VariableValue).Label || x.Name], "asc").map((element, idx) => (
                            <FormResourceInput
                                key={idx}
                                element={element}
                                onButtonSelected={() => console.error("Buttons not expected here")}
                                onChanged={(value) => props.onParameterChanged({ VariableName: element.Name, Value: value })}
                                value={props.form.Values[element.Name]}
                            />
                        ))}
                    </div>
                </ExpandableFormSection>
            )}
        </div>
    );

    function buildSummary() {
        const numOfParams = props.form.Elements ? props.form.Elements.length : 0;
        const count = props.form.Elements.filter((e) => props.form.Values[e.Name]).length;
        return count > 0
            ? Summary.summary(
                  <div>
                      {numOfParams} {numOfParams > 1 ? "parameters" : "parameter"} in total, {count} provided.
                  </div>
              )
            : Summary.placeholder("Enter the parameter values");
    }
};
export default PromptVariables;
