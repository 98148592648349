import * as React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import type { Dispatch, Action } from "redux";
import { actions as dialogActions, selectors as dialogSelectors } from "./store";
import withDialogIdentifier from "./withDialogIdentifier";

interface DialogTriggerRenderProps {
    open: boolean;
    sequence: string;
    closeDialog(): void;
    openDialog(): void;
}

interface DialogTriggerProps {
    render: (props: DialogTriggerRenderProps) => React.ReactNode;
}

class DialogTrigger extends React.PureComponent<DialogTriggerProps & DialogTriggerRenderProps> {
    constructor(props: DialogTriggerProps & DialogTriggerRenderProps) {
        super(props);
    }

    render() {
        const { open, closeDialog, openDialog, sequence, render } = this.props;
        return render({ open, closeDialog, openDialog, sequence });
    }

    open() {
        this.props.openDialog();
    }
}

const mapGlobalStateToProps = (state: GlobalState, { sequence }: { sequence: string }) => ({
    open: dialogSelectors.createDialogOpenSelector(sequence)(state),
    sequence,
});

const mapDialogTriggerDispatchProps = (dispatch: Dispatch<Action>, { sequence }: { sequence: string }) => ({
    openDialog: () => dispatch(dialogActions.open(sequence)),
    closeDialog: () => dispatch(dialogActions.closeAll()),
});

type EnhancedTriggerProps = Pick<DialogTriggerProps, "render"> & Partial<DialogTriggerProps>;

function enhancer<T>(comp: React.ComponentType<T & DialogTriggerRenderProps>): React.ComponentType<T & Partial<DialogTriggerRenderProps>> {
    return compose<T & DialogTriggerRenderProps, T & Partial<DialogTriggerRenderProps>>(withDialogIdentifier(), connect(mapGlobalStateToProps, mapDialogTriggerDispatchProps))(comp);
}

export { enhancer as withDialogTriggers, EnhancedTriggerProps, DialogTriggerProps, DialogTriggerRenderProps, DialogTrigger };

const EnhancedDialogTrigger = enhancer(DialogTrigger);

export default EnhancedDialogTrigger;
