/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion */

import * as _ from "lodash";
import * as React from "react";
import type { EnvironmentResource, LifecycleResource, PhaseResource } from "~/client/resources";
import { DeployNewReleaseActionResource } from "~/client/resources";
import { EnvironmentChip, ChipIcon } from "~/components/Chips";
import LookupResourceChipComponent from "~/components/LookupResourceChip";
import { ExpandableFormSection, Select, Summary } from "~/components/form";

interface DeployNewReleaseActionEditorProps {
    action: DeployNewReleaseActionResource;
    allEnvironments: EnvironmentResource[];
    lifecycle: LifecycleResource;
    onActionChange(action: DeployNewReleaseActionResource): void;
}

interface DeployNewReleaseActionEditorState {
    destinationEnvironmentId: string;
}

//eslint-disable-next-line react/no-unsafe
export class DeployNewReleaseActionEditor extends React.Component<DeployNewReleaseActionEditorProps, DeployNewReleaseActionEditorState> {
    channelNameMap: any = {};
    environmentNameMap: any = {};
    phaseEnvironments: string[] = [];

    constructor(props: DeployNewReleaseActionEditorProps) {
        super(props);

        props.allEnvironments.forEach((env) => {
            this.environmentNameMap[env.Id] = env.Name;
        });

        this.phaseEnvironments = this.getEnvironmentsWithSimulatedReleaseProgression(props.lifecycle.Phases);

        this.state = this.initState(this.props.action);
    }

    UNSAFE_componentWillReceiveProps(newProps: DeployNewReleaseActionEditorProps) {
        newProps.allEnvironments.forEach((env) => {
            this.environmentNameMap[env.Id] = env.Name;
        });

        this.phaseEnvironments = this.getEnvironmentsWithSimulatedReleaseProgression(newProps.lifecycle.Phases);

        if (this.props.action.EnvironmentId !== newProps.action.EnvironmentId) {
            this.setState(this.initState(newProps.action));
        }
    }

    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="DestinationEnvironment" title="Destination environment" focusOnExpandAll summary={this.buildDestinationEnvironmentSummary()} help="The environment to deploy the created release to.">
                    <Select
                        items={this.phaseEnvironments.map((e) => {
                            return { value: e, text: this.environmentNameMap[e] };
                        })}
                        value={this.state.destinationEnvironmentId}
                        onChange={this.onDestinationEnvironmentChange}
                        sortItems={false}
                    />
                </ExpandableFormSection>
            </div>
        );
    }

    initState(value?: DeployNewReleaseActionResource) {
        const action = value || new DeployNewReleaseActionResource();
        return {
            destinationEnvironmentId: action.EnvironmentId,
        };
    }

    private getEnvironmentsWithSimulatedReleaseProgression(phases: PhaseResource[]): string[] {
        const firstPhase = phases[0];
        const remainingPhases: PhaseResource[] = [];
        for (const p of phases.slice(1)) {
            // because we don't have a release, we can't get it's 'Progression' calculated from server side,
            // so lets just do better than suggesting only the 'firstPhase'
            // walk the phases till we get to the non-optional one, include it in the list
            remainingPhases.push(p);
            if (!p.IsOptionalPhase) {
                break;
            }
        }

        return _.flatten([
            // seed it with the first phase
            ...firstPhase.AutomaticDeploymentTargets,
            ...firstPhase.OptionalDeploymentTargets,
            // populate with the rest of the phases worked out above
            ...remainingPhases.map((p) => [...p.AutomaticDeploymentTargets, ...p.OptionalDeploymentTargets]),
        ]);
    }

    private environmentChip = (id: string) => {
        const LookupEnvironmentChip = LookupResourceChipComponent<EnvironmentResource>();

        return <LookupEnvironmentChip lookupCollection={this.props.allEnvironments} key={id} lookupId={id} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name} />} />;
    };

    private buildDestinationEnvironmentSummary = () => {
        return this.state.destinationEnvironmentId
            ? Summary.summary(<span>New release will be deployed to the {this.environmentChip(this.state.destinationEnvironmentId)} environment</span>)
            : Summary.placeholder("No destination environment selected");
    };

    private onDestinationEnvironmentChange = (environmentId: string | undefined) => {
        this.setState(
            {
                destinationEnvironmentId: environmentId!,
            },
            () => this.raiseChange()
        );
    };

    private raiseChange = () => {
        this.props.onActionChange({
            ...this.props.action,
            EnvironmentId: this.state.destinationEnvironmentId,
        });
    };
}
