import React from "react";
import { useOctopusTheme } from "~/components/Theme";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import styles from "./ProcessActionErrorIndicator.module.less";

interface ProcessActionErrorIndicatorProps {
    actionErrors: string[];
}

export const ProcessActionErrorIndicator: React.FC<ProcessActionErrorIndicatorProps> = ({ actionErrors }) => {
    const theme = useOctopusTheme();
    const items = actionErrors.map((x) => <li>{x}</li>);

    return (
        <ToolTip content={<ul className={styles.itemContainer}>{items}</ul>}>
            <div className={styles.indicatorContainer}>
                <em className="fa fa-exclamation-triangle" style={{ color: theme.danger }} />
            </div>
        </ToolTip>
    );
};
