/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import type { ErrorInfo } from "react";
import { useLocation } from "react-router";
import * as StackTrace from "stacktrace-js";
import Logger from "~/client/logger";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { client } from "../../clientInstance";

type StackFrame = StackTrace.StackFrame;

interface ErrorBoundaryState {
    error?: {
        originalError: Error;
        info: ErrorInfo;
        mappedStackTrace?: string;
    };
}

interface ErrorBoundProps {
    children: any;
    pathname?: string;
}

class ErrorBoundaryInternal extends React.Component<ErrorBoundProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundProps) {
        super(props);
        this.state = {};
    }

    async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Logger.error(error);
        Logger.error(errorInfo);

        this.setState({ error: { originalError: error, info: errorInfo } });
        await this.setStackTraceFromOriginalSourceCode(error);
    }

    componentDidUpdate(previousProps: ErrorBoundProps) {
        if (previousProps.pathname !== this.props.pathname) {
            this.setState({ error: undefined });
        }
    }

    render() {
        if (this.state.error) {
            const serverInfo = client.tryGetServerInformation();
            const version = serverInfo ? serverInfo.version : undefined;
            return <ErrorPanel message={`An unexpected error occurred in Octopus v${version}: "${this.state.error.originalError.name}: ${this.state.error.originalError.message}"`} errors={this.state.error.info.componentStack.split("\n").slice(1)} />;
        }
        return this.props.children;
    }

    private async setStackTraceFromOriginalSourceCode(error: Error) {
        const frames: StackFrame[] = await StackTrace.fromError(error);
        const mappedStackTrace = error.stack?.split("\n")[0] + "\n\n" + frames.map(this.stringify).join("\n");
        this.setState((prevState) => ({
            ...prevState,
            error: { ...prevState.error!, mappedStackTrace },
        }));
    }

    private stringify = (frame: StackFrame) => {
        const normalizedFrame = {
            ...frame,
            fileName: this.normalizeFileName(frame.fileName),
        };
        return `${normalizedFrame.functionName} (${normalizedFrame.fileName}:${normalizedFrame.lineNumber}:${normalizedFrame.columnNumber})`;
    };

    private normalizeFileName(fileName: string) {
        const index = Math.max(fileName.indexOf("/app"), fileName.indexOf("/node_modules"));
        if (index === -1) {
            return fileName;
        }

        return fileName.substr(index);
    }
}

export const RouteAwareErrorBoundary: React.FC<ErrorBoundProps> = (props) => {
    const location = useLocation();
    return <ErrorBoundaryInternal pathname={location.pathname} {...props} />;
};

export default ErrorBoundaryInternal;
