/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { Dictionary } from "lodash";
import { cloneDeep, isEmpty, flatten, map, reduce, slice, take } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, AnalyticView, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { EnvironmentResource, VariablesScopedToEnvironmentResponse } from "~/client/resources";
import type { EnvironmentSettingsMetadata, ExtensionSettingsValues } from "~/client/resources/extensionSettingsValues";
import Permission from "~/client/resources/permission";
import { repository } from "~/clientInstance";
import { NavigationButton } from "~/components/Button";
import DynamicForm from "~/components/DynamicForm/DynamicForm";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import Markdown from "~/components/Markdown";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PermissionCheck } from "~/components/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, ExpandableFormSection, Summary, required, Checkbox, FormSectionHeading } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

interface EnvironmentModel {
    name: string;
    description: string;
    useGuidedFailure: boolean;
    allowDynamicInfrastructure: boolean;
    sortOrder: number;
    extensionSettings: ExtensionSettingsValues[];
}

const defaultModel: EnvironmentModel = {
    name: "",
    description: "",
    useGuidedFailure: false,
    allowDynamicInfrastructure: false,
    sortOrder: -1,
    extensionSettings: [],
};

interface EnvironmentLayoutPageProps {
    newOrExistingEnvironment:
        | { createNewEnvironment: true } // Is this case still possible?
        | { existingEnvironmentId: string; createNewEnvironment: false };
}

interface InitialData {
    environment: EnvironmentResource;
    variablesScopedToThisEnvironment: VariablesScopedToEnvironmentResponse;
    metadata: EnvironmentSettingsMetadata[];
}

const EnvironmentLayoutFormPage = FormPage<InitialData>();
const Title = "Environments";

type EnvironmentLayoutInternalProps = {
    initialData: InitialData;
    trackAction: AnalyticTrackedActionDispatcher;
};

interface InternalState extends FormBaseComponentState<EnvironmentModel> {
    deleted: boolean;
}

interface InternalStateWithEnvironment extends InternalState {
    environment: EnvironmentResource;
    variablesScopedToThisEnvironment: VariablesScopedToEnvironmentResponse;
    metadata: EnvironmentSettingsMetadata[];
}

type EnvironmentLayoutInternalState = InternalState | InternalStateWithEnvironment;

const EnvironmentsLayoutPage: React.FC<EnvironmentLayoutPageProps> = (props) => {
    if (props.newOrExistingEnvironment.createNewEnvironment) {
        throw Error("Environment Id must be supplied");
    }
    const environmentId = props.newOrExistingEnvironment.existingEnvironmentId;
    const trackAction = useAnalyticTrackedActionDispatch();

    return (
        <EnvironmentLayoutFormPage
            title={Title}
            load={async () => {
                const environment = await repository.Environments.get(environmentId);
                const variablesScopedToThisEnvironment = repository.Environments.variablesScopedOnlyToThisEnvironment(environment);
                const metadata = repository.Environments.getMetadata(environment);

                return {
                    environment,
                    variablesScopedToThisEnvironment: await variablesScopedToThisEnvironment,
                    metadata: await metadata,
                };
            }}
            renderWhenLoaded={(initialData) => <EnvironmentLayoutInternal initialData={initialData} trackAction={trackAction} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class EnvironmentLayoutInternal extends FormBaseComponent<EnvironmentLayoutInternalProps, EnvironmentLayoutInternalState, EnvironmentModel> {
    constructor(props: EnvironmentLayoutInternalProps) {
        super(props);

        const data = props.initialData;

        this.state = {
            environment: data.environment,
            model: this.buildModel(data.environment),
            cleanModel: cloneDeep(this.buildModel(data.environment)),
            deleted: false,
            variablesScopedToThisEnvironment: data.variablesScopedToThisEnvironment,
            metadata: data.metadata,
        };
    }

    descriptionSummary() {
        return this.state.model.description ? Summary.summary(<Markdown markup={this.state.model.description} />) : Summary.placeholder("No description provided");
    }

    renderOverflowActions(state: InternalStateWithEnvironment) {
        // Check if we need to provide additional warnings about variables scoped to this environment.
        let warningMessage = null;
        let errorMessage = null;
        const variablesResponse = state.variablesScopedToThisEnvironment;

        if (variablesResponse) {
            if (isEmpty(variablesResponse.VariableMap) && !variablesResponse.HasUnauthorizedProjectVariables && !variablesResponse.HasUnauthorizedLibraryVariableSetVariables) {
                warningMessage = null;
            } else {
                const allWarnings = [];
                let additionalWarnings = [];
                const allWarningValues = map(variablesResponse.VariableMap, (dic, key) => {
                    const area = key; // eg. Project
                    const mapDict = dic as Dictionary<number>;
                    return Object.keys(mapDict).map((mapDictKey) => {
                        const value = mapDict[mapDictKey];
                        const variableSuffix = value > 1 ? "s" : "";
                        return (
                            <div key={mapDictKey}>
                                {value} variable{variableSuffix} in the{" "}
                                <strong>
                                    <em>{mapDictKey}</em>
                                </strong>{" "}
                                {area.toLowerCase()}
                            </div>
                        );
                    });
                });

                allWarnings.push(flatten(allWarningValues));
                const allWarningLines = flatten(reduce(allWarnings, (memo, warning) => warning as [], []));
                const shownWarnings = take(allWarningLines, 5);
                if (allWarnings[0].length > 5) {
                    additionalWarnings = slice(allWarningLines, 5, allWarningLines.length - 5);
                }

                warningMessage =
                    shownWarnings.length > 0 ? (
                        <Callout type={CalloutType.Warning} title="Warning">
                            <p>
                                Deleting this environment will also delete <strong>ALL</strong> the variables that are scoped only to this environment in the following locations:
                            </p>
                            <ul>
                                {shownWarnings.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        </Callout>
                    ) : null;

                const errors: string[] = [];
                if (variablesResponse.HasUnauthorizedProjectVariables || variablesResponse.HasUnauthorizedLibraryVariableSetVariables) {
                    if (variablesResponse.HasUnauthorizedProjectVariables) {
                        errors.push("Variables exist in projects for which you do not have edit permissions");
                    }
                    if (variablesResponse.HasUnauthorizedLibraryVariableSetVariables) {
                        errors.push("Variables exist in library variable sets for which you do not have edit permissions");
                    }
                }

                errorMessage =
                    errors.length > 0 ? (
                        <Callout type={CalloutType.Danger} title="Errors">
                            <ul>
                                {errors.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        </Callout>
                    ) : null;
            }
        }

        return [
            OverflowMenuItems.deleteItem(
                "Delete",
                "Are you sure you want to delete this environment?",
                this.handleDeleteConfirm,
                <div>
                    {warningMessage}
                    {errorMessage}
                    <p>Deleting this environment is permanent. There is no going back.</p>
                    <p>Do you wish to continue?</p>
                </div>,
                { permission: Permission.EnvironmentDelete, environment: "*" }
            ),
            [
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForEnvironment(state.environment.Id), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ],
        ];
    }

    renderExtensionSettings(state: InternalStateWithEnvironment) {
        return state.metadata.map((m) => {
            let valuesForExtension = state.environment.ExtensionSettings.find((e) => e.ExtensionId === m.ExtensionId);
            if (!valuesForExtension || !valuesForExtension.Values) {
                valuesForExtension = {
                    ExtensionId: m.ExtensionId,
                    Values: {},
                };

                this.state.model.extensionSettings.push(valuesForExtension);
            }

            return (
                <div>
                    <FormSectionHeading title={m.Metadata.Description} />
                    <DynamicForm
                        types={m.Metadata.Types}
                        values={valuesForExtension.Values}
                        onChange={() => {
                            this.setState({
                                model: this.state.model,
                            });
                        }}
                    />
                </div>
            );
        });
    }

    hasEnvironment = (variableToCheck: object): variableToCheck is InternalStateWithEnvironment => (variableToCheck as InternalStateWithEnvironment).environment !== undefined;

    render() {
        const state = this.state;
        let overFlowActions;

        if (this.hasEnvironment(state)) {
            overFlowActions = this.renderOverflowActions(state);
        }

        return (
            <InfrastructureLayout {...this.props}>
                <AnalyticView resource="Environment" />
                <FormPaperLayout
                    title={this.state.model.name}
                    breadcrumbTitle={"Environments"}
                    breadcrumbPath={routeLinks.infrastructure.environments.root}
                    busy={this.state.busy}
                    errors={this.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: Permission.EnvironmentEdit, environment: "*" }}
                    onSaveClick={this.handleSaveClick}
                    saveText={"Environment details updated"}
                    expandAllOnMount={false}
                    overFlowActions={overFlowActions}
                    secondaryAction={this.addEnvironmentButton()}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.environments.root} />}
                    {this.hasEnvironment(this.state) && <InternalRedirect to={routeLinks.infrastructure.environment(this.state.environment.Id)} />}
                    {
                        <TransitionAnimation>
                            <ExpandableFormSection
                                errorKey="name"
                                title="Name"
                                focusOnExpandAll
                                summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your environment")}
                                help="A short, memorable, unique name for this environment. Example: Development."
                            >
                                <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Name" validate={required("Please enter a environment name")} autoFocus={true} />
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your environment.">
                                <MarkdownEditor value={this.state.model.description} label="Environment description" onChange={(description) => this.setModelState({ description })} />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="useGuidedFailure"
                                title="Default Guided Failure Mode"
                                summary={this.state.model.useGuidedFailure ? Summary.summary("Yes") : Summary.default("No")}
                                help="Select whether guided failure mode is enabled by default"
                            >
                                <div>
                                    <Checkbox
                                        value={this.state.model.useGuidedFailure}
                                        label="Use guided failure mode by default"
                                        onChange={(useGuidedFailure) => this.setModelState({ useGuidedFailure })}
                                        note={<span>If guided failure is enabled for an environment, Octopus Deploy will prompt for user intervention if a deployment fails in the environment.</span>}
                                    />
                                </div>
                            </ExpandableFormSection>
                            <ExpandableFormSection
                                errorKey="dynamicInfrastructure"
                                title="Dynamic Infrastructure"
                                summary={this.state.model.allowDynamicInfrastructure ? Summary.default("Yes") : Summary.summary("No")}
                                help="Select whether dynamic infrastructure is allowed in this environment"
                            >
                                <div>
                                    <Checkbox
                                        value={this.state.model.allowDynamicInfrastructure}
                                        label="Allow dynamic infrastructure"
                                        onChange={(allowDynamicInfrastructure) => this.setModelState({ allowDynamicInfrastructure })}
                                        note={
                                            <span>
                                                In many deployment scenarios, <ExternalLink href="DynamicInfrastructure">infrastructure is created dynamically</ExternalLink> as part of the deployment. If dynamic infrastructure is enabled for an
                                                environment, deployments to this environment are allowed to create infrastructure, such as targets and accounts.
                                            </span>
                                        }
                                    />
                                </div>
                            </ExpandableFormSection>
                            {this.hasEnvironment(state) && this.renderExtensionSettings(state)}
                        </TransitionAnimation>
                    }
                </FormPaperLayout>
            </InfrastructureLayout>
        );
    }

    private handleSaveClick = async () => {
        const model = this.state.model;

        const environment: EnvironmentResource = {
            Id: this.props.initialData.environment.Id,
            Name: model.name,
            Description: model.description,
            UseGuidedFailure: model.useGuidedFailure,
            AllowDynamicInfrastructure: model.allowDynamicInfrastructure,
            SortOrder: model.sortOrder,
            ExtensionSettings: model.extensionSettings,
            SpaceId: this.props.initialData.environment.SpaceId,
            Links: this.props.initialData.environment.Links,
        };

        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Environment",
            };

            await this.props.trackAction("Save Environment", ev, async () => {
                const result = await repository.Environments.save(environment);

                this.setState({
                    environment: result,
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    deleted: false,
                });
            });
        });
    };

    private buildModel(environment: EnvironmentResource): EnvironmentModel {
        const model: EnvironmentModel = {
            name: environment.Name,
            description: environment.Description,
            useGuidedFailure: environment.UseGuidedFailure,
            allowDynamicInfrastructure: environment.AllowDynamicInfrastructure,
            sortOrder: environment.SortOrder,
            extensionSettings: environment.ExtensionSettings,
        };
        return model;
    }

    private handleDeleteConfirm = async () => {
        if (this.hasEnvironment(this.state)) {
            await repository.Environments.del(this.state.environment);
            this.setState({
                model: cloneDeep(defaultModel),
                cleanModel: cloneDeep(defaultModel), //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            });
            return true;
        }
        return false;
    };

    private addEnvironmentButton() {
        let environmentId;
        if (this.hasEnvironment(this.state)) {
            environmentId = this.state.environment.Id;
        }

        return (
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                <NavigationButton href={routeLinks.infrastructure.machines.new(environmentId)} label="Add deployment target" />
            </PermissionCheck>
        );
    }
}

export default EnvironmentsLayoutPage;
