/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-imports */

import AccessTimeIcon from "material-ui/svg-icons/device/access-time";
import * as React from "react";
import type { ProjectResource, NonVcsRunbookResource, TriggerResource, ResourceCollection, TriggerActionCategory } from "~/client/resources";
import { Permission } from "~/client/resources";
import { List } from "~/components/List/List";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { withTheme } from "~/components/Theme";
import Note from "~/primitiveComponents/form/Note/Note";
import routeLinks from "~/routeLinks";
import styles from "./NextScheduledRuns.module.less";

class TriggersList extends List<TriggerResource> {}

export interface NextScheduledRunsBuildTriggerRowProps {
    trigger: TriggerResource;
}

interface NextScheduledRunsProps {
    project: ProjectResource;
    runbook?: NonVcsRunbookResource;
    triggersResponse: ResourceCollection<TriggerResource>;
    triggerActionCategory: TriggerActionCategory;
    renderBuildTriggerRow: (props: NextScheduledRunsBuildTriggerRowProps) => React.ReactElement<any>;
}

export const NextScheduleRunsTitle: React.FC = () =>
    withTheme((theme) => (
        <h4 className={styles.container}>
            <AccessTimeIcon color={theme.secondaryText} />
            &nbsp;&nbsp;Scheduled Triggers
        </h4>
    ));

export const NextScheduledRuns: React.FC<NextScheduledRunsProps> = ({ project, runbook, triggersResponse, triggerActionCategory, renderBuildTriggerRow }) => {
    const overflowMenuItems = [
        OverflowMenuItems.navItem("Create scheduled trigger", routeLinks.project(project).operations.scheduledTriggers.newForRunbook(runbook!.Id), {
            permission: Permission.TriggerCreate,
            project: project.Id,
            wildcard: true,
        }),
    ];
    const overflowMenu = <OverflowMenu menuItems={overflowMenuItems} />;
    return (
        <PermissionCheck permission={Permission.TriggerView} project={project.Id}>
            <>
                <div className={styles.nextScheduledRunsTitleContainer}>
                    <NextScheduleRunsTitle />
                    {overflowMenu}
                </div>
                {!runbook && triggersResponse.Items.length === 0 && (
                    <Note>
                        No triggers are currently setup for runbooks.
                        {/* <InternalLink to={routeLinks.project(project.Slug).operations.scheduledTriggers.new}>Create a trigger now</InternalLink>. */}
                    </Note>
                )}
                {runbook && triggersResponse.Items.length === 0 && (
                    <Note>
                        No triggers are currently setup for this runbook.
                        {/* <InternalLink to={routeLinks.project(project.Slug).operations.scheduledTriggers.newForRunbook(runbook.Id)}>Create a trigger now</InternalLink>. */}
                    </Note>
                )}
                {triggersResponse && (
                    <TriggersList
                        initialData={triggersResponse}
                        additionalRequestParams={new Map([["triggerActionCategory", [triggerActionCategory]]])}
                        onRow={(trigger: TriggerResource) => renderBuildTriggerRow({ trigger })}
                        filterSearchEnabled={false}
                        wrapRowsInListItem={false}
                    />
                )}
            </>
        </PermissionCheck>
    );
};
