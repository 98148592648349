/* eslint-disable no-restricted-imports */

import { Card } from "@material-ui/core";
import classnames = require("classnames");
import * as React from "react";
import type { FormSectionBaseProps } from "~/components/form/Sections/FormSectionBase";
import { FormSectionBase } from "~/components/form/Sections/FormSectionBase";
import CardTitle from "./CardTitle";
import styles from "./style.module.less";

interface FormSectionProps extends FormSectionBaseProps {
    title?: React.ReactNode;
    help?: React.ReactNode;
    includeBorder?: boolean;
}

//todo: mattr: rename this to NonExpandableFormSection and add new FormSection component that switches between this and ExpandableFormSection
export default class FormSection extends FormSectionBase<FormSectionProps> {
    render() {
        return (
            <Card className={classnames(styles.formSection, this.props.includeBorder ? styles.formBorder : null)}>
                {this.props.title && <CardTitle title={this.title(this.props.title, this.help(this.props.help))} />}
                {this.body(styles.cardMedia, false)}
            </Card>
        );
    }
}
