/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { AnonymousVcsCredentials, GitCredentialResource, ProjectResource, ReferenceVcsCredentials, UsernamePasswordVcsCredentials, VersionControlCompatibilityResponse, GitPersistenceSettings } from "~/client/resources";
import { AuthenticationType, HasVersionControlledPersistenceSettings, IsUsingUsernamePasswordAuth, IsUsingAnonymousAuth, IsUsingReferenceAuth, Permission, PersistenceSettingsType, ProcessType } from "~/client/resources";
import type ConvertProjectToVersionControlledCommand from "~/client/resources/convertProjectToVersionControlledCommand";
import { branchToShowByDefault, getBasePathToShowByDefault, toGitBranchShort } from "~/client/resources/versionControlledResource";
import { repository } from "~/clientInstance";
import ActionButton, { ActionButtonType } from "~/components/Button";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import type { PrimaryActionProps } from "~/components/FormPaperLayout/FormPaperLayout";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import PermissionCheck, { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, Note, RadioButton, Summary, Text, UnstructuredFormSection } from "~/components/form";
import type { SummaryNode } from "~/components/form";
import Sensitive from "~/components/form/Sensitive/Sensitive";
import { required } from "~/components/form/Validators";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import ToolTip, { ToolTipPosition } from "~/primitiveComponents/dataDisplay/ToolTip";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import type { WithProjectContextInjectedProps } from "../../../context";
import { withProjectContext } from "../../../context";
import { isVersionControlledProcess } from "../../Process/Common/CommonProcessHelpers";
import CommitDialog from "./CommitDialog";
import { GitChip } from "./GitChip";
import GitCredentialSelect from "./GitCredentialSelect";
import { SettingsNoteSection } from "./SettingsNoteSection";
import TestConnectionButton from "./TestConnectionButton";
import { UnsupportedFeaturesSection } from "./UnsupportedFeaturesSection";

interface VersionControlSettingsState extends OptionalFormBaseComponentState<GitPersistenceSettings> {
    project?: ProjectResource;
    gitCredentials: GitCredentialResource[];
    commitDialogActive: boolean;
    commitMessage: CommitMessageWithDetails;
    vcsCompatibilityReport: VersionControlCompatibilityResponse | null;
    saveForm: () => Promise<boolean>;
}

interface VersionControlSettingsAnalyticsProps {
    trackAction: AnalyticTrackedActionDispatcher;
    dispatchAction: AnalyticActionDispatcher;
}

type VersionControlSettingsProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps;
type VersionControlSettingsPropsInternal = VersionControlSettingsProps & VersionControlSettingsAnalyticsProps;

const defaultCommitMessage = "Initial commit of deployment process";

class AuthenticationTypeRadioButtonGroup extends RadioButtonGroup<AuthenticationType> {}

class VersionControlSettingsInternal extends FormBaseComponent<VersionControlSettingsPropsInternal, VersionControlSettingsState, GitPersistenceSettings> {
    constructor(props: VersionControlSettingsPropsInternal) {
        super(props);
        this.state = {
            commitDialogActive: false,
            gitCredentials: [],
            commitMessage: { summary: "", details: "" },
            vcsCompatibilityReport: null,
            saveForm: () => Promise.resolve(false),
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Deployment, false);
            const vcsCompatibilityReport = !isVersionControlled ? await repository.Projects.vcsCompatibilityReport(project) : null;

            this.setState({
                project,
                vcsCompatibilityReport,
                model: this.buildModel(project),
                cleanModel: this.buildModel(project),
            });

            await this.refreshGitCredentials();
        });
    }

    buildModel(project: ProjectResource): GitPersistenceSettings {
        if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
            return this.defaultVersionControlSettings(project);
        }

        return {
            Type: PersistenceSettingsType.VersionControlled,
            Url: project.PersistenceSettings.Url,
            DefaultBranch: project.PersistenceSettings.DefaultBranch || toGitBranchShort(branchToShowByDefault),
            BasePath: project.PersistenceSettings.BasePath || getBasePathToShowByDefault(project.Name),
            Credentials: project.PersistenceSettings.Credentials,
            ConversionState: project.PersistenceSettings.ConversionState,
        };
    }

    handleSaveClick = async () => {
        this.props.dispatchAction("Commit VCS", { resource: "Version Control Settings", action: Action.Commit });

        if (!this.state.model) {
            throw Error("Tried to save with an empty model");
        }
        const model = this.state.model;

        await this.doBusyTask(async () => {
            if (!this.state.project) {
                throw new Error("No Project loaded");
            }
            if (HasVersionControlledPersistenceSettings(this.state.project.PersistenceSettings)) {
                await this.saveProject({ ...this.state.project, PersistenceSettings: model });
            } else {
                const formattedMessage = getFormattedCommitMessage(this.state.commitMessage, defaultCommitMessage);
                const vcsSettingsAndCommitMessage: ConvertProjectToVersionControlledCommand = {
                    CommitMessage: formattedMessage,
                    VersionControlSettings: model,
                };

                await this.convertProjectToVcs(this.state.project, vcsSettingsAndCommitMessage);

                await this.props.projectContext.actions.refreshModel();
            }
        });
    };

    handleBasePathChange = async (basePath: string) => {
        // We want to ensure the `.octopus/` prefix is applied to the base path,
        // despite not allowing the portal to configure the root
        this.setModelState({ BasePath: basePath ? `.octopus/${basePath}` : `.octopus` });
    };

    getBasePath = (): string => {
        // The `.octopus/` base path is expected to always be set on the incoming BasePath
        // despite not being (currently) configurable.
        // To ensure it isn't configurable, we strip the root directory from the value bound to the textbox

        if (!this.state.model) {
            return "";
        }
        let basePath = this.state.model.BasePath;
        if (basePath.startsWith(".octopus")) {
            basePath = basePath.substring(".octopus".length);
        }
        if (basePath.startsWith("/")) {
            return basePath.substring(1);
        }
        return basePath;
    };

    getCredentialName = async (id: string): Promise<string> => {
        return (await repository.GitCredentials.get(id)).Name;
    };

    refreshGitCredentials = async () => {
        if (hasPermission(Permission.GitCredentialView)) {
            // SelectWithAddRefresh does not have a filter re-search capability. Not going to break that
            // ground here, instead just get all of the credentials and show them in the list.
            const gitCredentials = await repository.GitCredentials.list({ take: repository.takeAll });
            this.setState({
                gitCredentials: gitCredentials.Items,
            });
        } else {
            this.setState({
                gitCredentials: [],
            });
        }
    };

    render() {
        const canViewLibraryGitCredentials = hasPermission(Permission.GitCredentialView);

        const baseLibraryRadioButton = (
            <RadioButton value={AuthenticationType.Reference} key={AuthenticationType.Reference} label={"Library"} disabled={!canViewLibraryGitCredentials} isDefault={canViewLibraryGitCredentials} accessibleName={"Use library credentials"} />
        );
        const libraryRadioButtonWithOptionalToolTip = canViewLibraryGitCredentials ? (
            baseLibraryRadioButton
        ) : (
            <ToolTip content={"Requires Git Credential View permission"} size="small" position={ToolTipPosition.Left}>
                {baseLibraryRadioButton}
            </ToolTip>
        );

        const isExpanded = this.state.model && !this.state.model.Url;
        const formFields = this.state.model && this.state.project && (
            <>
                <TransitionAnimation>
                    {this.state.project.IsDisabled && (
                        <UnstructuredFormSection stretchContent={true}>
                            <Callout type={CalloutType.Warning} title={"This project is currently disabled"} />
                        </UnstructuredFormSection>
                    )}
                    <ExpandableFormSection errorKey="Url" title="Git Repository" summary={this.summary()} help="Add the Git repository where you want to store this project's deployment process" isExpandedByDefault={isExpanded}>
                        <Callout type={CalloutType.Information} title="Repository must be initialized">
                            <div>If you are creating a new repository, it must be initialized with at least one branch</div>
                        </Callout>
                        <Text
                            key="Url"
                            value={this.state.model.Url}
                            onChange={(Url) => this.setModelState({ Url })}
                            label="URL"
                            error={this.getFieldError("Url")}
                            validate={required("Enter a Git repository URL.")}
                            autoFocus={true}
                            disabled={!!this.state.busy}
                            accessibleName={"URL for Git repository"}
                        />
                        <Note>
                            The HTTPS URL to your git repo. E.g. <code>https://github.com/acme/hello-world.git</code>
                        </Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="DefaultBranch" title="Default Branch Name" summary={this.branchSummary()} help="Provide the name of the default branch" isExpandedByDefault={isExpanded}>
                        <Text
                            key="DefaultBranch"
                            value={this.state.model.DefaultBranch}
                            onChange={(DefaultBranch) => this.setModelState({ DefaultBranch })}
                            label="Default Branch"
                            error={this.getFieldError("DefaultBranch")}
                            validate={required("Enter a default branch.")}
                            disabled={!!this.state.busy}
                            accessibleName={"Name of the default branch on the Git repository"}
                        />
                        <FeatureToggleVisibility toggle={"GitVariablesFeatureToggle"}>
                            <Note>Runbooks will use variables from the default branch</Note>
                        </FeatureToggleVisibility>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="Authentication" title="Authentication" summary={this.authSummary()} help="Choose a method to authenticate with the above Git repository" isExpandedByDefault={isExpanded}>
                        <AuthenticationTypeRadioButtonGroup
                            value={this.state.model.Credentials.Type}
                            onChange={(authType) => {
                                if (authType === AuthenticationType.Reference) {
                                    this.setCredentialsForReference({ Type: AuthenticationType.Reference });
                                } else if (authType === AuthenticationType.UsernamePassword) {
                                    this.setCredentialsForUsernamePassword({ Type: AuthenticationType.UsernamePassword });
                                } else if (authType === AuthenticationType.Anonymous) {
                                    this.setCredentialsForAnonymous({ Type: AuthenticationType.Anonymous });
                                }
                            }}
                            disabled={!!this.state.busy}
                            accessibleName={"Authentication for the Git repository"}
                        >
                            {libraryRadioButtonWithOptionalToolTip}
                            {IsUsingReferenceAuth(this.state.model.Credentials) && (
                                <PermissionCheck permission={Permission.GitCredentialView} alternate={<Note>Currently configured with a library credential. You do not have permission to change the credential or repository URL</Note>}>
                                    <GitCredentialSelect items={this.state.gitCredentials} onChange={(Id) => this.setCredentialsForReference({ Id })} value={this.state.model.Credentials.Id} onRequestRefresh={this.refreshGitCredentials} />
                                    <Note> Use credential from the Git credential library </Note>
                                </PermissionCheck>
                            )}

                            <RadioButton
                                value={AuthenticationType.UsernamePassword}
                                key={AuthenticationType.UsernamePassword}
                                label={"Username/Password"}
                                isDefault={!canViewLibraryGitCredentials}
                                accessibleName={"Authenticate using username and password"}
                            />
                            {IsUsingUsernamePasswordAuth(this.state.model.Credentials) && (
                                <>
                                    <Text
                                        key="Username"
                                        value={this.state.model.Credentials.Username}
                                        onChange={(Username) => this.setCredentialsForUsernamePassword({ Username })}
                                        label="Username"
                                        error={this.getFieldError("Username")}
                                        validate={required("Enter authentication details.")}
                                        disabled={!!this.state.busy}
                                    />
                                    <Sensitive
                                        key="Password"
                                        value={this.state.model.Credentials.Password}
                                        onChange={(Password) => this.setCredentialsForUsernamePassword({ Password })}
                                        label="Password or Personal Access Token"
                                        error={this.getFieldError("Password")}
                                        disabled={!!this.state.busy}
                                    />
                                    <Note>If you are using a personal access token, ensure it is scoped with read and write access for the repo.</Note>
                                </>
                            )}
                            <RadioButton value={AuthenticationType.Anonymous} key={AuthenticationType.Anonymous} label={"Anonymous"} accessibleName={"No authentication"} />
                            {IsUsingAnonymousAuth(this.state.model.Credentials) && <Note>When no credentials are required (e.g. locally hosted repositories).</Note>}
                        </AuthenticationTypeRadioButtonGroup>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="BasePath" title="Git File Storage Directory" summary={this.basePathSummary()} help="The directory where Octopus should store the project files in the repository" isExpandedByDefault={isExpanded}>
                        <Text
                            inputProps={{
                                startAdornment: (
                                    <>
                                        <code>.octopus</code>
                                        &nbsp;/
                                    </>
                                ),
                            }}
                            key="BasePath"
                            value={this.getBasePath()}
                            onChange={this.handleBasePathChange}
                            label="Git File Storage Directory"
                            error={this.getFieldError("BasePath")}
                            disabled={!!this.state.busy}
                            accessibleName={"Directory in the repository where Octopus related files should be stored"}
                        />
                        <Note>This controls where in your repository the Octopus configuration files will be written.</Note>
                        <Note>
                            If this is the only Octopus project which will be persisted in the repository, <code>.octopus</code> is a good option. If multiple Octopus projects will be stored in the same repository, adding the project name to the path
                            is the recommended convention, e.g. <code>.octopus/acme</code>
                        </Note>
                    </ExpandableFormSection>
                </TransitionAnimation>
            </>
        );

        const configurationDialog = this.state.model && this.state.project && this.state.commitDialogActive && (
            <CommitDialog
                open={this.state.commitDialogActive}
                defaultSummary={defaultCommitMessage}
                commitMessage={this.state.commitMessage}
                setCommitMessage={(commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage })}
                errors={this.errors}
                project={this.state.project}
                onNext={() => {
                    // TODO: try and clone the repository, passing the commit message.
                    // if that succeeds, save the VCS settings
                    return this.state.saveForm();
                }}
                onCancel={() => {
                    this.props.dispatchAction("Cancel Configuring VCS", { resource: "Version Control Settings", action: Action.Cancel });
                }}
                onClose={() => {
                    this.setState({ commitDialogActive: false });
                }}
            />
        );

        const hasErrors = this.state.vcsCompatibilityReport && this.state.vcsCompatibilityReport.Errors.length > 0 ? true : false;
        const enabled = this.state.project?.IsVersionControlled ?? false;
        const isVersionControlled = this.state.project ? this.state.project.IsVersionControlled : false;

        return (
            <FormPaperLayout
                title={[<span key="title">Version Control Settings&nbsp;</span>, <GitChip key="chip" size="small" enabled={enabled} />]}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                saveButtonLabel={enabled ? "Save" : "Configure..."}
                saveButtonBusyLabel={enabled ? "Saving" : "Configuring..."}
                savePermission={this.editPermission()}
                onSaveClick={this.handleSaveClick}
                forceDisableFormSaveButton={hasErrors}
                forceDisabledReason={"Conversion errors must be resolved"}
                customPrimaryAction={(primaryActionProps: PrimaryActionProps) => {
                    // For this page (unlike most form layouts) we only save after a successful clone.
                    // We do that by first popping up a dialog, doing the clone and then saving if it succeeds.
                    // In order to do that, here we're storing a reference to the default form save function
                    // to call later on from the dialog.
                    const onClick = (e: React.MouseEvent) => {
                        this.props.dispatchAction("Configure VCS", { resource: "Version Control Settings", action: Action.Add });

                        if (enabled) {
                            primaryActionProps.onClick();
                        } else {
                            e.preventDefault();
                            this.setState({
                                saveForm: primaryActionProps.onClick as () => Promise<boolean>,
                                commitDialogActive: true,
                            });
                        }
                    };

                    return <ActionButton type={ActionButtonType.Save} label={primaryActionProps.label} disabled={primaryActionProps.disabled} busyLabel={primaryActionProps.busyLabel} onClick={onClick} />;
                }}
                saveText="Your project now supports version control"
                secondaryAction={!hasErrors && this.state.model && this.state.project && <TestConnectionButton disabled={this.state.busy || !this.state.model.Url} project={this.state.project} model={this.state.model} />}
            >
                <SettingsNoteSection isVersionControlled={isVersionControlled} />
                {this.state.vcsCompatibilityReport && <UnsupportedFeaturesSection vcsCompatibilityReport={this.state.vcsCompatibilityReport} />}
                {formFields}
                {configurationDialog}
            </FormPaperLayout>
        );
    }

    private setCredentialsForReference<K extends keyof ReferenceVcsCredentials>(state: Pick<ReferenceVcsCredentials, K>) {
        this.setChildState2("model", "Credentials", state);
    }

    private setCredentialsForUsernamePassword<K extends keyof UsernamePasswordVcsCredentials>(state: Pick<UsernamePasswordVcsCredentials, K>) {
        this.setChildState2("model", "Credentials", state);
    }

    private setCredentialsForAnonymous<K extends keyof AnonymousVcsCredentials>(state: Pick<AnonymousVcsCredentials, K>) {
        this.setChildState2("model", "Credentials", state);
    }

    private summary(): SummaryNode {
        const url = this.state.model && this.state.model.Url;
        return url ? Summary.summary(url) : Summary.placeholder("Enter a Git repository URL");
    }

    private authSummary(): SummaryNode {
        if (this.state.model) {
            if (IsUsingReferenceAuth(this.state.model.Credentials)) {
                const { Id: id } = this.state.model.Credentials;
                const credential = this.state.gitCredentials.find((c) => c.Id == id);

                return Summary.summary(
                    React.Children.toArray([
                        <span>Using library credential</span>,
                        <span>
                            <strong>{credential ? " " + credential.Name : ""}</strong>
                        </span>,
                    ])
                );
            } else if (IsUsingUsernamePasswordAuth(this.state.model.Credentials)) {
                const { Username: username, Password: password } = this.state.model.Credentials;
                if (username && password && password.HasValue) {
                    return Summary.summary(
                        React.Children.toArray([
                            <span>Authenticated with username&nbsp;</span>,
                            <span>
                                <strong>{username}</strong>
                            </span>,
                        ])
                    );
                }
            } else {
                return Summary.summary(
                    React.Children.toArray([
                        <span>
                            Authenticated as an <strong>Anonymous</strong> user
                        </span>,
                    ])
                );
            }
        }
        return Summary.placeholder("Enter authentication details");
    }

    private branchSummary(): SummaryNode {
        const shortBranchName = toGitBranchShort(branchToShowByDefault);
        if (!this.state.model || this.state.model.DefaultBranch === shortBranchName) {
            return Summary.default(shortBranchName);
        }

        if (!this.state.model.DefaultBranch) {
            return Summary.placeholder("Enter a default branch");
        }

        return Summary.summary(this.state.model.DefaultBranch);
    }

    private basePathSummary(): SummaryNode {
        // TODO: Refactor
        const basePathToShowByDefault = this.state.project ? getBasePathToShowByDefault(this.state.project.Name) : "";

        if (!this.state.model || this.state.model.DefaultBranch === basePathToShowByDefault) {
            return Summary.default(basePathToShowByDefault);
        }

        if (!this.state.model.BasePath) {
            return Summary.placeholder("Enter a base path");
        }

        return Summary.summary(this.state.model.BasePath);
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }

    private async saveProject(project: ProjectResource) {
        const result = await repository.Projects.save(project);

        this.setState({
            model: this.buildModel(result),
            cleanModel: this.buildModel(result),
            project: result,
        });

        if (!this.errors?.errors.length) {
            window.location.reload();
        }
    }

    private async convertProjectToVcs(project: ProjectResource, versionControlledPersistenceSettings: ConvertProjectToVersionControlledCommand) {
        const event: ActionEvent = {
            resource: "Version Control Settings",
            action: Action.ServerResponse,
        };

        await this.props.trackAction("Convert to VCS", event, async (errorCallback) => await repository.Projects.convertToVcs(project, versionControlledPersistenceSettings));

        const updatedProject = await repository.Projects.get(project.Id);
        if (!HasVersionControlledPersistenceSettings(updatedProject.PersistenceSettings)) throw new Error("Converted Project is not version controlled");
        await this.props.projectContext.actions.onVersionControlEnabled(updatedProject);

        this.setState({
            model: this.buildModel(updatedProject),
            cleanModel: this.buildModel(updatedProject),
            project: updatedProject,
        });
    }

    private defaultVersionControlSettings(project: ProjectResource): GitPersistenceSettings {
        const canViewLibraryGitCredentials = hasPermission(Permission.GitCredentialView);

        const defaultReference: ReferenceVcsCredentials = {
            Type: AuthenticationType.Reference,
            Id: "",
        };
        const defaultUsernamePassword: UsernamePasswordVcsCredentials = {
            Type: AuthenticationType.UsernamePassword,
            Username: "",
            Password: {
                HasValue: false,
            },
        };

        const defaultCredentials = canViewLibraryGitCredentials ? defaultReference : defaultUsernamePassword;

        return {
            Type: PersistenceSettingsType.VersionControlled,
            Url: "",
            DefaultBranch: toGitBranchShort(branchToShowByDefault),
            BasePath: getBasePathToShowByDefault(project.Name),
            Credentials: defaultCredentials,
            ConversionState: { VariablesAreInGit: false },
        };
    }
}

export function VersionControlSettingsInternalWithAnalytics(props: VersionControlSettingsProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();
    return <VersionControlSettingsInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction} />;
}

export default withProjectContext(VersionControlSettingsInternalWithAnalytics);
