import LetsEncryptConfigurationRepository from "~/client/repositories/letsEncryptConfigurationRepository";
import type { RouteArgs } from "~/client/resolver";
import AuthenticationRepository from "./authentication/authenticationRepository";
import type { Client } from "./client";
import SettingsRepository from "./configuration/settingsRepository";
import AccountRepository from "./repositories/accountRepository";
import ActionTemplateRepository from "./repositories/actionTemplateRepository";
import ArtifactRepository from "./repositories/artifactRepository";
import BuildInformationRepository from "./repositories/buildInformationRepository";
import CertificateConfigurationRepository from "./repositories/certificateConfigurationRepository";
import CertificateRepository from "./repositories/certificateRepository";
import ChannelRepository from "./repositories/channelRepository";
import CloudTemplateRepository from "./repositories/cloudTemplateRepository";
import CommunityActionTemplateRepository from "./repositories/communityActionTemplateRepository";
import DashboardConfigurationRepository from "./repositories/dashboardConfigurationRepository";
import DashboardRepository from "./repositories/dashboardRepository";
import DefectRepository from "./repositories/defectRepository";
import DeploymentRepository from "./repositories/deploymentRepository";
import DynamicExtensionRepository from "./repositories/dynamicExtensionRepository";
import EnvironmentRepository from "./repositories/environmentRepository";
import EventRepository from "./repositories/eventRepository";
import ExternalSecurityGroupProviderRepository from "./repositories/externalSecurityGroupProviderRepository";
import ExternalSecurityGroupRepository from "./repositories/externalSecurityGroupRepository";
import ExternalUsersRepository from "./repositories/externalUsersRepository";
import FeaturesConfigurationRepository from "./repositories/featuresConfigurationRepository";
import FeedRepository from "./repositories/feedRepository";
import GitCredentialRepository from "./repositories/gitCredentialRepository";
import IconRepository from "./repositories/iconRepository";
import ImportExportActions from "./repositories/importExportActions";
import InterruptionRepository from "./repositories/interruptionRepository";
import InvitationRepository from "./repositories/inviteRepository";
import LibraryVariableRepository from "./repositories/libraryVariableRepository";
import LicenseRepository from "./repositories/licenseRepository";
import LifecycleRepository from "./repositories/lifecycleRepository";
import MachinePolicyRepository from "./repositories/machinePolicyRepository";
import MachineRepository from "./repositories/machineRepository";
import MachineRoleRepository from "./repositories/machineRoleRepository";
import MachineShellsRepository from "./repositories/machineShellsRepository";
import MaintenanceConfigurationRepository from "./repositories/maintenanceConfigurationRepository";
import OctopusServerNodeRepository from "./repositories/octopusServerNodeRepository";
import PackageRepository from "./repositories/packageRepository";
import PerformanceConfigurationRepository from "./repositories/performanceConfigurationRepository";
import PermissionDescriptionRepository from "./repositories/permissionDescriptionRepository";
import ProgressionRepository from "./repositories/progressionRepository";
import ProjectGroupRepository from "./repositories/projectGroupRepository";
import ProjectRepository from "./repositories/projectRepository";
import ProjectTriggerRepository from "./repositories/projectTriggerRepository";
import ProxyRepository from "./repositories/proxyRepository";
import ReleasesRepository from "./repositories/releasesRepository";
import RetentionDefaultConfigurationRepository from "./repositories/retentionDefaultConfigurationRepository";
import RunbookProcessRepository from "./repositories/runbookProcessRepository";
import RunbookRepository from "./repositories/runbookRepository";
import RunbookRunRepository from "./repositories/runbookRunRepository";
import RunbookSnapshotRepository from "./repositories/runbookSnapshotRepository";
import SchedulerRepository from "./repositories/schedulerRepository";
import ScopedUserRoleRepository from "./repositories/scopedUserRoleRepository";
import ServerConfigurationRepository from "./repositories/serverConfigurationRepository";
import ServerStatusRepository from "./repositories/serverStatusRepository";
import SmtpConfigurationRepository from "./repositories/smtpConfigurationRepository";
import SpaceRepository from "./repositories/spaceRepository";
import StepPackageDeploymentTargetRepository from "./repositories/stepPackageDeploymentTargetRepository";
import StepPackageRepository from "./repositories/stepPackageRepository";
import SubscriptionRepository from "./repositories/subscriptionRepository";
import TagSetRepository from "./repositories/tagSetRepository";
import TaskRepository from "./repositories/taskRepository";
import TeamMembershipRepository from "./repositories/teamMembershipRepository";
import TeamRepository from "./repositories/teamRepository";
import TelemetryConfigurationRepository from "./repositories/telemetryConfigurationRepository";
import TelemetryRepository from "./repositories/telemetryRepository";
import TenantRepository from "./repositories/tenantRepository";
import TenantVariableRepository from "./repositories/tenantVariableRepository";
import UpgradeConfigurationRepository from "./repositories/upgradeConfigurationRepository";
import UserIdentityMetadataRepository from "./repositories/userIdentityMetadataRepository";
import UserOnBoardingRepository from "./repositories/userOnBoardingRepository";
import UserPermissionRepository from "./repositories/userPermissionRepository";
import UserRepository from "./repositories/userRepository";
import UserRoleRepository from "./repositories/userRoleRepository";
import VariableRepository from "./repositories/variableRepository";
import WorkerRepository from "./repositories/workerRepository";
import WorkerShellsRepository from "./repositories/workerShellsRepository";
import WorkerPoolsRepository from "./repositories/workerpoolsRepository";

interface ServerInformation {
    version: string;
}

export interface OctopusCommonRepository {
    Events: EventRepository;
    Tasks: TaskRepository;
    Teams: TeamRepository;
    ScopedUserRoles: ScopedUserRoleRepository;
    UserPermissions: UserPermissionRepository;
    TeamMembership: TeamMembershipRepository;
    Invitations: InvitationRepository;
    spaceId: string | null;
}

export interface OctopusSystemRepository extends OctopusCommonRepository {
    Authentication: AuthenticationRepository;
    CommunityActionTemplates: CommunityActionTemplateRepository;
    FeaturesConfiguration: FeaturesConfigurationRepository;
    Icons: IconRepository;
    LetsEncryptConfiguration: LetsEncryptConfigurationRepository;
    MaintenanceConfiguration: MaintenanceConfigurationRepository;
    OctopusServerNodes: OctopusServerNodeRepository;
    PerformanceConfiguration: PerformanceConfigurationRepository;
    PermissionDescriptions: PermissionDescriptionRepository;
    Scheduler: SchedulerRepository;
    ServerConfiguration: ServerConfigurationRepository;
    ServerStatus: ServerStatusRepository;
    Settings: SettingsRepository;
    SmtpConfiguration: SmtpConfigurationRepository;
    Spaces: SpaceRepository;
    Users: UserRepository;
    UserRoles: UserRoleRepository;
    CloudTemplates: CloudTemplateRepository;
    ExternalSecurityGroupProviders: ExternalSecurityGroupProviderRepository;
    ExternalSecurityGroups: ExternalSecurityGroupRepository;
    ExternalUsers: ExternalUsersRepository;
    Licenses: LicenseRepository;
    UpgradeConfiguration: UpgradeConfigurationRepository;
    UserIdentityMetadata: UserIdentityMetadataRepository;
    getServerInformation: () => ServerInformation;
    StepPackageRepository: StepPackageRepository;
    StepPackageDeploymentTarget: StepPackageDeploymentTargetRepository;
}

export interface OctopusSpaceRepository extends OctopusCommonRepository {
    Accounts: AccountRepository;
    ActionTemplates: ActionTemplateRepository;
    Artifacts: ArtifactRepository;
    CertificateConfiguration: CertificateConfigurationRepository;
    Certificates: CertificateRepository;
    Channels: ChannelRepository;
    DashboardConfiguration: DashboardConfigurationRepository;
    Dashboards: DashboardRepository;
    Defects: DefectRepository;
    Deployments: DeploymentRepository;
    Environments: EnvironmentRepository;
    Feeds: FeedRepository;
    ImportExport: ImportExportActions;
    Interruptions: InterruptionRepository;
    LibraryVariableSets: LibraryVariableRepository;
    Lifecycles: LifecycleRepository;
    MachinePolicies: MachinePolicyRepository;
    MachineRoles: MachineRoleRepository;
    MachineShells: MachineShellsRepository;
    Machines: MachineRepository;
    Runbooks: RunbookRepository;
    RunbookProcess: RunbookProcessRepository;
    RunbookSnapshots: RunbookSnapshotRepository;
    RunbookRuns: RunbookRunRepository;
    Packages: PackageRepository;
    Progression: ProgressionRepository;
    ProjectGroups: ProjectGroupRepository;
    Projects: ProjectRepository;
    ProjectTriggers: ProjectTriggerRepository;
    Proxies: ProxyRepository;
    Releases: ReleasesRepository;
    Subscriptions: SubscriptionRepository;
    TagSets: TagSetRepository;
    Telemetry: TelemetryRepository;
    Tenants: TenantRepository;
    TenantVariables: TenantVariableRepository;
    UserOnboarding: UserOnBoardingRepository;
    Variables: VariableRepository;
    WorkerPools: WorkerPoolsRepository;
    WorkerShells: WorkerShellsRepository;
    Workers: WorkerRepository;
}

// Repositories provide a helpful abstraction around the Octopus Deploy API
class Repository implements OctopusSpaceRepository, OctopusSystemRepository {
    readonly takeAll = 2147483647;
    readonly takeDefaultPageSize = 30; // Only used when we don't rely on the default that's applied server-side.
    Accounts: AccountRepository;
    ActionTemplates: ActionTemplateRepository;
    Artifacts: ArtifactRepository;
    Authentication: AuthenticationRepository;
    BuildInformationRepository: BuildInformationRepository;
    CertificateConfiguration: CertificateConfigurationRepository;
    Certificates: CertificateRepository;
    Channels: ChannelRepository;
    CloudTemplates: CloudTemplateRepository;
    CommunityActionTemplates: CommunityActionTemplateRepository;
    DashboardConfiguration: DashboardConfigurationRepository;
    Dashboards: DashboardRepository;
    Defects: DefectRepository;
    Deployments: DeploymentRepository;
    DynamicExtensions: DynamicExtensionRepository;
    Environments: EnvironmentRepository;
    Events: EventRepository;
    ExternalSecurityGroupProviders: ExternalSecurityGroupProviderRepository;
    ExternalSecurityGroups: ExternalSecurityGroupRepository;
    ExternalUsers: ExternalUsersRepository;
    FeaturesConfiguration: FeaturesConfigurationRepository;
    Feeds: FeedRepository;
    Icons: IconRepository;
    ImportExport: ImportExportActions;
    Interruptions: InterruptionRepository;
    Invitations: InvitationRepository;
    LetsEncryptConfiguration: LetsEncryptConfigurationRepository;
    LibraryVariableSets: LibraryVariableRepository;
    Licenses: LicenseRepository;
    Lifecycles: LifecycleRepository;
    MachinePolicies: MachinePolicyRepository;
    MachineRoles: MachineRoleRepository;
    MachineShells: MachineShellsRepository;
    Machines: MachineRepository;
    MaintenanceConfiguration: MaintenanceConfigurationRepository;
    OctopusServerNodes: OctopusServerNodeRepository;
    RetentionDefaultConfiguration: RetentionDefaultConfigurationRepository;
    Runbooks: RunbookRepository;
    RunbookProcess: RunbookProcessRepository;
    RunbookSnapshots: RunbookSnapshotRepository;
    RunbookRuns: RunbookRunRepository;
    Packages: PackageRepository;
    PerformanceConfiguration: PerformanceConfigurationRepository;
    PermissionDescriptions: PermissionDescriptionRepository;
    Progression: ProgressionRepository;
    ProjectGroups: ProjectGroupRepository;
    Projects: ProjectRepository;
    ProjectTriggers: ProjectTriggerRepository;
    Proxies: ProxyRepository;
    Releases: ReleasesRepository;
    ScopedUserRoles: ScopedUserRoleRepository;
    Scheduler: SchedulerRepository;
    ServerStatus: ServerStatusRepository;
    ServerConfiguration: ServerConfigurationRepository;
    Settings: SettingsRepository;
    SmtpConfiguration: SmtpConfigurationRepository;
    Spaces: SpaceRepository;
    Subscriptions: SubscriptionRepository;
    TagSets: TagSetRepository;
    Tasks: TaskRepository;
    Teams: TeamRepository;
    Tenants: TenantRepository;
    TenantVariables: TenantVariableRepository;
    Telemetry: TelemetryRepository;
    TelemetryConfiguration: TelemetryConfigurationRepository;
    UpgradeConfiguration: UpgradeConfigurationRepository;
    UserIdentityMetadata: UserIdentityMetadataRepository;
    UserOnboarding: UserOnBoardingRepository;
    UserRoles: UserRoleRepository;
    UserPermissions: UserPermissionRepository;
    TeamMembership: TeamMembershipRepository;
    Users: UserRepository;
    Variables: VariableRepository;
    getServerInformation: () => ServerInformation;
    WorkerPools: WorkerPoolsRepository;
    WorkerShells: WorkerShellsRepository;
    Workers: WorkerRepository;
    StepPackageRepository: StepPackageRepository;
    StepPackageDeploymentTarget: StepPackageDeploymentTargetRepository;
    GitCredentials: GitCredentialRepository;

    constructor(private readonly client: Client) {
        this.Accounts = new AccountRepository(client);
        this.ActionTemplates = new ActionTemplateRepository(client);
        this.Artifacts = new ArtifactRepository(client);
        this.Authentication = new AuthenticationRepository(client);
        this.BuildInformationRepository = new BuildInformationRepository(client);
        this.CertificateConfiguration = new CertificateConfigurationRepository(client);
        this.Certificates = new CertificateRepository(client);
        this.CloudTemplates = new CloudTemplateRepository(client);
        this.CommunityActionTemplates = new CommunityActionTemplateRepository(client);
        this.DashboardConfiguration = new DashboardConfigurationRepository(client);
        this.Dashboards = new DashboardRepository(client);
        this.Defects = new DefectRepository(client);
        this.Deployments = new DeploymentRepository(client);
        this.DynamicExtensions = new DynamicExtensionRepository(client);
        this.Environments = new EnvironmentRepository(client);
        this.Events = new EventRepository(client);
        this.ExternalSecurityGroupProviders = new ExternalSecurityGroupProviderRepository(client);
        this.ExternalSecurityGroups = new ExternalSecurityGroupRepository(client);
        this.ExternalUsers = new ExternalUsersRepository(client);
        this.FeaturesConfiguration = new FeaturesConfigurationRepository(client);
        this.Feeds = new FeedRepository(client);
        this.Icons = new IconRepository(client);
        this.ImportExport = new ImportExportActions(client);
        this.Interruptions = new InterruptionRepository(client);
        this.Invitations = new InvitationRepository(client);
        this.LetsEncryptConfiguration = new LetsEncryptConfigurationRepository(client);
        this.LibraryVariableSets = new LibraryVariableRepository(client);
        this.Licenses = new LicenseRepository(client);
        this.Lifecycles = new LifecycleRepository(client);
        this.MachinePolicies = new MachinePolicyRepository(client);
        this.MachineRoles = new MachineRoleRepository(client);
        this.MachineShells = new MachineShellsRepository(client);
        this.Machines = new MachineRepository(client);
        this.MaintenanceConfiguration = new MaintenanceConfigurationRepository(client);
        this.OctopusServerNodes = new OctopusServerNodeRepository(client);
        this.RetentionDefaultConfiguration = new RetentionDefaultConfigurationRepository(client);
        this.Runbooks = new RunbookRepository(client);
        this.RunbookProcess = new RunbookProcessRepository(client);
        this.RunbookSnapshots = new RunbookSnapshotRepository(client);
        this.RunbookRuns = new RunbookRunRepository(client);
        this.Packages = new PackageRepository(client);
        this.PerformanceConfiguration = new PerformanceConfigurationRepository(client);
        this.PermissionDescriptions = new PermissionDescriptionRepository(client);
        this.Progression = new ProgressionRepository(client);
        this.ProjectGroups = new ProjectGroupRepository(client);
        this.Projects = new ProjectRepository(client);
        this.Channels = new ChannelRepository(this.Projects, client);
        this.ProjectTriggers = new ProjectTriggerRepository(client);
        this.Proxies = new ProxyRepository(client);
        this.Releases = new ReleasesRepository(client);
        this.Scheduler = new SchedulerRepository(client);
        this.ScopedUserRoles = new ScopedUserRoleRepository(client);
        this.ServerStatus = new ServerStatusRepository(client);
        this.ServerConfiguration = new ServerConfigurationRepository(client);
        this.Settings = new SettingsRepository(client);
        this.SmtpConfiguration = new SmtpConfigurationRepository(client);
        this.Spaces = new SpaceRepository(client);
        this.Subscriptions = new SubscriptionRepository(client);
        this.TagSets = new TagSetRepository(client);
        this.Tasks = new TaskRepository(client);
        this.Teams = new TeamRepository(client);
        this.Tenants = new TenantRepository(client);
        this.TenantVariables = new TenantVariableRepository(client);
        this.UpgradeConfiguration = new UpgradeConfigurationRepository(client);
        this.UserIdentityMetadata = new UserIdentityMetadataRepository(client);
        this.UserOnboarding = new UserOnBoardingRepository(client);
        this.UserPermissions = new UserPermissionRepository(client);
        this.TeamMembership = new TeamMembershipRepository(client);
        this.Telemetry = new TelemetryRepository(client);
        this.TelemetryConfiguration = new TelemetryConfigurationRepository(client);
        this.UserRoles = new UserRoleRepository(client);
        this.Users = new UserRepository(client);
        this.Variables = new VariableRepository(client);
        this.getServerInformation = client.getServerInformation.bind(client);
        this.WorkerPools = new WorkerPoolsRepository(client);
        this.WorkerShells = new WorkerShellsRepository(client);
        this.Workers = new WorkerRepository(client);
        this.StepPackageRepository = new StepPackageRepository(client);
        this.StepPackageDeploymentTarget = new StepPackageDeploymentTargetRepository(client);
        this.GitCredentials = new GitCredentialRepository(client);
    }

    public resolve = (path: string, uriTemplateParameters?: RouteArgs) => this.client.resolve(path, uriTemplateParameters);
    public get spaceId(): string | null {
        return this.client.spaceId;
    }

    switchToSpace(spaceId: string): Promise<void> {
        return this.client.switchToSpace(spaceId);
    }

    switchToSystem(): void {
        this.client.switchToSystem();
    }

    async forSpace(spaceId: string): Promise<OctopusSpaceRepository> {
        if (this.spaceId !== spaceId) {
            return new Repository(await this.client.forSpace(spaceId));
        }

        return this;
    }

    forSystem(): OctopusSystemRepository {
        return new Repository(this.client.forSystem());
    }
}
export default Repository;
