import amplitude from "amplitude-js";
import jsSHA from "jssha";
import { anonymizeString } from "~/analytics/anonymizeString";
import { getServerHost } from "~/client/utils";
import { getTutorialPackageExperimentVariation } from "~/components/GettingStarted/gettingStartedExperiments";
import Environment from "~/environment";
import type { AnalyticFields, AnalyticSession } from "./AnalyticSession";

export class AmplitudeSession implements AnalyticSession {
    private api: amplitude.AmplitudeClient;

    private constructor(private readonly installationId: string, userId: string, userName: string, emailAddress?: string) {
        const sha = new jsSHA("SHA-1", "TEXT", { encoding: "UTF8" });
        sha.update(emailAddress ?? `${installationId}-${userId}`);
        const analyticsUserId = sha.getHash("HEX");

        this.api = amplitude.getInstance();
        this.api.options.apiEndpoint = `${getServerHost()}/api/telemetry/process`;
        this.api.options.forceHttps = !Environment.isInDevelopmentMode();
        // The real API token is injected in the Telemetry Proxy before forwarding to Amplitude, but Amplitude.JS requires one anyway.
        this.api.init("dummy-token");
        this.api.setUserId(analyticsUserId);

        // Setting the user properties to indicate which variations of experiments they are seeing (https://help.amplitude.com/hc/en-us/articles/115001580108-Analyze-A-B-test-results-in-Amplitude)
        this.api.setUserProperties({ "user-onboarding-experiments": [getTutorialPackageExperimentVariation(userId)] });
    }

    public static Start(installationId: string, userId: string, userName: string, emailAddress?: string): AnalyticSession {
        return new AmplitudeSession(installationId, userId, userName, emailAddress);
    }

    track(name: string, event: AnalyticFields): void {
        this.api.logEvent(name, { ...event });
    }

    end() {
        try {
            this.api.setUserId(null);
            this.api.regenerateDeviceId();
        } catch (err) {
            console.error(`(Amplitude) error in 'reset()'`);
            console.error(err);
        }
    }

    anonymize(value: string): string {
        return anonymizeString(`${this.installationId}-${value}`);
    }
}
