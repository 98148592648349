import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { ActionExecutionLocation } from "~/client/resources";
import Roles from "~/components/Actions/Roles";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import type { TerraformProperties } from "./terraformAction";
import TerraformActionEdit from "./terraformAction";

class TerraformApplyActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (
            <div>
                Apply a Terraform template
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

class TerraformApplyActionEdit extends BaseComponent<ActionEditProps<TerraformProperties, ScriptPackageProperties>, never> {
    render() {
        return (
            <TerraformActionEdit
                projectId={this.props.projectId}
                properties={this.props.properties}
                packages={this.props.packages}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                plugin={this.props.plugin}
                errors={this.props.errors}
                getFieldError={this.props.getFieldError}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                actionName={"apply"}
                expandedByDefault={this.props.expandedByDefault}
                additionalParametersLink="TerraformApply"
                parameters={this.props.parameters}
            />
        );
    }
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.TerraformApply",
    summary: (properties, targetRolesAsCSV) => <TerraformApplyActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => true,
    canBeChild: true,
    edit: TerraformApplyActionEdit,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    getInitialProperties: () => ({
        "Octopus.Action.GoogleCloud.UseVMServiceAccount": "True",
        "Octopus.Action.GoogleCloud.ImpersonateServiceAccount": "False",
    }),
});
