import * as React from "react";
import { useCallback } from "react";
import type { MenuNode } from "~/components/LinksMenu/MenuNode";
import { isMenuGroup } from "~/components/LinksMenu/MenuNode";
import { SimpleMenu } from "~/primitiveComponents/navigation/Menu/SimpleMenu";
import type { MenuTargetAriaAttributes } from "~/primitiveComponents/navigation/Menu/useMenuState";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import type { SimpleMenuItem } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";

interface LinksMenuProps {
    items: MenuNode[];
    label?: string | JSX.Element;
    icon?: string;
    activeItemClassName?: string;
}

export function LinksMenu(props: LinksMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();

    // TODO: Pass a better accessible name in
    const accessibleName = "links";
    return (
        <>
            <LinksMenuButton onClick={openMenu} label={props.label} icon={props.icon} activeItemClassName={props.activeItemClassName} ariaAttributes={buttonAriaAttributes} />
            <SimpleMenu menuState={menuState} items={props.items.map(convertMenuNodeToSimpleMenuItem)} accessibleName={accessibleName} />
        </>
    );
}

function convertMenuNodeToSimpleMenuItem(menuNode: MenuNode): SimpleMenuItem {
    if (isMenuGroup(menuNode)) {
        return { type: "nested-menu", children: menuNode.children.map(convertMenuNodeToSimpleMenuItem), label: menuNode.label };
    }
    return { type: "internal-link", path: menuNode.url, label: menuNode.text, showAsActive: menuNode.exact ? "if path matches exactly" : "if path partially matches" };
}

interface LinksMenuButtonProps {
    onClick: (event: React.MouseEvent) => void;
    label?: string | JSX.Element;
    icon?: string;
    activeItemClassName?: string;
    ariaAttributes: MenuTargetAriaAttributes;
}

function LinksMenuButton({ icon, label, onClick, activeItemClassName, ariaAttributes }: LinksMenuButtonProps) {
    const onClickCallback = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            if (event.currentTarget.contains(event.target as HTMLElement)) {
                event.preventDefault();
                onClick(event);
            }
        },
        [onClick]
    );
    return (
        <a onClick={onClickCallback} href="#" className={activeItemClassName} {...ariaAttributes}>
            {icon && <em className={icon} />}
            {!icon && <span>{label}</span>}
            {!icon && <em className="fa fa-caret-down" />}
        </a>
    );
}
