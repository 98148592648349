/* eslint-disable @typescript-eslint/consistent-type-assertions */

import React from "react";
import { useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import GitRefSelector from "~/areas/projects/components/GitRefSelector/GitRefSelector";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import type { GitPersistenceSettings } from "~/client/resources";
import { ProcessType } from "~/client/resources";
import { toGitBranch } from "~/client/resources/versionControlledResource";
import type { GitBranch, GitReference } from "~/client/resources/versionControlledResource";
import ActionButton from "~/components/Button/index";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { ExpandableFormSection } from "~/components/form/index";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";
import type { WithOptionalRunbookContextInjectedProps } from "../Runbooks/RunbookContext";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import styles from "./style.module.less";
const keycode = require("keycode");

interface GlobalConnectedProps {
    isConfigurationAsCodeForRunbooksEnabled?: boolean;
}

type BranchSelectorProps = WithProjectContextInjectedProps & WithOptionalRunbookContextInjectedProps & GlobalConnectedProps & RouteComponentProps<ProjectRouteParams> & { disabled?: boolean; onCreateBranch?: (newBranchName: string) => Promise<void> };

const BranchSelectorInternal: React.FC<BranchSelectorProps> = (props: BranchSelectorProps) => {
    const projectContext = useProjectContext();
    const project = props.projectContext.state.model;

    const onBranchCreated = async (newBranch: GitBranch) => {
        await props.projectContext.actions.onBranchSelected(project, newBranch);
    };

    const onChange = (gitRef: GitReference) => {
        // Note: When we have a newly selected branch, all we need to do is push it into our router history
        // Note: The props will then flow back into BranchSelector and its initialization useEffect will be triggered
        // Note: As it has a dependency on props.projectContext.state.branch, which in turn changes with there is a new branchName route match
        if (gitRef.GitRef) {
            projectContext.actions.changeGitRef(gitRef.GitRef);
        }
    };

    const isVersionControlled = isVersionControlledProcess(props.projectContext.state.model.IsVersionControlled, props.runbookContext ? ProcessType.Runbook : ProcessType.Deployment, !!props.isConfigurationAsCodeForRunbooksEnabled);
    const emptyElementNecessaryForActionListSpacing = <div />;
    return isVersionControlled && props.projectContext.state.gitRef ? (
        <>
            <div id="branchSelector" className={styles.selectContainer}>
                <GitRefSelector style={"grey"} onChange={onChange} projectContext={props.projectContext} selectedGitRef={getSelectedItem(props)} onBranchCreated={onBranchCreated} />
            </div>
        </>
    ) : (
        emptyElementNecessaryForActionListSpacing
    );
};

const getSelectedItem = (props: BranchSelectorProps): GitReference => {
    if (!props.disabled && props.projectContext.state.gitRef) {
        return { GitRef: props.projectContext.state.gitRef.CanonicalName };
    }
    const vcs = props.projectContext.state.model.PersistenceSettings as GitPersistenceSettings;
    return { GitRef: toGitBranch(vcs.DefaultBranch) };
};

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;
export const BranchSelector: React.FC<RouteComponentProps<ProjectRouteParams> & { disabled?: boolean; onCreateBranch?: (newBranchName: string) => Promise<void> }> = (props) => {
    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    const isFeatureEnabled = useEnabledFeatureToggle("SkunkworksFeatureToggle");
    if (isFeatureEnabled) {
        console.log("Skunkworks Feature Is Available");
    }

    return (
        <div className={styles.branchSelectorContainer}>
            <FeatureToggleVisibility toggle={"SkunkworksFeatureToggle"}>
                <ExpandableFormSection errorKey="Skunk" title="Skunkworks Feature" summary={"This is a secret setting"}>
                    <ActionButton onClick={() => window.alert("What Have You Done!?!")} label="Do Not Press" />
                </ExpandableFormSection>
            </FeatureToggleVisibility>
            <BranchSelectorInternal {...props} projectContext={projectContext} runbookContext={runbookContext} isConfigurationAsCodeForRunbooksEnabled={isConfigurationAsCodeForRunbooksEnabled} disabled={props.disabled} />
        </div>
    );
};

export default withRouter(BranchSelector);
