/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import cn from "classnames";
import type { Dictionary } from "lodash";
import * as _ from "lodash";
import { cloneDeep, flatten, groupBy, isEqual, keyBy, keys, uniq } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import useKeyedItemAccessForConfigurationAsCode from "~/areas/projects/components/Process/Contexts/useKeyedItemAccessForConfigurationAsCode";
import type { PackageEditInfo } from "~/areas/projects/components/Releases/packageModel";
import { VersionType } from "~/areas/projects/components/Releases/packageModel";
import { useProjectContext } from "~/areas/projects/context";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import type { ResourcesByNameOrId } from "~/client/repositories/basicRepository";
import { PackageReferenceNamesMatch } from "~/client/resources";
import type { ChannelResource } from "~/client/resources/channelResource";
import type { DeploymentProcessResource } from "~/client/resources/deploymentProcessResource";
import type { FeedResource, FeedType } from "~/client/resources/feedResource";
import { Permission } from "~/client/resources/permission";
import type { ProjectResource } from "~/client/resources/projectResource";
import { HasVersionControlledPersistenceSettings } from "~/client/resources/projectResource";
import type { ReleaseResource } from "~/client/resources/releaseResource";
import type { ReleaseTemplateResource } from "~/client/resources/releaseTemplateResource";
import type { ResourceCollection } from "~/client/resources/resourceCollection";
import { getGitRefType, IsDefaultBranch, toGitBranch, toGitRefShort } from "~/client/resources/versionControlledResource";
import type { IHaveGitReference, GitReference } from "~/client/resources/versionControlledResource";
import { repository } from "~/clientInstance";
import { ActionButtonType } from "~/components/Button/ActionButton";
import type { GlobalDispatchControlExpandersProps } from "~/components/ControlExpanders/ControlExpanders";
import { useSetExpanderState } from "~/components/ControlExpanders/useSetExpanderState";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { default as FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import KeyedItemAccessProvider from "~/components/KeyAccessProvider/KeyedItemAccessProvider";
import type { KeyedItemProps } from "~/components/KeyAccessProvider/types";
import LoadMoreWrapper from "~/components/LoadMoreWrapper/LoadMoreWrapper";
import MoreInfo from "~/components/MoreInfo/MoreInfo";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import WarningIcon from "~/components/WarningIcon/index";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import type { SummaryNode } from "~/components/form/Sections/ExpandableFormSection";
import { required } from "~/components/form/Validators";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import type { DataTableRowProps } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";
import Checkbox from "~/primitiveComponents/form/Checkbox/Checkbox";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import Select from "~/primitiveComponents/form/Select/Select";
import Text from "~/primitiveComponents/form/Text/Text";
import routeLinks from "~/routeLinks";
import { GitRefChip } from "../GitRefChip/GitRefChip";
import MissingProcessStepsMessage from "../MissingProcessStepsMessage";
import PackageListDialogContent from "../PackageListDialog/PackageListDialogContent";
import GitRefFormSection from "./GitRefFormSection";
import VersionNumberInfo from "./VersionNumberInfo";
import styles from "./style.module.less";

const versionExpanderKey = "version";

interface ReleaseModel {
    packages: PackageEditInfo[];
    channels: ResourceCollection<ChannelResource>;
    release: ReleaseResource;
}

interface ReleaseState extends OptionalFormBaseComponentState<ReleaseModel> {
    project: ProjectResource;
    originalVersion: string;
    originalChannelId: string;
    deploymentProc: DeploymentProcessResource;
    template: ReleaseTemplateResource;
    violatedPackages: string[];
    seeVersionExample: boolean;
    isNew: boolean;
    redirect: boolean;
    deleted: boolean;
    defaultCheckModel: ReleaseModel;
    feeds: ResourcesByNameOrId<FeedResource>;
    hasInitialModelUpdateCompleted: boolean; // To stop the user being able to interact with the release version input before we've finished loading version rules.
    busyTaskIsRunning: boolean;
    checkingDeploymentProcess: boolean;
    missingPackages: string[];
}

const DebounceText = DebounceValue(Text);
export type channelFilters = { versionRange?: string; preReleaseTag?: string };

interface ReleaseEditProps {
    channelId?: string;
    releaseVersion?: string;
}

interface CreateOrEditReleaseInternalProps extends ReleaseEditProps, GlobalDispatchControlExpandersProps, WithProjectContextInjectedProps, KeyedItemProps {
    trackAction: AnalyticTrackedActionDispatcher;
}

interface PackageDataTableRowProps extends DataTableRowProps {
    accessibleName: string;
}

class PackageDataTableRow extends React.Component<PackageDataTableRowProps> {
    render() {
        const { accessibleName, ...otherProps } = this.props;
        const accessibilityProps = {
            role: "radiogroup",
            "aria-label": accessibleName,
        };

        return (
            <DataTableRow {...otherProps} {...accessibilityProps}>
                {this.props.children}
            </DataTableRow>
        );
    }
}

class CreateOrEditReleaseInternal extends FormBaseComponent<CreateOrEditReleaseInternalProps, ReleaseState, ReleaseModel> {
    memoizedRepositoryChannelsRuleTest = _.memoize(
        (version: string, versionRange: string, preReleaseTag: string, feedType: FeedType) =>
            repository.Channels.ruleTest({
                version,
                versionRange,
                preReleaseTag,
                feedType,
            }),
        (...args) => args.join("_")
    );

    constructor(props: CreateOrEditReleaseInternalProps) {
        super(props);
        this.state = {
            project: null!,
            originalVersion: null!,
            originalChannelId: null!,
            deploymentProc: null!,
            template: null!,
            violatedPackages: [],
            seeVersionExample: false,
            isNew: true,
            redirect: false,
            deleted: false,
            defaultCheckModel: null!,
            feeds: {},
            hasInitialModelUpdateCompleted: false,
            busyTaskIsRunning: false,
            checkingDeploymentProcess: false,
            missingPackages: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project, projectContextRepository } = this.props.projectContext.state;
            const deploymentSettings = await this.props.projectContext.state.projectContextRepository.DeploymentSettings.get();
            const channels = await repository.Projects.getChannels(project);

            let release = null;
            let originalVersion: string = null!;
            let originalChannelId: string = null!;
            let deploymentProcPromise = null;
            let isNew = true;
            let cleanModel: any = null!;
            if (this.props.releaseVersion) {
                release = await repository.Projects.getReleaseByVersion(project, this.props.releaseVersion);
                originalVersion = release.Version;
                originalChannelId = release.ChannelId;
                isNew = false;
                deploymentProcPromise = projectContextRepository.DeploymentProcesses.getForRelease(release);
            } else {
                const defaultChannel = channels.Items.find((x) => x.IsDefault);
                release = {
                    ...this.defaultGitProperties(),
                    ProjectId: project.Id,
                    ChannelId: this.props.channelId ? this.props.channelId : defaultChannel ? defaultChannel.Id : channels.Items[0].Id,
                };
                deploymentProcPromise = this.props.projectContext.state.projectContextRepository.DeploymentProcesses.get();
                cleanModel = {
                    channelId: null,
                    version: null,
                    packages: [],
                    releaseNotes: null,
                    channels: [],
                    release: null,
                    options: null,
                };
            }

            const model: ReleaseModel = { release: release as ReleaseResource, packages: [], channels };
            const deploymentProc = await deploymentProcPromise;

            if (isNew) {
                model.release.ReleaseNotes = deploymentSettings.ReleaseNotesTemplate || null!;
            }

            await this.loadTemplate(model, deploymentProc, isNew);
            this.setState({
                project,
                originalVersion,
                originalChannelId,
                deploymentProc,
                model,
                cleanModel: cleanModel ? cleanModel : cloneDeep(model),
                defaultCheckModel: cloneDeep(model),
                isNew,
                hasInitialModelUpdateCompleted: true,
            });
        });
    }

    render() {
        const projectRoutes = routeLinks.project(this.props.projectContext.state.model.Slug);
        if (this.state.redirect) {
            return <InternalRedirect to={projectRoutes.release(this.state.model!.release.Version).root} push={true} />;
        }
        if (this.state.deleted) {
            return <InternalRedirect to={projectRoutes.releases} push={true} />;
        }

        const overFlowActions =
            !this.state.isNew && !!this.state.model && !!this.state.model.release
                ? [
                      OverflowMenuItems.deleteItemDefault(
                          "release",
                          this.handleDeleteConfirm,
                          {
                              permission: Permission.ReleaseDelete,
                              project: this.state.project && this.state.project.Id,
                              tenant: "*",
                          },
                          "The release and any of its deployments will be permanently deleted and they will disappear from all dashboards."
                      ),
                      [
                          OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.release.Id]), {
                              permission: Permission.EventView,
                              wildcard: true,
                          }),
                      ],
                  ]
                : [];

        let title = "Release";
        if (this.state.project) {
            title = this.state.isNew ? "Create release for " + this.state.project.Name : this.state.model && this.state.model.release ? "Edit release " + this.state.model.release.Version : "Edit release";
        }

        return (
            <FormPaperLayout
                busy={this.state.busy}
                errors={this.errors}
                title={title}
                breadcrumbTitle={"Releases"}
                breadcrumbPath={this.state.project ? routeLinks.project(this.state.project).releases : undefined}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                disableDirtyFormChecking={this.state.isNew && (this.disableDirtyFormCheck() || !this.deploymentProcessHasSteps(this.state.deploymentProc))}
                savePermission={{
                    permission: this.state.isNew ? Permission.ReleaseCreate : Permission.ReleaseEdit,
                    project: this.state.project && this.state.project.Id,
                    tenant: "*",
                }}
                onSaveClick={this.handleSaveClick}
                overFlowActions={overFlowActions}
                saveText="Release saved"
                forceDisableFormSaveButton={!this.deploymentProcessHasSteps(this.state.deploymentProc) || this.state.busyTaskIsRunning}
            >
                {this.state.originalChannelId && this.state.originalChannelId !== this.state.model!.release.ChannelId && (
                    <Callout title="Note" type={CalloutType.Danger}>
                        <p>Changing the channel of this release is only allowed where all the steps previously applicable (with respect to any channel filters) will still be applicable with the new channel.</p>
                        <p>Please make sure you are aware which steps are now active as you may be unable to reverse this change.</p>
                    </Callout>
                )}
                {this.state.model && this.state.hasInitialModelUpdateCompleted && (
                    <TransitionAnimation>
                        {!this.deploymentProcessHasSteps(this.state.deploymentProc) ? (
                            !this.state.checkingDeploymentProcess ? (
                                <MissingProcessStepsMessage project={this.state.project} {...(this.state.project?.IsVersionControlled ? { branchName: this.state.model && this.state.model.release.VersionControlReference.GitRef } : {})} />
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )}
                        {this.state.project && this.state.project.IsVersionControlled && (this.state.isNew || !!this.state.model.release.VersionControlReference?.GitRef) && (
                            <ExpandableFormSection errorKey="gitRef" title="Git Reference" summary={this.gitRefSummary()} help={this.gitRefHelp()}>
                                <GitRefFormSection value={this.state.model.release.VersionControlReference} onChange={this.onGitRefChange} canResetToDefaultBranch={true} disabled={!this.state.isNew} />
                            </ExpandableFormSection>
                        )}
                        {this.state.model.channels && this.state.model.channels.Items && this.state.model.channels.Items.length > 1 && (
                            <ExpandableFormSection
                                errorKey="channel"
                                title="Channel"
                                focusOnExpandAll
                                summary={this.state.model.release.ChannelId ? Summary.summary(this.state.model.channels.Items.find((x) => x.Id === this.state.model!.release.ChannelId)!.Name) : Summary.placeholder("Please select a channel")}
                                help={this.state.model.channels.Items.length > 1 ? "Select a channel for this release" : this.state.model.channels.Items[0].Name}
                            >
                                {this.state.model.channels && this.state.model.channels.Items.length > 1 && (
                                    <Select
                                        value={this.state.model.release.ChannelId}
                                        onChange={async (channelId) => this.onChannelChanged(channelId!)}
                                        items={this.state.model.channels.Items.map((c) => ({
                                            text: c.Name,
                                            value: c.Id,
                                        }))}
                                        label="Channel"
                                        disabled={this.state.busyTaskIsRunning}
                                    />
                                )}
                            </ExpandableFormSection>
                        )}
                        <ExpandableFormSection
                            errorKey={versionExpanderKey}
                            title="Version"
                            summary={this.state.model.release.Version ? Summary.summary(this.state.model.release.Version) : Summary.placeholder("Please enter a version")}
                            help="Enter a unique version number for this release with at least two parts."
                        >
                            <Text value={this.state.model.release.Version} onChange={(version) => this.setChildState2("model", "release", { Version: version })} label="Version" validate={required("Please enter a version number")} />
                            {this.state.project && this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion && (
                                <div>
                                    Most recent release: <InternalLink to={routeLinks.project(this.state.project).release(this.state.template.LastReleaseVersion).root}>{this.state.template.LastReleaseVersion}</InternalLink>
                                </div>
                            )}
                            <MoreInfo>
                                <VersionNumberInfo />
                            </MoreInfo>
                        </ExpandableFormSection>

                        {/* TODO: Pull PackageSelector into it's own component */}
                        {this.state.model.packages && this.state.model.packages.length > 0 && (
                            <ExpandableFormSection errorKey="packages" title="Packages" fillCardWidth={CardFill.FillAll} summary={this.packagesSummary()} help="Select package(s) for this release">
                                <div className={styles.packageTableContainer}>
                                    <DataTable className={styles.packageTable}>
                                        <DataTableHeader>
                                            <DataTableRow>
                                                <DataTableHeaderColumn>
                                                    <div className={styles.actionName}>Step</div>
                                                    Package
                                                </DataTableHeaderColumn>
                                                <DataTableHeaderColumn>
                                                    <ToolTip key="latest" content="The most recent package that we could find in the package feed that matches channel rules">
                                                        <ExternalLink href="LatestPackage">Latest</ExternalLink>
                                                        {this.state.model.packages && this.state.model.packages.length > 1 && (
                                                            <React.Fragment>
                                                                <br />
                                                                <Note>
                                                                    <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.latest, null!, false)}>
                                                                        Select all
                                                                    </a>
                                                                </Note>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolTip>
                                                </DataTableHeaderColumn>
                                                {this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion && (
                                                    <DataTableHeaderColumn>
                                                        <ToolTip key="last" content={"The version selected for release " + this.state.template.LastReleaseVersion}>
                                                            Last
                                                        </ToolTip>
                                                        {this.state.model.packages && this.state.model.packages.length > 1 && (
                                                            <React.Fragment>
                                                                <br />
                                                                <Note>
                                                                    <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.last, null!, false)}>
                                                                        Select all
                                                                    </a>
                                                                </Note>
                                                            </React.Fragment>
                                                        )}
                                                    </DataTableHeaderColumn>
                                                )}
                                                <DataTableHeaderColumn>
                                                    Specific
                                                    {this.state.model.packages && this.state.model.packages.length > 1 && this.state.model.release && this.state.model.release.Version && (
                                                        <React.Fragment>
                                                            <br />
                                                            <Note>
                                                                <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.specific, this.state.model!.release.Version, true)}>
                                                                    Select current release version
                                                                </a>
                                                            </Note>
                                                        </React.Fragment>
                                                    )}
                                                </DataTableHeaderColumn>
                                            </DataTableRow>
                                        </DataTableHeader>
                                        <DataTableBody>
                                            {this.state.model && this.state.model.packages && (
                                                <LoadMoreWrapper
                                                    items={this.state.model.packages}
                                                    renderLoadMore={(children) => {
                                                        return (
                                                            <DataTableRow>
                                                                <DataTableRowColumn colSpan={4}>{children}</DataTableRowColumn>
                                                            </DataTableRow>
                                                        );
                                                    }}
                                                    renderItem={(pack) => (
                                                        <PackageDataTableRow key={this.createPackageKey(pack)} accessibleName={`${pack.ActionName} for ${pack.ProjectName ? pack.ProjectName : pack.PackageId}`}>
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.packageColumn)}>
                                                                <div className={styles.actionName}>
                                                                    {pack.ActionName}
                                                                    {!!pack.PackageReferenceName && <span>/{pack.PackageReferenceName}</span>}
                                                                </div>
                                                                <ToolTip key="packageId" content={pack.ProjectName ? pack.ProjectName : `${pack.PackageId} from ${pack.FeedName}`}>
                                                                    {pack.ProjectName ? pack.ProjectName : pack.PackageId}
                                                                </ToolTip>
                                                                {this.state.missingPackages.includes(pack.PackageId) && (
                                                                    <span className={styles.missingPackageIcon}>
                                                                        <ToolTip key="missingPackage" content={`${pack.PackageId} could not be found in ${pack.FeedName}`}>
                                                                            <WarningIcon />
                                                                        </ToolTip>
                                                                    </span>
                                                                )}
                                                            </DataTableRowColumn>
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.latestColumn)}>
                                                                {this.buildRadioButton(pack, pack.LatestVersion, VersionType.latest, this.state.model!)}
                                                            </DataTableRowColumn>
                                                            {this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion && (
                                                                <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.lastColumn)}>
                                                                    <div className={styles.specificVersionDiv}>
                                                                        {pack.LastReleaseVersion && (
                                                                            <div>
                                                                                {!pack.IsLastReleaseVersionValid ? (
                                                                                    <ToolTip content="Package version does not satisfy channel rules">
                                                                                        {this.buildRadioButton(pack, pack.LastReleaseVersion, VersionType.last, this.state.model!)}
                                                                                    </ToolTip>
                                                                                ) : (
                                                                                    <div>{this.buildRadioButton(pack, pack.LastReleaseVersion, VersionType.last, this.state.model!)}</div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </DataTableRowColumn>
                                                            )}
                                                            <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.specificColumn)}>
                                                                <div className={styles.specificVersionDiv}>
                                                                    <div className={styles.inlineDiv}>{this.buildRadioButton(pack, pack.SpecificVersion, VersionType.specific, this.state.model!)}</div>
                                                                    <div className={styles.inlineDiv}>
                                                                        <div className={styles.editVersionArea}>
                                                                            <DebounceText
                                                                                debounceDelay={500}
                                                                                className={styles.versionTextbox}
                                                                                placeholder="Enter a version"
                                                                                accessibleName="Specific version"
                                                                                value={pack.SpecificVersion}
                                                                                onChange={async (version) => {
                                                                                    await this.specificVersionSelected(this.state.model!, pack, version);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.inlineDiv}>{this.packageVersionsButton(pack)}</div>
                                                                </div>
                                                            </DataTableRowColumn>
                                                        </PackageDataTableRow>
                                                    )}
                                                />
                                            )}
                                        </DataTableBody>
                                    </DataTable>
                                </div>
                                {this.state.violatedPackages && this.state.violatedPackages.length > 0 && (
                                    <Callout type={CalloutType.Warning} title="Version satisfaction">
                                        <Checkbox
                                            label="Force Version Selection"
                                            accessibleName="Force Version Selection"
                                            value={this.state.model.release.IgnoreChannelRules}
                                            onChange={(ignoreChannelRules) => {
                                                this.setChildState2("model", "release", { IgnoreChannelRules: ignoreChannelRules });
                                            }}
                                        />
                                        <p>
                                            You have selected a package version that violates the version rules specified by the selected channel. You must explicitly check the box above to force this selection and ignore the channel rules for the
                                            release to be created.
                                        </p>
                                    </Callout>
                                )}
                            </ExpandableFormSection>
                        )}
                        <ExpandableFormSection
                            errorKey="notes"
                            title="Release Notes"
                            summary={this.state.model.release.ReleaseNotes ? Summary.summary("Release notes have been provided") : Summary.placeholder("No release notes provided")}
                            help={this.buildReleaseNoteHelpInfo()}
                        >
                            <MarkdownEditor value={this.state.model.release.ReleaseNotes} label="Release notes" onChange={(releaseNotes) => this.setChildState2("model", "release", { ReleaseNotes: releaseNotes })} />
                        </ExpandableFormSection>
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    private setAllPackageVersionsTo = (e: React.MouseEvent, versionType: VersionType, specificVersion: string, includeConfirmation: boolean) => {
        e.preventDefault();
        if (includeConfirmation && !confirm(`This will set all packages to version ${specificVersion}. Are you sure this version exists for all the packages?`)) {
            return;
        }

        const model = this.state.model!;
        const release = model.release;
        release.SelectedPackages = [];
        for (const selection of this.state.model!.packages) {
            selection.VersionType = versionType;
            selection.SpecificVersion = specificVersion;
            release.SelectedPackages.push({
                ActionName: selection.ActionName,
                Version: specificVersion,
                PackageReferenceName: selection.PackageReferenceName,
            });
        }

        this.setState({ model });
    };

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: this.state.isNew ? "Create Release" : "Edit Release",
                isDefaultBranch: IsDefaultBranch(this.state.project, this.props.projectContext.state.gitRef?.CanonicalName),
                gitRefType: getGitRefType(this.props.projectContext.state.gitRef?.CanonicalName),
            };

            await this.props.trackAction("Save a Release", ev, async (cb: AnalyticErrorCallback) => {
                const model = this.state.model;
                const release = model!.release;
                release.SelectedPackages = [];
                for (const selection of this.state.model!.packages) {
                    let selectedVersion = "";
                    if (selection.VersionType === VersionType.latest) {
                        selectedVersion = selection.LatestVersion;
                    } else if (selection.VersionType === VersionType.last) {
                        selectedVersion = selection.LastReleaseVersion;
                    } else if (selection.VersionType === VersionType.specific) {
                        selectedVersion = selection.SpecificVersion;
                    }

                    release.SelectedPackages.push({
                        ActionName: selection.ActionName,
                        Version: selectedVersion,
                        PackageReferenceName: selection.PackageReferenceName,
                    });
                }

                const newRelease = await save(release);
                const newModel: ReleaseModel = {
                    release: newRelease,
                    packages: this.state.model!.packages,
                    channels: this.state.model!.channels,
                };
                this.setState({
                    model: newModel,
                    cleanModel: cloneDeep(newModel),
                    redirect: true,
                });

                if (!newRelease || !newRelease.Id) {
                    cb("Failed to save release");
                }
            });
        });

        function save(release: ReleaseResource) {
            if (release.Links) {
                return repository.Releases.modify(release);
            }
            return repository.Releases.create(release);
        }
    };

    private defaultGitProperties(): IHaveGitReference {
        const gitRef = this.props.projectContext.state.gitRef;
        const project = this.props.projectContext.state.model;
        if (gitRef) {
            return {
                VersionControlReference: {
                    GitRef: gitRef.CanonicalName,
                },
            };
        }

        if (HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
            return {
                VersionControlReference: {
                    GitRef: toGitBranch(project.PersistenceSettings.DefaultBranch),
                },
            };
        }

        return {
            VersionControlReference: {
                GitRef: undefined,
            },
        };
    }

    private packageVersionsButton = (pack: PackageEditInfo) => {
        const feed = this.state.feeds[pack.FeedId];

        const openDialog = (disabled: boolean) => (
            <OpenDialogButton type={ActionButtonType.Secondary} wideDialog={true} disabled={disabled} label="Select Version" accessibleName={`Select version of ${pack.PackageReferenceName} for ${pack.ActionName}`}>
                <PackageListDialogContent
                    package={pack}
                    feed={feed}
                    onVersionSelected={async (version) => {
                        await this.specificVersionSelected(this.state.model!, pack, version);
                    }}
                    channelFilters={this.getChannelFilters(this.state.model!, pack.ActionName, pack.PackageReferenceName)}
                />
            </OpenDialogButton>
        );

        if (feed) {
            return openDialog(false);
        }
        return <ToolTip content="No feed available. Package step may be using a variable as feed.">{openDialog(true)}</ToolTip>;
    };

    private gitRefHelp(): string {
        return this.state.isNew ? "Choose a GitRef for this release to use." : "The GitRef for an existing release cannot be modified.";
    }

    private gitRefSummary(): SummaryNode {
        if (!this.state.model || !this.state.model.release.VersionControlReference.GitRef) {
            return Summary.placeholder("Select a GitRef");
        }
        const gitRef = this.state.model.release.VersionControlReference.GitRef;
        if (!HasVersionControlledPersistenceSettings(this.state.project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
        const defaultBranch = this.state.project.PersistenceSettings.DefaultBranch;
        const chip = <GitRefChip vcsRef={{ GitRef: gitRef }} />;
        return toGitRefShort(gitRef) === toGitRefShort(defaultBranch) ? Summary.default(chip) : Summary.summary(chip);
    }

    private packagesSummary = () => {
        if (!this.state.model!.packages || this.state.model!.packages.length === 0) {
            return Summary.placeholder("No package is included");
        }

        const packageVersions = this.state.model!.packages.map((p) => this.getPackageInfoVersion(p));

        if (packageVersions.length === 1) {
            return Summary.summary(
                packageVersions[0] ? (
                    "1 package included, at version " + packageVersions[0]
                ) : (
                    <span>
                        1 package included, <strong>no version specified</strong>
                    </span>
                )
            );
        }

        const firstVersion = packageVersions.find((p) => !!p);
        const noneHaveVersion = !firstVersion;
        const allOnSameVersion = firstVersion && packageVersions.every((p) => p === firstVersion);
        const numberWithNoVersion = packageVersions.filter((p) => !p).length;
        const packagesIncluded = packageVersions.length + " packages included";
        const noVersionSummary = numberWithNoVersion ? (
            <span>
                ,{" "}
                <strong>
                    {numberWithNoVersion} {numberWithNoVersion === 1 ? "has" : "have"} no version selected
                </strong>
            </span>
        ) : (
            <span />
        );
        const versionSummary = allOnSameVersion ? ", all at version " + firstVersion : noneHaveVersion ? "" : ", with a mix of versions";
        return Summary.summary(
            <span>
                {packagesIncluded}
                {versionSummary}
                {noVersionSummary}
            </span>
        );
    };

    private getPackageInfoVersion(info: PackageEditInfo): string {
        switch (info.VersionType) {
            case VersionType.specific:
                return info.SpecificVersion;
            case VersionType.last:
                return info.LastReleaseVersion;
            case VersionType.latest:
                return info.LatestVersion;
            default:
                throw Error("Unsupported version type");
        }
    }

    private disableDirtyFormCheck = () => {
        // don't want "dirty" to be triggered by the version being auto populated or channel from route param
        return this.state.cleanModel && isEqual(this.state.defaultCheckModel, this.state.model);
    };

    private buildRadioButton(pack: PackageEditInfo, version: string, type: VersionType, model: ReleaseModel) {
        if (!pack.IsResolvable && type === VersionType.latest) {
            return <div />;
        }
        return (
            <RadioButtonGroup
                className={styles.radioButtonContainer}
                value={type}
                onChange={async () => {
                    await this.packageVersionChanged(model, pack, version, type);
                }}
            >
                <RadioButton accessibleName={`${type} package version ${version}`} className={styles.myRadioButton} value={pack.VersionType} label={type === VersionType.specific ? "" : version} />
            </RadioButtonGroup>
        );
    }

    private buildReleaseNoteHelpInfo = () => {
        const helpInfo = "Enter a summary of what has changed in this release, such as which features were added and which bugs were fixed. " + "These notes will be shown on the release page. You can edit these notes later.";
        return helpInfo;
    };

    private specificVersionSelected = async (model: ReleaseModel, pack: PackageEditInfo, version: string) => {
        pack.SpecificVersion = version;
        await this.packageVersionChanged(model, pack, version, VersionType.specific);
    };

    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (!this.state.isNew) {
            await repository.Releases.del(this.state.model!.release);
            this.setState(() => {
                return {
                    model: null,
                    cleanModel: null,
                    deleted: true,
                };
            });
            return true;
        } else {
            return false;
        }
    };

    private async getTemplate(deploymentProc: DeploymentProcessResource, model: ReleaseModel, isNew: boolean): Promise<ReleaseTemplateResource> {
        const { model: project, projectContextRepository } = this.props.projectContext.state;

        // We can only load branch information when loading for NEW vcs releases,
        // we cant make any assumptions about the branches for existing releases
        if (isNew && project?.IsVersionControlled) {
            const branch = await repository.Projects.getGitRef(project, model.release.VersionControlReference.GitRef!);
            return await projectContextRepository.Branches.getTemplate(branch, model.release.ChannelId);
        } else {
            return await projectContextRepository.DeploymentProcesses.getTemplate(deploymentProc, model.release.ChannelId, model.release.Id);
        }
    }

    private async loadTemplate(model: ReleaseModel, deploymentProc: DeploymentProcessResource, isNew: boolean) {
        const template = await this.getTemplate(deploymentProc, model, isNew);

        if (!model.release.Id && template?.NextVersionIncrement) {
            model.release.Version = template.NextVersionIncrement;
        }

        const existingSelections: { [key: string]: string } = {};
        if (model.release.SelectedPackages) {
            for (const p of model.release.SelectedPackages) {
                existingSelections[this.createPackageKey(p)] = p.Version;
            }
        }

        const selectionByFeed: { [feedId: string]: PackageEditInfo[] } = {};
        const packageSelections = [];

        for (const p of template.Packages) {
            const specificVersion = existingSelections[this.createPackageKey(p)] ?? "";
            const isResolvable = p.IsResolvable;
            const lastReleaseVersion = p.VersionSelectedLastRelease;
            const selection: PackageEditInfo = {
                ActionName: p.ActionName,
                PackageReferenceName: p.PackageReferenceName,
                PackageId: p.PackageId,
                ProjectName: p.ProjectName,
                FeedId: p.FeedId,
                FeedName: p.FeedName,
                LatestVersion: "",
                SpecificVersion: specificVersion,
                IsResolvable: isResolvable,
                LastReleaseVersion: lastReleaseVersion,
                VersionType: specificVersion ? VersionType.specific : isResolvable ? VersionType.latest : lastReleaseVersion ? VersionType.last : VersionType.specific,
                IsLastReleaseVersionValid: !isBound(p.FeedId),
            };
            packageSelections.push(selection);

            if (selection.IsResolvable) {
                if (!selectionByFeed[selection.FeedId]) {
                    selectionByFeed[selection.FeedId] = [];
                }
                selectionByFeed[selection.FeedId].push(selection);
            }
        }

        const allRelevantFeedIdOrName = uniq(template.Packages.map((x) => x.FeedId)).filter((x) => !isBound(x));
        const relevantFeeds = await repository.Feeds.list({ skip: 0, take: repository.takeAll, ...(this.props.itemKey === "Name" && isNew ? { name: allRelevantFeedIdOrName } : { ids: allRelevantFeedIdOrName }) });

        await this.setStateAsync({ ...this.state, template, feeds: keyBy(relevantFeeds.Items, isNew ? this.props.itemKey : "Id") });
        await this.loadVersions(model, selectionByFeed); // This function depends on template being in state.

        model.packages = packageSelections;
        this.setState({ model });
        if (!model.release.Version) {
            this.props.setExpanderState(versionExpanderKey, true);
        }
    }

    private setVersionSatisfaction = async (model: ReleaseModel, pkg: PackageEditInfo, version: string, versionType: VersionType, feedType: FeedType) => {
        const violatedPackages = this.state.violatedPackages.slice();
        const filters = this.getChannelFilters(model, pkg.ActionName, pkg.PackageReferenceName);
        if (versionType) {
            pkg.VersionType = versionType;
        }
        await this.doBusyTask(async () => {
            const result = await this.memoizedRepositoryChannelsRuleTest(version, filters.versionRange!, filters.preReleaseTag!, feedType);
            const isSelectedVersionValid = result.Errors.indexOf("Invalid Version Number") !== -1 || (result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag);
            const position = violatedPackages.indexOf(pkg.ActionName);

            if (isSelectedVersionValid && position !== -1) {
                violatedPackages.splice(position, 1);
            } else if (!isSelectedVersionValid && position === -1) {
                violatedPackages.push(pkg.ActionName);
            }

            this.setState({ violatedPackages });
        });
    };

    private loadVersions(model: ReleaseModel, selectionsByFeed: Dictionary<PackageEditInfo[]>): Promise<boolean> {
        const memoizedRepositoryFeedsGet = (id: string) => this.state.feeds[id];

        const checkForRuleSatisfaction = async (selection: PackageEditInfo, filters: { versionRange?: string; preReleaseTag?: string }, feedType: FeedType) => {
            if (selection.LastReleaseVersion) {
                const result = await this.memoizedRepositoryChannelsRuleTest(selection.LastReleaseVersion, filters.versionRange!, filters.preReleaseTag!, feedType);
                selection.IsLastReleaseVersionValid = result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag;
            } else {
                selection.IsLastReleaseVersionValid = false;
            }
        };

        const getPackageVersion = async (feedId: string): Promise<any> => {
            const feed = memoizedRepositoryFeedsGet(feedId);
            const selections = selectionsByFeed[feedId];

            const packageSearchGroups = groupBy(
                selections.map((selection) => ({ selection, filter: this.getChannelFilters(model, selection.ActionName, selection.PackageReferenceName) })),
                ({ selection, filter }) => selection.PackageId + JSON.stringify(filter || {})
            );

            const t = Object.values(packageSearchGroups).map(async (sameFilteredPackages) => {
                const packageId = sameFilteredPackages[0].selection.PackageId;
                const foundPackages = await repository.Feeds.searchPackages(feed, { term: packageId });
                const packageExists = foundPackages.Items.length > 0;
                if (!packageExists) {
                    this.setState({ missingPackages: [...this.state.missingPackages, packageId] });
                }

                const releases = (
                    await repository.Feeds.searchPackageVersions(feed, sameFilteredPackages[0].selection.PackageId, {
                        ...sameFilteredPackages[0].filter,
                        take: 1,
                    })
                ).Items;

                return sameFilteredPackages.map(async ({ selection, filter }) => {
                    await checkForRuleSatisfaction(selection, filter, feed.FeedType);
                    if (releases.length === 0) {
                        // no latest version found
                        selection.IsResolvable = false;
                        // Docker feeds may not conform to semver, in which case there will be no valid versions.
                        // However you can manually enter a version like "latest", and this will be shown as the
                        // last version. It is convenient to select that last version rather than default to
                        // the specific version field.
                        selection.VersionType = selection.LastReleaseVersion ? VersionType.last : VersionType.specific;
                        if (!packageExists) {
                            return;
                        }

                        return this.setVersionSatisfaction(model, selection, selection.SpecificVersion, null!, feed.FeedType);
                    }

                    const pkg = releases[0];
                    selection.LatestVersion = pkg.Version;
                    if (!model.release.Id) {
                        return this.packageVersionChanged(model, selection, pkg.Version, null!);
                    }

                    if (!packageExists) {
                        return;
                    }

                    return this.setVersionSatisfaction(model, selection, selection.SpecificVersion, null!, feed.FeedType);
                });
            });
            return Promise.all(flatten(await Promise.all(t)));
        };

        return this.doBusyTask(() => {
            return Promise.all(
                keys(selectionsByFeed)
                    .filter((f) => !isBound(f))
                    .map((f) => getPackageVersion(f))
            );
        });
    }

    private packageVersionChanged = async (m: ReleaseModel, pkg: PackageEditInfo, version: string, versionType: VersionType) => {
        const model = { ...m };
        if (
            this.state.template &&
            this.state.template.VersioningPackageStepName &&
            this.state.template.VersioningPackageStepName === pkg.ActionName &&
            this.state.template.VersioningPackageReferenceName === pkg.PackageReferenceName &&
            this.state.isNew
        ) {
            model.release.Version = version;
        }

        if (versionType) {
            pkg.VersionType = versionType;
            if (versionType === VersionType.specific) {
                pkg.SpecificVersion = version;
            }
        }

        if (!isBound(pkg.FeedId) && this.state.feeds) {
            const feed = this.state.feeds[pkg.FeedId];
            if (feed) {
                await this.setVersionSatisfaction(model, pkg, version, versionType, feed.FeedType);
            }
        }

        this.setState({ model });
    };

    private getChannelFilters = (model: ReleaseModel, deploymentActionName: string, packageReferenceName: string | undefined): channelFilters => {
        const filters: channelFilters = {};

        if (!model || !model.release.ChannelId) {
            return filters;
        }

        const applicableRules = model.channels.Items.find((x) => {
            return x.Id === model.release.ChannelId;
        })!.Rules.find((rule) => {
            return rule.ActionPackages.length === 0 || rule.ActionPackages.findIndex((x) => x.DeploymentAction === deploymentActionName && PackageReferenceNamesMatch(packageReferenceName, x.PackageReference)) >= 0;
        });
        if (applicableRules && applicableRules.VersionRange) {
            filters.versionRange = applicableRules.VersionRange;
        }

        if (applicableRules && applicableRules.Tag) {
            filters.preReleaseTag = applicableRules.Tag;
        }
        return filters;
    };

    private onGitRefChange = async (branch: GitReference | undefined) => {
        this.setState(
            (state) => {
                if (!state.model) return state;
                state.model.release.VersionControlReference.GitRef = branch?.GitRef;

                return { ...state, busyTaskIsRunning: true, checkingDeploymentProcess: true };
            },
            () => {
                if (!this.state.model) return;
                const model = { ...this.state.model };

                if (branch && branch.GitRef) {
                    this.doBusyTask(async () => {
                        await this.props.projectContext.actions.onBranchSelected(this.state.project, branch.GitRef!);

                        const deploymentProc = await this.props.projectContext.state.projectContextRepository.DeploymentProcesses.get();
                        this.setState({ model, deploymentProc, checkingDeploymentProcess: false, ...(this.deploymentProcessHasSteps(deploymentProc) ? { busyTaskIsRunning: false } : { busyTaskIsRunning: true }) }, () => {
                            if (deploymentProc.Steps.length > 0) {
                                this.doBusyTask(async () => {
                                    this.loadTemplate(model, deploymentProc, this.state.isNew);
                                });
                            }
                        });
                    });
                }
            }
        );
    };

    private deploymentProcessHasSteps = (DeploymentProcess: DeploymentProcessResource) => {
        return DeploymentProcess && DeploymentProcess.Steps.length > 0;
    };

    private onChannelChanged = async (channelId: string) => {
        this.state.model!.release.ChannelId = channelId;
        await this.doBusyTask(async () => {
            await this.loadTemplate(_.cloneDeep(this.state.model!), this.state.deploymentProc, this.state.isNew);
        });
    };

    private createPackageKey(pkg: { ActionName: string; PackageReferenceName?: string }) {
        let key = pkg.ActionName;
        if (pkg.PackageReferenceName) {
            key += `[${pkg.PackageReferenceName}]`;
        }
        return key;
    }
}

const CreateOrEditRelease: React.FC<ReleaseEditProps> = (props) => {
    const setExpanderState = useSetExpanderState();
    const projectContext = useProjectContext();
    const accessItemsBy = useKeyedItemAccessForConfigurationAsCode();
    const trackAction = useAnalyticTrackedActionDispatch(projectContext.state.model.Id);

    return (
        <KeyedItemAccessProvider accessItemsBy={accessItemsBy}>
            <CreateOrEditReleaseInternal setExpanderState={setExpanderState} projectContext={projectContext} itemKey={accessItemsBy} trackAction={trackAction} {...props} />
        </KeyedItemAccessProvider>
    );
};

export default CreateOrEditRelease;
