/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Theme, WithStyles } from "@material-ui/core/styles";
import { createStyles, withStyles } from "@material-ui/core/styles";
import cn from "classnames";
import { keyBy } from "lodash";
import * as React from "react";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import type { TabItemProps } from "~/primitiveComponents/navigation/Tabs/TabItem";
import type { Theme as OctopusTheme } from "~/theme";

const styles = (theme: Theme) => {
    const octopusTheme = theme as OctopusTheme;
    return createStyles({
        warning: {
            color: octopusTheme.palette.status.danger,
            paddingLeft: "0.25rem",
        },
    });
};

const TabLabel: React.FC<{ label: React.ReactNode }> = ({ label }) => <span>{label}</span>;

interface WarningTabLabelProps extends WithStyles<typeof styles> {
    label: React.ReactNode;
    warning: string;
}

const WarningTabLabel: React.FC<WarningTabLabelProps> = ({ label, warning, classes }) => (
    <div>
        <span>{label}</span>
        <ToolTip content={warning}>
            <em className={cn("fa", "fa-warning", classes.warning)} />
        </ToolTip>
        &nbsp;
    </div>
);

const EnhancedWarningTabLabel = withStyles(styles)(WarningTabLabel);
export type RenderTabCallback = (props: RenderTabProps) => React.ReactNode;

export interface RenderTabProps {
    label: React.ReactNode;
    value: string;
}

const convertTab = (tab: React.ReactElement, fallbackValue: string, renderTab: RenderTabCallback) => {
    const { label, warning, value = fallbackValue, children, onActive }: TabItemProps = tab.props;
    const tabLabel = warning ? <EnhancedWarningTabLabel label={label} warning={warning} /> : <TabLabel label={label} />;

    const control = renderTab({ value, label: tabLabel });

    return {
        content: children,
        value,
        control,
        onActive,
    };
};

export const useTabConversion = (children: React.ReactNode, value: string, renderTab: RenderTabCallback) => {
    //We can't memoize children, they will always be a new array so no point trying.
    const validElements = React.Children.toArray(children).filter(React.isValidElement);
    const converted = validElements.map((tab, index) => convertTab(tab, index.toString(), renderTab));
    const lookup = keyBy(converted, (t) => t.value);
    const tabs = converted.map((x) => x.control);

    //Since lookup will always be a new object as it's based on the children there is no point trying to memoize this either.
    const { content } = lookup[value] || { content: null, onActive: null };
    const getTabDefinition = (name: string) => {
        return lookup[name];
    };

    return {
        tabs,
        value,
        content,
        getTabDefinition,
    };
};
