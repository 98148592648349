import React = require("react");
import { HowToCreateYourPackageDialogLink } from "~/areas/library/components/BuiltInRepository/HowToCreateYourPackageDialogLink";
import { HowToPushYourPackageDialogLink } from "~/areas/library/components/BuiltInRepository/HowToPushYourPackageDialogLink";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import { OverviewContainer, OverviewHighlightText, TermsText, TopicsContainer, LearnMoreResources } from "./CommonViews";

export class CommonLibraryBuiltInRepositoryOverview extends React.Component<{}> {
    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <OverviewContainer>
                <OverviewHighlightText>
                    <p>
                        The <ExternalLink href="BuiltInFeed">built-in repository</ExternalLink> is the best choice for storing packages. It offers better performance for your deployments and the most robust retention policy support for deployment
                        packages.
                    </p>
                </OverviewHighlightText>
                <TermsText>
                    <Note heading={"Packages"}>A package is an archive (zip, tar, Nuget) that contains your application assets. You can host packages in external repositories or the built-in Octopus repository.</Note>
                    <Note>The built-in repository accepts NuGet, zip, tar, tar gzip, tar bzip2, jar, war, ear and rar packages.</Note>
                    <Note heading={"How to create packages"}>
                        <b>
                            <HowToCreateYourPackageDialogLink label="Use our packaging guide" />
                        </b>
                        &nbsp; to determine the right package type for your application and how to create it.
                    </Note>
                    <Note heading={"How to push packages"}>
                        We recommend using a build server to package and push your packages to Octopus. Alternatively you can manually upload a package or use the command line.
                        <br />
                        <b>
                            <HowToPushYourPackageDialogLink label="View examples of how to push packages" />
                        </b>
                        .
                    </Note>
                    <Note heading={"Sample package"}>
                        Quickly create your first deployment with our sample package.
                        <br />
                        <ExternalLink href="HelloWorldPackageFile">
                            <b>hello-world.1.0.0.zip</b>
                        </ExternalLink>
                    </Note>
                </TermsText>
            </OverviewContainer>
        );
    }
}

export const CommonLibraryBuiltInRepositoryTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="BuiltInFeed">Built-In Repository</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
