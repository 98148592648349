import "./polyfills";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "./global.module.less";
import "font-awesome/css/font-awesome.min.css";
import type { ExtensionManager } from "~/extensionsManager";
import extensionManager from "~/extensionsManager";
import "~/components/Actions";
import "~/components/Features";
import "~/areas/starup";
import store from "~/store";
import App from "./App";
import captureClientMetrics from "./captureClientMetrics";

declare global {
    interface Window {
        Octopus: ExtensionManager;
        showSnackbar: boolean;
        snackbarText: string;
    }
}
declare let module: __WebpackModuleApi.Module;

window.Octopus = extensionManager;

const capturedMetrics = captureClientMetrics();

function render(Component: typeof App) {
    ReactDOM.render(<Component capturedMetrics={capturedMetrics} />, document.getElementById("app"));
}

render(App);

//Do NOT move to a separate file as this wil break hot reloading
if (module.hot) {
    module.hot.accept("./store", () => {
        // When reducers change, replace them but keep the current app state
        console.log("Store updated. Replacing root reducer");
        // If the callback to hot.accept throws
        // an exception, the HMR update will fail. If using
        // webpack-dev-server, the page will be reloaded,
        // and we lose the error. So try/catch/log.
        //
        try {
            const nextReducer = require("./store").reducers;
            store.replaceReducer(nextReducer);
        } catch (err) {
            console.error("Error updating store", err);
        }
    });

    module.hot.accept("./App", () => {
        try {
            const NextApp = require("./App").default;
            render(NextApp);
        } catch (err) {
            console.error("Error updating app", err);
        }
    });
}
