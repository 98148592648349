import { flatMap } from "lodash";
import type { FilterableValue } from "~/areas/variables/VariableFilter/index";
import { VariableType } from "~/client/resources/variableResource";
import { getCertificateIndex } from "~/components/certificates";
import { getTagIndex } from "~/components/tenantTagsets";
import { getWorkerPoolIndex } from "~/components/workerPools";

const collectIndexes = async (values: Array<FilterableValue>) => {
    const tags = getIdsForTenantTags(values);
    const certificates = getIdsForType(values, VariableType.Certificate);
    const workerPools = getIdsForType(values, VariableType.WorkerPool);
    const tagIndex = tags.length === 0 ? undefined : await getTagIndex(tags);
    const certificateIndex = certificates.length === 0 ? undefined : await getCertificateIndex(certificates);
    const poolIndex = workerPools.length === 0 ? undefined : await getWorkerPoolIndex(workerPools);
    return { tagIndex, certificateIndex, poolIndex };
};

const getIdsForType = (values: Array<FilterableValue>, type: VariableType): string[] =>
    values
        .filter((val) => val.type === type)
        .map((val) => val.value)
        .filter((val): val is string => typeof val !== "undefined");

const getIdsForTenantTags = (values: Array<FilterableValue>): string[] => flatMap(values, (val) => val.scope.TenantTag ?? []).filter((tag): tag is string => typeof tag !== "undefined");

export { collectIndexes };
