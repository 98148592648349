import { compact } from "lodash";
import * as React from "react";
import type { ProjectSummary } from "~/client/resources";
import Permission from "~/client/resources/permission";
import { Navigation } from "~/components/NavigationSidebarLayout/index";
import type { NavItem } from "~/components/NavigationSidebarLayout/index";
import routeLinks from "~/routeLinks";
import type { BranchSpecifier } from "../ProjectsRoutes/BranchSpecifier";
import { ShouldUseDefaultBranch } from "../ProjectsRoutes/BranchSpecifier";
import { IsAllowedToSeeDeploymentsOverview } from "../ProjectsRoutes/ProjectToOverviewRedirect";

export function ProjectLinks(projectSlug: string, branch: BranchSpecifier, projectId: string, projectSummary: ProjectSummary, isMultiTenancyEnabled: boolean | undefined) {
    const projectLinks = routeLinks.project(projectSlug);
    const navigationLinks: Array<NavItem | null> = [];
    const hasNoProcessOrRunbooks = projectSummary.HasDeploymentProcess === false && projectSummary.HasRunbooks === false;

    const deploymentsSubLinks: Array<NavItem | null> = [];
    deploymentsSubLinks.push(
        Navigation.navItem("Overview", projectLinks.deployments.root, true, {
            permission: Permission.ReleaseView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        Navigation.navItem("Process", ShouldUseDefaultBranch(branch) ? projectLinks.deployments.process.root : routeLinks.projectBranch(projectSlug, branch).deploymentProcess.root, undefined, {
            permission: Permission.ProcessView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        Navigation.navItem("Channels", projectLinks.deployments.channels, undefined, {
            permission: Permission.ProcessView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        Navigation.navItem("Releases", projectLinks.deployments.releases, undefined, {
            permission: Permission.ReleaseView,
            project: projectId,
            tenant: "*",
        })
    );
    deploymentsSubLinks.push(
        Navigation.navItem("Triggers", projectLinks.triggers, undefined, {
            permission: Permission.TriggerView,
            project: projectId,
        })
    );

    deploymentsSubLinks.push(Navigation.navItem("Settings", ShouldUseDefaultBranch(branch) ? projectLinks.deployments.settings : routeLinks.projectBranch(projectSlug, branch).deploymentSettings, undefined, undefined));

    if (IsAllowedToSeeDeploymentsOverview(projectId) && deploymentsSubLinks.length > 0) {
        navigationLinks.push(Navigation.navGroup("Deployments", projectLinks.deployments.root, compact(deploymentsSubLinks), undefined, hasNoProcessOrRunbooks || projectSummary.HasDeploymentProcess)); // Strip any that failed permission checks.
    }

    const operationsSubLinks: Array<NavItem | null> = [];
    operationsSubLinks.push(
        Navigation.navItem("Overview", projectLinks.operations.root, true, {
            permission: Permission.RunbookView,
            project: projectId,
            wildcard: true,
        })
    );
    operationsSubLinks.push(
        Navigation.navItem("Runbooks", projectLinks.operations.runbooks, undefined, {
            permission: Permission.RunbookView,
            project: projectId,
            wildcard: true,
        })
    );
    operationsSubLinks.push(
        Navigation.navItem("Triggers", projectLinks.operations.triggers, undefined, {
            permission: Permission.TriggerView,
            project: projectId,
        })
    );

    navigationLinks.push(
        Navigation.navGroup(
            "Operations",
            projectLinks.operations.root,
            compact(operationsSubLinks), // Strip any that failed permission checks.
            {
                permission: Permission.RunbookView,
                project: projectId,
                wildcard: true,
            },
            hasNoProcessOrRunbooks || projectSummary.HasRunbooks
        )
    );
    const variableSubLinks: Array<NavItem | null> = [];
    variableSubLinks.push(Navigation.navItem("Project", projectLinks.variables.root, true));
    if (isMultiTenancyEnabled) {
        variableSubLinks.push(Navigation.navItem("Project Templates", projectLinks.variables.projectTemplates));
        variableSubLinks.push(Navigation.navItem("Common Templates", projectLinks.variables.commonTemplates));
    }
    variableSubLinks.push(Navigation.navItem("Library Sets", projectLinks.variables.library));
    variableSubLinks.push(Navigation.navItem("All", projectLinks.variables.all));
    variableSubLinks.push(Navigation.navItem("Preview", projectLinks.variables.preview));

    navigationLinks.push(
        Navigation.navGroup(
            "Variables",
            projectLinks.variables.root,
            compact(variableSubLinks), // Strip any that failed permission checks.
            {
                permission: Permission.VariableView,
                project: projectId,
                wildcard: true,
            }
        )
    );
    navigationLinks.push(
        Navigation.navItem("Tasks", projectLinks.tasks, undefined, {
            permission: Permission.TaskView,
            project: projectId,
            projectGroup: "*",
            tenant: "*",
        })
    );

    const settingSubLinks: Array<NavItem | null> = [];

    settingSubLinks.push(Navigation.navItem("General", projectLinks.settings.root, true));
    settingSubLinks.push(Navigation.navItem(<React.Fragment>Version Control</React.Fragment>, projectLinks.settings.versionControl));

    navigationLinks.push(
        Navigation.navGroup(
            "Settings",
            projectLinks.settings.root,
            compact(settingSubLinks),
            {
                permission: Permission.ProjectView,
                project: projectId,
                projectGroup: "*",
                tenant: "*",
            },
            true
        )
    );

    return compact(navigationLinks);
}
