import * as React from "react";
import InvalidBranchCallout from "~/areas/projects/components/VersionControl/InvalidBranchCallout";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout/FormPaperLayout";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";

export const ProjectContextFormPaperLayout: React.FC<FormPaperLayoutProps> = ({ children, ...rest }) => {
    const missingBranchCallout = <InvalidBranchCallout />;
    return (
        <FormPaperLayout {...rest} callout={missingBranchCallout}>
            {children}
        </FormPaperLayout>
    );
};
