/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */

import type { BranchSpecifier } from "~/areas/projects/components/ProjectsRoutes/BranchSpecifier";
import { ShouldUseDefaultBranch } from "~/areas/projects/components/ProjectsRoutes/BranchSpecifier";
import type ConvertProjectToVersionControlledCommand from "~/client/resources/convertProjectToVersionControlledCommand";
import type { GitRef } from "~/client/resources/versionControlledResource";
import { isGitBranch, isGitCommit, isGitTag } from "~/client/resources/versionControlledResource";
import { repository } from "~/clientInstance";
import type { Client } from "../client";
import type { ValidateGitRefResponse, ChannelResource, ProjectResource, ReleaseResource, ResourceCollection, TriggerResource, GitRefResource, GitTagResource, GitCommitResource } from "../resources";
import type {
    ConvertProjectToVersionControlledResponse,
    DeploymentSettingsResource,
    NewProjectResource,
    NonVcsRunbookResource,
    ProjectGroupResource,
    ProjectSettingsMetadata,
    ProjectSummary,
    ProjectSummaryResource,
    TriggerActionCategory,
    TriggerActionType,
    GitBranchResource,
    VersionControlCompatibilityResponse,
} from "../resources/index";
import { HasVcsProjectResourceLinks, HasVersionControlledPersistenceSettings } from "../resources/index";
import BasicRepository from "./basicRepository";

export interface ListProjectsArgs {
    skip?: number;
    take?: number;
    partialName?: string;
    clonedFromProjectId?: string;
}

class ProjectRepository extends BasicRepository<ProjectResource, NewProjectResource> {
    constructor(client: Client) {
        super("Projects", client);
    }
    getDeployments(project: ProjectResource) {
        return this.client.get(this.client.getLink("Deployments"), { projects: project.Id });
    }
    getDeploymentSettings(project: ProjectResource): Promise<DeploymentSettingsResource> {
        return this.client.get(project.Links["DeploymentSettings"]);
    }
    getReleases(project: ProjectResource, args?: { skip?: number; take?: number; searchByVersion?: string }): Promise<ResourceCollection<ReleaseResource>> {
        return this.client.get<ResourceCollection<ReleaseResource>>(project.Links["Releases"], args!);
    }
    getReleaseByVersion(project: ProjectResource, version: string): Promise<ReleaseResource> {
        return this.client.get(project.Links["Releases"], { version });
    }
    list(args?: ListProjectsArgs): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(this.client.getLink("Projects"), { ...args });
    }
    listByGroup(projectGroup: ProjectGroupResource): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(projectGroup.Links["Projects"]);
    }
    getChannels(project: ProjectResource, skip: number = 0, take: number = repository.takeAll): Promise<ResourceCollection<ChannelResource>> {
        return this.client.get<ResourceCollection<ChannelResource>>(project.Links["Channels"], { skip, take });
    }
    getTriggers(
        project: ProjectResource,
        gitRef: GitRefResource | string | undefined,
        skip?: number,
        take?: number,
        triggerActionType?: TriggerActionType,
        triggerActionCategory?: TriggerActionCategory,
        runbooks?: string[],
        partialName?: string
    ): Promise<ResourceCollection<TriggerResource>> {
        return this.client.get<ResourceCollection<TriggerResource>>(project.Links["Triggers"], { skip, take, gitRef: GetBranchDetails(gitRef), triggerActionType, triggerActionCategory, runbooks, partialName });
    }
    orderChannels(project: ProjectResource) {
        return this.client.post(project.Links["OrderChannels"]);
    }
    getPulse(projects: ProjectResource[]) {
        const projectIds = projects
            .map((p) => {
                return p.Id;
            })
            .join(",");
        return this.client.get(this.client.getLink("ProjectPulse"), { projectIds });
    }
    getMetadata(project: ProjectResource): Promise<ProjectSettingsMetadata[]> {
        return this.client.get(project.Links["Metadata"], {});
    }
    getRunbooks(project: ProjectResource, args?: { skip?: number; take?: number }): Promise<ResourceCollection<NonVcsRunbookResource>> {
        return this.client.get<ResourceCollection<NonVcsRunbookResource>>(project.Links["Runbooks"], args);
    }
    summaries(): Promise<ProjectSummaryResource[]> {
        return this.client.get(this.client.getLink("ProjectsExperimentalSummaries"));
    }
    summariesVersionControlled(): Promise<ProjectSummaryResource[]> {
        return this.client.get(this.client.getLink("ProjectsExperimentalSummaries"), { isVersionControlled: true });
    }
    getSummary(project: ProjectResource, branch: GitBranchResource | string | undefined): Promise<ProjectSummary> {
        return this.client.get(project.Links["Summary"], { gitRef: GetBranchDetails(branch) });
    }
    getBranch(project: ProjectResource, branch: BranchSpecifier): Promise<GitBranchResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
            const branchName: string = ShouldUseDefaultBranch(branch) ? project.PersistenceSettings.DefaultBranch : branch;
            return this.client.get(project.Links.Branches, { name: branchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    getGitRef(project: ProjectResource, gitRef: GitRef): Promise<GitRefResource> {
        if (isGitBranch(gitRef)) {
            return this.getBranch(project, gitRef);
        } else if (isGitTag(gitRef)) {
            return this.getTag(project, gitRef);
        } else if (isGitCommit(gitRef)) {
            return this.getCommit(project, gitRef);
        } else {
            throw "Unknown Ref Type";
        }
    }
    getCommit(project: ProjectResource, hash: string): Promise<GitCommitResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(project.Links.Commits, { hash });
        }
        throw new Error("Cannot retrieve commits from non-VCS projects");
    }
    getTag(project: ProjectResource, tagName: string): Promise<GitTagResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasVersionControlledPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(project.Links.Tags, { name: tagName });
        }
        throw new Error("Cannot retrieve tags from non-VCS projects");
    }
    async tryGetBranch(project: ProjectResource, branch: BranchSpecifier): Promise<GitBranchResource | null> {
        try {
            return await this.getBranch(project, branch);
        } catch (ex) {
            if (ex.StatusCode === 404) {
                return null;
            }
            throw ex;
        }
    }
    getBranches(project: ProjectResource): Promise<ResourceCollection<GitBranchResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Branches);
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    getTags(project: ProjectResource): Promise<ResourceCollection<GitTagResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Tags);
        }
        throw new Error("Cannot retrieve tags from non-VCS projects");
    }

    searchTags(project: ProjectResource, partialBranchName: string): Promise<ResourceCollection<GitTagResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Tags, { searchByName: partialBranchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }

    searchBranches(project: ProjectResource, partialBranchName: string): Promise<ResourceCollection<GitBranchResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(project.Links.Branches, { searchByName: partialBranchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    convertToVcs(project: ProjectResource, payload: ConvertProjectToVersionControlledCommand): Promise<ConvertProjectToVersionControlledResponse> {
        return this.client.post<ConvertProjectToVersionControlledResponse>(project.Links.ConvertToGit, payload);
    }
    vcsCompatibilityReport(project: ProjectResource): Promise<VersionControlCompatibilityResponse> {
        return this.client.get(project.Links["GitCompatibilityReport"]);
    }
    validateGitRef(project: ProjectResource, gitRef: string): Promise<ValidateGitRefResponse> {
        return this.client.post(project.Links.ValidateGitRef, { gitRef });
    }

    // TODO: @team-config-as-code - Our project needs a custom "Delete" link that does _not_ include the GitRef in order for us to
    // successfully hit the /projects/{id} DEL endpoint. For EAP, we're out of time and just hacking it into the frontend client.
    del(project: ProjectResource) {
        if (project.IsVersionControlled) {
            // Our "Self" link should currently include the GitRef. If so, and our last path does not look like our projectId, strip it.
            const selfLinkParts = project.Links.Self.split("/");
            if (selfLinkParts[selfLinkParts.length - 1] !== project.Id) {
                selfLinkParts.pop();
            }
            const selfLink = selfLinkParts.join("/");
            return this.client.del(selfLink).then((d) => this.notifySubscribersToDataModifications(project));
        } else {
            return this.client.del(project.Links.Self).then((d) => this.notifySubscribersToDataModifications(project));
        }
    }

    markAsStale(project: ProjectResource): Promise<void> {
        return this.client.post(project.Links["RepositoryModified"]);
    }
}

function GetBranchDetails(branch: GitRefResource | string | undefined): string {
    if (typeof branch === "string" || branch instanceof String) {
        return branch as string;
    } else {
        return branch?.CanonicalName as string;
    }
}

export default ProjectRepository;
