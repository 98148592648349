/* eslint-disable no-restricted-imports */

import classnames from "classnames";
import { default as MaterialIconButton } from "material-ui/IconButton";
import React from "react";
import { useOctopusTheme } from "~/components/Theme";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import styles from "./styles.module.less";
import type { SharedButtonProps } from "./types";

interface MaterialIconButtonWithToolTipProps extends SharedButtonProps {
    icon?: JSX.Element;
    className?: string;
}

export const MaterialIconButtonWithToolTip: React.FC<MaterialIconButtonWithToolTipProps> = ({ icon, disabled, tabIndex, buttonRef, className, style = {}, onClick, toolTipContent, ...otherProps }) => {
    const theme = useOctopusTheme();

    const button = (
        <MaterialIconButton {...otherProps} className={classnames(styles.materialUIIcon, className)} disabled={disabled} tabIndex={tabIndex} onClick={onClick} ref={buttonRef} style={{ fill: theme.iconNeutral, ...style }}>
            {icon}
        </MaterialIconButton>
    );

    if (!toolTipContent) {
        return button;
    }

    return <ToolTip content={toolTipContent}>{button}</ToolTip>;
};
