import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import type { ProjectResource } from "../../../../client/resources/projectResource";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

const Onboarding = (props: { project: ProjectResource }) => {
    return (
        <OnboardingPage
            title="Create a release of your software to deploy"
            intro="Releases allow you to capture everything required to deploy a project in a repeatable and reliable manner."
            learnMore={
                <>
                    <ExternalLink href="OnboardingReleasesLearnMore">Learn more</ExternalLink>
                    <ExternalVideoLink href="OnboardingCreateReleaseDeploymentVideo" />
                </>
            }
        />
    );
};

export default Onboarding;
