import * as React from "react";
import InvalidBranchCallout from "~/areas/projects/components/VersionControl/InvalidBranchCallout";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import PaperLayout from "~/components/PaperLayout";

export const ProjectContextPaperLayout: React.FC<PaperLayoutProps> = ({ children, ...rest }) => {
    const missingBranchCallout = <InvalidBranchCallout />;
    return (
        <PaperLayout {...rest} callout={missingBranchCallout}>
            {children}
        </PaperLayout>
    );
};
