import type { StepPackageDeploymentTargetType } from "~/client/resources/stepPackageDeploymentTargetType";
import type { StepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import type { SampleDeploymentTargetInputs } from "./SampleDeploymentTargetInputs";
import { sampleDeploymentTargetInputSchema } from "./SampleDeploymentTargetInputs";
import { sampleDeploymentTargetUI } from "./SampleDeploymentTargetUI";

export const sampleDeploymentTargetPackage: StepPackageDeploymentTarget<SampleDeploymentTargetInputs> = {
    deploymentTargetTypeId: "sample-deployment-target",
    version: "0.0.1",
    name: "Sample Deployment Target",
    inputJsonSchema: sampleDeploymentTargetInputSchema,
    ui: sampleDeploymentTargetUI,
};

export function createSampleDeploymentTargetType(): StepPackageDeploymentTargetType {
    return {
        Id: "sample-deployment-target",
        Categories: ["Azure", "Aws", "Google Cloud"],
        Description: "A sample deployment target that cannot be used and only has a UI",
        Links: {
            Schema: "",
            UI: "",
            Logo: `/api/actiontemplates/sample-deployment-target/logo`,
        },
        Name: "Sample Deployment Target",
        Version: "0.0.1",
        Prerelease: true,
    };
}
