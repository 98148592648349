import * as React from "react";
import { useState } from "react";
import { Switch } from "react-router-dom";
import type { AnalyticSession } from "~/analytics/AnalyticSession";
import { AnalyticSessionProvider, getAnalyticSession, useAnalyticSession } from "~/analytics/AnalyticSession";
import AuthenticationLayout from "~/areas/authentication/AuthenticationLayout";
import Register from "~/areas/authentication/Register";
import SignIn from "~/areas/authentication/SignIn";
import SignOut from "~/areas/authentication/SignOut";
import Logger from "~/client/logger";
import type { UserResource } from "~/client/resources";
import { client, repository, session } from "~/clientInstance";
import ReloadableRoute from "~/components/ReloadableRoute";
import SecureRoute from "~/components/SecureRoute";
import { StandardLayout } from "~/components/StandardLayout";
import routeLinks from "~/routeLinks";

type SessionStartEvent = (user: UserResource) => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AuthenticationRoutes(props: React.ComponentProps<any>) {
    const fallbackAnalyticSession = useAnalyticSession();
    const [analyticsSession, setAnalyticsSession] = useState<AnalyticSession | undefined>(undefined);

    const onSessionStart: SessionStartEvent = async (user) => {
        try {
            const [featureConfigurations, featureToggles] = await Promise.all([repository.FeaturesConfiguration.get(), repository.ServerConfiguration.enabledFeatureToggles()]);
            session.start(user, featureConfigurations, featureToggles);
        } catch (error) {
            Logger.log(error);
            const message = "The sign in succeeded but we failed to get the resultant permissions for this user account. ";
            const reason = error.StatusCode === 401 ? "This can happen if the Octopus authentication cookie is blocked." : "There was a problem communicating with the Octopus Server: " + error.ErrorMessage;
            throw message + reason;
        }

        if (analyticsSession === undefined) {
            const serverInfo = client.tryGetServerInformation();
            if (serverInfo) {
                const session = getAnalyticSession(serverInfo.installationId, user.Id, user.Username, user.EmailAddress);
                setAnalyticsSession(session);
                // session.track("Sign In", {});
            }
        }
    };

    const onSessionEnd = () => {
        analyticsSession?.track("Sign Out", {});
        analyticsSession?.end();
        setAnalyticsSession(undefined);
    };

    return (
        <AnalyticSessionProvider session={analyticsSession ?? fallbackAnalyticSession}>
            <Switch>
                <ReloadableRoute
                    path={routeLinks.currentUser.signIn}
                    render={(props) => (
                        <AuthenticationLayout>
                            <SignIn {...props} onSessionStarted={onSessionStart} />
                        </AuthenticationLayout>
                    )}
                />
                <ReloadableRoute
                    path={routeLinks.currentUser.signOut}
                    render={(props) => (
                        <AuthenticationLayout>
                            <SignOut {...props} onSessionEnded={onSessionEnd} />
                        </AuthenticationLayout>
                    )}
                />
                <ReloadableRoute
                    path={routeLinks.currentUser.register(":inviteCode")}
                    render={(props) => (
                        <AuthenticationLayout>
                            <Register {...props} onSessionStarted={onSessionStart} />
                        </AuthenticationLayout>
                    )}
                />
                <SecureRoute path={routeLinks.root} component={StandardLayout} analyticsSession={analyticsSession} />
            </Switch>
        </AnalyticSessionProvider>
    );
}
