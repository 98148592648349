/* eslint-disable @typescript-eslint/consistent-type-assertions */

import React from "react";
import LoadingScreen from "~/areas/infrastructure/components/LoadingScreen/LoadingScreen";
import Logger from "~/client/logger";
import type { OctopusError } from "~/client/resources";
import { client } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";

interface ClientConnectorProps {
    renderWhenConnected: () => JSX.Element;
}

interface ClientConnectorState {
    inProgressMessage: string;
    connectError: OctopusError | null;
    isConnected: boolean;
}

interface ClientConnectorProps {
    renderWhenConnected: () => JSX.Element;
}

export class ClientConnector extends BaseComponent<ClientConnectorProps, ClientConnectorState> {
    constructor(props: ClientConnectorProps) {
        super(props);
        this.state = {
            isConnected: false,
            connectError: null,
            inProgressMessage: "Trying to connect to Octopus",
        };
    }

    async componentDidMount() {
        await this.connectClient();
    }

    async connectClient() {
        await client.connect((progress: string, error: OctopusError | undefined) => {
            Logger.log("Progress: ", progress);
            if (error) {
                Logger.log("Error: ", error);
            }
            this.setState({ inProgressMessage: progress, connectError: error as OctopusError });
        });

        this.setState({ isConnected: true });
    }

    render() {
        return this.state.isConnected ? this.props.renderWhenConnected() : <LoadingScreen errorDetails={this.state.connectError} inProgressMessage={this.state.inProgressMessage} />;
    }
}
