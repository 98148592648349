import React from "react";
import type { TaskResource } from "~/client/resources";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { BooleanRadioButtonGroup, ExpandableFormSection, Note, RadioButton, Summary } from "~/components/form";
import { TriggerSyncTask } from "./TriggerSyncTask";

type CommunityActionTemplatesFeatureProps = {
    isEnabled: boolean;
    isSyncEnabled: boolean;
    lastSyncedTask?: TaskResource<{}>;
    busy?: Promise<unknown> | boolean;
    onChange: (isEnabled: boolean) => void;
    onSyncClick: () => void;
};

export function CommunityActionTemplatesFeature({ isEnabled, isSyncEnabled, lastSyncedTask, busy, onChange, onSyncClick }: CommunityActionTemplatesFeatureProps) {
    return (
        <ExpandableFormSection
            key="IsCommunityActionTemplatesEnabled"
            errorKey="IsCommunityActionTemplatesEnabled"
            title="Community Step Templates"
            summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
            help="Enable access to the Community Library."
        >
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" isDefault={true} />
                <RadioButton value={false} label="Disabled" />
            </BooleanRadioButtonGroup>

            <TriggerSyncTask isSyncEnabled={isSyncEnabled} lastSyncedTask={lastSyncedTask} busy={busy} onSyncClick={onSyncClick} />
            <Note>
                This feature requires internet to access the <ExternalLink href="CommunityLibrary">Community Library</ExternalLink>. Octopus will fetch and store the
                <ExternalLink href="CommunityContributedStepTemplates"> community contributed steps</ExternalLink> locally, to be available when creating a deployment process and step templates.
            </Note>
        </ExpandableFormSection>
    );
}
