/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { ProcessType } from "~/client/resources/processType";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { DialogLayoutConnect } from "~/components/Dialog/DialogLayoutConnect";
import type { DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";

interface UpdateVariablesProps {
    processType: ProcessType;
    onUpdateVariablesClicked(): any;
}

interface UpdateVariablesState extends DataBaseComponentState {
    isBusy: boolean;
}

interface UpdateVariablesMessageProps {
    processType: ProcessType;
}

export class UpdateVariablesMessage extends React.PureComponent<UpdateVariablesMessageProps> {
    render() {
        const { processType } = this.props;
        const snapshotTerm = processType === ProcessType.Deployment ? "release" : "snapshot";
        return (
            <p>
                Octopus takes a snapshot of the variables each time a {snapshotTerm} is created. By updating the variables, the current snapshot will be discarded, and the latest variables (as seen on the Variables tab) will be imported. As your
                process has changed since the {snapshotTerm} was created, if you removed any steps, keep in mind that variables might also have been deleted that you still rely on for this {snapshotTerm}.
            </p>
        );
    }
}

class UpdateVariablesInternal extends DataBaseComponent<UpdateVariablesProps & DialogLayoutDispatchProps, UpdateVariablesState> {
    constructor(props: UpdateVariablesProps & DialogLayoutDispatchProps) {
        super(props);
        this.state = {
            isBusy: false,
        };
    }

    render() {
        const actions = [];
        actions.push(<ActionButton label="Cancel" key="CancelBlock" onClick={() => this.props.close()} />);
        actions.push(
            <ActionButton
                label="Update variables"
                key="UpdateVariables"
                type={ActionButtonType.Primary}
                disabled={this.state.isBusy}
                onClick={() =>
                    this.doBusyTask(async () => {
                        this.setState({ isBusy: true });
                        await this.props.onUpdateVariablesClicked();
                        this.setState({ isBusy: false });
                        this.props.close();
                    })
                }
            />
        );
        return (
            <DialogLayout title="Confirm Variable Update" busy={this.state.busy} errors={this.errors} key="UpdateVariables" closeDialog={this.props.close} actions={actions}>
                <UpdateVariablesMessage processType={this.props.processType} />
            </DialogLayout>
        );
    }
}

const UpdateVariables = DialogLayoutConnect.to<UpdateVariablesProps>(UpdateVariablesInternal);
export default UpdateVariables;
