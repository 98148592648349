/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type * as React from "react";
import { connect } from "react-redux";
import type { Dispatch, Action } from "redux";
import { actions as dialogActions } from "~/components/Dialog/store";

interface GlobalDispatchProps {
    openDialog(id: string): void;
}

interface GlobalConnectedProps {
    openDialogs: { [key: string]: boolean };
}

//export type OpenDialogProps = GlobalDispatchProps & StateProps;
export interface OpenDialogProps extends GlobalDispatchProps, GlobalConnectedProps {}

export class OpenDialogConnect {
    static to<TProps extends OpenDialogProps>(internal: React.ComponentType<TProps>, withRef: boolean = false) {
        type ExternalProps = Omit<TProps, keyof OpenDialogProps>;

        const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>, props: TProps): GlobalDispatchProps => {
            return {
                openDialog: (key: string) => {
                    dispatch(dialogActions.open(key));
                },
            };
        };

        const mapGlobalStateToProps = (state: GlobalState, props: TProps): GlobalConnectedProps => ({
            openDialogs: state.dialogs || {},
        });

        return (connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps, null, { forwardRef: true })(internal as any) as any) as React.ComponentType<ExternalProps>;
    }
}
