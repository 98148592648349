import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import type { ProjectResource, GitBranchResource, GitPersistenceSettings } from "../../client/resources";
import { HasVersionControlledPersistenceSettings } from "../../client/resources";

const getTextValuePairForBranch = (branch: GitBranchResource, persistenceSettings: GitPersistenceSettings): GitRefOption => {
    const isDefaultBranch = branch.Name === persistenceSettings.DefaultBranch;
    return {
        text: `${branch.Name} ${isDefaultBranch ? "(default)" : ""}`,
        value: branch.CanonicalName,
    };
};

const buildBranchNamesList = (branches: GitBranchResource[], project: ProjectResource): GitRefOption[] => {
    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const vcsPersistenceSettings = project.PersistenceSettings;

    return branches.map((branch) => getTextValuePairForBranch(branch, vcsPersistenceSettings));
};

export { getTextValuePairForBranch, buildBranchNamesList };
