/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import { compact } from "lodash";
import moment from "moment";
import * as React from "react";
import { ChangeTaskCap } from "~/areas/configuration/components/Nodes/ChangeTaskCap";
import { EditServerConfiguration } from "~/areas/configuration/components/Nodes/EditServerConfiguration";
import { TaskFilterState } from "~/areas/tasks/components/Tasks/Tasks";
import { OctopusServerNodeSummaryResource } from "~/client/resources";
import type { LetsEncryptConfigurationResource, OctopusServerNodeResource, ServerConfigurationResource, TaskResource, LicenseStatusResource } from "~/client/resources";
import Permission from "~/client/resources/permission";
import { repository } from "~/clientInstance";
import { NavigationButton } from "~/components/Button/NavigationButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import UseLabelStrategy from "~/components/LabelStrategy/LabelStrategy";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import SimpleDataTable from "~/components/SimpleDataTable/SimpleDataTable";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";
import { SelectInternal } from "~/primitiveComponents/form/Select/Select";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter";
import styles from "./style.module.less";

const SelectWithoutPrefix = UseLabelStrategy(SelectInternal, (fieldName) => fieldName);

export enum NodesFilterState {
    RecentlyActive = "RecentlyActive",
    Running = "Running",
    Draining = "Draining",
    Drained = "Drained",
    Offline = "Offline",
}

interface NodesState extends DataBaseComponentState {
    nodes?: OctopusServerNodeSummaryResource[];
    filteredNodes?: OctopusServerNodeSummaryResource[];
    serverConfiguration?: ServerConfigurationResource;
    letsEncryptConfiguration?: LetsEncryptConfigurationResource;
    latestLetsEncryptTasks?: Array<TaskResource<{}>>;
    hasLoadedOnce?: boolean;
    filter: NodesFilterState;
    licenseStatus?: LicenseStatusResource;
}

class NodeTable extends SimpleDataTable<OctopusServerNodeSummaryResource> {}

function filterNodes(nodes: OctopusServerNodeSummaryResource[], filter: NodesFilterState, cutoffDate: moment.Moment): OctopusServerNodeSummaryResource[] {
    return nodes.filter((node) => {
        switch (filter) {
            case NodesFilterState.RecentlyActive:
                //Tuesday, September 4, 2018 12:05:12 PM
                const lastSeen: moment.Moment = moment(node.LastSeen);
                return !node.IsOffline || lastSeen > cutoffDate;
            case NodesFilterState.Running:
                return !node.IsOffline && !node.IsInMaintenanceMode;
            case NodesFilterState.Draining:
                return !node.IsOffline && node.IsInMaintenanceMode && node.RunningTaskCount;
            case NodesFilterState.Drained:
                return !node.IsOffline && node.IsInMaintenanceMode && !node.RunningTaskCount;
            case NodesFilterState.Offline:
                return node.IsOffline;
            default:
                return true;
        }
    });
}

export class Nodes extends DataBaseComponent<{}, NodesState> {
    constructor(props: {}) {
        super(props);
        this.state = { filter: NodesFilterState.RecentlyActive };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.refresh(), 5000);
        });
    }

    async refresh() {
        const getServerConfiguration = repository.ServerConfiguration.get();
        const licenseStatus = await repository.Licenses.getCurrentStatus();
        const nodes = (await repository.OctopusServerNodes.summary()).Nodes;
        const filteredNodes = filterNodes(nodes, this.state.filter, moment().add(-1, "hours"));
        return {
            nodes,
            filteredNodes,
            serverConfiguration: await getServerConfiguration,
            hasLoadedOnce: true,
            licenseStatus,
        };
    }

    save(node: OctopusServerNodeResource) {
        return this.doBusyTask(() => repository.OctopusServerNodes.modify(node));
    }

    render() {
        return withTheme((theme) => {
            const configurationSettingsButton = (
                <PermissionCheck permission={Permission.AdministerSystem}>
                    <NavigationButton label="Server Settings" href={routeLinks.configuration.nodes.serverSettings} />
                </PermissionCheck>
            );

            const onRow = (node: OctopusServerNodeSummaryResource) => this.onRow(node, theme);
            const table = this.state.nodes && this.state.filteredNodes && <NodeTable data={this.state.filteredNodes} headerColumns={["Name", "Status", "Last Seen", "Task Cap", "Running Tasks", null]} onRow={onRow} />;

            const sidebar = this.state.serverConfiguration && (
                <div>
                    <h4>Server Uri</h4>
                    <div className={styles.serverUri}>{this.state.serverConfiguration.ServerUri || "Not Set"}</div>
                    <OpenDialogButton label="Change">
                        <EditServerConfiguration onSaveDone={(serverConfiguration) => this.setState({ serverConfiguration })} />
                    </OpenDialogButton>
                </div>
            );

            const stateFilter = this.state.nodes && this.state.nodes.length > 1 && (
                <Section className={styles.filterBoxStandardWidth}>
                    <SelectWithoutPrefix
                        value={this.state.filter}
                        onChange={(state) => this.search(state)}
                        items={Object.keys(NodesFilterState).map((value) => ({ value, text: value.split(/(?=[A-Z])/).join(" ") }))}
                        allowClear={true}
                        fieldName="Filter by"
                        placeholder="All nodes"
                    />
                </Section>
            );

            const clusterTaskLimitMessage = this.state.licenseStatus && this.state.licenseStatus.IsClusterTaskLimitControlledByLicense && (
                <div>
                    <Callout title="Note" type={CalloutType.Information}>
                        The task cap for your Octopus Server cluster is controlled by your license. This means the maximum number of concurrent tasks you can run across the entire cluster will be {this.state.licenseStatus.EffectiveClusterTaskLimit}.
                    </Callout>
                </div>
            );

            return (
                <PaperLayout title="Nodes" sectionControl={configurationSettingsButton} busy={this.state.busy} enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce} errors={this.errors}>
                    {stateFilter}
                    {clusterTaskLimitMessage}
                    <SidebarLayout sideBar={sidebar}>{table}</SidebarLayout>
                </PaperLayout>
            );
        });
    }

    search(filter: string | undefined) {
        this.setState({ filter: filter as NodesFilterState }, async () => this.doRefresh());
    }

    private onRow = (node: OctopusServerNodeSummaryResource, theme: OctopusTheme) => {
        const status = node.IsOffline ? (
            <ToolTip key="offline" content="This Octopus Server node is offline">
                <span style={{ color: theme.danger }}>Offline</span>
            </ToolTip>
        ) : node.IsInMaintenanceMode ? (
            <ToolTip key="drain" content="New tasks are prevented from executing on this node">
                <span style={{ color: theme.alert }}>{node.RunningTaskCount ? "Draining" : "Drained"}</span>
            </ToolTip>
        ) : (
            <span style={{ color: theme.success }}>Running</span>
        );

        const nodeTaskCap = this.state.licenseStatus!.IsNodeTaskLimitControlledByLicense ? (
            <ToolTip
                key="nodeTaskCap"
                content={"The task cap for your Octopus Server node is controlled by your license. This means the maximum number of concurrent tasks you can run on this node will be " + this.state.licenseStatus!.EffectiveNodeTaskLimit + "."}
            >
                <span style={{ color: theme.alert }}>{node.MaxConcurrentTasks}</span>
            </ToolTip>
        ) : (
            <span>{node.MaxConcurrentTasks}</span>
        );

        const lastSeen = node.LastSeen ? (
            node.IsOffline ? (
                <span style={{ color: theme.danger }}>{DateFormatter.dateToLongFormatWithSeconds(node.LastSeen)}</span>
            ) : (
                DateFormatter.dateToLongFormatWithSeconds(node.LastSeen)
            )
        ) : (
            <span style={{ color: theme.danger }}>Never</span>
        );

        const overflowMenu = <OverflowMenu menuItems={this.getOverflowMenuItems(node)} />;

        return [
            node.Name,
            status,
            lastSeen,
            nodeTaskCap,
            <InternalLink key={node.Id} to={routeLinks.tasks.filtered({ node: node.Id, state: TaskFilterState.Running, spaces: [], includeSystem: true })}>
                {this.getTaskRunningText(node.RunningTaskCount)}
            </InternalLink>,
            overflowMenu,
        ];
    };

    private getOverflowMenuItems(node: OctopusServerNodeSummaryResource) {
        const changeTaskCap = OverflowMenuItems.dialogItem("Change Task Cap", <ChangeTaskCap nodeId={node.Id} onSaveDone={this.doRefresh} />);

        const maintMode = OverflowMenuItems.item(node.IsInMaintenanceMode ? "Disable Node Drain" : "Drain Node", () => this.setIsInMaintenanceMode(node, !node.IsInMaintenanceMode));

        const deleteItem = this.state.nodes!.length > 1 && OverflowMenuItems.deleteItemDefault("node", () => this.deleteNode(node));

        const auditTrail = OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingNode(node), {
            permission: Permission.EventView,
            wildcard: true,
        });

        return compact([changeTaskCap, maintMode, deleteItem, [auditTrail]]);
    }

    private getTaskRunningText(n: number) {
        switch (n) {
            case 0:
                return "No running tasks";
            case 1:
                return "1 running task";
            default:
                return n + " running tasks";
        }
    }

    private async setIsInMaintenanceMode(node: OctopusServerNodeSummaryResource, value: boolean) {
        await this.doBusyTask(async () => {
            const freshNode = await repository.OctopusServerNodes.get(node.Id);
            freshNode.IsInMaintenanceMode = value;
            await repository.OctopusServerNodes.modify(freshNode);
        });
        await this.doRefresh();
    }

    private async deleteNode(node: OctopusServerNodeSummaryResource) {
        await repository.OctopusServerNodes.del(node);
        await this.doRefresh();
        return true;
    }

    private doRefresh: Refresh = () => Promise.resolve();
}

export default Nodes;
export { OctopusServerNodeSummaryResource, filterNodes };
